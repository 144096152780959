import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Nav, Button, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import BlockUi from 'react-block-ui';
import './NavMenu.css';
import { authContext } from '../Utils/AdalConfig';
import { SmallScreenUser } from './Base/CommonUIComponents'
import { getAPI, postAPI } from './Base/API';
import LanguageSwitch from './Profile/LanguageSwitch';
import Authorization, { CheckAuthorization } from './Base/Authorization';
import CustomSelect from './Base/CustomSelect';
import reviewPro from '../img/LogoReviewPro.png';
import profileNow from '../img/profileNow.png';
import typeform from '../img/Typeform.ico';
import googleBusiness from '../img/GoogleBusiness.ico';
import ChatGPT from '../img/chatgpt.png';
import GoogleAnalytics from '../img/GoogleAnalytics.png';

import logo from '../img/ProfileNow_logo.png';
import 'moment/locale/es-us';
import 'moment/locale/pt';
import { AdminIcon } from './AdminIcon';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor (props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.changeDefaultProperty = this.changeDefaultProperty.bind(this);
        this.logout = this.logout.bind(this);
        this.state = {
            collapsed: true,
            block: false,
            user: this.props.user,
            property: this.props.property,
            hotels: [],
            isMobile: false,
            personalArea: false,
        };
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        
        this.getHotels();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    handleResize = () => {
        const screenWidth = document.documentElement.clientWidth || document.body.clientWidth;
        global.isMobile = screenWidth <= 991;

        this.setState({ isMobile: global.isMobile });
    }

    togglePersonalArea = () => {
        this.setState({
            personalArea: !this.state.personalArea,
            collapsed: true
        });
    }

    getHotels = () => {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var list = [];
                if (data.response && data.response.length > 0) {
                    data.response.forEach(el => {
                        var property = {
                            value: el.hotelId,
                            label: el.name2
                        }
                        list.push(property);
                    })
                    global.hotelList = list;
                }
                this.setState({ block: false, hotels: list });
            }
        }, '/api/gms/Hotel/v1/hotel/list');
    }

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed,
            personalArea: false
        });
    }

    changeDefaultProperty(evt) {
        let hotelGuid = evt && evt.value ? evt.value : evt.target && evt.target.id;

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ selectedProperty: hotelGuid });
                window.location.reload();
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/User/v1/User/${hotelGuid}/setPreferedHotel`);
    }

    logout() {
        
        //window.localStorage.clear();
        //window.location.href = ''
        var fra = document.getElementById("logouiframe");
        fra.onload = () => {
            authContext.logOut();
        }
        fra.src = "https://login.microsoftonline.com/logout.srf";

    }

    handleHotelFilter = (evt) => {
        const { name, value } = evt.target;

        var list = [...this.state.hotels];

        if (value !== '') {
            for (var i = 0; i < list.length; i++) {
                if (list[i].label && list[i].label.toLowerCase().includes(value.toLowerCase())) {
                    list[i].visible = true;
                }
                else {
                    list[i].visible = false;
                }
            }
        }
        else {
            for (var i = 0; i < list.length; i++) { list[i].visible = true; }
        }

        this.setState({
            user: {
                ...this.state.user,
                userAllowedProperties: list
            }
        })
    }

    render() {
        const { collapsed, block, user, isMobile, personalArea } = this.state;

        const selProp = user && user.userAllowedProperties && user.userAllowedProperties.find(prop => prop.property === user.defaultProperty)


        return (
            <Container className={`bg-white ${isMobile ? 'px-0' : ''}`}>
                <iframe title="logoutiframe" id="logouiframe" style={{ display: "none" }}></iframe>
                <Navbar expand={'lg'} className={`navbar-expand-lg navbar-toggleable-lg bg-white px-0 px-lg-2 sticky-top max_navbar_height mb-2`} style={{ fontWeight: '600' }}>
                    <NavbarBrand className={`m-0 ${isMobile ? 'pl-2' : ''}`} tag={Link} to="/"><img src={logo} width="110px" alt="" /></NavbarBrand>
                    {isMobile ? 
                        <div className='d-flex align-items-center pr-2'>
                            <Button onClick={this.toggleNavbar} className="text-dark mx-2 py-0" style={{'border': 'none', 'backgroundColor': 'white'}}>
                                <i className="fas fa-bars"></i>
                            </Button>
                            <div>
                                <SmallScreenUser
                                    func={this.togglePersonalArea}
                                />
                            </div>
                        </div>
                    :''}

                    <Collapse className={`d-lg-inline-flex flex-lg-row-reverse ${isMobile ? 'bg-white p-3' : ''}`} isOpen={!collapsed} navbar>
                        {!isMobile ?
                            <ul className="navbar-nav ml-auto">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret className="text-secondary">
                                        {authContext.getCachedUser().userName}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <BlockUi tag="div" blocking={block}>
                                            <UncontrolledDropdown direction="left">
                                                <DropdownToggle className="dropdown-item" caret>
                                                    <FormattedMessage id="NavMenu.Property"/>
                                                </DropdownToggle>
                                                
                                                <Authorization
                                                    perform="allProperties:changeProperty"
                                                    yes={() => (
                                                        <DropdownMenu style={{ width: '355px', transform: 'translate3d(-357px, -4px, 0px)', fontWeight: '400' }} className='border-sm-0 bg-light'>
                                                            <DropdownItem header>
                                                                <FormattedMessage id="NavMenu.ChangeProperty" />
                                                            </DropdownItem>
                                                            <div className="mx-3 shadow">
                                                                <CustomSelect
                                                                    isSearchable
                                                                    placeholder={""}
                                                                    options={this.state.hotels}
                                                                    onChange={(combo) => this.changeDefaultProperty(combo)}
                                                                />
                                                            </div>
                                                        </DropdownMenu>
                                                    )}
                                                    no={() =>
                                                        <DropdownMenu className='border-sm-0 bg-light' style={{ width: '355px', transform: 'translate3d(-357px, -4px, 0px)' }}>
                                                            <DropdownItem header>
                                                                <FormattedMessage id="NavMenu.ChangeProperty" />
                                                                {user ? <Input className="mt-2 shadow" type="text" name="searchHotel" onChange={(e) => this.handleHotelFilter(e)} placeholder="Hotel" /> : ''}
                                                            </DropdownItem>
                                                            {global.userAllowedProperties?.map((item, key) =>
                                                                <DropdownItem
                                                                    key={key}
                                                                    active={user && item.propertyGuid === user.defaultProperty ? true : false}
                                                                    id={item.propertyGuid}
                                                                    onClick={() => this.changeDefaultProperty({ value: item.propertyGuid })}
                                                                >
                                                                    {item.propertyName}
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    }
                                                />
                                            </UncontrolledDropdown>
                                        </BlockUi>
                                        <LanguageSwitch />
                                        <DropdownItem tag={Link} to="/Profile">
                                            <FormattedMessage id="NavMenu.Profile"/>
                                        </DropdownItem>

                                            <Authorization
                                                perform="help&support:view"
                                                yes={() => (
                                                    <>
                                                        <DropdownItem tag="a" href="https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2130870273/Profile+Now+Onboarding" target="_blank">
                                                            <span > <i className="fas fa-external-link-alt mr-2"></i><FormattedMessage id="NavMenu.Help" /> </span>
                                                        </DropdownItem>
                                                        <DropdownItem tag="a" href={process.env.REACT_APP_SUPPORT_URL} target="_blank">
                                                            <span > <i className="fas fa-headset mr-2" /><FormattedMessage id="NavMenu.Support" /> </span>
                                                        </DropdownItem>
                                                    </>
                                                )}
                                                no={() => <div />}
                                            />

                                            <DropdownItem divider />
                                            <DropdownItem onClick={this.logout}>
                                                <FormattedMessage id="NavMenu.Logout" />
                                            </DropdownItem>
                                        </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className="font_size_xxxs text-secondary position-absolute" style={{ bottom: '1px' }}>
                                    {user && user.hotelName}
                                </div>
                            </ul>
                        :''}
                        {!isMobile ?
                        <ul className="navbar-nav mx-auto">
                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.Admin" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu className='border-sm-0' style={{ width: 'max-content' }}>
                                            <Nav className='d-block'>
                                                <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                    <DropdownItem>
                                                        <i className="icon-icon-hotel mr-1" />
                                                        <FormattedMessage id="NavMenu.Hotel" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                    </DropdownItem>
                                                    <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                        <DropdownItem tag={Link} to="/HotelGroup">
                                                            <i className="icon-icon-hotel mr-1" />
                                                            <FormattedMessage id="NavMenu.HotelGroup" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/HotelList">
                                                            <i className="icon-icon-hotel mr-1" />
                                                            <FormattedMessage id="NavMenu.HotelList" />
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                                <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                    <DropdownItem>
                                                        <i className="icon-icon-e-goi-config mr-1" />
                                                        <FormattedMessage id="NavMenu.Config" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                    </DropdownItem>
                                                    <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                        <DropdownItem tag={Link} to="/TagsList">
                                                            <i className="fas fa-tag mr-1" />
                                                            <FormattedMessage id="NavMenu.TagsList" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/TagGroupsList">
                                                            <i className="fas fa-tags mr-1" />
                                                            <FormattedMessage id="NavMenu.TagGroupsList" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/TopicManagement">
                                                            <i className="fas fa-stream mr-1" />
                                                            <FormattedMessage id="NavMenu.DataQualityTopic" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/ConfigPNForms">
                                                            <i className="fas fa-file-signature mr-1" />
                                                            <FormattedMessage id="NavMenu.ProfileNowForms" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/DataRulesActions">
                                                            <i className="fas fa-list-ol mr-1" />
                                                            <FormattedMessage id="NavMenu.DataRulesActions" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/Actions">
                                                            <i className="fas fa-sync mr-1" />
                                                            <FormattedMessage id="NavMenu.Actions" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/MachineLearningConfig">
                                                            <i className="fas fa-brain mr-1" />
                                                            <FormattedMessage id="NavMenu.MachineLearning" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/ConsentsConfig">
                                                            <i className="icon-icon-consents mr-1" />
                                                            <FormattedMessage id="NavMenu.Consents" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/Reports">
                                                            <i className="fas fa-file-contract mr-1" />
                                                            <FormattedMessage id="NavMenu.Reports" />
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                                <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                    <DropdownItem>
                                                        <i className="fas fa-user mr-1" />
                                                        <FormattedMessage id="NavMenu.User" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                    </DropdownItem>
                                                    <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                        <DropdownItem tag={Link} to="/ApiGatewayUserManagement">
                                                            <i className="fas fa-user-cog mr-1" />
                                                            <FormattedMessage id="NavMenu.ApiGatewayUserManagement" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/AdminUserManagement" >
                                                            <i className="fas fa-user mr-2" /><FormattedMessage id="NavMenu.AdminUserManagement" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/AccessList" >
                                                            <i className="fas fa-user-lock mr-1" /><FormattedMessage id="NavMenu.AccessList" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/SendGridAdmin" >
                                                            <i className="fas fa-envelope-open-text mr-1" /><FormattedMessage id="NavMenu.SendGridAdmin" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/TwilioMessagingAdmin">
                                                            <i className="fas fa-comments mr-1" /><FormattedMessage id="NavMenu.TwilioMessagingAdmin" />
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                                <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                    <DropdownItem>
                                                        <i className="fas fa-file-medical mr-1" />
                                                        <FormattedMessage id="NavMenu.HealthCheck" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                    </DropdownItem>
                                                    <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                        <DropdownItem tag={Link} to="/QualityRuns">
                                                            <i className="fas fa-running mr-1" /><FormattedMessage id="NavMenu.QualityRuns" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/TopMergeProfiles">
                                                            <i className="fas fa-people-arrows mr-1" /><FormattedMessage id="NavMenu.TopMergeProfiles" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/SyncEvents">
                                                            <i className="fas fa-sync-alt mr-1" />
                                                            <FormattedMessage id="NavMenu.SyncEvents" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/QueueStatusDashboard">
                                                            <i className="fas fa-briefcase mr-1" />
                                                            <FormattedMessage id="NavMenu.QueueStatusDashboard" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/SystemEvents">
                                                            <i className="fas fa-exclamation-circle mr-1" />
                                                            <FormattedMessage id="NavMenu.SystemEvents" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/PlatformDeepDive">
                                                            <i className="far fa-chart-bar mr-1" /> 
                                                            <FormattedMessage id="NavMenu.PlatformDeepDive" />
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                                <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                    <DropdownItem  >
                                                        <i className="fas fa-wrench mr-1" />
                                                        <FormattedMessage id="NavMenu.Maintenance" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                    </DropdownItem>
                                                    <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                        <DropdownItem tag={Link} to="/DataClean">
                                                            <i className="fas fa-trash mr-1" /><FormattedMessage id="NavMenu.DataClean" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/RunningProcesses">
                                                            <i className="fas fa-database mr-1" /><FormattedMessage id="NavMenu.RunningProcesses" />
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                                <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                    <DropdownItem  >
                                                        <i className="fas fa-tools mr-1" />
                                                        <FormattedMessage id="NavMenu.Actions" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                    </DropdownItem>
                                                    <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                        <DropdownItem tag={Link} to="/MessageDecryption">
                                                            <i className="fas fa-file-import mr-1" />
                                                            <FormattedMessage id="DecryptMessage.DecryptedMessageTitle" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/DeleteProfiles">
                                                            <i className="fas fa-user-times mr-1" />
                                                            <FormattedMessage id="NavMenu.DeleteProfiles" />
                                                        </DropdownItem>
                                                        <DropdownItem tag={Link} to="/ResendReservation">
                                                            <i className="fas fa-share-square"></i>
                                                            <FormattedMessage id="NavMenu.ResendReservation" />
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>

                                                <DropdownItem tag={Link} to="/ScheduleTask">
                                                    <i className="fas fa-list mr-1" />
                                                    <FormattedMessage id="NavMenu.ScheduleTask" />
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to="/ManageRegEx">
                                                    <i className="fas fa-not-equal mr-1" />
                                                    <FormattedMessage id="NavMenu.RegExRules" />
                                                </DropdownItem>

                                                <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                    <DropdownItem>
                                                        <i className="fas fa-heartbeat mr-1" />
                                                        <FormattedMessage id="NavMenu.Monitoring" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                    </DropdownItem>
                                                    <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                        <DropdownItem tag={Link} to="/SyncStatus">
                                                            <i className="fas fa-sync mr-1" />
                                                            <FormattedMessage id="NavMenu.SyncStatus" />
                                                        </DropdownItem>

                                                        <DropdownItem tag={Link} to="/AllDQTemplates">
                                                            <i className="fas fa-file-alt mr-1" />
                                                            <FormattedMessage id="NavMenu.AllDQTemplates" />
                                                        </DropdownItem>

                                                        {global.modules && global.modules.some(m => m === 'Egoi') &&
                                                            <DropdownItem tag={Link} to="/MonitorCampaigns">
                                                                <i className="icon-icon-communication-journey mr-1" />
                                                                <FormattedMessage id="NavMenu.MonitorCampaigns" />
                                                            </DropdownItem>
                                                        }

                                                        <DropdownItem tag={Link} to="/MonitorReservations">
                                                            <i className="icon-icon-reservas mr-1" />
                                                            <FormattedMessage id="NavMenu.MonitorReservations" />
                                                        </DropdownItem>

                                                        <DropdownItem tag={Link} to="/DuplicateRules">
                                                            <i className="fas fa-list mr-1" />
                                                            <FormattedMessage id="NavMenu.DuplicateRules" />
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </Nav>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                            
                                )}
                                no={() => <div/>}
                            />

                            <Authorization
                                perform="dataQualityMenu:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.DataQuality" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu className='border-sm-0'>

                                            <Authorization
                                                perform="dataQualityTemplateDashboard:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/DataQualityTemplateDashboard">
                                                        <i className="fas fa-file-alt  mr-1" />
                                                        <FormattedMessage id="NavMenu.Templates" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="ruleDashboard:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/RuleDashboard">
                                                        <i className="fas fa-list-ol  mr-1" />
                                                        <FormattedMessage id="NavMenu.DataQualityRule" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="taskResultDashboard:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/TaskResultDashboard">
                                                        <i className="fas fa-tasks mr-1"></i>
                                                        <FormattedMessage id="NavMenu.TaskResults" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="dataQualityIssues:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/DataQualityIssues">
                                                        <i className="fas fa-exclamation-triangle mr-1"></i>
                                                        <FormattedMessage id="NavMenu.Issues" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="reportsConfig:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/ReportsConfig">
                                                        <i className="fas fa-file-invoice mr-1"></i>
                                                        <FormattedMessage id="NavMenu.Reports" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />


                                            <Authorization
                                                perform="mostRepeated:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/MostRepeated">
                                                        <i className="fas fa-clone mr-1"></i>
                                                        <FormattedMessage id="NavMenu.MostRepeated" />
                                                        <AdminIcon />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div />}
                            />

                            <Authorization
                                perform="hotelMenu:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav  className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.Hotel" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu className='border-sm-0'>
                                            <DropdownItem tag={Link} to="/HotelList">
                                                <i className="icon-icon-hotel  mr-1" />
                                                <FormattedMessage id="NavMenu.HotelList" />
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/CurrentHotelGroup">
                                                <i className="icon-icon-hotel  mr-1" />
                                                <FormattedMessage id="NavMenu.CurrentHotelGroup" />
                                            </DropdownItem>                                    
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div />}
                            />

                            <Authorization
                                perform="analiticsMenu:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.Analitics" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu className='border-sm-0'>
                                            { /*

                                            <DropdownItem tag={Link} to="/ReservationSummary">
                                                <i className="fas fa-suitcase  mr-1" />
                                                <FormattedMessage id="NavMenu.Reservations" />
                                            </DropdownItem>
                            
                                            <DropdownItem tag={Link} to="/Revenue">
                                                <i className="fas fa-chart-line  mr-1" />
                                                <FormattedMessage id="NavMenu.Revenue" />
                                            </DropdownItem>
                                    */}
                                            <Authorization
                                                perform="qualityMetrics:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/QualityMetrics">
                                                        <i className="fas fa-database  mr-1" />
                                                        <FormattedMessage id="NavMenu.QualityMetrics" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="profileSummary:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/ProfileSummary">
                                                        <i className="fas fa-user-cog  mr-1" />
                                                        <FormattedMessage id="NavMenu.ProfileSummary" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="formsAndReviews:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/FormsAndReviews">
                                                        <i className="fas fa-user-edit mr-1" />
                                                        <FormattedMessage id="NavMenu.FormsAndReviews" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="campaignResults:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/CampaignResults">
                                                        <i className="fas fa-poll mr-1"/>
                                                        <FormattedMessage id="NavMenu.CampaignResults" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            {global.modules && global.modules.some(m => m === 'Egoi') &&
                                                <DropdownItem tag={Link} to="/UnsubscribedStatistics">
                                                    <i className="fas fa-times-circle mr-1" />
                                                    <FormattedMessage id="NavMenu.UnsubscribedStatistics" />
                                                </DropdownItem>
                                            }

                                            {global.modules && global.modules.some(m => m === 'ML_ReservationCancel') &&
                                                <DropdownItem tag={Link} to="/CancelationForecast">
                                                    <i className="fas fa-ban mr-1" />
                                                    <FormattedMessage id="NavMenu.CancelationForecast" />
                                                </DropdownItem>
                                            }

                                            <Authorization
                                                perform="monthlyReportRuns:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/MonthlyReportRuns">
                                                        <i className="fas fa-calendar-day mr-1" />
                                                        <FormattedMessage id="NavMenu.MonthlyReport" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization  
                                                perform="perfil:credit"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/ProfileCreditBalance">
                                                        <i className="far fa-credit-card mr-1"></i>
                                                        <FormattedMessage id="NavMenu.ProfileCredit" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="reservationsByProfile:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/ReservationsByProfile">
                                                        <i className="icon-icon-reservas mr-1"></i>
                                                        <FormattedMessage id="NavMenu.ReservationsByProfile" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                        </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                no={() => <div />}
                            />

                            <Authorization
                                perform="profileMenu:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.Profile" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu className='border-sm-0'>
                                            <Authorization
                                                perform="searchProfile:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/SearchProfile">
                                                        <i className="fas fa-users mr-1" />
                                                        <FormattedMessage id="NavMenu.Search" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <DropdownItem tag={Link} to="/DetailProfileSearch">
                                                <i className="fas fa-user-astronaut mr-1"></i>
                                                <FormattedMessage id="NavMenu.DetailSearch" />
                                            </DropdownItem>
                                    
                                            <Authorization
                                                perform="ProfileMenu:import"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/ImportProfile">
                                                        <i className="fas fa-user-plus mr-1" />
                                                        <FormattedMessage id="NavMenu.ImportProfile" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />
                                    
                                            <Authorization
                                                perform="mergeProfiles:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/ProfileMergeDetail">
                                                        <i className="fas fa-people-arrows mr-1" />
                                                        <FormattedMessage id="NavMenu.Merge" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            { global.modules && global.modules.some(m => m === 'Profile360') ?
                                                <Authorization
                                                    perform="profileAnalysis:view"
                                                    yes={() => (
                                                        <DropdownItem tag={Link} to="/ProfileAnalysis">
                                                            <i className="fas fa-brain mr-1" />
                                                            <FormattedMessage id="NavMenu.analisys" />

                                                            <AdminIcon/>
                                                        </DropdownItem>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                                : <div />}

                                            <Authorization
                                                perform="associateTags:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/AssociateTags">
                                                        <i className="fas fa-tag mr-1" />
                                                        <FormattedMessage id="NavMenu.AssociateTags" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div />}
                                            />

                                            {global.modules && global.modules.some(m => m === 'WhatsApp') ?
                                                <Authorization
                                                    perform="WhatsAppChat:view"
                                                    yes={() => (
                                                        <DropdownItem tag={Link} to="/WhatsAppChat">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <i className="fab fa-whatsapp mr-1" style={{ color: '#25D366' }} />
                                                                <FormattedMessage id="NavMenu.WhatsApp" />
                                                            </div>
                                                        </DropdownItem>
                                                    )}
                                                />
                                            : ''}

                                            <Authorization
                                                perform="searchReservation:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/SearchReservation">
                                                        <i className="icon-icon-reservas mr-1" />
                                                        <FormattedMessage id="NavMenu.SearchReservation" />
                                                    </DropdownItem>
                                                )}
                                            />

                                            <Authorization
                                                perform="deletedProfiles:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/DeletedProfiles">
                                                        <i className="fas fa-user-times mr-1" />
                                                        <FormattedMessage id="NavMenu.DeletedProfiles" />

                                                        <AdminIcon />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div />}
                                            />
                                        </DropdownMenu>                                
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div />}
                            />

                            <Authorization
                                perform="formsMenu:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.Forms" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu className='border-sm-0'>
                                            <DropdownItem tag={Link} to="/SearchForm">
                                                <i className="fas fa-file-signature mr-1"/>
                                                <FormattedMessage id="NavMenu.SearchForm" />
                                                <AdminIcon />
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div />}
                            />

                            <Authorization
                                perform="configMenu:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.Config" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu className='border-sm-0' style={{ width: 'max-content' }}>
                                            <Nav className='d-block'>
                                                <Authorization
                                                    perform="configMenu:entries:view"
                                                    yes={() => (
                                                        <div>
                                                            <DropdownItem tag={Link} to="/TagMapping">
                                                                <i className="fas fa-tag mr-1" />
                                                                <FormattedMessage id="NavMenu.TagMapping" />
                                                            </DropdownItem>

                                                            <DropdownItem tag={Link} to="/ProfileScore">
                                                                <i className="fas fa-medal mr-1" />
                                                                <FormattedMessage id="NavMenu.profileScore" />
                                                            </DropdownItem>

                                                            <DropdownItem tag={Link} to="/ManageUser" >
                                                                <i className="fas fa-user mr-1" />
                                                                <FormattedMessage id="NavMenu.ManageUser" />
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to="/ReferenceData" >
                                                                <i className="fas fa-database mr-1" />
                                                                <FormattedMessage id="NavMenu.ReferenceData" />
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to="/ProfileMatchConfig" >
                                                                <i className="fas fa-cogs mr-1" />
                                                                <FormattedMessage id="NavMenu.ProfileMatchConfig" />
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to="/CustomTags">
                                                                <i className="fas fa-tags mr-1" />
                                                                <FormattedMessage id="NavMenu.CustomTags" />
                                                            </DropdownItem>

                                                            <Authorization
                                                                perform="mappings:view"
                                                                yes={() => ( global.modules && global.modules.some(m => m === 'Egoi') &&
                                                                    <DropdownItem tag={Link} to="/Mappings">
                                                                        <i className="fas fa-random mr-1" />
                                                                        <FormattedMessage id="NavMenu.Mappings" />

                                                                        <AdminIcon />
                                                                    </DropdownItem>                                                                    
                                                                )}
                                                                no={() => <div></div>}
                                                            />

                                                            <Authorization
                                                                perform="profileDataQuality:view"
                                                                yes={() => (
                                                                    <DropdownItem tag={Link} to="/ConfigProfileDataQuality">
                                                                        <i className="fas fa-user-cog mr-1" />
                                                                        <FormattedMessage id="NavMenu.ConfigProfileDataQuality" />
                                                                    </DropdownItem>
                                                                )}
                                                                no={() => <div></div>}
                                                            />

                                                            <Authorization
                                                                perform="retentionPolicyRule:view"
                                                                yes={() => (
                                                                    <DropdownItem tag={Link} to="/RetentionPolicyRule">
                                                                        <i className="fas fa-cog mr-1" />
                                                                        <FormattedMessage id="NavMenu.RetentionPolicyRule" />
                                                                        <AdminIcon />
                                                                    </DropdownItem>
                                                                )}
                                                                no={() => <div></div>}
                                                            />

                                                            <Authorization
                                                                perform="customData:config"
                                                                yes={() => (
                                                                    <DropdownItem tag={Link} to="/CustomFields">
                                                                        <i className="icon-icon-custom-fields mr-1" />
                                                                        <FormattedMessage id="NavMenu.CustomFields" />
                                                                    </DropdownItem>
                                                                )}
                                                                no={() => <div></div>}
                                                            />
                                                        </div>
                                                    )}
                                                    no={() => <div></div>}
                                                />



                                                <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                    <DropdownItem>
                                                        <i className="icon-icon-member-card mr-1" />
                                                        <FormattedMessage id="NavMenu.LoyaltyCard" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                    </DropdownItem>
                                                    <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                        <DropdownItem tag={Link} to="/LoyaltyCardRules" >
                                                            <i className="fas fa-id-card-alt mr-1" />
                                                            <FormattedMessage id="NavMenu.LoyaltyCardRules" />
                                                        </DropdownItem>

                                                    <Authorization
                                                        perform="loyaltyCardAttribution:view"
                                                        yes={() => (
                                                            global.modules && global.modules.some(m => m === 'Loyalty' || m === 'LoyaltyProgram') &&
                                                            <DropdownItem tag={Link} to="/LoyaltyCardAttribution" >
                                                                <i className="icon-icon-member-card mr-1" />
                                                                <FormattedMessage id="NavMenu.LoyaltyCardAttribution" />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div></div>}
                                                    />

                                                    <Authorization
                                                        perform="loyaltyCardLevels:view"
                                                        yes={() => (
                                                            global.modules && global.modules.some(m => m === 'Loyalty' || m === 'LoyaltyProgram') &&
                                                            <DropdownItem tag={Link} to="/LoyaltyCardLevels" >
                                                                <i className="fas fa-layer-group mr-1" />
                                                                <FormattedMessage id="NavMenu.LoyaltyCardLevels" />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div></div>}
                                                    />

                                                    <Authorization
                                                        perform="configCardPoints:view"
                                                        yes={() => (
                                                            global.modules && global.modules.some(m => m === 'LoyaltyProgram') &&
                                                            <DropdownItem tag={Link} to="/ConfigCardPoints" >
                                                                <i className="icon-icon-e-goi-config mr-1" />
                                                                <FormattedMessage id="NavMenu.ConfigCardPoints" />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                    <Authorization
                                                        perform="loyaltyCardLevels:view"
                                                        yes={() => (
                                                            global.modules && global.modules.some(m => m === 'OneCardByType') &&
                                                            <DropdownItem tag={Link} to="/LoyaltyCardTypes" >
                                                                <i className="icon-icon-member-card mr-1" />
                                                                <FormattedMessage id="NavMenu.LoyaltyCardTypes" />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                </DropdownMenu>
                                                </UncontrolledDropdown>

                                                {global.modules && global.modules.some(m => m === 'ReviewPro' || m === 'ProfileNowForms' || m === 'Typeform') &&
                                                (CheckAuthorization("reviewPro:view") || CheckAuthorization("PNForm:map") || CheckAuthorization("TypeForm:view")) ?
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                        <DropdownItem>
                                                            <i className="fas fa-pen-fancy mr-1" />
                                                            <FormattedMessage id="NavMenu.Review/Inquiry" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                        </DropdownItem>
                                                        <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                            {global.modules && global.modules.some(m => m === 'ReviewPro') && CheckAuthorization("reviewPro:view") ?
                                                                <DropdownItem tag={Link} to="/reviewProMenu">
                                                                    <img alt="" src={reviewPro} height="17px" className="mr-1" />
                                                                    <FormattedMessage id="NavMenu.reviewProConfig" />
                                                                </DropdownItem>
                                                            : ""}

                                                            {global.modules && global.modules.some(m => m === 'ProfileNowForms') && CheckAuthorization("PNForm:map") ?
                                                                <DropdownItem tag={Link} to="/ProfileNowConfig">
                                                                    <img alt="" src={profileNow} height="18px" className="mr-2" />
                                                                    <FormattedMessage id="NavMenu.ProfileNowForms" />
                                                                </DropdownItem>
                                                            : ''}
                                                            {global.modules && global.modules.some(m => m === 'Typeform') && CheckAuthorization("TypeForm:view") ?
                                                                <DropdownItem tag={Link} to="/Typeform">
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <img alt="" src={typeform} height="18px" className="mr-2" />
                                                                        <FormattedMessage id="NavMenu.TypeForm" />
                                                                        <AdminIcon />
                                                                    </div>
                                                                </DropdownItem>
                                                            : ''}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                : ''}
                                                
                                                {global.modules && global.modules.some(m => m === 'GoogleBusiness') ?
                                                    <Authorization
                                                        perform="googleBusiness:view"
                                                        yes={() => (
                                                            <DropdownItem tag={Link} to="/GoogleBusinessConfig">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img alt="" src={googleBusiness} height="18px" className="mr-2" />
                                                                    <FormattedMessage id="NavMenu.GoogleBusiness" />
                                                                </div>
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div />}
                                                    />
                                                : ''}
                                                
                                                {global.modules && global.modules.some(m => m === 'ChatGPT') ?
                                                    <Authorization
                                                        perform="GPT:config"
                                                        yes={() => (
                                                            <DropdownItem tag={Link} to="/AIConfig">
                                                                <img alt="Prompt Config" className='mr-2' width='18' height='18' src={ChatGPT}/>
                                                                <FormattedMessage id="NavMenu.AIConfig" />
                                                                <AdminIcon />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                    : ''}

                                                {global.modules && global.modules.some(m => m === 'SendGrid') ?
                                                    <Authorization
                                                        perform="admin:view"
                                                        yes={() => (
                                                            <DropdownItem tag={Link} to="/GoogleAnalyticsAccounts">
                                                                <img alt="Google Analytics" className='mr-2' width='18' height='18' src={GoogleAnalytics} />
                                                                <FormattedMessage id="NavMenu.GoogleAnalyticsAccounts" />
                                                                <AdminIcon />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                : ''}
                                                


                                                {global.modules && global.modules.some(m => m === 'SalesProcess') ?
                                                    <Authorization
                                                        perform="salesProcess:view"
                                                        yes={() => (
                                                            <DropdownItem tag={Link} to="/SalesProcess">
                                                                <i className="fas fa-search-dollar mr-1" />
                                                                <FormattedMessage id="NavMenu.SalesProcess" />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                : ''}

                                                {global.modules && global.modules.some(m => m === 'PriceListRules') ?
                                                    <Authorization
                                                        perform="salesProcess:view"
                                                        yes={() => (
                                                            <DropdownItem tag={Link} to="/PriceListRules">
                                                                <i className="fas fa-dollar-sign mr-1" />
                                                                <FormattedMessage id="NavMenu.PriceListRules" />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                : ''}
                                            </Nav>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div></div>}
                            />

                            {global.modules && global.modules.some(m => m === 'Egoi' || m === 'SendGrid') ?
                                <Authorization
                                    perform="marketingAutomationMenu:view"
                                    yes={() => (
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav className={`text-muted mx-2 mx-lg-1`}>
                                                <FormattedMessage id="NavMenu.MarketingAutomation" /> <i className="fas fa-caret-down pt-1" />
                                            </DropdownToggle>
                                            <DropdownMenu className='border-sm-0'>
                                                <DropdownItem tag={Link} to="/CommunicationJourney">
                                                    <i className="icon-icon-communication-journey mr-1" />
                                                    <FormattedMessage id="NavMenu.CommunicationJourney" />
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to="/CampaignTargets">
                                                    <i className="fas fa-hat-wizard mr-1" />
                                                    <FormattedMessage id="NavMenu.CampaignTargets" />
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to="/Campaigns">
                                                    <i className="icon-icon-recurring-campaign mr-1" />
                                                    <FormattedMessage id="NavMenu.campaigns" />
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to="/CustomCampaign">
                                                    <i className="icon-icon-unique-campaign mr-1" />
                                                    <FormattedMessage id="NavMenu.customsCampaign" />
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to="/SendDetails">
                                                    <i className="fas fa-paper-plane mr-1" />
                                                    <FormattedMessage id="NavMenu.SendDetails" />
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to="/MarketingAlerts">
                                                    <i className="icon-icon-alerts mr-1" />
                                                    <FormattedMessage id="NavMenu.Alerts" />
                                                </DropdownItem>
                                                {global.modules && global.modules.some(m => m === 'Journeys') ?
                                                    <Authorization
                                                        perform="admin:view"
                                                        yes={() => (
                                                            <DropdownItem tag={Link} to="/CustomerJourney">
                                                                <i className="fas fa-project-diagram mr-1"></i>
                                                                <FormattedMessage id="NavMenu.CustomerJourney" />

                                                                <AdminIcon />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div />}
                                                    />
                                                :''}


                                                {global.modules && global.modules.some(m => m === 'Egoi') ?
                                                    <>
                                                <Authorization
                                                    perform="exportProfiles:view"
                                                    yes={() => (
                                                        <DropdownItem tag={Link} to="/ExportProfiles">
                                                            <i className="icon-icon-export-profiles mr-1" />
                                                            <FormattedMessage id="NavMenu.ExportProfiles" />

                                                            <AdminIcon />
                                                        </DropdownItem>
                                                    )}
                                                    no={() => <div />}
                                                />

                                                <DropdownItem tag={Link} to="/MarketingConfigEgoi">
                                                    <i className="icon-icon-e-goi-config mr-1" />
                                                    <FormattedMessage id="NavMenu.ConfigEgoi" />
                                                </DropdownItem>

                                                <DropdownItem tag={Link} to="/StatsEgoi">
                                                    <i className="icon-icon-Satistics-menu mr-1" />
                                                    <FormattedMessage id="NavMenu.StatsEgoi" />
                                                </DropdownItem>
                                                    </>
                                                : ''}

                                                {global.modules && global.modules.some(m => m === 'StripoSharedAccount' || m === 'StripoOwnAccount') ?
                                                    <Authorization
                                                        perform="stripo:edit"
                                                        yes={() => (
                                                            <DropdownItem tag={Link} to="/EmailTemplate">
                                                                <i className="fas fa-envelope mr-1" />
                                                                <FormattedMessage id="NavMenu.EmailTemplate" />
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div/>}
                                                    />
                                                :''}
                                                {global.modules && global.modules.some(m => m === 'WhatsApp') ?
                                                    <Authorization
                                                        perform="WhatsApp:view"
                                                        yes={() => (
                                                            <DropdownItem tag={Link} to="/WhatsAppConfig">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <i className="fab fa-whatsapp mr-1" style={{ color: '#25D366' }}/>
                                                                    <FormattedMessage id="NavMenu.WhatsAppConfig" />
                                                                </div>
                                                            </DropdownItem>
                                                        )}
                                                        no={() => <div/>}
                                                    />
                                                    : ''}

                                                {global.modules && global.modules.some(m => m === 'SendGrid') ?
                                                    <DropdownItem tag={Link} to="/MailConfig">
                                                        <i className="fas fa-envelope-open-text mr-1" />
                                                        <FormattedMessage id="NavMenu.MailConfig" />
                                                    </DropdownItem>
                                                : ''}

                                                <Authorization
                                                    perform="admin:view"
                                                    yes={() => (
                                                        global.modules && global.modules.some(m => m === 'TwilioMessaging') ?
                                                            <DropdownItem tag={Link} to="/MessageConfig">
                                                                <i className="fas fa-comment-alt mr-1"/>
                                                                <FormattedMessage id="NavMenu.MessageConfig" />
                                                                <AdminIcon />
                                                            </DropdownItem>
                                                        :''
                                                    )}
                                                    no={() => <div />}
                                                />
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                    no={() => <div></div>}
                                />
                            : ''}

                            <Authorization
                                perform="integrations:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.Integrations" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu className='border-sm-0'>
                                            <Nav className='d-block'>
                                                <DropdownItem tag={Link} to="/BatchLogs">
                                                    <i className="fas fa-plug mr-1" />
                                                    <FormattedMessage id="NavMenu.BatchLogs" />
                                                </DropdownItem>

                                                <Authorization
                                                    perform="integrations:ApiLogs"
                                                    yes={() => (global.modules && global.modules.some(m => m === 'ProfileNowExternalApis') &&
                                                        <DropdownItem tag={Link} to="/ApiLogs">
                                                            <i className="fas fa-history mr-1" />
                                                            <FormattedMessage id="NavMenu.ApiLogs" />
                                                        </DropdownItem>
                                                        )}
                                                    no={() => <div />}
                                                />

                                                {global.modules && global.modules.some(m => m === 'HiJiffy') ?
                                                    <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                        <DropdownItem>
                                                            <i className="icon-icon-chat mr-1" />
                                                            <FormattedMessage id="NavMenu.Hijiffy" /> <i className="float-right fas fa-caret-right pt-1" />
                                                        </DropdownItem>
                                                        <DropdownMenu className='border-sm-0 pl-2 pl-lg-0'>
                                                            <DropdownItem tag={Link} to="/HijiffyInbox">
                                                                <i className="fas fa-inbox mr-1" />
                                                                <FormattedMessage id="Hijiffy.Inbox" />
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to="/HijiffyContacts">
                                                                <i className="fas fa-address-book mr-1" />
                                                                <FormattedMessage id="Hijiffy.Contacts" />
                                                            </DropdownItem>
                                                            <DropdownItem tag={Link} to="/HijiffyProfile">
                                                                <i className="fas fa-user mr-1" />
                                                                <FormattedMessage id="Hijiffy.Profile" />
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    : ''}


                                                <Authorization
                                                    perform="admin:view"
                                                    yes={() => (
                                                        <DropdownItem tag={Link} to="/ProcessIssues">
                                                            <i className="fas fa-bug mr-1" />
                                                            <FormattedMessage id="NavMenu.ProcessIssues" />
                                                            <AdminIcon/>
                                                        </DropdownItem>
                                                    )}
                                                    no={() => <div />}
                                                />
                                            </Nav>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div></div>}
                            />

                            {/* <Authorization
                                perform="salesAutomation:view"
                                yes={() => (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav className="text-muted mx-2">
                                            <FormattedMessage id="NavMenu.SalesAutomation" /> <i className="fas fa-caret-down pt-1" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem tag={Link} to="/Pipelines">
                                                <i className="fas fa-rocket mr-1" />
                                                <FormattedMessage id="NavMenu.Config" />
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )}
                                no={() => <div></div>}
                            />*/}

                            { /*
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="text-secondary">
                                    <FormattedMessage id="NavMenu.Reservations" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to="/ReservationList">
                                        <i className="fas fa-suitcase mr-1" />
                                        <FormattedMessage id="NavMenu.ReservationList" />
                                    </DropdownItem>
                                </DropdownMenu>

                            </UncontrolledDropdown>
                            */}

                            {global.modules && global.modules.some(m => m === 'HeyCard') ?
                                <Authorization
                                    perform="heyCard:view"
                                    yes={() => (
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav className={`text-muted mx-2 mx-lg-1`}>
                                                <FormattedMessage id="NavMenu.Hey!Card" /> <i className="fas fa-caret-down pt-1" />
                                            </DropdownToggle>
                                            <DropdownMenu className='border-sm-0'>

                                                <DropdownItem tag={Link} to="/AvailableCards">
                                                    <i className="fas fa-gift mr-1" />
                                                    <FormattedMessage id="NavMenu.AvailableCards" />
                                                </DropdownItem>
                                                <DropdownItem tag={Link} to="/SearchCards">
                                                    <i className="fas fa-search mr-1" />
                                                    <FormattedMessage id="NavMenu.SearchCards" />
                                                </DropdownItem>

                                                {global.modules.some(m => m === 'ProfileNowExternalApis') &&
                                                    <DropdownItem tag={Link} to="/BulkVouchersCreation">
                                                        <i className="icon-icon-vouchers mr-1" />
                                                        <FormattedMessage id="NavMenu.BulkVouchersCreation" />
                                                    </DropdownItem>
                                                }
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                    no={() => <div></div>}
                                />
                            : ''}
                        </ul>
                        :
                            <ul className="navbar-nav mx-auto">
                                <UncontrolledDropdown nav inNavbar>
                                    <Nav className='d-block'>
                                        <Authorization
                                            perform="admin:view"
                                            yes={() => (
                                                <UncontrolledDropdown inNavbar>
                                                    <DropdownToggle tag={NavLink} className='dropdown-item' style={{ padding: '0.25rem 1.5rem', fontWeight: 'bold' }}>
                                                        <i className="fas fa-user-shield mr-1" />
                                                        <FormattedMessage id="NavMenu.Admin" /> 
                                                        <i className="fas fa-caret-down ml-1" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='border-0 pl-4' style={{ width: 'max-content' }}>
                                                        <Nav className='d-block'>
                                                            <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                                <DropdownItem>
                                                                    <i className="icon-icon-hotel mr-1" />
                                                                    <FormattedMessage id="NavMenu.Hotel" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                                </DropdownItem>
                                                                <DropdownMenu className='border-0 pl-3 pl-lg-0'>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/HotelGroup">
                                                                        <i className="icon-icon-hotel mr-1" />
                                                                        <FormattedMessage id="NavMenu.HotelGroup" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/HotelList">
                                                                        <i className="icon-icon-hotel mr-1" />
                                                                        <FormattedMessage id="NavMenu.HotelList" />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                            <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                                <DropdownItem>
                                                                    <i className="icon-icon-e-goi-config mr-1" />
                                                                    <FormattedMessage id="NavMenu.Config" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                                </DropdownItem>
                                                                <DropdownMenu className='border-0 pl-3 pl-lg-0'>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/TagsList">
                                                                        <i className="fas fa-tag mr-1" />
                                                                        <FormattedMessage id="NavMenu.TagsList" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/TagGroupsList">
                                                                        <i className="fas fa-tags mr-1" />
                                                                        <FormattedMessage id="NavMenu.TagGroupsList" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/TopicManagement">
                                                                        <i className="fas fa-stream mr-1" />
                                                                        <FormattedMessage id="NavMenu.DataQualityTopic" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/ConfigPNForms">
                                                                        <i className="fas fa-file-signature mr-1" />
                                                                        <FormattedMessage id="NavMenu.ProfileNowForms" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/DataRulesActions">
                                                                        <i className="fas fa-list-ol mr-1" />
                                                                        <FormattedMessage id="NavMenu.DataRulesActions" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/Actions">
                                                                        <i className="fas fa-sync mr-1" />
                                                                        <FormattedMessage id="NavMenu.Actions" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/MachineLearningConfig">
                                                                        <i className="fas fa-brain mr-1" />
                                                                        <FormattedMessage id="NavMenu.MachineLearning" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/ConsentsConfig">
                                                                        <i className="icon-icon-consents mr-1" />
                                                                        <FormattedMessage id="NavMenu.Consents" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/Reports">
                                                                        <i className="fas fa-file-contract mr-1" />
                                                                        <FormattedMessage id="NavMenu.Reports" />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                            <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                                <DropdownItem>
                                                                    <i className="fas fa-user mr-1" />
                                                                    <FormattedMessage id="NavMenu.User" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                                </DropdownItem>
                                                                <DropdownMenu className='border-0 pl-3 pl-lg-0'>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/ApiGatewayUserManagement">
                                                                        <i className="fas fa-user-cog mr-1" />
                                                                        <FormattedMessage id="NavMenu.ApiGatewayUserManagement" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/AdminUserManagement" >
                                                                        <i className="fas fa-user mr-2" /><FormattedMessage id="NavMenu.AdminUserManagement" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/AccessList" >
                                                                        <i className="fas fa-user-lock mr-1" /><FormattedMessage id="NavMenu.AccessList" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/SendGridAdmin" >
                                                                        <i className="fas fa-envelope-open-text mr-1" /><FormattedMessage id="NavMenu.SendGridAdmin" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/TwilioMessagingAdmin">
                                                                        <i className="fas fa-comments mr-1" /><FormattedMessage id="NavMenu.TwilioMessagingAdmin" />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                            <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                                <DropdownItem>
                                                                    <i className="fas fa-file-medical mr-1" />
                                                                    <FormattedMessage id="NavMenu.HealthCheck" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                                </DropdownItem>
                                                                <DropdownMenu className='border-0 pl-3 pl-lg-0'>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/QualityRuns">
                                                                        <i className="fas fa-running mr-1" /><FormattedMessage id="NavMenu.QualityRuns" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/TopMergeProfiles">
                                                                        <i className="fas fa-people-arrows mr-1" /><FormattedMessage id="NavMenu.TopMergeProfiles" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/SyncEvents">
                                                                        <i className="fas fa-sync-alt mr-1" />
                                                                        <FormattedMessage id="NavMenu.SyncEvents" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/QueueStatusDashboard">
                                                                        <i className="fas fa-briefcase mr-1" />
                                                                        <FormattedMessage id="NavMenu.QueueStatusDashboard" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/SystemEvents">
                                                                        <i className="fas fa-exclamation-circle mr-1" />
                                                                        <FormattedMessage id="NavMenu.SystemEvents" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/PlatformDeepDive">
                                                                        <i className="far fa-chart-bar mr-1" /> 
                                                                        <FormattedMessage id="NavMenu.PlatformDeepDive" />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                            <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                                <DropdownItem  >
                                                                    <i className="fas fa-wrench mr-1" />
                                                                    <FormattedMessage id="NavMenu.Maintenance" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                                </DropdownItem>
                                                                <DropdownMenu className='border-0 pl-3 pl-lg-0'>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/DataClean">
                                                                        <i className="fas fa-trash mr-1" /><FormattedMessage id="NavMenu.DataClean" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/RunningProcesses">
                                                                        <i className="fas fa-database mr-1" /><FormattedMessage id="NavMenu.RunningProcesses" />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                            <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                                <DropdownItem  >
                                                                    <i className="fas fa-tools mr-1" />
                                                                    <FormattedMessage id="NavMenu.Actions" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                                </DropdownItem>
                                                                <DropdownMenu className='border-0 pl-3 pl-lg-0'>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/MessageDecryption">
                                                                        <i className="fas fa-file-import mr-1" />
                                                                        <FormattedMessage id="DecryptMessage.DecryptedMessageTitle" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/DeleteProfiles">
                                                                        <i className="fas fa-user-times mr-1" />
                                                                        <FormattedMessage id="NavMenu.DeleteProfiles" />
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/ResendReservation">
                                                                        <i className="fas fa-share-square"></i>
                                                                        <FormattedMessage id="NavMenu.ResendReservation" />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                            <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/ScheduleTask">
                                                                <i className="fas fa-list mr-1" />
                                                                <FormattedMessage id="NavMenu.ScheduleTask" />
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/ManageRegEx">
                                                                <i className="fas fa-not-equal mr-1" />
                                                                <FormattedMessage id="NavMenu.RegExRules" />
                                                            </DropdownItem>

                                                            <UncontrolledDropdown nav={true} inNavbar direction="right" >
                                                                <DropdownItem>
                                                                    <i className="fas fa-heartbeat mr-1" />
                                                                    <FormattedMessage id="NavMenu.Monitoring" /> <i className="float-right fas fa-caret-right pt-1 ml-2" />
                                                                </DropdownItem>
                                                                <DropdownMenu className='border-0 pl-3 pl-lg-0'>
                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/SyncStatus">
                                                                        <i className="fas fa-sync mr-1" />
                                                                        <FormattedMessage id="NavMenu.SyncStatus" />
                                                                    </DropdownItem>

                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/AllDQTemplates">
                                                                        <i className="fas fa-file-alt mr-1" />
                                                                        <FormattedMessage id="NavMenu.AllDQTemplates" />
                                                                    </DropdownItem>

                                                                    {global.modules && global.modules.some(m => m === 'Egoi') &&
                                                                        <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/MonitorCampaigns">
                                                                            <i className="icon-icon-communication-journey mr-1" />
                                                                            <FormattedMessage id="NavMenu.MonitorCampaigns" />
                                                                        </DropdownItem>
                                                                    }

                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/MonitorReservations">
                                                                        <i className="icon-icon-reservas mr-1" />
                                                                        <FormattedMessage id="NavMenu.MonitorReservations" />
                                                                    </DropdownItem>

                                                                    <DropdownItem onClick={() => this.toggleNavbar()} tag={Link} to="/DuplicateRules">
                                                                        <i className="fas fa-list mr-1" />
                                                                        <FormattedMessage id="NavMenu.DuplicateRules" />
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </Nav>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                        
                                            )}
                                            no={() => <div/>}
                                        />
                                    </Nav>
                                    <Nav className='d-block'>
                                        <Authorization
                                            perform="searchProfile:view"
                                            yes={() => (
                                                <DropdownItem tag={Link} to="/SearchProfile" onClick={() => this.toggleNavbar()} style={{ /* padding: '1rem 1.5rem', */ fontWeight: 'bold' }}>
                                                    <i className="fas fa-users mr-1" />
                                                    <FormattedMessage id="NavMenu.Search" />
                                                </DropdownItem>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Nav>
                                    <Nav className='d-block'>
                                        {global.modules && global.modules.some(m => m === 'SalesProcess') ?
                                            <Authorization
                                                perform="salesProcess:view"
                                                yes={() => (
                                                    <DropdownItem tag={Link} to="/SalesProcess" onClick={() => this.toggleNavbar()} style={{ /* padding: '1rem 1.5rem', */ fontWeight: 'bold' }}>
                                                        <i className="fas fa-search-dollar mr-1" />
                                                        <FormattedMessage id="NavMenu.SalesProcess" />
                                                    </DropdownItem>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        : ''}
                                    </Nav>
                                </UncontrolledDropdown>
                            </ul>
                        }
                    </Collapse>
                    {isMobile ?
                        <Collapse className={`d-lg-inline-flex flex-sm-row-reverse ${isMobile ? 'bg-white p-3' : ''}`} isOpen={personalArea} navbar>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <LanguageSwitch
                                        isMobile={isMobile}
                                    />
                                </div>
                                <div>
                                    <UncontrolledDropdown direction="left">
                                        <DropdownToggle tag="a" className="p-0 d-flex align-items-center dropdown-item" caret>
                                            <div className='ml-2'>
                                                <div className="pb-0">
                                                    {authContext.getCachedUser().userName??''}
                                                </div>
                                                <div className="font_size_xxxs text-secondary">
                                                    {user && user.hotelName}
                                                </div>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className='border-0' direction="left">
                                            <BlockUi tag="div" blocking={block}>
                                                <Authorization
                                                    perform="allProperties:changeProperty"
                                                    yes={() => (
                                                        <DropdownMenu className='mobileChangeProperty' style={{ position: 'absolute', right: '2vw', width: '60vw', marginTop: '4vh', transform: 'unset !important' }}>
                                                            <DropdownItem header>
                                                                <FormattedMessage id="NavMenu.ChangeProperty" />
                                                            </DropdownItem>
                                                            <div className="mx-3 shadow">
                                                                <CustomSelect
                                                                    isSearchable
                                                                    placeholder={""}
                                                                    options={this.state.hotels}
                                                                    onChange={(combo) => this.changeDefaultProperty(combo)}
                                                                />
                                                            </div>
                                                        </DropdownMenu>
                                                    )}
                                                    no={() =>
                                                        <DropdownMenu className='mobileChangeProperty' style={{ position: 'absolute', right: '2vw', width: '60vw', marginTop: '4vh' }}>
                                                            <DropdownItem header>
                                                                <FormattedMessage id="NavMenu.ChangeProperty" />
                                                                {user ? <Input className="mt-2 shadow" type="text" name="searchHotel" onChange={(e) => this.handleHotelFilter(e)} placeholder="Hotel" /> : ''}
                                                            </DropdownItem>
                                                            {global.userAllowedProperties?.map((item, key) =>
                                                                <DropdownItem
                                                                    key={key}
                                                                    active={user && item.propertyGuid === user.defaultProperty ? true : false}
                                                                    id={item.propertyGuid}
                                                                    onClick={() => this.changeDefaultProperty({ value: item.propertyGuid })}
                                                                >
                                                                    {item.propertyName}
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    }
                                                />
                                            </BlockUi>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                            </div>
                            <div className='text-left text-secondary pr-2'>
                                <div className='mt-2' >
                                    <Link to="/Profile" onClick={this.togglePersonalArea} style={{ color: 'unset', textDecoration: 'none' }}>
                                        <FormattedMessage id="NavMenu.Profile"/>
                                    </Link>
                                </div>
                                <Authorization
                                    perform="help&support:view"
                                    yes={() => (
                                        <>
                                            <div className='mt-2 text-secondary'>
                                                <a onClick={this.togglePersonalArea} href="https://hostpms.atlassian.net/wiki/spaces/CMSup/pages/2130870273/Profile+Now+Onboarding" target="_blank" rel="noopener noreferrer" style={{ color: 'unset', textDecoration: 'none' }}>
                                                    <span>
                                                        <i className="fas fa-external-link-alt mr-2"></i>
                                                        <FormattedMessage id="NavMenu.Help"/>
                                                    </span>
                                                </a>
                                            </div>
                                            <div className='mt-2 text-secondary'>
                                                <a onClick={this.togglePersonalArea} href={process.env.REACT_APP_SUPPORT_URL} target="_blank" rel="noopener noreferrer" style={{ color: 'unset', textDecoration: 'none' }}>
                                                    <span>
                                                        <i className="fas fa-headset mr-2"/>
                                                        <FormattedMessage id="NavMenu.Support"/>
                                                    </span>
                                                </a>
                                            </div>
                                        </>
                                    )}
                                    no={() => <div />}
                                />
                                <hr />
                                <div className='w-100 text-secondary d-flex align-items-center justify-content-start'>
                                    <div className="pt-2" /* style={{ width: 'fit-content' }} */ onClick={this.logout}>
                                        <FormattedMessage id="NavMenu.Logout" />
                                    </div>
                                </div>
                                {/* <DropdownItem onClick={this.logout}>
                                    <FormattedMessage id="NavMenu.Logout" />
                                </DropdownItem> */}
                            </div>
                        </Collapse>
                    :''}
                </Navbar>
            </Container>
        );
    }
}
