import React, { Component } from 'react';
import { ErrorAlert } from '../../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import SalesProcessDealsRooms from './SalesProcessDealsRooms';
import { Button, Card, Col, CustomInput, Row, UncontrolledPopover } from 'reactstrap';
import moment from 'moment-timezone';
import { handleNotification } from '../../Base/Notification';
import { getAPI } from '../../Base/API';
import { SelectCustomType } from '../SalesProcessFunctions';
import { CommonHelper, FilterButton, SelectHotel } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import CustomTwoMonthDateRangePicker from '../../Base/CustomTwoMonthDateRangePicker';

class SalesProcessGrid extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            isDataFromProps: this.props.year ? true : false,
            startDate: moment().set('year', (this.props.year ?? moment().format('YYYY'))).set('month', (this.props.selectedMonth ? (parseInt(this.props.selectedMonth) - 1) : (parseInt(moment().format('MM')) - 1))).startOf('month'),
            endDate: (moment().set('year', (this.props.year ?? moment().format('YYYY'))).set('month', (this.props.selectedMonth ? (parseInt(this.props.selectedMonth) - 1) : (parseInt(moment().format('MM')) - 1)))),
            hotelList: [],
            statusList: ["Finished", "WaitingHotel", "WaitingClient"],
            hotelIds: this.props.selectedHotel ?? [],
            stageTypeList: [],
            staffIds: [],
            submittedEndDate: null,
            submittedStartDate: null,
            submittedHotelIds: [],
            hideStageType: false,
            hideOption: false,
            hideNoInv: false,
            unsubmittedHideStageType: false,
            unsubmittedHideOption: false,
            unsubmittedHideNoInv: false,
            spaceList: [],
            segmentList: [],
            subSegmentList: [],
            segmentOptions: [],
            subSegmentOptions: [],
            subSegmentOptionsUnfiltered: [],
            segmentOptionsUnfiltered: [],
            hideCancelled: true,
            focused: null
        };
    }

    componentDidMount() {
        const search = new URLSearchParams(window.location.search);
        
        const startDate = (search.get('startDate') && moment(search.get('startDate'))) ?? null;
        const endDate = (search.get('endDate') && moment(search.get('endDate'))) ?? null;
        const statusList = (search.get('statusList') && JSON.parse(search.get('statusList'))) ?? [];
        const hotelIds = (search.get('hotelIds') && JSON.parse(search.get('hotelIds'))) ?? [];
        const stageTypeList = (search.get('stageTypeList') && JSON.parse(search.get('stageTypeList'))) ?? [];
        const staffIds = (search.get('staffIds') && JSON.parse(search.get('staffIds'))) ?? [];
        const segmentList = (search.get('segmentList') && JSON.parse(search.get('segmentList'))) ?? [];
        const subSegmentList = (search.get('subSegmentList') && JSON.parse(search.get('subSegmentList'))) ?? [];
        const hideStageType = search.get('hideStageType') === "true";
        const hideOption = search.get('hideOption') === "true";
        const hideNoInv = search.get('hideNoInv') === "true";
        const hideCancelled = search.get('hideCancelled') === "true";

        if(startDate && endDate){
            this.setState({
                hideStageType,
                hideOption,
                hideNoInv,
                hideCancelled,
                startDate,
                endDate,
                statusList,
                hotelIds,
                stageTypeList,
                staffIds,
                segmentList,
                subSegmentList,
                unsubmittedHideStageType: hideStageType,
                unsubmittedHideOption: hideOption,
                unsubmittedHideNoInv: hideNoInv
            }, () => this.getResources());
        }
        else if(this.state.isDataFromProps){
            let endDateTemp = this.state.endDate;
            if (this.props.isFromMonthly) {
                endDateTemp = this.state.endDate.endOf('month');
            }

            this.setState({ endDate: endDateTemp }, () => this.getResources());
        }else{
            this.setState({ endDate: this.state.endDate.startOf('month').add(15, 'days') });
        }

        this.getSpaceList();
    }

    getResources = () => {
        const { statusList, hotelIds, stageTypeList, staffIds, hideCancelled, segmentList, subSegmentList, startDate, endDate,
        unsubmittedHideStageType, unsubmittedHideOption, unsubmittedHideNoInv } = this.state;

        let qs = `startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&hideCancelled=${hideCancelled}`;

        if(statusList?.length){
            statusList.forEach(s => {
                qs += `&statusList=${s}`;
            });
        }

        if(hotelIds?.length){
            hotelIds.forEach(h => {
                qs += `&hotelIds=${h}`;
            });
        }

        if(stageTypeList?.length){
            stageTypeList.forEach(s => {
                qs += `&stageTypeList=${s}`;
            });
        }

        if(staffIds && staffIds.length > 0){
            staffIds.forEach(s => {
                qs += `&staffIds=${s}`;
            });
        }

        if(subSegmentList && subSegmentList.length > 0){
            subSegmentList.forEach(s => {
                qs += `&subSegmentList=${s}`;
            });
        }

        if(segmentList && segmentList.length > 0){
            segmentList.forEach(s => {
                qs += `&segmentList=${s}`;
            });
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    hotelList: data.data??[],
                    block: false,
                    submittedEndDate: endDate,
                    submittedStartDate: startDate,
                    submittedHotelIds: [...hotelIds],
                    hideStageType: unsubmittedHideStageType,
                    hideOption: unsubmittedHideOption,
                    hideNoInv: unsubmittedHideNoInv
                }, () => this.setQS());

                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/resourcegrid?${qs}`);
    }

    setQS = () => {
        if(window.location.hash === "#SalesProcessGrid"){
            const { startDate, endDate, statusList, hotelIds, stageTypeList, staffIds, hideStageType, hideOption, hideNoInv, hideCancelled } = this.state;
            
            const search = new URLSearchParams(window.location.search);

            search.set('startDate', startDate.format('YYYY-MM-DD'));
            search.set('endDate', endDate.format('YYYY-MM-DD'));
            search.set('hotelIds', JSON.stringify(hotelIds));
            
            if(statusList?.length)
                search.set('statusList', JSON.stringify(statusList));

            if(stageTypeList?.length)
                search.set('stageTypeList', JSON.stringify(stageTypeList));
            
            if(staffIds?.length)
                search.set('staffIds', JSON.stringify(staffIds));

            if(hideStageType)
                search.set('hideStageType', hideStageType);

            if(hideOption)
                search.set('hideOption', hideOption);

            if(hideNoInv)
                search.set('hideNoInv', hideNoInv);

            if(hideCancelled)
                search.set('hideCancelled', hideCancelled);
            

            const string = search.toString();

            this.props.history.push({ pathname: `/SalesProcess`, search: `?${string}` });
            window.location.hash = "#SalesProcessGrid";
        }
    }

    getSpaceList = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                this.setState({ spaceList: data.response?.filter(({active}) => active)??[] }, () => this.getRefData());
            }
            else {
                this.setState({ error: errorMessage });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=EventSpaces`);
    }

    getRefData = () => {
        const getSegment = () => {
            getAPI(result => {
                const { data, error } = result;
    
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    const { filtered, brute } = this.refDataTreatment(data.response);
                    this.setState({
                        segmentOptions: filtered,
                        segmentOptionsUnfiltered: brute
                    }, () => getSubSegment());
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Hotel/v1/ReferenceData?type=Segments`);
        };

        const getSubSegment = () => {
            getAPI(result => {
                const { data, error } = result;
    
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    const { filtered, brute } = this.refDataTreatment(data.response);
                    this.setState({
                        subSegmentOptions: filtered,
                        subSegmentOptionsUnfiltered: brute,
                        block: false
                    });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Hotel/v1/ReferenceData?type=SubSegments`);
        };

        getSegment();
    }

    refDataTreatment = (data, hotelIdsNew) => {
        const hotelIds = hotelIdsNew ?? this.state.hotelIds
        const returnValue = {
            filtered: data
                ?.filter(({hotelId}) => !hotelId || hotelIds.includes(hotelId))
                .map(({description, code, hotelId}) => ({
                    value: code,
                    description,
                    code,
                    hotelId,
                    label: (
                        <span>
                            {description}
                            <b className="ml-1">({code})</b>
                        </span>
                    )
                })),
            brute: data?.map(({description, code, hotelId}) => ({
                value: code,
                hotelId,
                description,
                code,
                label: (
                    <span>
                        {description}
                        <b className="ml-1">({code})</b>
                    </span>
                )
            })),
        }
        return returnValue;
    }

    handleDates = (startDate, endDate, focusedInput) => {
        this.setState({ startDate, endDate: (focusedInput === "startDate" && startDate) ? null : endDate });
    }
    
    handleStatus = (status) => {
        let { statusList } = this.state;
        if(status === "Negotiation" && (statusList.includes("WaitingHotel") || statusList.includes("WaitingClient"))){
            statusList = statusList.filter(s => s !== "WaitingHotel" && s !== "WaitingClient");
        }
        else if(statusList.includes(status)){
            statusList = statusList.filter(s => s !== status);
        }
        else{
            if(status === "Negotiation"){
                statusList.push("WaitingHotel");
                statusList.push("WaitingClient");
            }
            else{
                statusList.push(status);
            }
        }
        this.setState({ statusList });
    }
    
    handleStage = (combo) => {
        this.setState({ stageTypeList: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handleHotels = (n, combo) => {
        let { subSegmentOptions, segmentOptions, subSegmentOptionsUnfiltered, segmentOptionsUnfiltered } = this.state;
        const hotelIds = combo ? combo?.map(cmb => cmb.value) : [];

        if(segmentOptionsUnfiltered?.length){
            segmentOptions = this.refDataTreatment(segmentOptionsUnfiltered, hotelIds).filtered;
        }
        if(subSegmentOptionsUnfiltered?.length){
            subSegmentOptions = this.refDataTreatment(subSegmentOptionsUnfiltered, hotelIds).filtered;
        }
        this.setState({ subSegmentOptions, segmentOptions, hotelIds });
    }

    handleMultiCombo = (combo, name) => {
        this.setState({ [name]: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ block: true }, () => this.getResources());
    }

    toggleBool = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    renderHelperMsg = () => {
        return (
            <div style={{ width: '250px' }}>
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-start">
                            <div style={{
                                width: '50px',
                                height: '20px',
                                background: 'repeating-linear-gradient(45deg, #bdd4f3, #bdd4f3 5px, #ffffff 5px, #ffffff 12px)'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.NotAffectingAvail"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-start">
                            <div style={{
                                width: '50px',
                                height: '20px',
                                background: 'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #ffffff 5px, #ffffff 12px'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.OptionalProposals"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-start">
                            <div style={{
                                width: '50px',
                                height: '20px',
                                borderRadius: '3px',
                                background: 'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #bdd4f3 5px, #bdd4f3 12px)'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.OptionalProposalsAndNotAffectingAvail"/>
                        </div>
                    </div>
                </div>
                <hr/>
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-3'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div style={{
                                width: '18px',
                                height: '35px',
                                borderRadius: '3px',
                                border: `1px solid #5cb85c`,
                                background: '#5cb85c'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.OccFromDeals"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div style={{
                                width: '18px',
                                height: '35px',
                                borderRadius: '3px',
                                border: `1px solid #f38375`,
                                background: '#f38375'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.OverbookingFromDeals"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div style={{
                                width: '18px',
                                height: '35px',
                                borderRadius: '3px',
                                border: `1px solid #5cb85c`,
                                background: 'repeating-linear-gradient(45deg, #5cb85c, #5cb85c 4px, #ffffff 5px, #ffffff 9px)'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.OccFromOtherSources"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div style={{
                                width: '18px',
                                height: '35px',
                                border: `1px solid #f38375`,
                                background: 'repeating-linear-gradient(45deg, #f38375, #f38375 4px, #ffffff 5px, #ffffff 9px)'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.OverbookingFromOtherSources"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    loadMonths = (monthKey) => {
        const unit = monthKey > 0 ? 15 : -15;
        const startDate = this.state.startDate.clone().add(unit, 'days');
        const endDate = this.state.endDate.clone().add(unit, 'days');
        this.setState({ startDate, endDate });
    }

    countFilters = (e) => {
        if (e) e.preventDefault();
        const { stageTypeList, staffIds, hideCancelled, unsubmittedHideNoInv, unsubmittedHideOption, unsubmittedHideStageType, subSegmentList, segmentList } = this.state;
        let count = 0;

        if (stageTypeList?.length > 0) count++;
        if (staffIds?.length > 0) count++;
        if (hideCancelled) count++;
        if (unsubmittedHideNoInv) count++;
        if (unsubmittedHideOption) count++;
        if (unsubmittedHideStageType) count++;
        if (subSegmentList?.length > 0) count++;
        if (segmentList?.length) count++;

        return count;
    }

    render() {
        const { error, block, submittedEndDate, submittedStartDate, hotelList, statusList, hotelIds, stageTypeList, staffIds, hideCancelled, startDate, endDate, submittedHotelIds, hideStageType,
        spaceList, hideOption, hideNoInv, segmentList, subSegmentList, segmentOptions, subSegmentOptions, unsubmittedHideStageType, unsubmittedHideOption, unsubmittedHideNoInv } = this.state;
        const { icon, intl, salesUsers, isChild, toggleTab, selectedHotel, isFromMonthly, selectDealDetail, currentUser } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2" id="SalesProcessGrid">
                <ErrorAlert error={error} />
                <form onSubmit={this.handleSubmit}>
                    <div className='mb-4 d-flex align-items-center justify-content-between'>
                        <div style={{ whiteSpace: 'nowrap' }} className='d-flex align-items-center'>
                            {isChild ?
                                <div onClick={_ => toggleTab(isFromMonthly ? "SalesProcessExecutiveReportMonthly" : "SalesProcessExecutiveReportYearly", selectedHotel)} className='p-3 cursor-pointer mr-2'>
                                    <i className='fas fa-chevron-left'/>
                                </div>
                            :''}
                            <h4 className='m-0'>
                                <i className={`${icon} mr-2`} />
                                <FormattedMessage id="SalesProcess.Grid" />
                            </h4>
                        </div>
                        <div>
                            <Button className="btn-sm btn-host mr-2" type="submit" id="buttonSubmit" disabled={!(startDate && endDate && hotelIds?.length)}>
                                <i className="fas fa-search"></i>
                            </Button>
                            <CommonHelper help={this.renderHelperMsg()} id={'salesGridHelper'} />
                        </div>
                    </div>
                    <Card className="border-0 p-3 shadow">
                        <BlockUi tag="div" blocking={block}>
                            <Row className="">
                                <Col className="col-4">
                                    <SelectHotel
                                        maxOptions={3}
                                        isSearchable
                                        name='hotels'
                                        isMulti={true}
                                        onChangeFunc={this.handleHotels}
                                        placeholder={'Hotels'}
                                        value={hotelIds || ''}
                                        params='?onlyFromHotelGroup=true'
                                        required={true}
                                        hotelIdsToFilter={currentUser?.hotelIds}
                                    />
                                </Col>
                                <Col className='col-4 d-flex align-items-center justify-content-center'>
                                    <div className='cursor-pointer' onClick={_ => this.loadMonths(-1)}>
                                        <b><i className="fas fa-chevron-left"/></b>
                                    </div>
                                    <CustomTwoMonthDateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        handleDates={this.handleDates}
                                        required={true}
                                        isOutsideRange={(day, focusedInput) => startDate && focusedInput === "endDate" && day.isAfter(moment(startDate).add(31, "day"))}
                                    />
                                    <div className='cursor-pointer' onClick={_ => this.loadMonths(1)}>
                                        <b><i className="fas fa-chevron-right"/></b>
                                    </div>
                                </Col>
                                <Col className="col-4 d-flex align-items-center justify-content-end">
                                    <div className="d-flex align-items-center justify-content-end" style={{ fontSize: '0.7em', whiteSpace: 'nowrap' }}>
                                        <div onClick={_ => this.handleStatus("Finished")} className={`deal-action-badge-Finished ${statusList.includes("Finished") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                            <i className="fas fa-trophy mr-1"/>
                                            <FormattedMessage id={`SalesProcess.Won`}/>
                                        </div>
                                        <div onClick={_ => this.handleStatus("Negotiation")} className={`deal-action-badge-OnGoing ${statusList.includes("WaitingHotel") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                            <i className="fas fa-people-arrows mr-1"/>
                                            <FormattedMessage id={`SalesProcess.Negotiation`}/>
                                        </div>
                                        <div onClick={_ => this.handleStatus("Cancelled")} className={`deal-action-badge-Cancelled ${statusList.includes("Cancelled") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                            <i className="far fa-thumbs-down mr-1"/>
                                            <FormattedMessage id={`SalesProcess.Lost`}/>
                                        </div>
                                    </div>
                                    <div className='ml-4'>
                                        <FilterButton totalFilters={this.countFilters()} butId={"buttonSubmit"}>
                                            <div className="fullWidth">
                                                <div>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className='fas fa-route mr-2' />
                                                        <FormattedMessage id="SalesProcess.StageType" />
                                                    </div>
                                                    <div>
                                                        <SelectCustomType
                                                            isMulti={true}
                                                            onChangeFunc={this.handleStage}
                                                            value={stageTypeList}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.StageType" })}
                                                            isDisabled={false}
                                                            isClearable={true}
                                                            type={'PipelineStageType'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className={`fas fa-users mr-2`} />
                                                        <FormattedMessage id="SalesProcess.Staff" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={salesUsers}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            value={salesUsers?.filter(({ value }) => staffIds.includes(value))}
                                                            isSearchable={true}
                                                            onChange={e => this.handleMultiCombo(e, 'staffIds')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className="fas fa-layer-group mr-2" />
                                                        <FormattedMessage id="SalesProcess.Segment" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={segmentOptions}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            value={segmentOptions?.filter(({ value }) => segmentList.includes(value))}
                                                            isSearchable={true}
                                                            onChange={e => this.handleMultiCombo(e, 'segmentList')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.Segment" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className="fas fa-layer-group mr-2" />
                                                        <FormattedMessage id="SalesProcess.SubSegment" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={subSegmentOptions}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            value={subSegmentOptions?.filter(({ value }) => subSegmentList.includes(value))}
                                                            isSearchable={true}
                                                            onChange={e => this.handleMultiCombo(e, 'subSegmentList')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.SubSegment" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={"hideStageType"}
                                                        className="text-ellipsis mr-4"
                                                        checked={unsubmittedHideStageType}
                                                        onChange={_ => this.toggleBool("unsubmittedHideStageType")}
                                                    >
                                                        <FormattedMessage id="SalesProcess.HideStageType" />
                                                    </CustomInput>
                                                </div>
                                                <div className='mt-3'>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={"hideOption"}
                                                        className="text-ellipsis mr-4"
                                                        checked={unsubmittedHideOption}
                                                        onChange={_ => this.toggleBool("unsubmittedHideOption")}
                                                    >
                                                        <FormattedMessage id="SalesProcess.HideOption" />
                                                    </CustomInput>
                                                </div>
                                                <div className='mt-3'>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={"hideNoInv"}
                                                        className="text-ellipsis mr-4"
                                                        checked={unsubmittedHideNoInv}
                                                        onChange={_ => this.toggleBool("unsubmittedHideNoInv")}
                                                    >
                                                        <FormattedMessage id="SalesProcess.HideNoInv" />
                                                    </CustomInput>
                                                </div>
                                                <div className='mt-3'>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={"hideCancelled"}
                                                        className="text-ellipsis mr-4"
                                                        checked={hideCancelled}
                                                        onChange={_ => this.toggleBool("hideCancelled")}
                                                    >
                                                        <FormattedMessage id="SalesProcess.HideCancelled" />
                                                    </CustomInput>
                                                </div>
                                            </div>
                                        </FilterButton>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <SalesProcessDealsRooms
                                    hotelIds={submittedHotelIds}
                                    hotelList={hotelList}
                                    startDate={submittedStartDate}
                                    endDate={submittedEndDate}
                                    history={this.props.history}
                                    hideStageType={hideStageType}
                                    hideOption={hideOption}
                                    hideNoInv={hideNoInv}
                                    spaceList={spaceList}
                                    selectDealDetail={selectDealDetail}
                                />
                            </div>
                        </BlockUi>
                    </Card>
                </form>
            </div>
        );
    }
}

export default injectIntl(SalesProcessGrid);