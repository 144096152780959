import React from 'react';
import { Badge, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import CustomSelect from "../../Base/CustomSelect";
import { handleNotification } from '../../Base/Notification';


export const PreviewTemplates = ({ hasTemplates, templates, showOptions, onEditClick, onStatsClick, onClose }) => (
    <div>
        {showOptions ?
            <div className="mb-3 d-flex justify-content-between">
                <div>
                    <b className="mr-3" >
                        <i className="icon-icon-preview" /> <FormattedMessage id="CommonFunctions.Preview" />
                    </b>
                    <span onClick={onEditClick} className="mr-3 cursor-pointer week-day">
                        <i className="fas fa-edit" /> <FormattedMessage id="CommonFunctions.Edit" />
                    </span>
                    <span onClick={onStatsClick} className="mr-3 cursor-pointer week-day">
                        <i className="icon-icon-statistics-campaigns" /> <FormattedMessage id="CommonFunctions.Statistics" />
                    </span>
                </div>
                <div>
                    <span sm={1} onClick={onClose}>
                        <i className="fas fa-lg fa-times cursor-pointer" />
                    </span>
                </div>
            </div>
            : ''}

        <Row>
            {hasTemplates ?
                templates.map((template, key) =>
                    <Col className={'px-2 ' + (key + 1 < templates.length ? 'border-right' : '')} key={key} >
                        <div>
                            <h6>{template.templateName}</h6>
                            <div className="mb-2"><b>{template.subject}</b></div>
                            <div className="week-day mb-3">
                                {template.isDefault ?
                                    "Default Template" :
                                    template.countries && template.countries.map((country, k) =>
                                        <span className={`flag-icon flag-icon-${country.toLowerCase()} px-2 mr-2`} key={k} style={{ borderRadius: '3px' }} />
                                    )}
                            </div>
                        </div>
                        <div style={{ width: 'auto', height: 'auto', height: '240px' }}>
                            <img src={template.thumbnail} style={{ width: 'inherit', height: 'inherit' }} alt={template.templateName} />
                        </div>
                        <div className="d-flex">
                            {template.clickTracking ? <Badge className="my-2 p-2 mr-2" color="primary">Click Tracking</Badge> : ''}
                            {template.openTracking ? <Badge className="my-2 p-2" color="primary">Open Tracking</Badge> : ''}
                        </div>
                    </Col>
                )
                : <Col className="text-center my-3"> <FormattedMessage id="PreviewTemplate.ThereIsNoTemplates" /> </Col>}
        </Row>
    </div>
);

//Recurring campaigns page
export const EventCircle = ({ bgColor, borderColor, icon }) => (
    <div className={`d-flex align-items-center justify-content-center ${bgColor} ${borderColor}`}
        style={{ border: 'solid 1px', padding: '4px', backgroundClip: 'content-box', color: 'white', width: '45px', height: '45px', borderRadius: '30px'}}>
        <i className={icon} />
    </div>
);


export const isAValidJson = (text) => {
    try {
        JSON.parse(text);
    } catch (e) {
        return false;
    }

    return true;
}

export const CampaignBox = ({ channel, bgColor, campaignName, adjust, type, id, handlePreview, hasFilters, active }) => (
    <div style={{ width: '186px', height: '50px', border: '1px solid #C9DEFE', borderRadius: '10px', display: 'inline-block', padding: '9px 10px 0 10px', fontWeight: 'bolder', zIndex: '999', cursor: 'pointer', backgroundColor: '#FAFCFF' }} id={id} onClick={handlePreview}>
        <div className="d-flex align-items-center">
            <div className={`d-flex align-items-center justify-content-center ${bgColor}` } style={{ borderRadius: '30px', height: '30px', width: '30px', color: 'white', opacity: active ? '1' : '0.3' }}>
                {channel === "Email" ? <i className="icon-icon-mail" /> : <i className="icon-icon-sms" />}
            </div>
            <span className="overflow-hidden text-nowrap text-truncate ml-2" title={`${campaignName} (${active ? 'active' : 'inactive'})`} style={{ width: '130px', color: active ? 'black' : '#e0e0e0' }}>
                {campaignName}
            </span>
        </div>

        <div className="d-flex" style={{ position: 'relative', top: '2px', color: 'white', fontSize: '10px', float: 'right', zIndex: '2' }}>
            {hasFilters ?
                <div className={'bg-dark-blue mr-2'} style={{ borderRadius: '10px', padding: '1px 4px 0px 4px' }}>
                    <i className="fa-sm icon-icon-filter" />
                </div>
            : ''}

            {adjust && adjust !== 0 ?
                <div className={(adjust > 0 ? 'bg-orange' : 'bg-green') + " px-2 d-flex align-items-center"} style={{ borderRadius: '10px' }}>
                    {adjust > 0 ? <span> {adjust} <FormattedMessage id="CommonFunctions.daysAfter" /> </span> : <span> {adjust * (-1)} <FormattedMessage id="CommonFunctions.daysBefore" /> </span>}
                    </div>

                : type !== 0 ?
                    <div className={'bg-dark-blue px-2'} style={{ borderRadius: '10px' }}>
                        <span> <FormattedMessage id={`Campaigns.${type}`} /> </span>
                    </div>

                : ''}
        </div>
    </div>
);

export function errorHandler(msg) {
    const data = {
        warning:[
            {
                Message: msg
            }
        ]
    }
    handleNotification(data);
}


//Feriados e datas comemorativas
export function getHolidays(year) {

    const getMothersDay = (year) => {
        const date = `${year}-05-03`;

        let dayOfWeek = 0, diffDays = 0;
        let day = moment(date).day();

        if (day > dayOfWeek) {
            diffDays = 7 - (day - dayOfWeek);
        }

        return parseInt(moment(date).add(diffDays, 'day').format("D"));
    }

    const holidays = [
        {
            country: 'PT',
            months: [
                {
                    month: 1,
                    celebrations: [
                        { day: 1, label: 'Dia de Ano Novo' },
                        { day: 6, label: 'Dia de Reis' },
                    ]
                },
                {
                    month: 2, //Ver Carnaval
                    celebrations: [
                    ]
                },
                {
                    month: 3,
                    celebrations: [
                        { day: 8, label: 'Dia Internacional da Mulher' },
                        { day: 19, label: 'Dia do Pai' },
                    ]
                },
                {
                    month: 4, //Ver sexta-feira santa e Páscoa
                    celebrations: [
                        { day: 25, label: 'Dia da Liberdade' },
                    ]
                },
                {
                    month: 5,
                    celebrations: [
                        { day: 1, label: 'Dia do Trabalhador' },
                        { day: getMothersDay(year), label: 'Dia da Mãe' },
                    ]
                },
                {
                    month: 6,
                    celebrations: [
                        { day: 1, label: 'Dia Mundial da Criança' },
                        { day: 3, label: 'Corpo de Deus' },
                        { day: 10, label: 'Dia de Portugal' },
                    ]
                },
                {
                    month: 7,
                    celebrations: [
                    ]
                },
                {
                    month: 8,
                    celebrations: [
                        { day: 15, label: 'Assunção de Nossa Senhora' },
                    ]
                },
                {
                    month: 9,
                    celebrations: [
                        { day: 27, label: 'Dia Mundial do Turismo' },
                    ]
                },
                {
                    month: 10,
                    celebrations: [
                        { day: 1, label: 'Dia Mundial da Música' },
                        { day: 5, label: 'Implantação da República' },
                    ]
                },
                {
                    month: 11,
                    celebrations: [
                        { day: 1, label: 'Dia de Todos os Santos' },
                    ]
                },
                {
                    month: 12,
                    celebrations: [
                        { day: 1, label: 'Restauração da Independência' },
                        { day: 8, label: 'Dia da Imaculada Conceição' },
                        { day: 25, label: 'Natal' },
                        { day: 31, label: 'Réveillon' },
                    ]
                }
            ]
        }
    ];

    return holidays;
};


//#region twilio
export function getTwilioStatus(intl) {
    return [
        { value: "phoneNumbers", label: intl.formatMessage({ id: "TwilioMessaging.PhoneNumbers" }), color: '#FBC753' },
        { value: "outBoundSms", label: intl.formatMessage({ id: "TwilioMessaging.OutBoundSms" }), color: '#6EDE8A' },
        { value: "inBoundSms", label: intl.formatMessage({ id: "TwilioMessaging.InBoundSms" }), color: '#CF53FA' },
    ]
}

export function getAggregatedByOptions(intl) {
    return [
        { value: "Daily", label: intl.formatMessage({ id: "SendGrid.Day" }) },
        { value: "Monthly", label: intl.formatMessage({ id: "SendGrid.Month" }) },
        { value: "Yearly", label: intl.formatMessage({ id: "TwilioMessaging.Year" }) },
    ]
}

export const SelectAggregatedBy = ({ intl, handleChange, required, value, isDisabled }) => {
    const options = getAggregatedByOptions(intl);

    return (
        <CustomSelect
            icon="far fa-clock"
            options={options}
            required={required}
            isSearchable
            value={options.filter(el => el.value === value) || ''}
            placeholder=""
            onChange={(e) => handleChange("aggregatedBy", e)}
            isDisabled={isDisabled}
        />
    )
};
//#endregion