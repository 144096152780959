import React from 'react';
import CoolDataGridRow from './CoolDataGridRow';

const CoolDataGridColumn = ({ onScroll, tableName, col, rows, isFixed, hasIndent, firstCol, newHotelRowStyle, colKey }) => {

    return (
        <div onScroll={e => isFixed && onScroll(e)} id={isFixed ? "FixedCoolDataGrid" : ""} style={{
            position: col.isFixed ? 'sticky' : '',
            left: col.isFixed ? '0' : !isFixed ? `${((col.width) * colKey)}px` : '',
            minWidth: col.width ?? '',
            maxWidth: col.width ?? '',
            maxHeight: isFixed ? '75vh' : '',
            overflow: isFixed ? 'auto' : '',
            position: !isFixed ? 'absolute' : ''
        }} className={isFixed ? 'noScrollBar' : ''}>
            <div id={`table-${tableName}-col-${col.key}`} style={{...col.style, position: 'sticky', top: '0'}} className={`${isFixed ? 'text-left' : ''} CoolDataGrid-cols ${!col.style?.width ? "w-100 CoolDataGrid-cols" : ""} ${col.isWeekend ? 'CoolDataGrid-cols-weekend' : ''}
             ${col.isToday ? 'CoolDataGrid-cols-today' : ''}`}>
                {col.name} 
            </div>
            {rows ?
                <div className={`d-flex flex-column align-items-start CoolDataGrid-rows-container ${col.isWeekend ? 'CoolDataGrid-rows-container-weekend' : ''}${col.isToday ? 'CoolDataGrid-rows-container-today' : ''}`}>
                    {rows.map((row, rowKey) =>
                        <CoolDataGridRow
                            row={row}
                            isFixed={isFixed}
                            key={rowKey}
                            firstCol={firstCol}
                            rowKey={rowKey}
                            col={col}
                            hasIndent={hasIndent}
                            tableName={tableName}
                            newHotelRowStyle={newHotelRowStyle}
                        />
                    )}
                </div>
            :''}
        </div>
    );
}

export default CoolDataGridColumn;
