import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import CustomSelect from '../Base/CustomSelect';


export function getSalesSourceTypes(intl) {
    return [
        { value: 'Event', label: intl.formatMessage({ id: "ReferenceDataFunctions.Event" }) },
        { value: 'Form', label: intl.formatMessage({ id: "ReferenceDataFunctions.Form" }) },
        { value: 'Partner', label: intl.formatMessage({ id: "ReferenceDataFunctions.Partner" }) },
        { value: 'Direct', label: intl.formatMessage({ id: "ReferenceDataFunctions.Direct" }) },
        { value: 'Renegotiation', label: intl.formatMessage({ id: "ReferenceDataFunctions.Renegotiation" }) },
    ]
}

export function getSalesConfigTypes(intl) {
    return [
        { value: 'HotelGroup', label: intl.formatMessage({ id: "ReferenceDataFunctions.HotelGroup" }) },
        { value: 'Brand', label: intl.formatMessage({ id: "ReferenceDataFunctions.Brand" }) },
        { value: 'Hotel', label: intl.formatMessage({ id: "ReferenceDataFunctions.Hotel" }) },
    ]
}

export function getSalesPipelineTypes(intl) {
    return [
        { value: 'Event', label: intl.formatMessage({ id: "ReferenceDataFunctions.Event" }) },
        { value: 'GroupReservation', label: intl.formatMessage({ id: "ReferenceDataFunctions.GroupReservation" }) },
        { value: 'Generic', label: intl.formatMessage({ id: "ReferenceDataFunctions.Generic" }) }
    ]
}

export function getSalesPipelineStageTypes(intl) {
    return [
        { value: 'Lead', label: intl.formatMessage({ id: "ReferenceDataFunctions.Lead" }) },
        { value: 'DealProcess', label: intl.formatMessage({ id: "ReferenceDataFunctions.DealProcess" }) },
        { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
} //remover aqui

export function getDealStatus(intl) {
    return [
        { value: 'WaitingClient', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingClient" }), color: 'primary' },
        { value: 'WaitingHotel', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingHotel" }), color: 'primary' },
        { value: 'Cancelled', label: intl.formatMessage({ id: "ReferenceDataFunctions.Cancelled" }), color: 'danger' },
        { value: 'Finished', label: intl.formatMessage({ id: "ReferenceDataFunctions.Finished" }), color: 'success' }
        // { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
}

export function getLeadStatus(intl) {
    return [
        { value: 'WaitingClient', label: intl.formatMessage({ id: "SalesProcess.InNegotiation" }) },
        { value: 'Cancelled', label: intl.formatMessage({ id: "SalesProcess.Cancelled" }) },
        { value: 'Finished', label: intl.formatMessage({ id: "SalesProcess.Finished" }) }
    ]
}

export function getUsersRoles(intl) {
    return [
        { value: 'Responsible', label: intl.formatMessage({ id: "ReferenceDataFunctions.Responsible" }) },
        { value: 'Assistant', label: intl.formatMessage({ id: "ReferenceDataFunctions.Assistant" }) },
        { value: 'Related', label: intl.formatMessage({ id: "ReferenceDataFunctions.Related" }) }
    ]
}

export function getUsersRoleType(intl){ 
    return [
        { value: 'CentralUser', label: intl.formatMessage({ id: "ReferenceDataFunctions.CentralUser" }) },
        { value: 'HelpDeskUser', label: intl.formatMessage({ id: "ReferenceDataFunctions.HelpDeskUser" }) },
        { value: 'Manager', label: intl.formatMessage({ id: "ReferenceDataFunctions.Manager" }) },
        { value: 'SalesRep', label: intl.formatMessage({ id:"ReferenceDataFunctions.SalesRep" }) },
        { value: 'Admin', label: intl.formatMessage({ id:"ReferenceDataFunctions.Admin" }) },
    ]
}

export function getCustomTypeFixedValues(intl) {        
    return [
        { value: 'Duplicate', label: intl.formatMessage({ id: "ReferenceDataFunctions.Duplicate" }) },
        { value: 'Won', label: intl.formatMessage({ id: "ReferenceDataFunctions.Won" }) },
        { value: 'Lost', label: intl.formatMessage({ id: "ReferenceDataFunctions.Lost" }) },
        { value: 'Negotiation', label: intl.formatMessage({ id: "ReferenceDataFunctions.Negotiation" }) },
        { value: 'Agency', label: intl.formatMessage({ id: "ReferenceDataFunctions.Agency" }) }
    ]
}

export const SelectCustomType = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled, isClearable = true, isSearchable=true, type, bigCol }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');
    
    useEffect(() => {
        window.setTimeout(
            () => {
                getAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        setError(errorMessage);
                        setLoad(false)
                        return;
                    }
                    if (data && data.data && data.data.length > 0) {
                        var combo = data.data.map((el) => {
                            return {
                                label: el.name,
                                value: el.id
                            }
                        });

                        setData(combo);
                    }
                    setLoad(false);
                }, `/api/gms/SalesProcess/v1/customtypes?type=${type}`);
            },
            global.modules ? 0 : 2500
        );
    }, [type]);

    if(name === 'cancelReasonType'){
        if(!data || data.length === 0){
            return null;
        }else{
            return (
                <Col className={`${bigCol ? '' : 'col-3'}`}>
                    <div className="title-sm required"> <FormattedMessage id="SalesDeal.CancellationReasonType" /></div>
                    <CustomSelect 
                        icon={icon}
                        name={name}
                        options={data || []}
                        required={required}
                        isClearable={isClearable}
                        isMulti={isMulti}
                        value={data ? data.find(r => r.value === value) : ''}
                        placeholder={placeholder}
                        onChange={onChangeFunc.bind(this)}
                        isDisabled={isDisabled}
                        isLoading={block}
                        isSearchable={isSearchable}
                    />
                </Col>
            )
        }
    }else{
        return (
            <CustomSelect
                icon={icon}
                name={name}
                options={data || []}
                required={required}
                isClearable={isClearable}
                isMulti={isMulti}
                value={data ? data.find(r => r.value === value) : ''}
                placeholder={placeholder}
                onChange={onChangeFunc.bind(this)}
                isDisabled={isDisabled}
                isLoading={block}
                isSearchable={isSearchable}
            /> 
        )
    }

    
};

export const ProfileCard = ({ name, roleId, notes, handleListElement, removeElement }) => {
    const nameDivided = name?.split(' ');

    return <div>
        <Row className="align-items-center">
            <Col className="d-flex align-items-center">
                {nameDivided ?
                    <Badge className="profileBadge mr-2">
                        {nameDivided[0].substr(0, 1).toUpperCase()}{nameDivided?.length > 1 ? nameDivided[nameDivided.length - 1].substr(0, 1).toUpperCase() : ''}
                    </Badge>
                    : ''}

                <span style={{ "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", display: 'block' }}>
                    {name}
                </span>
            </Col>

            <Col>
                <SelectCustomType 
                    name="roleId"
                    onChangeFunc={(combo) => handleListElement('roleId', combo ? combo.value : null)}
                    required={true}
                    value={roleId}
                    placeholder=""
                    isDisabled={false}
                    isClearable={true}
                    type={'DealProfileRole'}
                />
            </Col>
            <Col className="pr-0">
                <Input
                    required
                    type="text"
                    name="notes"
                    placeholder=""
                    value={notes || ''}
                    onChange={(event) => handleListElement('notes', event.target.value)}
                    maxLength={500}
                />
            </Col>
            <Col sm={1} className=" text-right pr-4">
                <i className="fas fa-trash-alt text-danger pointer" onClick={removeElement} />
            </Col>
        </Row>
    </div>
};

export const DealStatusBadge = ({ status, isLead, id }) => {
    const color = (status === "WaitingHotel" || status === "WaitingClient") ? "primary" : status === "Finished" ? "success" : status === "Cancelled" ? "danger" : '';

    return (
        isLead && ["WaitingClient", "WaitingHotel"].includes(status) ?
            <Badge id={id} color="primary" className="py-1 px-2" style={{ fontSize: '0.8em' }}>
                <FormattedMessage id={`SalesProcess.InNegotiation`} />
            </Badge>
            :
            <Badge id={id} color={color} className={"py-1 px-2 " + (status === "Effective" && ' bg-green ' )} style={{fontSize: '0.8em' }}>
                <FormattedMessage id={`SalesProcess.${status}`} />
            </Badge>
    )
}


//#region Sales Process Users
export function getAllowedSalesProcessUsers() {
    const usersList = global.salesProcessUsers;

    if (usersList) {
        const currentUser = usersList?.find(el => el.isCurrentUser);

        if (currentUser) {
            if (currentUser.role === 'Manager' || currentUser.role === 'SalesRep') {
                return usersList.filter(user => user.hotelIds?.some(hotel => global.hotelList?.some(el => el.value === hotel)))
            }
            else {
                return usersList;
            }
        }
    }
};

export function getCurrentUser() {
    return global.salesProcessUsers?.find(el => el.isCurrentUser);
};

export const SalesProcessUsersCombo = ({ icon, required, isClearable, isMulti, value, placeholder, onChangeFunc, name, isDisabled, block, setDefault }) => {
    const [usersList, setUsersList] = useState();

    useEffect(() => {
        const list = getAllowedSalesProcessUsers();

        setUsersList(list);

        if (setDefault) {
            const currentUser = list.find(el => el.isCurrentUser);

            if (isMulti) {
                onChangeFunc(name, [currentUser]);
            }
            else {
                onChangeFunc(name, currentUser);
            }
        }
    }, [])


    return (
        <CustomSelect
            icon={icon}
            options={usersList}
            required={required}
            isClearable={isClearable}
            isMulti={isMulti}
            value={global.salesProcessUsers ? global.salesProcessUsers.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
            isSearchable
            placeholder={placeholder}
            onChange={(e) => onChangeFunc(name, e)}
            isDisabled={isDisabled}
            isLoading={block}
        />
    )
};

//#endregion

//#region Sales Process User Hotels
export const SalesProcessHotelCombo = ({ icon, required, isClearable, isMulti, value, placeholder, onChangeFunc, name, isDisabled, block }) => {
    const [hotelList, setHotelList] = useState([]);

    useEffect(() => {
        const currentUser = global.salesProcessUsers?.find(u => u.isCurrentUser);

        if (currentUser && global.hotelList) {
            if ((currentUser.role === 'Manager' || currentUser.role === 'SalesRep') && currentUser.hotelIds) {
                setHotelList(global.hotelList.filter(hotel => currentUser.hotelIds.some(hotelId => hotelId === hotel.value)));
            }
            else {
                setHotelList(global.hotelList);
            }
        }
    }, [])


    return (
        <CustomSelect
            icon={icon}
            options={hotelList}
            required={required}
            isClearable={isClearable}
            isMulti={isMulti}
            value={hotelList ? hotelList.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
            isSearchable
            placeholder={placeholder}
            onChange={(e) => onChangeFunc(name, e)}
            isDisabled={isDisabled}
            isLoading={block}
        />
    )
};
//#endregion


//#region User Access
const userPermissions = (componentHotelList, userHotelList, userId, componentUsersIds) => {
    return {
        'SalesProcessExecutiveReport': ['Admin', 'Manager', 'CentralUser', 'SalesRep'],
        'SalesSource': ['Admin', 'Manager', 'CentralUser'],
        'SalesPipeline': () => ['Admin'],
        'SalesLeads': ['Admin', 'SalesRep', 'Manager', 'CentralUser', 'HelpDeskUser'],
        'SalesPipelineStage': ['Admin', 'SalesRep', 'Manager', 'CentralUser', 'HelpDeskUser'],
        'SalesDealList': ['Admin', 'SalesRep', 'Manager', 'CentralUser', 'HelpDeskUser'],
        'SalesProcessGrid': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'SalesProcessTasks': ['Admin', 'CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser'],
        'SalesDealUsers': ['Admin', 'CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser'],
        'SalesDealUsers:edit': () => {
            let roles = ['Admin'];

            if (componentUsersIds?.some(el => el.value === userId)) {
                roles = roles.concat(['CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser']);
            }

            return roles;
        },
        'SalesDealUsers:manageAll': ['Admin'],
        'SalesProcessSettings': ['Admin', 'CentralUser', 'Manager'],
        'SalesProcessCustomization': ['Admin'],
        'SalesBudget': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'SalesLead:detail': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];

            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h.value)) && componentUsersIds?.some(el => el.value === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'SalesDealDetail': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];

            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h.value)) && componentUsersIds?.some(el => el.value === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'SalesDealDetail:proposal': () => {
            let roles = ['Admin'];

            if (componentUsersIds?.some(el => el.value === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'ImportDeals': ['Manager', 'SalesRep'],

        //Profile
        'ProfileDetails:deals': ['Admin', 'CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser'],
        'ProfileDetails:deals:details': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];
            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h)) && componentUsersIds?.some(el => el.userId === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'ProfileDetails:dealActions': ['Admin', 'CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser'],
        'ProfileDetails:dealActions:details': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];

            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h)) && componentUsersIds?.some(el => el.userId === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
    }
}

export const checkSalesProcessPermission = (name, role, componentHotelList, userHotelList, componentUsersIds, userId) => {
    var pagePermissions = userPermissions(componentHotelList, userHotelList, userId, componentUsersIds)[name];

    if (pagePermissions && !Array.isArray(pagePermissions)) {
        pagePermissions = pagePermissions();
    }


    return pagePermissions?.some(el => el === role);
}
//#endregion