import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, CustomInput, Row, Label, Input, Modal, ModalBody, Form, UncontrolledTooltip } from 'reactstrap';
import { getAPI, putAPI, deleteAPI } from "../Base/API";
import { StyledCard, ActiveInactiveStatusCombo, BlankCard } from "../Base/CommonUIComponents";
import { getEntityList, getComparationList, getFieldList, getConjunctionTypeList, getOperatorTypeList, getRuleTypeList, getDuplicateMatchTypeList, getMatchTypeOptions } from "../Base/ReferenceDataFunctions";
import { handleNotification } from '../Base/Notification';
import RuleTransformDetails from './RuleTransformDetails';
import RuleFiltersDetails from './RuleFiltersDetails';
import CustomSelect from '../Base/CustomSelect';

import Authorization from '../Base/Authorization';

export class RuleDetail extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.getRule = this.getRule.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.state = {
            block: false,
            rule: {
                isToCleanField: false
            },
            ruleList: [],
            comboRuleList:[],
            ruleFilters: [],
            rulesRegexTransform: [],
            modal: false,
            selectedRuleFilter: null,
            ruleTransformModal: false,
            selectedRuleTransform: null,
            ignoreNullOptions: [{ value: true, label: <FormattedMessage id="RuleDashboard.Ignore" /> }, { value: false, label: <FormattedMessage id="RuleDashboard.DoNotIgnore" /> }],
            matchText: false,
            tags: [],
            allTags: []
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getRule();
        }
        else {
            if (this.props.location && this.props.location.duplicateId) {
                this.getRule(this.props.location.duplicateId);
            }
            else {
                this.setState({ rule: { ...this.state.rule, ruleType: 'FieldCheck' } });
            }
        }

        this.getRegEx();
        this.getTagGroups();
    }
       
    getRegEx() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {              
                let res = [];
                data.response.map((el, k) => res.push({
                    'label': el.code,
                    'value': el.id,
                    'type': el.regularExpressionType
                }));
                this.setState({ ruleList: data.response, comboRuleList: res });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/regularExpressionRule');
    }

    getTagGroups = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                let tags = [], allTags = [];

                data.response && data.response.forEach(group => {
                    const obj = { label: group.code, options: [] };

                    group.tag && group.tag.forEach(tag => {
                        obj.options.push({ value: tag.id, label: tag.code });
                        allTags.push({ value: tag.id, label: tag.code });
                    });

                    tags.push(obj);
                })

                this.setState({ tags, allTags });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }

    getRule(duplicateId) {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const rule = data.response && data.response.length > 0 ? data.response[0] : { isToCleanField: false };

                if (duplicateId) {
                    rule.active = false;

                    delete rule.id;
                    delete rule.ruleFilters;
                    delete rule.rulesRegexTransform;
                }

                let ruleFilters = rule.ruleFilters ? rule.ruleFilters : [];
                let rulesRegexTransform = rule.rulesRegexTransform ? rule.rulesRegexTransform : [];

                this.setState({ rule, ruleFilters, rulesRegexTransform });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/Rule/' + (duplicateId ? duplicateId : this.props.match.params.id));
    }

    saveRule() {
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });
        let payload = { request: this.state.rule };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="RuleDetail.RuleSaved" />, <FormattedMessage id="generic.success" />);

                if (data.response && data.response.length > 0) {
                    if (!this.props.match.params.id) {
                        this.props.history.push({ pathname: `/RuleDetail/${data.response[0].id}` });
                    }

                    this.setState({ rule: data.response[0] });
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/Rule', payload);
    }

    handleChangeCombo = (name, el) => {
        const rule = { ...this.state.rule };
        const value = el ? el.value : null;

        rule[name] = value;

        if (name === 'entity') {
            rule.field = null
        }

        this.setState({ rule });
    };

    handleChange(evt) {       
        const value = evt.target ? evt.target.value : null;
        const name = evt.target.name;
        this.setState({
            rule: {
                ...this.state.rule,
                [name]: value
            }
        });
    };

    handleRuleType = (combo) => {
        this.setState({
            rule: {
                ...this.state.rule,
                ruleType: combo ? combo.value : null,
                entity: null,
                field: null,
                ignoreNull: false,
                rulesRegexId: null,
                duplicateMatchType: null,
                isToCleanField: false,
                tagId: null
            }
        });
    };

    handleSwitchChange(evt) {
        if (evt && evt.target) {
            const { rule } = this.state;
            const { name, checked } = evt.target;
            rule[name] = checked
            this.setState({ rule });
        }
    }

    filterData(event) {
        event.preventDefault();
        
        try {
            let ignoreRes = event.target.value;
            let applyRes = "";
            let matchText = false;
            let replaceRes = "";
            let appliedTransformRules = []

            const { rulesRegexTransform } = this.state;

            if(rulesRegexTransform &&  rulesRegexTransform.length > 0){

                rulesRegexTransform.forEach((trans) => {
                    if(trans.regularExpressionRule){
                        let rule = trans.regularExpressionRule.regularExpression;
                        let changeTo = trans.regularExpressionRule.regularExpressionTransform;
                        var flags = rule.replace(/.*\/([gimy]*)$/, '$1');
                        var pattern = rule.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
                        var regex = new RegExp(pattern, flags);
                        let replaceResDup = replaceRes.slice(); 

                        if(replaceRes && replaceRes.length > 0){
                            replaceRes = replaceRes.replace(regex, changeTo);
                        }
                        else{
                            replaceRes = ignoreRes.replace(regex, changeTo);
                        }
                        if(replaceRes !== ignoreRes && replaceRes !== replaceResDup){
                            appliedTransformRules.push(trans.name)
                        }
                    }
                });
            }

            if (this.state.rule.ignoreRulesRegexId !== null) {

                let regexIgnoreString = this.state.ruleList.find(el => el.id === this.state.rule.ignoreRulesRegexId).regularExpression;
                
                var flagsIgnore = regexIgnoreString.replace(/.*\/([gimy]*)$/, '$1');
                var patternIgnore = regexIgnoreString.replace(new RegExp('^/(.*?)/' + flagsIgnore + '$'), '$1');
                var regex = new RegExp(patternIgnore, flagsIgnore);
                
                ignoreRes = ignoreRes.replace(regex, " ");
            }

            if (this.state.rule.rulesRegexId !== null) {

                let regexString = this.state.ruleList.find(el => el.id === this.state.rule.rulesRegexId).regularExpression;

                var flags = regexString.replace(/.*\/([gimy]*)$/, '$1');
                var pattern = regexString.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
                var regex = new RegExp(pattern, flags);

                applyRes = ignoreRes.replace(regex, (match) => `<b className="color-green" id="match">${match}</b>`);

                let html = new DOMParser().parseFromString(applyRes, "text/html")

                if (html && html.body && html.body.firstChild && html.body.firstChild.id === "match") {
                    matchText = true;
                }
            }
            
            this.setState({
                textSample: event.target.value,
                ignoreRes: ignoreRes,
                applyRes: applyRes,
                matchText,
                replaceRes: replaceRes,
                appliedTransformRules: appliedTransformRules
            });

        }
        catch (e) {
            console.log(e);
        }

    }

    toggleModal = (modalName, selectedName, data) => {
        this.setState(prevState => ({
            [modalName]: !prevState[modalName],
            [selectedName]: data ? data : null
        }));
    }

    deleteRule = (e, id, type) => {
        e.preventDefault();

        deleteAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            else {
                if (type === 'RuleFilter') {
                    handleNotification(data, <FormattedMessage id="RuleDetail.RuleFilterDeleted" />, <FormattedMessage id="generic.success" />);
                    var rules = this.state.ruleFilters.filter(rf => rf.id !== id);
                    this.setState({ block: false, ruleFilters: rules });
                }
                else {
                    handleNotification(data, <FormattedMessage id="RuleDetail.RuleTransformDeleted" />, <FormattedMessage id="generic.success" />);
                    var rules = this.state.rulesRegexTransform.filter(rf => rf.id !== id);
                    this.setState({ block: false, rulesRegexTransform: rules });
                }
            }
        }, `/api/gms/DataQuality/v1/${type}/${id}`);
    }

    updateTable = (rule, ruleName) => {
        const list = [...this.state[ruleName]]
        this.setState({ block: true });

        const index = list && list.indexOf(list.find(rf => rf.id === rule.id));

        if (index === -1) {
            list.push(rule);
        }
        else {
            list[index] = rule;
        }

        this.setState({ [ruleName]: list, block: false });
    }

    addRemoveException = (evt, removeValue) => {
        evt.preventDefault();

        const { rule } = this.state;
        let exceptions = rule.exceptions ? rule.exceptions : [];

        if (removeValue) {
            exceptions = exceptions.filter(e => e !== removeValue);
        }
        else {
            exceptions.push(this.state.newException);
        }

        rule.exceptions = exceptions;

        this.setState({
            rule,
            newException: null
        });
    }

    validateRule = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="RuleDetail.VerificationInProgress" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ error: errorMessage, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gmsscheduletask/ScheduleTasks/ValidateRule?ruleId=${this.props.match.params.id}`);
    }

    render() {
        const { block, error, modal, ruleTransformModal, ruleFilters, rulesRegexTransform, ignoreNullOptions, exceptionModal, appliedTransformRules, replaceRes, tags, allTags } = this.state;

        const showCleanOption = this.state.rule.ruleType === 'FieldCheck' || this.state.rule.ruleType === 'OtaEmailCheck' || (this.state.rule.ruleType === 'MasterDuplicationCheck' && this.state.rule.duplicateMatchType === "ContactNumber") || this.state.rule.ruleType === 'OthersCheck';

        return (
            <StyledCard block={block} error={error} >
                {this.state.rule ? <>
                    <Row className="mb-2">
                        <Col>
                            <h5>
                                <i className="fas fa-list-ol mr-2"></i>
                                <FormattedMessage id="RuleDetail.title" />
                            </h5>                        
                        </Col>

                        <Col className="text-right">
                            {this.props.match.params.id && this.state.rule.ruleType === 'FieldCheck' ?
                                <>
                                    <Button className="btn btn-sm btn-host ml-2" onClick={this.validateRule} id="validateRule">
                                        <i className="fas fa-clipboard-check" />
                                    </Button>

                                    <UncontrolledTooltip placement="left" target="validateRule" >
                                        <FormattedMessage id="RuleDetail.CheckRule" />
                                    </UncontrolledTooltip>
                                </>
                            : ''}

                            <Authorization
                                perform="ruleDetail:save"
                                yes={() => (
                                    <Button className="btn btn-sm btn-host ml-2" onClick={this.saveRule.bind(this)}>
                                        <i className="fas fa-save"></i>
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>

                    <form ref={this.form}>
                        <Row>
                            <Col>
                                <b><FormattedMessage id="RuleDetail.RuleDefiniton" /></b>
                                <hr className="my-1"/>
                                <Row className="my-2">
                                    <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.Name" /></Label></Col>
                                    <Col><Input type="text" name="name" required value={this.state.rule.name ? this.state.rule.name : ''} onChange={(evt) => this.handleChange(evt)} maxLength={50}> </Input> </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.Description" /></Label></Col>
                                    <Col><Input type="text" name="description" required value={this.state.rule.description ? this.state.rule.description : ''} onChange={(evt) => this.handleChange(evt)}> </Input> </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.Status" /></Label></Col>
                                    <Col style={{ zIndex: 35 }}>
                                        <ActiveInactiveStatusCombo
                                            name='active'
                                            onChangeFunc={this.handleChangeCombo.bind(this)}
                                            placeholder={<FormattedMessage id="RuleDetail.SelectStatus" />}
                                            value={this.state.rule.active !== null ? this.state.rule.active : ''}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-3"><Label> <FormattedMessage id="RuleFiltersDetails.RuleType" /></Label></Col>
                                    <Col style={{ zIndex: 30 }}>
                                        <CustomSelect
                                            options={getRuleTypeList(this.props.intl)}
                                            required={true}
                                            isClearable
                                            isMulti={false}
                                            value={this.state.rule.ruleType && getRuleTypeList(this.props.intl).find(el => el.value === this.state.rule.ruleType) || ''}
                                            isSearchable
                                            onChange={this.handleRuleType.bind(this)}
                                            placeholder={<FormattedMessage id="RuleDetail.SelectType" />}
                                        />
                                    </Col>
                                </Row>
                                {this.state.rule.ruleType === 'FieldCheck' ?
                                    <>
                                        <Row className="mb-2">
                                            <Col className="col-3"><Label> <FormattedMessage id="RuleFiltersDetails.Entity" /></Label></Col>
                                            <Col style={{ zIndex: 25 }}>
                                                <CustomSelect
                                                    options={getEntityList()}
                                                    required={true}
                                                    isClearable
                                                    isMulti={false}
                                                    value={this.state.rule.entity && getEntityList().find(el => el.value === this.state.rule.entity) || ''}
                                                    isSearchable
                                                    onChange={this.handleChangeCombo.bind(this, 'entity')}
                                                    placeholder={<FormattedMessage id="RuleDetail.SelectEntity" />}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="col-3"><Label> <FormattedMessage id="RuleFiltersDetails.Field" /></Label></Col>
                                            <Col style={{ zIndex: 20 }}>
                                                <CustomSelect
                                                    options={getFieldList(this.state.rule.entity, this.props.intl)}
                                                    required={true}
                                                    isClearable
                                                    isDisabled={this.state.rule.entity === null}
                                                    isMulti={false}
                                                    value={this.state.rule.field && getFieldList(this.state.rule.entity, this.props.intl).find(el => el.value === this.state.rule.field) || ''}
                                                    onChange={this.handleChangeCombo.bind(this, 'field')}
                                                    isSearchable
                                                    placeholder={<FormattedMessage id="RuleDetail.SelectField" />}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="col-3"><Label> <FormattedMessage id="RuleDashboard.NullValue" /></Label></Col>
                                            <Col style={{ zIndex: 15 }}>
                                                <CustomSelect
                                                    options={ignoreNullOptions}
                                                    required={true}
                                                    isClearable
                                                    isMulti={false}
                                                    value={ignoreNullOptions.find(el => el.value === this.state.rule.ignoreNull) || ''}
                                                    isSearchable
                                                    onChange={this.handleChangeCombo.bind(this, 'ignoreNull')}
                                                    placeholder={<FormattedMessage id="RuleDashboard.NullValue" />}
                                                />
                                            </Col>
                                        </Row>
                                        {/*<Row className="mb-2">
                                            <Col className="col-3"><Label><FormattedMessage id="RuleDetail.Comparation" /> </Label></Col>
                                            <Col> <CustomSelect
                                                icon={"fas fa-greater-than"}
                                                options={getComparationList()}
                                                required={true}
                                                isClearable
                                                isMulti={false}
                                                value={this.state.rule.comparisonType && getComparationList().find(el => el.value === this.state.rule.comparisonType)}
                                                onChange={this.handleChangeCombo.bind(this, 'comparisonType')}
                                                isSearchable
                                                placeholder={<FormattedMessage id="RuleDetail.SelectCompare" />}

                                            /></Col>
                                        </Row>*/}
                                        <Row className="mb-2">
                                            <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.Ignore" /> </Label></Col>
                                            <Col style={{ zIndex: 10 }}>
                                                <CustomSelect
                                                    icon={"fas fa-not-equal"}
                                                    options={this.state.comboRuleList && this.state.comboRuleList.filter(el => el.type === 'Ignore')}
                                                    required={false}
                                                    isClearable
                                                    isMulti={false}
                                                    value={this.state.comboRuleList.find(el => el.value === this.state.rule.ignoreRulesRegexId) || ''}
                                                    onChange={this.handleChangeCombo.bind(this, 'ignoreRulesRegexId')}
                                                    isSearchable
                                                    placeholder={<FormattedMessage id="RuleDetail.SelectRule" />}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="col-3"></Col>
                                            <Col>{this.state.ruleList.find(el => el.id === this.state.rule.ignoreRulesRegexId) ? this.state.ruleList.find(el => el.id === this.state.rule.ignoreRulesRegexId).description : ''}</Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="col-3"><Label>  <FormattedMessage id="RuleDetail.Apply" /> </Label></Col>
                                            <Col style={{ zIndex: 5 }}>
                                                <CustomSelect
                                                    icon={"fas fa-equals"}
                                                    options={this.state.comboRuleList}
                                                    required={true}
                                                    isClearable
                                                    isMulti={false}
                                                    value={this.state.comboRuleList.find(el => el.value === this.state.rule.rulesRegexId) || ''}
                                                    isSearchable
                                                    placeholder={<FormattedMessage id="RuleDetail.SelectRule" />}
                                                    onChange={this.handleChangeCombo.bind(this, 'rulesRegexId')}

                                                /> </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="col-3"></Col>
                                            <Col><b>{this.state.ruleList.find(el => el.id === this.state.rule.rulesRegexId) ? this.state.ruleList.find(el => el.id === this.state.rule.rulesRegexId).description : ''}</b></Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col className="col-3">
                                                <Label>  <FormattedMessage id="RuleDetail.Tag" /> </Label>
                                            </Col>
                                            <Col>
                                                <CustomSelect options={tags} onChange={this.handleChangeCombo.bind(this, 'tagId')} value={allTags.find(tg => tg.value === this.state.rule.tagId)} isClearable isSearchable />
                                            </Col>
                                        </Row>
                                    </>
                                :
                                    this.state.rule.ruleType === 'OtaEmailCheck' ?
                                        <Row className="mb-2">
                                            <Col className="col-3">
                                                <Label> <FormattedMessage id="RuleDetail.Channel" /> </Label>
                                            </Col>
                                            <Col>
                                                <CustomSelect
                                                    options={getMatchTypeOptions(this.state.rule.ruleType, this.props.intl)}
                                                    required={true}
                                                    isClearable
                                                    isMulti={false}
                                                    value={getDuplicateMatchTypeList(this.props.intl).find(el => el.value === this.state.rule.duplicateMatchType) || ''}
                                                    isSearchable
                                                    placeholder={<FormattedMessage id="RuleDetail.Channel" />}
                                                    onChange={this.handleChangeCombo.bind(this, 'duplicateMatchType')}

                                                />
                                            </Col>
                                        </Row>
                                    :
                                        <Row className="mb-2">
                                            <Col className="col-3">
                                                <Label>  <FormattedMessage id="RuleDetail.Rule" /> </Label>
                                            </Col>
                                            <Col>
                                                <CustomSelect
                                                    icon={"fas fa-equals"}
                                                    options={getMatchTypeOptions(this.state.rule.ruleType, this.props.intl)}
                                                    required={true}
                                                    isClearable
                                                    isMulti={false}
                                                    value={getDuplicateMatchTypeList(this.props.intl).find(el => el.value === this.state.rule.duplicateMatchType) || ''}
                                                    isSearchable
                                                    placeholder={<FormattedMessage id="RuleDetail.SelectDuplicateMatchType" />}
                                                    onChange={this.handleChangeCombo.bind(this, 'duplicateMatchType')}

                                                />
                                            </Col>
                                        </Row>
                                }

                                {showCleanOption ?
                                    <Row className="align-items-center">
                                        <Col className="col-3">
                                            <Label>
                                                <FormattedMessage id="RuleDetail.CleanField" />
                                            </Label>
                                        </Col>
                                        <Col style={{ zIndex: 0 }} className="d-flex">
                                            <CustomInput
                                                type="switch"
                                                id="isToCleanField"
                                                name="isToCleanField"
                                                onChange={(e) => this.handleSwitchChange(e)}
                                                checked={this.state.rule.isToCleanField}
                                            />
                                            {this.state.rule.isToCleanField && ((this.state.rule.entity && (this.state.rule.entity === 'ProfileContact' || this.state.rule.entity === 'ProfileDocument')) || (this.state.rule.ruleType === 'MasterDuplicationCheck' && this.state.rule.duplicateMatchType === "ContactNumber")) ?
                                                <span>
                                                    <i className="icon-icon-warnings-enabled color-yellow ml-3 mr-2" />
                                                    <FormattedMessage id={`RuleDetail.${this.state.rule.entity || 'ProfileContact'}DataWillBeDeleted`} /> 
                                                </span>
                                            : ''}
                                        </Col>
                                    </Row>
                                    : this.state.rule.ruleType === 'MasterDuplicationCheck' && (this.state.rule.duplicateMatchType === "SecondaryMailOnly" || this.state.rule.duplicateMatchType === "SecondaryMobileOnly" || this.state.rule.duplicateMatchType === "SecondaryPhoneOnly") ?
                                        <Row>
                                            <Col className="col-3">
                                                <Label>
                                                    <FormattedMessage id="RuleDetail.PromoteToMain" />
                                                </Label>
                                            </Col>
                                            <Col style={{ zIndex: 0 }} className="d-flex">
                                                <CustomInput
                                                    type="switch"
                                                    id="isToCleanField"
                                                    name="isToCleanField"
                                                    onChange={(e) => this.handleSwitchChange(e)}
                                                    checked={this.state.rule.isToCleanField}
                                                />
                                            </Col>
                                        </Row>
                                : ''}
                            </Col>

                            {this.state.rule.ruleType === 'FieldCheck' ?
                                <Col>
                                    <b> <FormattedMessage id="RuleDetail.RuleValidation" /></b>
                                    <hr className="my-1" />
                                    <Row className="my-2">
                                        <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.FilledSample" /></Label></Col>
                                        <Col><Input type="textarea" rows="2" onChange={(e) => this.filterData(e)}  > </Input> </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleAfterIgnore" /></Label></Col>
                                        <Col>{this.state.ignoreRes}</Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleDetection" /></Label></Col>
                                        <Col><div dangerouslySetInnerHTML={{ __html: this.state.applyRes }}></div></Col>
                                        <Col className="col-1 text-center">
                                            {this.state.matchText ?
                                                <>
                                                    <i className="color-green fas fa-check-circle" id="check-circle"/>

                                                    <UncontrolledTooltip placement="left" target="check-circle" >
                                                        <FormattedMessage id="RuleDetail.ValidText" />
                                                    </UncontrolledTooltip>
                                                </>
                                                : <>
                                                    <i className="color-light-red fas fa-times-circle" id="times-circle"></i>

                                                    <UncontrolledTooltip placement="left" target="times-circle" >
                                                        <FormattedMessage id="RuleDetail.RuleNotApplied" />
                                                    </UncontrolledTooltip>
                                                </>}
                                        </Col>
                                    </Row>
                                    {rulesRegexTransform && rulesRegexTransform.length > 0 &&
                                        <Row className="mb-2">
                                            <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleReplacement" /></Label></Col>
                                            <Col>
                                                {replaceRes}
                                            </Col>
                                            <Col className="col-1 text-center">
                                                {appliedTransformRules && appliedTransformRules.length > 0 ?
                                                    <>
                                                        <i className="color-green fas fa-check-circle" id="Transform-check-circle"/>

                                                        <UncontrolledTooltip placement="left" target="Transform-check-circle" >
                                                            <div style={{ textAlign: 'left' }}>
                                                                {appliedTransformRules.map((rule) => 
                                                                    <div>{rule}</div>
                                                                )}
                                                            </div>
                                                        </UncontrolledTooltip>
                                                    </>
                                                    :
                                                    <>
                                                        <i className="color-light-red fas fa-times-circle" id="Transform-times-circle"></i>

                                                        <UncontrolledTooltip placement="left" target="Transform-times-circle" >
                                                            <FormattedMessage id="RuleDetail.RuleNotApplied" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            : ''}
                        </Row>
                    </form>


                    {this.state.rule.ruleType !== 'OthersCheck' &&
                        <Row className="mt-5">
                            <Col>
                                <Row>
                                    <Col><b><FormattedMessage id="RuleDetail.Exceptions" /></b></Col>

                                    <Authorization
                                        perform="ruleDetail:exceptions:add"
                                        yes={() => (
                                            <Col className="text-right">
                                                <Button className="btn btn-sm btn-host" onClick={() => this.toggleModal('exceptionModal', 'newException', null)} >
                                                    <i className="fas fa-plus"></i>
                                                </Button>
                                            </Col>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </Row>

                                <hr className="my-1" />

                                <Row className="mb-3">
                                    <Col><b><FormattedMessage id="RuleDetail.Value" /></b></Col>
                                    <Authorization
                                        perform="ruleDetail:ruleTransform:delete"
                                        yes={() => (
                                            <Col className="col-1 text-right" />
                                        )}
                                        no={() => ''}
                                    />
                                </Row>
                                {this.state.rule.exceptions && this.state.rule.exceptions.map((exception, key) =>
                                    <Row className="mb-2 overbglight" key={key}>
                                        <Col>
                                            <Row>
                                                <Col>{exception}</Col>
                                            </Row>
                                        </Col>
                                        <Authorization
                                            perform="ruleDetail:ruleTransform:delete"
                                            yes={() => (
                                                <Col className="col-1 text-right">
                                                    <Button className="btn btn-sm btn-host" onClick={(e) => this.addRemoveException(e, exception)}>
                                                        <i className="fas fa-trash"></i>
                                                    </Button>
                                                </Col>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    }

                    {this.state.rule.ruleType === 'FieldCheck' ?
                        <div>
                            <Row className="mt-5">
                                <Col>
                                    <Row>
                                        <Col><b><FormattedMessage id="RuleDetail.RuleFilters" /></b></Col>

                                        <Authorization
                                            perform="ruleDetail:filters:add"
                                            yes={() => (
                                                <Col className="text-right">
                                                    <Button className="btn btn-sm btn-host" onClick={() => this.toggleModal('modal', 'selectedRuleFilter', null)} disabled={this.state.rule == null || this.state.rule.id == null}>
                                                        <i className="fas fa-plus"></i>
                                                    </Button>
                                                </Col>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Row>

                                    <hr className="my-1" />
                                    <Row className="mb-3">
                                        <Col><b><FormattedMessage id="RuleFiltersDetails.Entity" /></b></Col>
                                        <Col><b><FormattedMessage id="RuleFiltersDetails.Field" /></b></Col>
                                        <Col><b><FormattedMessage id="RuleFiltersDetails.ConjunctionType" /></b></Col>
                                        <Col><b><FormattedMessage id="RuleFiltersDetails.OperatorType" /></b></Col>
                                        <Col><b><FormattedMessage id="RuleFiltersDetails.Values" /></b></Col>
                                        <Authorization
                                            perform="ruleDetail:filters:delete"
                                            yes={() => (
                                                <Col className="col-1 text-right" />
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Row>
                                    {ruleFilters && ruleFilters.map((el, key) => {
                                        const entity = getEntityList().find(e => e.value === el.entity);
                                        const field = getFieldList(el.entity, this.props.intl).find(f => f.value === el.field);
                                        const fieldOptions = field?.fieldOptions;

                                        return <Row className="mb-2 overbglight" style={{ cursor: 'pointer' }} key={key}>
                                            <Col onClick={() => this.toggleModal('modal', 'selectedRuleFilter', el)}>
                                                <Row>
                                                    <Col> {entity ? entity.label : ''}</Col>
                                                    <Col> {field ? field.label : ''}</Col>
                                                    <Col> {getConjunctionTypeList().find(ct => ct.value === el.conjunctionType) ? getConjunctionTypeList().find(ct => ct.value === el.conjunctionType).label : ''}</Col>
                                                    <Col> {getOperatorTypeList().find(ot => ot.value === el.operatorType) ? getOperatorTypeList().find(ot => ot.value === el.operatorType).label : ''}</Col>
                                                    <Col> {el.values ?
                                                        fieldOptions ? el.values.map(v => fieldOptions.find(el => el.value.toString() === v.toString())?.label).join(', ')
                                                           : el.values.join(', ') : ''}
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Authorization
                                                perform="ruleDetail:filters:delete"
                                                yes={() => (
                                                    <Col className="col-1 text-right">
                                                        <Button className="btn btn-sm btn-host" onClick={(e) => this.deleteRule(e, el.id, 'RuleFilter')}>
                                                            <i className="fas fa-trash"></i>
                                                        </Button>
                                                    </Col>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Row>
                                    })}
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col>
                                    <Row>
                                        <Col><b><FormattedMessage id="RuleDetail.RuleTransform" /></b></Col>

                                        <Authorization
                                            perform="ruleDetail:ruleTransform:add"
                                            yes={() => (
                                                <Col className="text-right">
                                                    <Button className="btn btn-sm btn-host" onClick={() => this.toggleModal('ruleTransformModal', 'selectedRuleTransform', null)} disabled={this.state.rule == null || this.state.rule.id == null}>
                                                        <i className="fas fa-plus"></i>
                                                    </Button>
                                                </Col>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Row>

                                    <hr className="my-1" />
                                    <Row className="mb-3">
                                        <Col><b><FormattedMessage id="RuleDetail.Name" /></b></Col>
                                        <Col><b><FormattedMessage id="RuleDetail.Code" /></b></Col>
                                        <Col><b><FormattedMessage id="RuleDetail.Description" /></b></Col>
                                        <Authorization
                                            perform="ruleDetail:ruleTransform:delete"
                                            yes={() => (
                                                <Col className="col-1 text-right" />
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Row>
                                    {rulesRegexTransform && rulesRegexTransform.map((el, key) =>
                                        <Row className="mb-2 overbglight" style={{ cursor: 'pointer' }} key={key}>
                                            <Col onClick={() => this.toggleModal('ruleTransformModal', 'selectedRuleTransform', el)}>
                                                <Row>
                                                    <Col>{el.name}</Col>
                                                    <Col>{el.regularExpressionRule.code}</Col>
                                                    <Col>{el.regularExpressionRule.description}</Col>
                                                </Row>
                                            </Col>
                                            <Authorization
                                                perform="ruleDetail:ruleTransform:delete"
                                                yes={() => (
                                                    <Col className="col-1 text-right">
                                                        <Button className="btn btn-sm btn-host" onClick={(e) => this.deleteRule(e, el.id, 'ruleTransform')}>
                                                            <i className="fas fa-trash"></i>
                                                        </Button>
                                                    </Col>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    : ''}

                    {modal ?
                        <RuleFiltersDetails
                            modal={modal}
                            toggleModal={() => this.toggleModal('modal', 'selectedRuleFilter', null)}
                            selectedRuleFilter={this.state.selectedRuleFilter}
                            updateTable={this.updateTable}
                            ruleId={this.props.match.params.id}
                        />
                    : ''}


                    {ruleTransformModal ?
                        <RuleTransformDetails
                            ruleList={this.state.ruleList}
                            modal={ruleTransformModal}
                            toggleModal={() => this.toggleModal('ruleTransformModal', 'selectedRuleTransform', null)}
                            selectedRuleTransform={this.state.selectedRuleTransform}
                            updateTable={this.updateTable}
                            ruleId={this.props.match.params.id}
                        />
                    : ''}

                    {exceptionModal ?
                        <Modal isOpen={exceptionModal} size="md" style={{ minWidth: 0 }}>
                            <Button onClick={() => this.toggleModal('exceptionModal', 'newException', null)} className="closeModal">
                                <i className="fas fa-times fa-sm"></i>
                            </Button>
                            <ModalBody>
                                <BlankCard>
                                    <Form onSubmit={(e) => { this.addRemoveException(e); this.toggleModal('exceptionModal', 'newException', null) }}>
                                        <Row className="mb-3">
                                            <Col>
                                                <h5> <FormattedMessage id="RuleDetail.AddException" /></h5>
                                            </Col>
                                            <Col className="text-right col-2">
                                                <Button className="btn-sm btn-host" type="submit">
                                                    <i className="fas fa-save" />
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm={2}> <FormattedMessage id="RuleDetail.Value" /></Label>
                                            <Col sm={10}>
                                                <Input type="text" required onChange={(e) => this.setState({ newException: e.target.value }) }/>
                                            </Col>
                                        </Row>
                                    </Form>
                                </BlankCard>
                            </ModalBody>
                        </Modal>
                    : ''}
                </>
            : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(RuleDetail)
