import React, { Component } from 'react';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getGenderOptions, getISSOptions, getProfileType } from '../Base/ReferenceDataFunctions';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';
import countryList from 'react-select-country-list';
import { handleNotification } from '../Base/Notification';
import { putAPI } from '../Base/API';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';

class CreateProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            profile: {}
        };
    }

    createProfile = (e) => {
        e.preventDefault();
        this.setState({ block: true }, () => {
            const { associateNewProfile } = this.props;
            const { profile } = this.state;
    
            putAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="CreateProfile.ProfileCreated" />, <FormattedMessage id="generic.success" />);
                        
                        const newProfile = data.response[0];

                        associateNewProfile(newProfile.id, newProfile.firstName, newProfile.lastName);
                        return;
                    }
                    this.setState({ block: false });
                }
            }, `/api/gms/Profile/v1/profile`, JSON.stringify({ request: profile }))
        });
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            profile: {
                ...prevState.profile,
                [name]: combo ? combo.value : null
            }
        }));
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;
        this.setState(prevState => ({
            profile: {
                ...prevState.profile,
                [name]: value
            }
        }));
    }

    render() {
        const { block, error, profile } = this.state;

        return (
            <BlockUi block={block}>
                <ErrorAlert error={error} />
                <Form className='py-2' onSubmit={this.createProfile}>
                    <div className="mb-3 mt-2 d-flex align-items-center justify-content-between">
                        <div>
                            <h5>
                                <i className="fas fa-user mr-2"/>
                                <FormattedMessage id="CreateProfile.CreateProfile"/>
                            </h5>
                        </div>
                        <div className="text-right">
                            <Button className="btn-sm btn-host" type='submit'>
                                <i className="fas fa-save" />
                            </Button>
                        </div>
                    </div>
                    <FormGroup row>
                        <Label sm={2} >
                            <FormattedMessage id="ProfileDetails.EntityType" />
                        </Label>
                        <Col className="col-2">
                            <CustomSelect
                                placeholder=""
                                options={getProfileType(this.props.intl)}
                                value={getProfileType(this.props.intl).find(t => t.value === profile.type) || ''}
                                onChange={this.handleSelect.bind(this, 'type')}
                                isClearable={false}
                                isSearchable={false}
                                required
                            />
                        </Col>
                        <Label sm={2} id="Gender">
                            <FormattedMessage id="ProfileDetails.Gender" />
                        </Label>
                        <Col sm={2}>
                            <CustomSelect
                                placeholder=""
                                options={getGenderOptions(this.props.intl)}
                                value={getGenderOptions(this.props.intl).find(g => g.value === profile.gender) || ''}
                                onChange={this.handleSelect.bind(this, 'gender')}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Label sm={2} id="BirthDate">
                            <FormattedMessage id="ProfileDetails.DateOfBirth" />
                        </Label>
                        <Col sm={2}>
                            <SingleDatePicker
                                id="dateOfBirth"
                                isOutsideRange={day => day > moment()}
                                date={profile.birthDate ? moment(profile.birthDate) : null}
                                focused={this.state.focused}
                                onFocusChange={({ focused }) => this.setState({ focused })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                onDateChange={date => this.setState({ profile: { ...this.state.profile, birthDate: date.format('YYYY-MM-DD') } })}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2} id="FirstName">
                            <FormattedMessage id="ProfileDetails.Name" />
                        </Label>
                        <Col sm={2}>
                            <Input
                                type="text"
                                maxLength="100"
                                name="firstName"
                                required={profile.type === 'Person'}
                                value={profile.firstName ? profile.firstName : ''}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Label sm={2} id="LastName">
                            <FormattedMessage id="ProfileDetails.Surname" />
                        </Label>
                        <Col sm={2}>
                            <Input
                                type="text"
                                maxLength="100"
                                name="lastName"
                                required
                                value={profile.lastName ? profile.lastName : ''}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Label sm={2}>
                            <FormattedMessage id="ProfileDetails.OtherNames" />
                        </Label>
                        <Col sm={2}>
                            <Input
                                type="text"
                                maxLength="100"
                                name="middleName"
                                value={profile.middleName || ''}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2} id="FullName" className="pr-0">
                            <FormattedMessage id="ProfileDetails.FullName" />
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="text"
                                name="fullName"
                                value={profile.fullName ? profile.fullName : ''}
                                maxLength="200"
                                onChange={this.handleChange}
                                readOnly
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2} id="Nationality">
                            <FormattedMessage id="ProfileDetails.Nationality" />
                        </Label>
                        <Col sm={2}>
                            <CustomSelect
                                options={countryList().getData()}
                                placeholder=""
                                value={profile.nationality ? countryList().getData().find(country => country.value === profile.nationality) : ''}
                                onChange={this.handleSelect.bind(this, 'nationality')}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Label sm={2} id="Language">
                            <FormattedMessage id="ProfileDetails.Language" />
                        </Label>
                        <Col sm={2}>
                            <Input
                                type="text"
                                className="shadow"
                                maxLength="5"
                                name="language"
                                value={profile.language ? profile.language : ''}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Label sm={2} className="pr-0">
                            <FormattedMessage id="ProfileDetails.ISS" />
                        </Label>
                        <Col sm={2}>
                            <CustomSelect
                                options={getISSOptions(this.props.intl)}
                                placeholder=""
                                value={getISSOptions(this.props.intl).find(el => el.value == profile.iss) || ''}
                                onChange={this.handleSelect.bind(this, 'iss')}
                                isClearable
                                isSearchable
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className='mt-3'>
                        <Col sm={12}>
                            <h5 className="m-0">
                                <i className="fas fa-highlighter mr-2"/>
                                <FormattedMessage id="ProfileDetails.GeneralNotes" />
                            </h5>
                        </Col>
                        <Col sm={12} className='mt-2'>
                            <Input
                                type="textarea"
                                name="guestInfo"
                                className="py-2"
                                style={{ minHeight: '150px' }}
                                value={profile.guestInfo || ''}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </FormGroup>
                </Form>
            </BlockUi>
        );
    }
}

export default injectIntl(CreateProfile);