import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ProfileDefaultDetail } from './ProfileDefaultDetail';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { ProfileHistory } from '../Base/HistoryComponents';
import { DefaultPage } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { deepClone } from '../Base/ReferenceDataFunctions';


export class ProfileDefault extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            modal: false,
            defaultTexts: null,
            title: null,
            deleteModal: false
        };
    }

    componentDidMount() {
        this.canCreate(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isMaster !== nextProps.isMaster || this.props.profileDefault !== nextProps.profileDefault || this.props.hotels !== nextProps.hotels) {
            this.canCreate(nextProps);
        }
    }

    canCreate = (props) => {
        let canCreate = false;
        let hotelList = props.hotels ? deepClone(props.hotels) : [];

        if (props.isMaster) {
            hotelList.forEach(hotel => {
                if (props.profileDefault?.some(el => el.hotelId === hotel.value)) {
                    hotel.isDisabled = true;
                }
                else {
                    hotel.isDisabled = false;
                }
            })

            canCreate = hotelList.some(h => !h.isDisabled);
        }
        else {
            canCreate = false;
        }

        this.setState({ hotels: hotelList, canCreate });
    }

    toggleModal() {
        this.setState(prevState => ({ modal: !prevState.modal, defaultTexts: null, title: <FormattedMessage id="ProfileDetails.CreateNewDefault" /> }));
    }

    toggleDeleteModal = (selectedDefault) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedDefault
        }));
    }

    removeDefault = () => {
        this.props.removeElement(this.props.profileId, this.state.selectedDefault.id, 'default', 'profileDefault');

        this.toggleDeleteModal();
    }

    render() {
        const { block, profileDefault, profileId, updateTable, isMaster, canEditProfile } = this.props;
        const { modal, title, defaultTexts, deleteModal, canCreate, hotels } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                {
                    modal ?
                        <ProfileDefaultDetail
                            isOpen={modal}
                            toggle={this.toggleModal}
                            title={title}
                            defaultTexts={defaultTexts}
                            updateTable={updateTable}
                            profileId={profileId}
                            hotels={hotels || []}
                        />
                        :
                        <div />
                }
                <Row className="mb-2 mt-5">
                    <Col>
                        <h5>
                            <i className="icon-icon-e-goi-config mr-2" />
                            <FormattedMessage id="ProfileDetails.Default" />
                        </h5>
                    </Col>

                    <Col className="text-right">
                        <ProfileHistory
                            component={"default"}
                            profileId={this.props.profileId}
                        />

                        {canCreate && canEditProfile && 
                            <Authorization
                                perform="profileDefault:add"
                                yes={() =>
                                    <Button className="btn-sm btn-host ml-2" onClick={this.toggleModal}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                }
                                no={() => <div></div>}
                            />
                        }
                    </Col>
                </Row>

                {profileDefault?.map((pd, i) =>
                    <Row className="mb-3" key={i}>
                        <Col>
                            <Card className="border-0 mb-1 shadow">
                                <CardBody className="py-2">
                                    <Row className="mb-3 border-bottom pb-2 pt-1">
                                        <Col className='col-10 text-truncate'>
                                            <i className="icon-icon-hotel" /> {pd.hotelName}
                                        </Col>
                                        <Col className='col-2 text-right'>
                                            <Authorization
                                                perform="profileDefault:delete"
                                                yes={() => canEditProfile && (
                                                    <span onClick={() => this.toggleDeleteModal(pd)} style={{ cursor: 'pointer' }}>
                                                        <i className="fas fa-trash-alt" />
                                                    </span>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </Col>
                                    </Row>
                                    <div style={{ cursor: 'pointer' }} onClick={_ => { if (canEditProfile && CheckAuthorization("profileDefault:add", null)) this.setState({ modal: true, defaultTexts: pd, title: <FormattedMessage id="ProfileDetails.EditDefault" /> }) }}>         
                                        <Row>
                                            <Col >
                                                <div className="mb-2"><b>Stay</b></div>
                                                <Row>
                                                    <Col className='col-6 col-lg-4'>
                                                        <div className="title-sm text-truncate"><FormattedMessage id="ProfileDetails.Package" /></div>
                                                        {pd.defaultPackage || '-'}
                                                    </Col>
                                                    <Col className='col-6 col-lg-4'>
                                                        <div className="title-sm text-truncate"><FormattedMessage id="ProfileDetails.RoomCategory" /></div>
                                                        {pd.defaultRoomCategory || '-'}
                                                    </Col>
                                                    <Col className='col-12 col-lg-4'>
                                                        <div className="title-sm text-truncate mt-2 mt-lg-0"><FormattedMessage id="ProfileDetails.Room" /></div>
                                                        {pd.defaultRoom || '-'}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col >
                                                <div className="mb-2"><b>Discounts</b></div>
                                                <Row>
                                                    <Col className='col-6 col-lg-4'>
                                                        <div className="title-sm text-truncate"><FormattedMessage id="ProfileDetails.Discount" /></div>
                                                        {pd.defaultDiscount || '-'}
                                                    </Col>
                                                    <Col className='col-6 col-lg-4'>
                                                        <div className="title-sm text-truncate"><FormattedMessage id="ProfileDetails.DiscountRule" /></div>
                                                        {pd.defaultDiscountRule || '-'}
                                                    </Col>
                                                    <Col className='col-12 col-lg-4'>
                                                        <div className="title-sm text-truncate mt-2 mt-lg-0"><FormattedMessage id="ProfileDetails.PriceList" /></div>
                                                        {pd.defaultPricelist || '-'}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <div className="mb-2 mt-4"><b>Restaurant</b></div>
                                                <Row>
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="ProfileDetails.RestaurantTable" /></div>
                                                        {pd.defaultRestaurantTable || '-'}
                                                    </Col>
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="ProfileDetails.PriceListPOS" /></div>
                                                        {pd.defaultPricelistPOS || '-'}
                                                    </Col>
                                                    <Col />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}

                {profileDefault == null || profileDefault.length == 0 ? 
                    <DefaultPage text="ProfileDetails.NoDefaultsAvailable" icon="icon-icon-e-goi-config" height='30vh' />
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeDefault}
                        text={<FormattedMessage id="ProfileDefault.ConfirmDeleteText" />}
                    />
                : ''}
            </BlockUi>
        );
    }
}