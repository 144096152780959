import React, { Component } from 'react';
import CoolDataGrid from '../../Base/CoolDataGrid/CoolDataGrid';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import SpaceCell from './SpaceCell';
import SpacePopover from './SpacePopover';
import SpaceList from './SpaceList';
import SpacePopup from './SpacePopup';

class SalesDealDetailProposalEventsSpaces extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewOptions: [
                { value: "Grid", label: this.props.intl.formatMessage({ id: "SalesProcess.SpacesGrid" }), icon: 'mr-2 fas fa-th' },
                { value: "List", label: this.props.intl.formatMessage({ id: "SalesProcess.SpacesList" }), icon: 'mr-2 fas fa-list' }
            ],
            selectedView: "Grid",
            openOptions: false,
            detailPopOver: false,
            selectedCell: null,
            isOrphan: null,
            draggingRow: null,
            spaceReservationId: null,
            detailPopup: false
        };
    }

    componentWillReceiveProps(nextProps){
        const { startDate, endDate } = this.props;
        if(nextProps.startDate !== startDate || nextProps.endDate !== endDate){
            this.inicialCalc(nextProps.spacesOptions, nextProps.spacesAvail, nextProps.startDate, nextProps.endDate);
        }
    }

    componentDidMount(){
        const { spacesOptions, spacesAvail, startDate, endDate } = this.props;

        this.inicialCalc(spacesOptions, spacesAvail, startDate, endDate);
    }

    inicialCalc = (spacesOptions, spacesAvail, startDate, endDate) => {
        const { intl, eventTypeOptions, customStatusOptions, eventData } = this.props;
        const { draggingRow, selectedCell } = this.state;

        const columns = [
            {
                key: 'name',
                isFixed: true,
                name: intl.formatMessage({ id: "SalesProcess.Name" }),
                filterable: true,
                width: 280
            }
        ];

        const numberOfDays = moment(endDate).diff(moment(startDate), 'day') + 1;

        for (let d = 1; d <= numberOfDays; d++) {
            const date = startDate.clone().add((d - 1), 'day');

            const isWeekend = (date.day() === 0) || (date.day()  === 6);

            columns.push(
                {
                    key: moment(date).format('YYYY-MM-DD'),
                    name: `${moment(date).format('ddd')} ${moment(date).format('DD-MM')}`,
                    width: 100,
                    isWeekend,
                    filterable: true,
                    isToday: moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
                }
            );
        }

        const days = columns.slice(1);

        const rows = spacesOptions
            .map((space, k) => {
                const spaceEvents = spacesAvail.filter(({spaceName}) => spaceName === space.description);

                const dailyInv = Object.assign({}, ...days.map(({key}) => {
                    const dailyEvents = spaceEvents.filter(({spaceFromDate, spaceToDate}) =>
                        moment(spaceFromDate).startOf('d').isSameOrBefore(key) &&
                        moment(spaceToDate).startOf('d').isSameOrAfter(key)
                    );

                    const eventSpaces = eventData.spaces.filter(({fromDate, toDate, spaceName}) =>
                        space.description === spaceName &&
                        moment(fromDate).startOf('d').isSameOrBefore(key) &&
                        moment(toDate).startOf('d').isSameOrAfter(key)
                    );

                    const value = dailyEvents.map(({spaceFromDate, spaceToDate}) => {
                        const timeDistribuition = [0,0,0,0];
                        const from = moment(spaceFromDate);
                        const to = moment(spaceToDate);
                        const isFirstDay = from.clone().startOf('d').isSame(key);
                        const isLastDay = to.clone().startOf('d').isSame(key);
                        const startHour = parseInt(from.format('HH'));
                        const endHour = parseInt(to.format('HH'));
                        if(isFirstDay && isLastDay){
                            if(startHour < 6 && endHour >= 6) {
                                timeDistribuition[0] = 1;
                            }
                            if(startHour < 12 && endHour >= 12) {
                                timeDistribuition[1] = 1;
                            }
                            if(startHour < 18 && endHour >= 18) {
                                timeDistribuition[2] = 1;
                            }
                            if(startHour < 24 && endHour >= 24) {
                                timeDistribuition[3] = 1;
                            }
                        }
                        else if(isFirstDay) {
                            if(startHour < 6) {
                                timeDistribuition[0] = 1;
                            }
                            if(startHour < 12) {
                                timeDistribuition[1] = 1;
                            }
                            if(startHour < 18) {
                                timeDistribuition[2] = 1;
                            }
                            if(startHour < 24) {
                                timeDistribuition[3] = 1;
                            }
                        }
                        else if(isLastDay) {
                            if(endHour > 6) {
                                timeDistribuition[1] = 1;
                            }
                            if(endHour > 12) {
                                timeDistribuition[2] = 1;
                            }
                            if(endHour > 18) {
                                timeDistribuition[3] = 1;
                            }
                        }
                        if(!isFirstDay && !isLastDay) { // only first and last days have hours
                            timeDistribuition[0] = 1;
                            timeDistribuition[1] = 1;
                            timeDistribuition[2] = 1;
                            timeDistribuition[3] = 1;
                        }
                        return timeDistribuition;
                    })
                    .reduce((a, b) => {
                        const arr = [...a];

                        arr[0] += (b[0]??0);
                        arr[1] += (b[1]??0);
                        arr[2] += (b[2]??0);
                        arr[3] += (b[3]??0);

                        return arr;
                    }, [0,0,0,0])
                    .map((value, k) => ({
                        value: value,
                        spaceReservationList: eventSpaces
                        .filter(({fromDate, toDate}) => {
                            const from = moment(fromDate);
                            const to = moment(toDate);
                            const isFirstDay = from.clone().startOf('d').isSame(key);
                            const isLastDay = to.clone().startOf('d').isSame(key);
                            const startHour = parseInt(from.format('HH'));
                            const endHour = parseInt(to.format('HH'));
                            const maxHour = ((k + 1) * 6);
                            const minHour = ((k + 1) * 6) - 6;
                            if(isFirstDay && isLastDay){
                                if(startHour < maxHour && endHour > minHour){
                                    return true;
                                }
                            }
                            else if(isFirstDay){
                                if(startHour < maxHour){
                                    return true;
                                }
                            }
                            else if(isLastDay){
                                if(maxHour <= endHour || (minHour < endHour && maxHour > endHour)){
                                    return true;
                                }
                            }
                            if(!isFirstDay && !isLastDay){
                                return true;
                            }

                        })
                        .map(({spaceReservationId}) => spaceReservationId)
                    }));

                    return ({
                        [key]: (
                            <SpaceCell
                                toggleDetailPopOver={_ => this.toggleDetailPopOver(`cell-${k}-${key}`)}
                                isPopOverOpened={_ => this.isPopOverOpened(`cell-${k}-${key}`)}
                                value={value}
                                rowKey={k}
                                colKey={key}
                                spaceCode={space.code}
                                eventTypeOptions={eventTypeOptions}
                                customStatusOptions={customStatusOptions}
                                eventData={eventData}
                                setDraggingRow={_ => this.setDraggingRow(k)}
                                draggingRow={draggingRow}
                                canDrag={_ => this.canDrag(k)}
                            />
                        )
                    })
                }));

                return ({
                    data: {
                        name: <div className='px-2'>{space.description}</div>,
                        ...dailyInv
                    },
                    cellsNoPadding: true,
                });
            });

        this.setState({ columns, rows })
    }

    isPopOverOpened = (cell) => {
        const { selectedCell, detailPopOver } = this.state;

        return detailPopOver && cell === selectedCell;
    }

    canDrag = (rowKey) => {
        return this.state.draggingRow === rowKey
    }

    setDraggingRow = (row) => {
        this.setState({ draggingRow: row === this.state.draggingRow ? null : row });
    }

    handleView = (selectedView) => {
        this.setState({ selectedView, openOptions: !this.state.openOptions });
    }

    toggleOpenOptions = () => {
        this.setState({ openOptions: !this.state.openOptions })
    }

    toggleDetailPopOver = (selectedCell, isOrphan) => {
        this.setState({ detailPopOver: !this.state.detailPopOver, selectedCell, isOrphan });
    }

    toggleDetailPopup = (spaceReservationId) => {
        this.setState({ detailPopup: !this.state.detailPopup, spaceReservationId });
    }

    render() {
        const { rows, columns, viewOptions, selectedView, openOptions, detailPopOver, selectedCell, spaceReservationId, detailPopup } = this.state;
        const { startDate, endDate, spacesAvail, spacesOptions, eventTypeOptions, customStatusOptions, eventData, saveSpace } = this.props;

        return (
            <div>
                <Row>
                    <Col className="col-4 d-flex align-items-center justify-content-start">
                        <div style={{ position: 'relative', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div className='d-flex align-items-center justify-content-center cursor-pointer' onClick={this.toggleOpenOptions}>
                                <span>
                                    <i className={viewOptions.find(({value}) => value === selectedView).icon}/>
                                    {viewOptions.find(({value}) => value === selectedView).label}
                                </span>
                                {openOptions ?
                                    <i className="ml-2 fas fa-caret-up"/>
                                :
                                    <i className="ml-2 fas fa-caret-down"/>
                                }
                            </div>
                            {openOptions ?
                                <div style={{ borderRadius: '5px', zIndex: '1050', bottom: '-40px', left: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                                    <div>
                                        {viewOptions.filter(({value}) => value !== selectedView).map((o, key) =>
                                            <React.Fragment key={key}>
                                                <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer' onClick={_ => this.handleView(o.value)}>
                                                    <i className={o.icon}/>
                                                    {o.label}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            :''}
                        </div>
                    </Col>
                    <Col className="col-4 d-flex align-items-center justify-content-center">
                        <b>
                            <span>{startDate?.format('DD MMMM')}</span>
                            <span className='mx-2'>-</span>
                            <span>{endDate?.format('DD MMMM')}</span>
                        </b>
                    </Col>
                    <Col className="col-4 d-flex align-items-center justify-content-end">
                        <Button id="create-orphan-space" className="ml-4 btn btn-host btn-sm" type="button" onClick={_ => selectedView === "Grid" ? this.toggleDetailPopOver('create-orphan-space', true) : this.toggleDetailPopup()}>
                            <i className="fas fa-plus"/>
                        </Button>
                    </Col>
                </Row>
                <div className='mt-4'>
                    {selectedView === "Grid" ?
                        <div>
                            {rows && columns ?
                                <CoolDataGrid
                                    rows={rows}
                                    cols={columns}
                                    tableName="GridSpaces"
                                    newHotelRowStyle={false}
                                />
                            :''}
                        </div>
                    : selectedView === "List" ?
                        <SpaceList
                            spaceList={eventData?.spaces}
                            customStatusOptions={customStatusOptions}
                            toggleDetailPopup={this.toggleDetailPopup}
                        />
                    :''}
                </div>
                {detailPopOver ?
                    <SpacePopover
                        toggleDetailPopOver={_ => this.toggleDetailPopOver()}
                        target={selectedCell}
                        eventTypeOptions={eventTypeOptions}
                        customStatusOptions={customStatusOptions}
                        eventData={eventData}
                        spacesAvail={spacesAvail}
                        saveSpace={saveSpace}
                        spacesOptions={spacesOptions}
                        // timeSlotsSpaceReservation={timeSlotsSpaceReservation}
                        // spaceName={spaceName}
                        // colKey={colKey}
                    />
                :''}
                {detailPopup ?
                    <SpacePopup
                        spaceReservationId={spaceReservationId}
                        toggleDetailPopup={this.toggleDetailPopup}
                        spaceList={eventData?.spaces}
                        eventData={eventData}
                        saveSpace={saveSpace}
                        eventTypeOptions={eventTypeOptions}
                        customStatusOptions={customStatusOptions}
                        spacesOptions={spacesOptions}
                    />
                :''}

            </div>
        );
    }
}

export default injectIntl(SalesDealDetailProposalEventsSpaces);