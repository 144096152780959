import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Badge, Button, Col, Form, Input, Row, Card, Collapse } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { KebabMenu } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { deleteAPI, getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import CreatableSelect from 'react-select/creatable';
import CustomSelect from '../Base/CustomSelect';
import { getCustomTypeFixedValues } from './SalesProcessFunctions';
import { CheckAuthorization } from '../Base/Authorization';


class SalesProcessCustomization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            supportedTypes: [
                {
                    area: 'Deal',
                    type: 'DealCancelReasonType',
                    values: []
                },
                {
                    area: 'Deal',
                    type: 'DealProfileRole',
                    values: []
                },
                {
                    area: 'Deal',
                    type: 'DealRelationType',
                    values: []
                },
                {
                    area: 'Pipeline',
                    type: 'PipelineStageType',
                    values: []
                },
                {
                    area: 'Action',
                    type: 'FileActionType',
                    values: []
                },
                {
                    area: 'Action',
                    type: 'TaskActionType',
                    values: []
                }
            ],
            confirmActionModal: false,
            selectedTypeKey: null,
            selectedValueKey: null
        };
    }

    componentDidMount(){
        this.getTypesList();
    }
    
    getTypesList = () => {
        const { supportedTypes } = this.state;
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length) {
                    const updatedTypes = supportedTypes.map(type => ({
                        ...type,
                        isOpen: true,
                        values: data.data.filter(v => v.type === type.type)
                    }));
                  
                    this.setState({
                      block: false,
                      supportedTypes: updatedTypes
                    });

                    return;
                  }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/customtypes`);
    }

    saveChanges = (e, customTypeList) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () => {
            const payload = [...customTypeList];

            if (payload.length === 1 && !payload[0].index) {
                payload[0].index = (this.state.supportedTypes.find(el => el.type === payload[0].type)?.values?.findLast(el => el.index)?.index ?? 0) + 1;
            }

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    } 
                    
                    handleNotification(data, <FormattedMessage id="SalesProcess.CustomDataSaved"/>, <FormattedMessage id="generic.success" />);
                    this.getTypesList();
                    return;
                } else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SalesProcess/v1/customtypes`, payload);
        });
    }

    changeFieldIndex = () => {
        const { grabbingType, grabbingFieldId, dragToFieldId, supportedTypes } = this.state;

        const customType = supportedTypes.find(ct => ct.type === grabbingType);
        const grabbingField = customType?.values.find(v => v.id === grabbingFieldId);
        const dragField = customType?.values.find(v => v.id === dragToFieldId);

        if (grabbingField && dragField) {
            const payload = [
                { ...grabbingField, index: dragField.index },
                { ...dragField, index: grabbingField.index }
            ];

            this.saveChanges(null, payload);
        }
    }

    deleteValue = () => {
        const { supportedTypes, selectedTypeKey, selectedValueKey } = this.state;
        const value = this.getCustomValue(supportedTypes, selectedTypeKey, selectedValueKey);
        const id = value?.id;

        if(!id)
            return;

        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockPipelines: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ confirmActionModal: false, block: false }, () =>
                            handleNotification(data)
                        );
                        return;
                    }
                    else {
                        this.setState({ confirmActionModal: false }, () => {
                            handleNotification(data, <FormattedMessage id="SalesProcess.CustomDataRemoved"/>, <FormattedMessage id="generic.success" />);
                            this.getTypesList();
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/customtypes/${id}`)
        });
    }

    addNewValue = (typeKey) => {
        const { supportedTypes } = this.state;
        const type = supportedTypes[typeKey];

        if(!type)
            return;
        
        if(type.values?.some(({isEditable}) => isEditable)){
            type.values = type.values.filter(({id}) => id !== null && id !== undefined);

            const editingValue = type.values.find(({isEditable}) => isEditable);

            if(editingValue){
                editingValue.isEditable = false;
            }

            this.setState({ supportedTypes });
            return;
        }   

        type.values.unshift({
            isEditable: true,
            name: ''
        });
        
        this.setState({ supportedTypes });
    }

    toggleEdit = (typeKey, valueKey) => {
        const { supportedTypes } = this.state;
        const value = this.getCustomValue(supportedTypes, typeKey, valueKey);

        if(!value)
            return;

        value.isEditable = !value.isEditable;

        this.setState({ supportedTypes });
    }

    toggleConfirmActionModal = (typeKey, valueKey) => {
        this.setState({ confirmActionModal: !this.state.confirmActionModal, selectedTypeKey: typeKey, selectedValueKey: valueKey });
    }

    getCustomValue = (supportedTypes, typeKey, valueKey) => {
        if(!supportedTypes)
            return;

        const type = supportedTypes[typeKey];

        if(!type)
            return;
        
        const value = type.values[valueKey];

        return value;
    }

    handleChange = (e, typeKey, valueKey) => {
        const { supportedTypes } = this.state;
        const value = this.getCustomValue(supportedTypes, typeKey, valueKey);

        if(!value) 
            return;

        value[e?.target?.name] = e?.target?.value ?? '';

        this.setState({ supportedTypes });
    }

    handleSelect = (field, combo, action, typeKey, valueKey) => {
        const { supportedTypes } = this.state;
        const value = this.getCustomValue(supportedTypes, typeKey, valueKey);
        
        if (!value)
            return;

            
        if (action) {
            if (action.action === "remove-value") {
                value[field] = value[field].filter(c => c !== action.removedValue.value);
            }
            else if (action.action === "clear") {
                value[field] = []
            }
        }
        else {
            value[field] = combo?.value || null;
        }
        
        this.setState({ supportedTypes });
    }

    handleSelectCreate = (label, typeKey, valueKey) => {
        const { supportedTypes } = this.state;

        const type = supportedTypes[typeKey];
        
        if (type) {
            const value = type.values[valueKey];

            if (!value) return;

            if (type.values.some(el => el.mappingCodes?.some(el => el.toLowerCase() === label.toLowerCase()))) {
                const data = { warnings: [{ message: <FormattedMessage id="SalesProcess.MappingCodeAssign" /> } ] };
                handleNotification(data);
                return;
            }

            if (!value.mappingCodes) value.mappingCodes = [];

            value.mappingCodes.push(label);

            this.setState({ supportedTypes });
        }
    };

    toggleTypeCard = (typeKey) => {
        const { supportedTypes } = this.state;

        supportedTypes[typeKey].isOpen = !supportedTypes[typeKey].isOpen;

        this.setState({ supportedTypes });
    }

    onDragStart = (grabbingType, grabbingFieldId) => {
        this.setState(prevState => ({ ...prevState, grabbingType, grabbingFieldId }))
    }

    onDragOver = (e, dragToFieldId) => {
        e.preventDefault();

        if (this.state.dragToFieldId !== dragToFieldId) {
            this.setState({ dragToFieldId });
        }
    }

    onDragEnd = () => {
        this.setState({ grabbingType: null, grabbingFieldId: null, dragToFieldId: null })
    }


    render() {
        const { error, block, supportedTypes, confirmActionModal, grabbingType, grabbingFieldId, dragToFieldId } = this.state;
        const { icon } = this.props;
        

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.Customization" />
                                </h4>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        {supportedTypes.map((type, key) =>
                            <Card className="shadow mb-3 py-0" key={key} body>
                                <Row className="border-bottom align-items-center py-3">
                                    <Col>
                                        <span style={{ fontSize: '1.1em' }}>
                                            <span className="pointer" onClick={_ => this.toggleTypeCard(key)}>
                                                <i className={`fas fa-chevron-${type.isOpen ? 'up' : 'down'} mr-3`} />

                                                <FormattedMessage id={`SalesProcess.${type.type}`} />
                                            </span>

                                            <Badge color='primary' className="ml-3">
                                                <FormattedMessage id={`SalesProcess.${type.area}`} />
                                            </Badge>
                                        </span>
                                    </Col>
                                    <Col className="col-2 text-right">
                                        <Button className="btn btn-host btn-sm" onClick={_ => this.addNewValue(key)}>
                                            {type?.values?.some(t => t.isEditable) ?
                                                <i className="fas fa-times"></i>
                                                :
                                                <i className='fas fa-plus' />
                                            }
                                        </Button>
                                    </Col>
                                </Row>

                                <Collapse isOpen={type.isOpen} className="mb-2">
                                    {type.values.map((value, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                {k ?
                                                    <hr className="px-2 my-0" />
                                                    : ''}
                                                <div>
                                                    <Row className="overbglight py-2 pl-3"
                                                        draggable={true}
                                                        style={{ border: value.id === dragToFieldId && dragToFieldId !== grabbingFieldId ? 'dashed 1px black' : '' }}
                                                        onDragStart={_ => this.onDragStart(type.type, value.id)}
                                                        onDragOver={(e) => grabbingType === type.type && this.onDragOver(e, value.id)}
                                                        onDragEnd={this.onDragEnd}
                                                        onDrop={(e) => dragToFieldId !== grabbingFieldId && grabbingType === type.type && this.changeFieldIndex(e)}
                                                    >
                                                        <Col sm={5} className="d-flex align-items-center pl-0">
                                                            <div className={`draggable mr-4 pr-4 ml-2 border-right ${grabbingFieldId && grabbingFieldId === dragToFieldId ? 'active' : ''}`}>
                                                                {type.values.length > 1 ? <i className="fas fa-grip-vertical text-muted" /> : <div className="mx-1" />}
                                                            </div>
                                                            {type.type === 'PipelineStageType' &&
                                                                <div className={"colorInputWrapper d-flex align-items-center justify-content-center mr-4 " + (value.isEditable ? ' pointer ' : '')}>
                                                                    {value.color || value.isEditable ?
                                                                        <input
                                                                            value={value.color || "#FFFFFF"}
                                                                            type="color"
                                                                            name="color"
                                                                            disabled={!(CheckAuthorization("admin:view") && value.isEditable)}
                                                                            onChange={(e) => this.handleChange(e, key, k)}
                                                                        />
                                                                        :
                                                                        <i className="fas fa-tint-slash fa-xs" style={{ color: 'lightgray' }} />
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="w-75">
                                                                <div className="title-sm m-0"> <FormattedMessage id="generic.name" /> </div>

                                                                {value.isEditable ?
                                                                    <Input
                                                                        className={!CheckAuthorization("admin:view") && "smallerInput"}
                                                                        type="text"
                                                                        name="name"
                                                                        value={value.name || ''}
                                                                        onChange={e => this.handleChange(e, key, k)}
                                                                        required={true}
                                                                    />
                                                                    :
                                                                    value.name
                                                                }
                                                            </div>
                                                        </Col>

                                                        <Col sm={4}>
                                                            <div className="title-sm m-0"> <FormattedMessage id="SalesProcess.MappingCodes" /> </div>

                                                            {value.isEditable && CheckAuthorization("admin:view") ?
                                                                <CreatableSelect
                                                                    isClearable
                                                                    isMulti
                                                                    options={value.mappingCodes ? value.mappingCodes.map(el => ({ value: el, label: el })) : []}
                                                                    onChange={(combo, action) => this.handleSelect("mappingCodes", combo, action, key, k)}
                                                                    onCreateOption={e => this.handleSelectCreate(e, key, k)}
                                                                    formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                                    value={value.mappingCodes ? value.mappingCodes.map(el => ({ value: el, label: el })) : []}
                                                                    placeholder=""
                                                                />
                                                                :
                                                                value.mappingCodes?.join(', ') || '-'
                                                            }
                                                        </Col>

                                                        <Col sm={2}>
                                                            <div className="title-sm m-0"> <FormattedMessage id="SalesProcess.FixedValue" /> </div>
                                                            {value.isEditable && CheckAuthorization("admin:view") ?
                                                                <CustomSelect
                                                                    options={getCustomTypeFixedValues(this.props.intl)}
                                                                    isSearchable isClearable
                                                                    placeholder=""
                                                                    onChange={(e) => this.handleSelect("fixedValue", e, null, key, k)}
                                                                    value={getCustomTypeFixedValues(this.props.intl).find(el => el.value === value.fixedValue) || ''}
                                                                />
                                                                :
                                                                value.fixedValue || '-'
                                                            }
                                                        </Col>
                                                        <Col className='col-1 text-right'>
                                                            {value.isEditable ?
                                                                <Button className="btn btn-host btn-sm" onClick={e => this.saveChanges(e, [{ ...value, type: type.type }])}>
                                                                    <i className='fas fa-save' />
                                                                </Button>
                                                                :
                                                                <div className="pr-2">
                                                                    {!value.fixedValue ?
                                                                        <KebabMenu
                                                                            direction={'left'}
                                                                            editFunction={_ => this.toggleEdit(key, k)}
                                                                            deleteFunction={_ => this.toggleConfirmActionModal(key, k)}
                                                                        />
                                                                        :
                                                                        <KebabMenu
                                                                            direction={'left'}
                                                                            editFunction={_ => this.toggleEdit(key, k)}
                                                                        />
                                                                    }
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </Collapse>
                            </Card>
                        )}
                    </div>

                    {confirmActionModal ?
                        <ConfirmActionModal
                            modal={confirmActionModal}
                            toggleModal={() => this.toggleConfirmActionModal()}
                            actionFunction={this.deleteValue}
                            text={<FormattedMessage id="SalesProcess.ConfirmDeleteCustomValue"/>}
                            block={block}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessCustomization);
