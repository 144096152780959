import React, { Component } from 'react'
import { handleNotification } from '../Base/Notification';
import { getAPI, deleteAPI, putAPI } from '../Base/API';
import { Row, Col, Card, Button } from 'reactstrap';
import { KebabMenu } from '../Base/CommonUIComponents';
import { ErrorAlert } from '../Base/ErrorAlert';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import SalesProcessConfigModal from './SalesProcessConfigModal';
import SalesProcessEditTemplateModal from './SalesProcessEditTemplateModal';
import ConfirmActionModal from '../Base/ConfirmActionModal';

class SalesProcessSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            masterTemplates: [],
        };
    }

    componentDidMount() {
        this.getMasterTemplates();
    }

    getMasterTemplates = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    const list = data.data;

                    this.setState({ masterTemplates: list, block: false }, this.getConfigList);
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/mastertemplates`)
        });
    }

    getConfigList = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    const list = data.data;

                    this.setState({ configs: list, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/configs`)
        });
    }

    deleteMasterTemplate = (id) => {
        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ block: false }, this.getMasterTemplates)
                    this.toggleDeleteModal();                        ;
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/mastertemplate/${id}`)
        });
    }

    deleteConfig = (id) => {
        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ block: false }, this.getMasterTemplates);
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/mastertemplate/${id}`)
        });
    }

    updateMasterTemplate = (e) => {
        const { selectedTemplate } = this.state;
        if (e) e.preventDefault()
        this.setState({ block: true }, () => {
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ block: false }, this.getMasterTemplates);
                    this.toggleModal()
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/mastertemplate`, selectedTemplate)
        });
    }

    toggleModal = (temp) => {
        this.setState({ modal: !this.state.modal, selectedTemplate: temp });
    }

    toggleDeleteModal = (temp) => {
        this.setState({ deleteModal: !this.state.deleteModal, selectedTemplate: temp });
    }

    toggleConfigModal = (cfg) => {
        this.setState({ configModal: !this.state.configModal, selectedcfg: cfg });
    }

    addConfig = (configId) => {
        this.setState({
            selectedTemplate: {
                ...this.state.selectedTemplate,
                configIds: [...this.state.selectedTemplate.configIds, configId]
            }
        })
    };

    handleListElement = (index, value, isToRemove) => {
        if (index !== -1) {
            const { selectedTemplate } = this.state;

            if (isToRemove) {
                selectedTemplate.configIds.splice(index, 1);
            }
            else
                selectedTemplate.configIds[index] = value;

            this.setState({ selectedTemplate });
        }
    }

    render() {
        const { masterTemplates, configs, modal, selectedTemplate, error, block, previewTemplate, selectedcfg, configModal, dragType, deleteModal } = this.state;

        return (
            <>
                <div className="border-0 h-100 pb-2">
                    <ErrorAlert error={error} />
                    <BlockUi tag="div" blocking={block}>
                        <Row className='mb-4'>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${this.props.icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.SalesSettings" />
                                </h4>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <h5 className='m-0'>
                                    <FormattedMessage id="SalesProcess.Templates" />
                                </h5>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            {masterTemplates && masterTemplates.map((template, k) => {
                                const canEdit = template.hotelGroupId !== '0000-000-00-0-00-000';
                                return (
                                    <Col className='col-6 mb-3' key={k}>
                                        <Card body className='p-3 h-100'>
                                            <Row className="align-items-center cursor-pointer">
                                                <Col className='col-3' onClick={canEdit ? _ => this.toggleModal(template) : undefined}>
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.Name" />
                                                    </div>
                                                    <div className="text-truncate" title={template.name}>
                                                        <b>{template.name}</b>
                                                    </div>
                                                </Col>
                                                <Col className="col-3" onClick={canEdit ? _ => this.toggleModal(template) : undefined}>
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.Type" />
                                                    </div>
                                                    <div>
                                                        {template.type}
                                                    </div>
                                                </Col>
                                                <Col className="col-5" onClick={canEdit ? _ => this.toggleModal(template) : undefined}>
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.Config" />
                                                    </div>
                                                    {template.configIds && template.configIds.length > 0 ?
                                                        <div>
                                                            {template.configIds?.map(id => configs?.find(config => config.id === id)?.name)?.join(', ')}
                                                        </div>
                                                        : '-'}
                                                </Col>
                                                <Col className='col-1 text-right'>
                                                    <KebabMenu
                                                        deleteFunction={canEdit ? _ => this.toggleDeleteModal(template) : undefined}
                                                        editFunction={canEdit ? _ => this.toggleModal(template) : undefined}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <h5 className='m-0'>
                                    <FormattedMessage id="SalesProcess.ConfigManage" />
                                </h5>
                            </Col>
                            <Col className='col-2 text-right'>
                                <Button className="btn-sm btn-host mr-2" onClick={_ => this.toggleConfigModal()}>
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            {configs && configs.map((cfg, k) => {
                                return (
                                    <Col className='col-6 mb-3' key={k}>
                                        <Card body className='p-3 h-100'>
                                            <Row className="align-items-center cursor-pointer" onClick={() => this.toggleConfigModal(cfg)}>
                                                <Col className='col-3'>
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.Name" />
                                                    </div>
                                                    <div className="text-truncate" title={cfg.name}>
                                                        <b>{cfg.name}</b>
                                                    </div>
                                                </Col>
                                                <Col className="col-3">
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.Type" />
                                                    </div>
                                                    <div>
                                                        {cfg.type}
                                                    </div>
                                                </Col>
                                                <Col className="col-6">
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.HotelGroup" />
                                                    </div>
                                                    <div>
                                                        {cfg.hotelGroupName}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                )
                            })
                            }
                        </Row>
                    </BlockUi>
                </div>

                {modal ?
                    <SalesProcessEditTemplateModal
                        selectedTemplate={selectedTemplate}
                        toggleModal={this.toggleModal}
                        modal={modal}
                        getMasterTemplates={this.getMasterTemplates}
                        getMasterTemplateHtml={this.getMasterTemplateHtml}
                        dragType={dragType}
                        configs={configs}
                        previewTemplate={previewTemplate}
                        handleListElement={this.handleListElement}
                        updateMasterTemplate={this.updateMasterTemplate}
                        addConfig={this.addConfig}
                    />
                    : ''}

                {configModal ?
                    <SalesProcessConfigModal
                        config={selectedcfg}
                        toggleModal={this.toggleConfigModal}
                        modal={configModal}
                        getConfigList={this.getConfigList}
                    />
                    : ''}

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={() => this.deleteMasterTemplate(selectedTemplate.id)}
                        text={<FormattedMessage id="SalesProcess.ConfirmDeleteText" values={{ template: selectedTemplate.name }} />}
                        block={block}
                    />
                    : ''}
            </>
        );
    };
}

export default SalesProcessSettings;
