import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import SearchProfile from '../ProfileMenu/SearchProfile';
import { getSalesSourceTypes } from './SalesProcessFunctions';


class SalesSourceDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            blockFormSelect: false,
            formOptions: [],
            error: null,
            source: this.props.source || {}
        };
    }

    componentDidMount() {
        if(this.props.source && this.props.source.type === 'Form'){
            this.getPNForms();
        }
    }

    saveSource = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            const payload = { ...this.state.source };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                        return;
                    }

                    this.setState({ block: false }, () =>
                        handleNotification(data, <FormattedMessage id="SalesSource.SavedSuccessfully" />, <FormattedMessage id="generic.success" />)
                    );

                    this.props.toggleModal();
                    this.props.getSalesSourceList();
                    return;
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/sources`, payload);
        });
    }

    getPNForms = () => {
        this.setState({ blockFormSelect: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockFormSelect: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ formOptions: [], blockFormSelect: false });
                    }
                    
                    if(data.response && data.response.length > 0){
                        const formOptions = data.response.map(form => {
                            return {
                                value: form.id ? form.id.toString() : null,
                                label: form.name
                            }
                        });
                        this.setState({ formOptions, blockFormSelect: false });
                    }
                }else{
                    this.setState({ error: errorMessage, blockFormSelect: false });
                }   
            }, `/api/gms/Survey/v1/survey`);
        });
    }

    selectProfile = (profile) => {
        this.setState({
            source: {
                ...this.state.source,
                mainContactId: profile ? profile.id : null,
                mainContactName: profile ? profile.firstName + ' ' + profile.lastName : null
            },
            showSearchProfile: false
        })
    }

    handleSelect = (name, combo) => {
        this.setState({
            source: {
                ...this.state.source,
                [name]: combo ? combo.value : null
            }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            source: {
                ...this.state.source,
                [name]: value
            }
        })
    }

    toggleSearchProfile = () => {
        this.setState(prevState => ({
            showSearchProfile: !prevState.showSearchProfile
        }))
    }

    render() {
        const { block, blockFormSelect, error, source, formOptions } = this.state;
        const { modal, toggleModal, salesUsers, salesPipelines } = this.props;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.saveSource}>
                        <Row className="pb-3">
                            <Col>
                                <h4><FormattedMessage id={`SalesSource.${source.id ? 'Edit' : 'Create'}Source`} /></h4>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Col>
                                <div className="title-sm"> <FormattedMessage id="generic.name"/> </div>
                                <div>
                                    <Input
                                        required
                                        type="text"
                                        name="name"
                                        placeholder=""
                                        value={source.name || ''}
                                        onChange={this.handleChange}
                                        maxLength={100}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <div className="title-sm"> <FormattedMessage id="generic.Description" /> </div>
                                <div>
                                    <Input
                                        type="text"
                                        name="description"
                                        placeholder=""
                                        value={source.description || ''}
                                        onChange={this.handleChange}
                                        maxLength={250}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="generic.Type" /> </div>
                                <div>
                                    <CustomSelect
                                        required
                                        name="type"
                                        placeholder=""
                                        isSearchable
                                        options={getSalesSourceTypes(this.props.intl)}
                                        value={getSalesSourceTypes(this.props.intl).find(({ value }) => source.type === value)}
                                        onChange={(combo) => this.handleSelect('type', combo)}
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="SalesSource.SalesPipeline"/> </div>
                                <div>
                                    <CustomSelect
                                        name="type"
                                        placeholder=""
                                        isSearchable
                                        isClearable
                                        options={salesPipelines}
                                        value={salesPipelines.find(({ value }) => source.salesPipelineId === value)}
                                        onChange={(combo) => this.handleSelect('salesPipelineId', combo)}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                        {source && source.type === 'Form' ?
                            <FormGroup row>
                                <Col>
                                    <div className="title-sm"> <FormattedMessage id="SalesProcess.PNForm" /> </div>
                                    <div>
                                        <CustomSelect
                                            isLoading={blockFormSelect}
                                            name="Form"
                                            placeholder=""
                                            isSearchable
                                            options={formOptions}
                                            value={formOptions ? formOptions.find(({ value }) => source.formId === value) : ''}
                                            onChange={(combo) => this.handleSelect('formId', combo)}
                                        />
                                    </div>
                                </Col>
                            </FormGroup>
                            : ''}
                        <FormGroup row>
                            <Col sm={6}>
                                <div className="title-sm">
                                    <FormattedMessage id={source.type === "Partner" ? "SalesSource.Partner" : "SalesSource.MainContact"} />
                                    <i className="fas fa-edit pointer ml-2" onClick={this.toggleSearchProfile} />
                                </div>
                                <div className=" d-flex align-items-center justify-content-end ">
                                    <Input
                                        className="pr-5"
                                        type="text"
                                        name="mainContactId"
                                        placeholder=""
                                        value={source.mainContactName || source.mainContactId || ''}
                                        disabled
                                    />
                                    {source.mainContactId && <i className="fas fa-times text-muted pointer position-absolute pr-3" onClick={_ => this.selectProfile(null)} />}
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="title-sm"> <FormattedMessage id="SalesSource.Responsable" /> </div>
                                <div>
                                    <CustomSelect
                                        name="type"
                                        placeholder=""
                                        isClearable
                                        isSearchable
                                        options={salesUsers}
                                        value={source.responsableId ? salesUsers.find(({ value }) => source.responsableId === value) || { value: source.responsableId, label: source.responsableName } : ''}
                                        onChange={(combo) => this.handleSelect('responsableId', combo)}
                                        isLoading={this.state.blockUsers}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                    </Form>


                    {this.state.showSearchProfile ?
                        <Row>
                            <Col>
                                <SearchProfile
                                    fromAnotherPage={true}
                                    addProfile={this.selectProfile}
                                    pageSize={5}
                                    isMaster={this.state.isMaster}
                                />
                            </Col>
                        </Row>
                    : ''}
                </BlankCard>
            </StyledModal>
        )
    }
}

export default injectIntl(SalesSourceDetails);