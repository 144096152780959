import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { CoolTooltip, FormatAmountNumber, KebabMenu } from '../Base/CommonUIComponents';
import { Row, Col, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import CustomToolTip from '../Base/CustomToolTip';
import moment from 'moment';


class SalesDealProposalResume extends Component {

    render() {
        const { salesDealProposals, canManageProposals } = this.props;
        
        return (
            <>
                <div className='d-flex align-items-center justify-content-between px-3'>
                    <h5 className='m-0'><FormattedMessage id="SalesDeal.ProposalResume" /></h5>
                </div>
                <div className='pt-4 px-3'>
                    {salesDealProposals.map((proposal, key) => {
                        const hotel = global.hotelList?.find(({ value }) => value === proposal.hotelId);
                        const rooms = proposal.proposalJSON?.groupedCategories;
                        const events = proposal.proposalJSON?.data && proposal.proposalJSON.data[0]?.DetailsEvents;
                        const totalPriceRooms = rooms?.reduce((acc, cur) => {
                            acc += cur.priceTotal;
                            return acc;
                        }, 0)
                        return (
                            <div key={key} className='shadow w-100 mb-3 bg-white p-3'>
                                <div className='d-flex justify-content-between'>
                                    <div style={{ maxWidth: '80%' }}>
                                        <div className='cursor-pointer text-left' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(proposal.externalId, proposal.externalId, "Reservation")}>
                                            <b className='d-flex align-items-center' style={{ maxWidth: '280px' }}>
                                                <i id={`blockOrEventProposal-${key}`} className={`fas fa-${proposal.type === 'Block' ? 'cube' : 'theater-masks'} mr-2`}></i>
                                                <span id={`blockOrEventProposalName-${key}`} className='text-truncate'>
                                                    {(proposal.type === 'Block' ? proposal.proposalJSON?.BlockData?.headerData?.[0]?.Code : proposal.proposalJSON?.data?.[0]?.Name) || proposal.externalId}
                                                </span>
                                            </b>
                                            <CoolTooltip placement="top" target={`blockOrEventProposal-${key}`}>
                                                <FormattedMessage id={proposal.type === 'Block' ? "SalesProcess.Block" : "SalesProcess.Event"} />
                                            </CoolTooltip>
                                            <CoolTooltip placement="top" target={`blockOrEventProposalName-${key}`}>
                                                {(proposal.type === 'Block' ? proposal.proposalJSON?.BlockData?.headerData?.[0]?.Code : proposal.proposalJSON?.data?.[0]?.Name) || proposal.externalId}
                                            </CoolTooltip>
                                            {this.props.hasFilter(proposal.externalId, "Reservation") ?
                                                <FilterDot />
                                            : ''}
                                        </div>
                                        <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative', fontSize: '0.8em' }} onClick={_ => this.props.setFilters(proposal.hotelId, hotel?.label, "Hotel")}>
                                            <div className='text-truncate text-muted'>
                                                {hotel?.label}
                                            </div>
                                            {this.props.hasFilter(proposal.hotelId, "Hotel") ?
                                                <FilterDot />
                                            :''}
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {proposal.isOption ?
                                            <>
                                                <div className='mr-4' id={`optional-${key}`}>
                                                    <i className="far fa-bell-slash"/>
                                                </div>
                                                <CoolTooltip target={`optional-${key}`}>
                                                    <FormattedMessage id="SalesProcess.IsOption"/>
                                                </CoolTooltip>
                                            </>
                                            : ''}
                                        {canManageProposals ?
                                            <KebabMenu
                                                direction={'left'}
                                                extraFields={proposal.type === "Block" || proposal.type === "Event" ?
                                                    [{
                                                        function: _ => this.props.importGroupRes(proposal.externalId, proposal.hotelId, proposal.id),
                                                        icon: 'fas fa-sync-alt mr-2',
                                                        text: 'SalesDeal.UpdateReservation'
                                                    },
                                                    {
                                                        function: _ => this.props.toggleActiveSupraTab(`${proposal.type}s`, proposal),
                                                        icon: 'fas fa-edit mr-2',
                                                        text: proposal.type === "Block" ? `SalesProcess.EditBlock` : 'SalesProcess.EditEvent'
                                                    },
                                                    {
                                                        function: _ => this.props.toggleIsOption(proposal.id, !proposal.isOption),
                                                        icon: 'far fa-bell-slash mr-2',
                                                        text: proposal.isOption ? 'SalesProcess.RemoveIsOption' : 'SalesProcess.SetIsOption'
                                                    }]
                                                    :
                                                    [{
                                                        function: _ => this.props.importGroupRes(proposal.externalId, proposal.hotelId, proposal.id),
                                                        icon: 'fas fa-sync-alt mr-2',
                                                        text: 'SalesDeal.UpdateReservation'
                                                    },
                                                    {
                                                        function: _ => this.props.toggleIsOption(proposal.id, !proposal.isOption),
                                                        icon: 'far fa-bell-slash mr-2',
                                                        text: proposal.isOption ? 'SalesProcess.RemoveIsOption' : 'SalesProcess.SetIsOption'
                                                    }]
                                                }
                                                deleteFunction={_ => this.props.removeGroupReservation(proposal.id)}
                                                deleteText={"SalesDeal.Remove"}
                                            />
                                        : ''}
                                    </div>
                                </div>
                                <Row className='text-truncate title-sm mt-2 mx-1'>
                                    <Col className="text-left pl-0">
                                        <span id={`check-in-${key}`} >
                                            <i className="far fa-calendar-alt color-green mr-1" /> {proposal.proposalJSON.data[0]?.CheckIn ? moment(proposal.proposalJSON.data[0].CheckIn).format("YYYY-MM-DD") : '-'}
                                        </span>
                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`check-in-${key}`}>
                                            <PopoverBody> Check-in </PopoverBody>
                                        </CustomToolTip>
                                    </Col>
                                    <Col className="text-right pr-0">
                                        <span id={`check-out-${key}`} >
                                            <i className="far fa-calendar-alt color-red mr-1" />{proposal.proposalJSON.data[0]?.CheckOut ? moment(proposal.proposalJSON.data[0].CheckOut).format("YYYY-MM-DD") : '-'}
                                        </span>
                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="left" target={`check-out-${key}`}>
                                            <PopoverBody> Check-out </PopoverBody>
                                        </CustomToolTip>
                                    </Col>
                                </Row>
                                <div className='px-2 mt-3'>
                                    {proposal.numberOfRooms ?
                                        <div className='pb-2'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(null, null, "Rooms", proposal.externalId)}>
                                                    <FormattedMessage id="SalesDeal.Rooms" />
                                                    {this.props.hasFilter(null, "Rooms", proposal.externalId) ?
                                                        <FilterDot />
                                                        : ''}
                                                </div>
                                                <div>
                                                    <FormatAmountNumber currency={global.hotelCurrency} value={totalPriceRooms ?? 0} />
                                                </div>
                                            </div>
                                            <div className='px-2 mt-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                {rooms?.map((room, key) =>
                                                    <React.Fragment key={key}>
                                                        {key ?
                                                            <hr className='my-1' />
                                                            : ''}
                                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                                            <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.props.setFilters(room.categoryId, room.categoryCode, "Rooms", proposal.externalId)}>
                                                                {room.categoryCode}
                                                                <div style={{ fontSize: '0.9em' }} className='ml-1 text-muted'>
                                                                    {room.totalRooms}
                                                                </div>
                                                                {this.props.hasFilter(room.categoryId, "Rooms", proposal.externalId) ?
                                                                    <FilterDot />
                                                                    : ''}
                                                            </div>
                                                            <div>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={room.priceTotal ?? 0} />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        : ''}
                                    {events?.length > 0 ? //proposal.numberOfSpaces ?
                                        <div className='pb-2 mt-3'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(null, null, "Events", proposal.externalId)}>
                                                    <FormattedMessage id="SalesDeal.Events" />
                                                    {this.props.hasFilter(null, "Events", proposal.externalId) ?
                                                        <FilterDot />
                                                        : ''}
                                                </div>
                                                <div>
                                                    <FormatAmountNumber currency={global.hotelCurrency} value={proposal.eventsValue ?? 0} />
                                                </div>
                                            </div>
                                            <div className='px-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                {events.map((event, key) =>
                                                    <React.Fragment key={key}>
                                                        {key ?
                                                            <hr className='my-1' />
                                                            : ''}
                                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                                            <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.props.setFilters(event.EventName, event.EventName, "Events", proposal.externalId)}>
                                                                <div className='text-truncate'>{event.EventName}</div>
                                                                <div style={{ fontSize: '0.9em' }} className='ml-1 text-muted'>
                                                                    {event.Spaces.length}
                                                                </div>
                                                                {this.props.hasFilter(event.EventName, "Events", proposal.externalId) ?
                                                                    <FilterDot />
                                                                    : ''}
                                                            </div>
                                                            <div>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={event.PriceTotal ?? 0} />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        : ''}

                                    {salesDealProposals.length > 1 ?
                                        <div className='d-flex align-items-center justify-content-between mt-2 pt-2 pb-2' style={{ borderTop: '1px solid lightgrey' }}>
                                            <div>
                                                <FormattedMessage id="SalesDeal.Total" />
                                            </div>
                                            <div>
                                                <FormatAmountNumber
                                                    currency={global.hotelCurrency}
                                                    value={proposal.totalValue ?? 0}
                                                />
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
            </>
        );
    }
}

export default SalesDealProposalResume;



const FilterDot = () => {
    return <div style={{
        width: '5px',
        height: '5px',
        background: '#0665ff',
        borderRadius: '100%',
        position: 'absolute',
        top: '0',
        right: '-7px'
    }} />
}