import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Input, FormGroup } from 'reactstrap';
import CustomSelect from '../../Base/CustomSelect';
import { SalesProcessHotelCombo, getDealStatus, getLeadStatus } from '../SalesProcessFunctions';
import { SelectCustomType } from '../SalesProcessFunctions';
import { SelectHotel } from '../../Base/CommonUIComponents';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

class SalesDealStep1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleDates = ({ startDate, endDate }) => {
        if (startDate)
            this.props.handleDate('businessStartDate', startDate);

        if (endDate)
            this.props.handleDate('businessEndDate', endDate)
    }

    render() {
        const { handleChange, handleSelect, sources, intl, deal, stages, salesPipelines, editableSalesPipeline, isLead, currentUser, changeLeadToDeal, 
            createNewAssociation, handleDealRelationChange, selectedDealRelationTypeId } = this.props;

        return (
            <div>
                <FormGroup row>
                    <Col className="col-6 mb-3">
                        <div className="title-sm required"> <FormattedMessage id="generic.name" /></div>
                        <Input
                            required
                            type="text"
                            name="name"
                            placeholder=""
                            value={deal.name || ''}
                            onChange={handleChange}
                            maxLength={100}
                        />
                    </Col>
                    <Col className="col-6 mb-3">
                        <div className="title-sm required"> <FormattedMessage id="generic.status" /></div>
                        <CustomSelect
                            required
                            name="type"
                            placeholder=""
                            isClearable={false}
                            isSearchable={false}
                            options={isLead ? getLeadStatus(intl): getDealStatus(intl)}
                            value={isLead ? (getLeadStatus(intl).find(({ value }) => deal.status === value) || '') : getDealStatus(intl).find(({ value }) => deal.status === value) || ''}
                            onChange={(combo) => handleSelect('status', combo)}
                        />
                    </Col>
                    <Col className="col-6 mb-3">
                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.Source" /></div>
                        <CustomSelect
                            name="salesSourceId"
                            placeholder=""
                            isClearable={true}
                            isSearchable={true}
                            options={sources}
                            value={sources?.find(({ value }) => deal.salesSourceId === value) || ''}
                            onChange={(combo) => handleSelect('salesSourceId', combo)}
                        />
                    </Col>
                    {salesPipelines?.length ?
                        <Col className='col-6 mb-3'>
                            <div className={`title-sm ${isLead ? '' : 'required'}`}> <FormattedMessage id="SalesDeal.Pipelines" /></div>
                            <CustomSelect
                                name="salesPipelineId"
                                placeholder=""
                                isClearable={true}
                                isSearchable={true}
                                options={salesPipelines}
                                value={salesPipelines?.find(({ value }) => (deal.salesPipelineId === value) || (deal.salesPipelineStage?.salesPipelineId === value)) || ''}
                                onChange={(combo) => handleSelect('salesPipelineId', combo)}
                                isDisabled={!editableSalesPipeline}
                            />
                        </Col>
                        : ''}
                    <Col className="fullWidth col-6 mb-3">
                        <div className={`title-sm ${isLead ? '' : 'required'}`}> <FormattedMessage id="SalesDeal.BusinessDate" /></div>
                        <DateRangePicker
                            startDate={deal.businessStartDate ? moment(deal.businessStartDate) : null}
                            startDateId="businessStartDate"
                            endDate={deal.businessEndDate ? moment(deal.businessEndDate) : null}
                            endDateId="businessEndDate"
                            isOutsideRange={_ => false}
                            onDatesChange={this.handleDates}
                            focusedInput={this.state.focusedInput}
                            showClearDates={true}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            required={!isLead}
                        />
                    </Col>
                    {!isLead ?
                        <>
                            <Col className='col-3 mb-3'>
                                <div className="title-sm"> <FormattedMessage id="SalesDeal.Adults" /></div>
                                <Input
                                    type="number"
                                    name="adults"
                                    placeholder=""
                                    value={deal.adults || ''}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col className='col-3 mb-3'>
                                <div className="title-sm"> <FormattedMessage id="SalesDeal.Children" /></div>
                                <Input
                                    type="number"
                                    name="children"
                                    placeholder=""
                                    value={deal.children || ''}
                                    onChange={handleChange}
                                />
                            </Col>
                        </>
                    :''}
                    <Col className="col-6 mb-3">
                        <div className={`title-sm ${isLead ? '' : 'required'}`}> <FormattedMessage id="SalesDeal.RelatedHotels" /></div>
                        <SalesProcessHotelCombo
                            isSearchable
                            isMulti
                            name='hotelIds'
                            icon={"icon-icon-hotel"}
                            onChangeFunc={handleSelect}
                            placeholder={'Hotel'}
                            required={!isLead}
                            value={deal.hotelIds || ''}
                            params='?onlyFromHotelGroup=true'
                        />
                    </Col>
                    {createNewAssociation ? 
                        <Col className="col-6 mb-3">
                            <div className={`title-sm required`}> <FormattedMessage id="SalesProcess.SelectDealAssociationType" /></div>
                            <SelectCustomType 
                                name="dealRelationType"
                                onChangeFunc={(combo) => handleDealRelationChange(combo)}
                                required={true}
                                value={selectedDealRelationTypeId}
                                placeholder=""
                                isDisabled={false}
                                isClearable={false}
                                type={'DealRelationType'}
                            />
                        </Col>
                    :''}
                {stages?.length ?
                        <Col className="col-12">
                            <div className="title-sm required"> <FormattedMessage id="SalesDeal.Stage" /></div>

                            <div className='mt-1 h-100 d-flex w-100'>
                                {stages?.filter(({ salesPipelineId }) => !deal.id || changeLeadToDeal || createNewAssociation ? salesPipelineId === deal.salesPipelineId : salesPipelineId === deal.salesPipelineStage?.salesPipelineId).map((stage, key) => {
                                    const isSelected = !deal.id ? stage.value === deal.salesPipelineStageId : stage.value === deal.salesPipelineStageId;

                                    return (
                                        <div key={key} className='h-100 pointer' style={{ width: '100%' }} onClick={_ => handleChange({ target: { name: 'salesPipelineStageId', value: stage.value } })}>
                                            <div className='text-center pipeline smallPipeline' style={{ height: '50px' }}>
                                                <div className={`arrow top smallPipeline noShadow ${isSelected ? 'selectedStage' : ''}`} style={{ height: '25px' }}></div>
                                                <div className={'pipelineName ' + (isSelected ? ' color-white ' : '')}>
                                                    <span>{stage.label}</span>
                                                </div>
                                                <div className={`arrow bottom noShadow ${isSelected ? 'selectedStage' : ''}`} ></div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Col>
                :''}
                </FormGroup>
                {deal.status === 'Cancelled' ?
                    <FormGroup row>
                        <SelectCustomType 
                            name="cancelReasonType"
                            onChangeFunc={(combo) => handleChange({ target: { name: 'cancelReasonType', value: combo ?? null } })}
                            required={true}
                            value={deal.cancelReasonId}
                            placeholder=""
                            isDisabled={false}
                            isClearable={true}
                            type={'DealCancelReasonType'}
                        />
                        <Col  className="col-6">
                            <div className="title-sm required"> <FormattedMessage id="SalesDeal.CancellationReason" /></div>
                            <Input
                                required
                                type="textarea"
                                name="statusReason"
                                placeholder=""
                                value={deal.statusReason || ''}
                                onChange={handleChange}
                                maxLength={100}
                                style={{ minHeight: '60px' }}
                            />
                        </Col>
                    </FormGroup>
                : ''}
            </div>
        )
    }
}

export default injectIntl(SalesDealStep1);