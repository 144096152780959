import React, { Component } from 'react';
import { Label, Input, Col, CardBody, CustomInput, Row, Button, Collapse, Badge, Form } from 'reactstrap';
import { getISSOptions, getProfileType } from '../../Base/ReferenceDataFunctions';
import { CommonHelper } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import SearchProfile from '../../ProfileMenu/SearchProfile';
import CustomSelect from "../../Base/CustomSelect";
import { ErrorAlert } from '../../Base/ErrorAlert';
import { getAPI, postAPI } from "../../Base/API";
import { SingleDatePicker } from "react-dates";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from 'moment';

import { highlightFields, updateMasterList } from './MergeFunctions';
import ProfileMainData from './ProfileMainData';
import ProfileDataTabs from './ProfileDataTabs';


class ProfileMergeDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockModal: false,
            duplicatedProfiles: [],
            profilesList: [],
            modal: false,
            selectedProfile: null,
            activeTab: '1',
            master: {
                addresses: [],
                consents: [],
                contacts: [],
                documents: [],
                //informations: [],
                //preferences: [],
                //salesMarketing: [],
                socialMedia: [],
                //profileWarnings: [],
                membershipCard: [],
                profileCredit: [],
                profileCustomData: []
            },
            mainFields: ['type', 'firstName', 'middleName', 'lastName', 'fullName', 'gender', 'nationality', 'language', 'birthDate', 'iss'],
            lists: ['addresses', 'consents', 'contacts', 'documents', /*'informations', 'preferences', 'salesMarketing', 'profileWarnings',*/ 'socialMedia', 'membershipCard', 'profileCredit', 'profileCustomData'],
            mergeType: 'createMaster',
            isMaster: false,
            disableMasterFilter: true,
            totalDuplicates: 0,
            masterUniqueValues: { //used to keep master data when they are replaced 
                addresses: [],
                consents: [],
                contacts: [],
                documents: [],
                socialMedia: [],
                profileCredit: [],
                membershipCard: [],
                profileCustomData: []
            },
            customFieldsList: []
        };
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.duplicatedIds && this.props.location.state.duplicatedIds.length > 0) {
            const length = this.props.location.state.duplicatedIds.length;

            this.setState({ totalDuplicates: length }, () =>
                this.props.location.state.duplicatedIds.forEach((el, idx) => {
                    if (idx === 0) {
                        this.getProfilesData(el, length, true);
                    }
                    else {
                        this.getProfilesData(el, length);
                    }
                })
            );
        }
        else {
            this.getConfiguredCustomFields();
        }
    }

    setMergeType = () => {
        if (this.state.duplicatedProfiles.some(p => p.firstGivenProfile)) { //Vem de outra página com botão de merge
            const firstProfile = this.state.duplicatedProfiles.find(p => p.firstGivenProfile);

            if (!firstProfile.isMaster && firstProfile.masterId && firstProfile.masterId !== firstProfile.id) { //Não é master e tem o masterId diferente do profileId - editMaster
                this.handleMergeType('editMaster', false);
                this.setState({ repeat: false }, () => this.getMaster(firstProfile.masterId, true));

                return;
            }
            if (this.state.duplicatedProfiles.every(p => p.isMaster === true)) {
                this.handleMergeType('mergeMasters', false);


                //put 1st profile in master column
                let firstMaster = [...this.state.duplicatedProfiles][0];
                let masters = [...this.state.duplicatedProfiles].filter(profile => profile !== firstMaster);

                this.setState({ master: firstMaster, duplicatedProfiles: masters, totalDuplicates: masters.length, repeat: false });
                return;
            }
            else {
                this.handleMergeType('createMaster', false);
                return;
            }
        }

        else if (this.state.duplicatedProfiles.every(p => p.isMaster === true)) { //No caso de todos serem master - merge masters
            this.handleMergeType('mergeMasters', false);


            //put 1st profile in master column
            let firstMaster = [...this.state.duplicatedProfiles][0];
            let masters = [...this.state.duplicatedProfiles].filter(profile => profile !== firstMaster);

            this.setState({ master: firstMaster, duplicatedProfiles: masters, totalDuplicates: masters.length, repeat: false });

            return;
        }
        else if (this.state.duplicatedProfiles.every(p => p.masterId)) { //Todos têm um master id
            this.handleMergeType('editMaster', false);

            let master = [...this.state.duplicatedProfiles].find(profile => profile.isMaster === true);
            let profiles = [...this.state.duplicatedProfiles].filter(profile => profile !== master);

            if (!master) {
                this.setState({ repeat: false }, () => this.getMaster(profiles[0].masterId, true));
            }
            else {
                this.setState({ master: master, duplicatedProfiles: profiles, repeat: false });
            }

            return;
        }
        else {
            this.handleMergeType('createMaster', false);
            return;
        }
    }

    getProfilesData = (id, duplicatedProfilesCount, firstGivenProfile, addTotalDuplicates) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage }));
                }
                if (data.response) {
                    let { customFieldsList, duplicatedProfiles, totalDuplicates } = this.state;

                    if (data.response.length > 0) {
                        let profile = data.response[0];

                        if (firstGivenProfile) profile.firstGivenProfile = true;

                        profile.profileCustomData.forEach(profileCustomData => {
                            profileCustomData.fieldSettings = customFieldsList.find(el => el.code === profileCustomData.typeCode) || {};
                        })

                        //order by pmsTimestamp desc
                        const index = duplicatedProfiles.findIndex(el => moment(profile.pmsTimestamp).isAfter(moment(el.pmsTimestamp)));

                        if (index === 0) {
                            duplicatedProfiles.unshift(profile);
                        }
                        else if (index > 0) {
                            duplicatedProfiles.splice(index, 0, profile);
                        }
                        else {
                            duplicatedProfiles.push(profile);
                        }

                        if (addTotalDuplicates) {
                            totalDuplicates++;
                        }
                    }

                    this.setState({ duplicatedProfiles, totalDuplicates }, () => {
                        if (duplicatedProfilesCount && duplicatedProfilesCount === this.state.duplicatedProfiles.length && this.state.repeat !== false) {
                            this.setMergeType();
                        }
                        this.getConfiguredCustomFields();
                    });
                }
                else this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/${id}`)
    }

    getConfiguredCustomFields = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ customFieldsList: data.response, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/CustomFieldConfig/v1`);
    }

    // Highlight fields used for creating the master and key fields
    highlightFields = () => {
        if (this.state.duplicatedProfiles && this.state.duplicatedProfiles.length > 0 && this.state.master.id) {
            let profiles = [...this.state.duplicatedProfiles];
            const master = { ...this.state.master };

            profiles = highlightFields(profiles, master, this.state.mainFields);

            this.setState({ duplicatedProfiles: profiles });
        }
    }

    saveMasterProfile = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        var associateProfileIds = []
        this.state.duplicatedProfiles.forEach(p => {
            associateProfileIds.push(p.id);
        })
        var body = { ...this.state.master };
        body.associateProfileIds = associateProfileIds;

        delete body.informations;
        delete body.preferences;
        delete body.salesMarketing;
        delete body.profileWarnings;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileMergeDetail.MasterProfileSaved" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/profile/master`, JSON.stringify({ request: body }))
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            master: {
                ...this.state.master,
                [name]: value
            }
        })
    };

    //Handle general data 
    handleSwitch = (e, value) => {
        const { name, checked, id } = e.target;
        const { master } = this.state;

        const count = this.state.duplicatedProfiles.length;
        master[name] = value;


        if (checked) {
            //uncheck other switchs
            for (var i = 0; i < count; i++) {
                const switchName = name + (i + 1);

                if (switchName !== id) {
                    this.setState({
                        [switchName]: false,
                        master
                    })
                }
                else {
                    this.setState({ [id]: checked, master })
                }
            }
        }
        else {
            this.setState({ [id]: checked, master })
        }
    }

    //Handle lists
    handleListSwitch = (e, value) => {
        const { name, checked, id } = e.target;
        var { master, masterUniqueValues } = this.state;

        if (name === 'addresses' || name === 'consents' || name === 'documents' || name === 'profileCredit') {
            //Profile can only have one address/consents/documents/profileCredit for each type

            const typeProp = name === 'profileCredit' ? 'creditLimitType' : 'type';
            const masterTypeIdx = master[name] && master[name].findIndex(a => a[typeProp] === value[typeProp]);

            master = updateMasterList(checked, value, master, masterUniqueValues, name, typeProp, masterTypeIdx);

        }
        else if ((name === 'socialMedia' && value.main) || (name === 'contacts' && value.main)) {
            //Master only can have 1 main contact/social media for each type

            const masterTypeIdx = master[name] && master[name].findIndex(a => a.type === value.type && a.main);

            master = updateMasterList(checked, value, master, masterUniqueValues, name, 'type', masterTypeIdx);
        }
        else if (name === 'contacts' && global.toMasterProperties?.some(prop => prop.includes(value.type))) {
            //Eg.: If toMasterProperties has Email2, master only can have 1 main email and 1 not main email, otherwise master can have 1 main and more than 1 not main

            const masterTypeIdx = master[name] && master[name].findIndex(a => a.type === value.type && !a.main);

            master = updateMasterList(checked, value, master, masterUniqueValues, name, 'type', masterTypeIdx);
        }
        else if (name === 'membershipCard') {
            //Master only can have 1 membership card
            master = updateMasterList(checked, value, master, masterUniqueValues, name, '', 0);
        }
        else if (name === 'profileCustomData') {
            const masterTypeIdx = master[name] && master[name].findIndex(a => a.typeCode === value.typeCode);

            master = updateMasterList(checked, value, master, masterUniqueValues, name, 'typeCode', masterTypeIdx);
        }
        else {
            if (checked) {
                master[name].push(value);
            }
            else {
                master[name] = master[name].filter(el => el.id !== value.id);
            }
        }

        this.setState({ [id]: checked, master });
    }

    handleAllSwitches = (colId, profile, isToRemove) => {
        //Handle general data
        const array = [...this.state.mainFields];

        array.forEach(el => {
            const pf = isToRemove ? null : profile[el];

            const e = {
                target: {
                    name: el,
                    checked: isToRemove ? false : true,
                    id: `${el}${colId}`
                }
            }

            this.handleSwitch(e, pf);
        })

        //Handle list elements
        this.state.lists.forEach(el => {
            const data = isToRemove ? null : profile[el];
            if (data !== undefined) {

                //Add list elements of selected profile
                if (data && data.length > 0) {
                    const profileData = { list: [], event: [] };

                    for (var i = 0; i < data.length; i++) {
                        const e = {
                            target: {
                                checked: true,
                                id: `${el}${colId}${i + 1}`
                            }
                        }
                        profileData.event.push(e);
                        profileData.list.push(data[i]);
                    }

                    this.handleLists(profileData, el, colId);
                }
                else {
                    //Remove/uncheck elemets from removed profile
                    this.handleLists(null, el, colId, isToRemove);
                }
            }
            else {
                //Clear master list when selected profile doesn't have elements of [listName]
                this.handleLists(null, el, colId);
            }
        })
    }

    //Handle all switches of a list
    handleLists = (listData, listName, colId, isToRemove) => {
        const { master } = this.state;
        master[listName] = [];

        if (listData) {
            //Add list elements to master profile array
            listData.list.forEach(el => {
                master[listName].push(el);
            })

            //Check switchs
            listData.event.forEach(el => {
                const { checked, id } = el.target;

                this.setState({ [id]: checked });
            })

            //Uncheck switches of others profiles
            for (var x = 0; x < this.state.duplicatedProfiles.length; x++) {
                if (colId !== (x + 1)) {
                    for (var i = 1; i < 11; i++) {
                        const switchName = listName + (x + 1) + (i);

                        this.setState({ [switchName]: false });
                    }
                }
            }
        }
        else {
            //If it is to remove profile, uncheck switchs of corresponding column
            if (isToRemove) {
                this.state.lists.forEach(name => {
                    for (var i = 1; i < 11; i++) {
                        const switchName = listName + colId + (i);

                        this.setState({ [switchName]: false });
                    }
                })
            }
            else {
                //When the selected profile has no list elements (listName), uncheck all switches from the remaining profiles
                for (var x = 0; x < this.state.duplicatedProfiles.length; x++) {
                    for (var i = 1; i < 11; i++) {
                        const switchName = listName + (x + 1) + (i);

                        this.setState({ [switchName]: false })
                    }
                }
            }
        }

        this.setState({ master })
    }

    addProfile = (profile) => {
        if (profile.isMaster) {
            if (this.state.mergeType === 'mergeMasters' && this.state.master.id) {
                this.getProfilesData(profile.id);
            }
            else {
                this.getMaster(profile.id);
            }
        }
        else {
            const { profilesList } = this.state;
            this.getProfilesData(profile.id, undefined, undefined, true);
            var list = profilesList.filter(el => el.value !== profile.id);

            this.setState({ profilesList: list })
        }
    }

    getMaster = (profileId, duplicatesLoaded) => {
        this.setState({ block: true }, () => this.state.mergeType === 'editMaster' && !duplicatesLoaded ? this.removeAllProfiles() : '');

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage }));
                }
                if (data.response && data.response.length > 0) {
                    const { customFieldsList } = this.state;
                    const master = Object.assign(this.state.master, data.response[0]);
                    const masterUniqueValues = {
                        addresses: [...data.response[0].addresses || []],
                        consents: [...data.response[0].consents || []],
                        contacts: [...data.response[0].contacts || []]/*.filter(el => el.main)*/,
                        documents: [...data.response[0].documents || []],
                        socialMedia: [...data.response[0].socialMedia || []]/*.filter(el => el.main)*/,
                        profileCredit: [...data.response[0].profileCredit || []],
                        membershipCard: data.response[0].membershipCard?.length > 0 ? [data.response[0].membershipCard[0]] : [],
                        profileCustomData: [...data.response[0].profileCustomData || []]
                    };

                    masterUniqueValues.profileCustomData.forEach(profileCustomData => {
                        profileCustomData.fieldSettings = customFieldsList.find(el => el.code === profileCustomData.typeCode) || {};
                    });

                    if (data.response[0].associateProfiles && this.state.mergeType === 'editMaster' && !duplicatesLoaded) {
                        this.setState({ master, masterUniqueValues });

                        this.setState({ totalDuplicates: data.response[0].associateProfiles.length }, () =>
                            data.response[0].associateProfiles.forEach(ap => {
                                this.getProfilesData(ap.id)
                            })
                        );
                    }
                    else {
                        this.setState({ master, masterUniqueValues, block: false }, () => { if (duplicatesLoaded) this.highlightFields() })
                    }
                }
            }
        }, `/api/gms/Profile/v1/${profileId}?includeConnections=true`)
    }

    removeProfile = (colId, profileId) => {
        this.handleAllSwitches(colId, null, true);

        var list = this.state.duplicatedProfiles.filter(p => p.id !== profileId);

        this.setState({ duplicatedProfiles: list, totalDuplicates: list.length })
    }

    showProfiles = (name) => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }))
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            master: {
                ...prevState.master,
                [name]: combo ? combo.value : null
            }
        }));
    }

    removeAllProfiles = () => {
        //Uncheck switches
        for (var i = 0; i < this.state.duplicatedProfiles.length; i++) {
            this.handleAllSwitches(i + 1, null, true)
        }

        this.setState({
            master: {
                addresses: [],
                consents: [],
                contacts: [],
                documents: [],
                //informations: [],
                //preferences: [],
                //salesMarketing: [],
                socialMedia: [],
                //profileWarnings: [],
                membershipCard: [],
                profileCredit: []
            },
            duplicatedProfiles: [],
            totalDuplicates: 0
        })
    }

    handleMergeType = (mergeType, remove) => {
        this.setState({
            mergeType,
            isMaster: mergeType === 'createMaster' ? false : mergeType === 'mergeMasters' ? true : null,
            disableMasterFilter: mergeType === 'createMaster' || mergeType === 'mergeMasters' ? true : false,
            totalDuplicates: remove ? 0 : this.state.totalDuplicates,
            collapse: false
        }, () => { if (remove === true) this.removeAllProfiles() });
    }

    removeData = (arrayName, index) => {
        const { master } = this.state;

        master[arrayName].splice(index, 1);

        this.setState({ master });
    }

    render() {
        const { block, error, duplicatedProfiles, master, mergeType, disableMasterFilter, isMaster, totalDuplicates } = this.state;

        return (
            <div>
                <BlockUi tag="div" blocking={block || (totalDuplicates && totalDuplicates !== duplicatedProfiles.length ? true : false)}>
                    <ErrorAlert error={error} />
                    <CardBody>
                        <Row>
                            <Col>
                                <h5><span className="fas fa-people-arrows mr-2"></span> <FormattedMessage id="NavMenu.Merge" /> </h5>
                            </Col>
                            <Col>
                                <CommonHelper help={<FormattedHTMLMessage id="ProfileMergeDetail.Help" />} id={'profileMergeDetail'} />
                            </Col>
                        </Row>

                        <Form className="my-3">
                            <Row>
                                <Col className="col-10">
                                    <div className="d-flex ">
                                        <CustomInput className="mr-3" type="radio" id="createMaster" label={<FormattedMessage id="ProfileMergeDetail.CreateNewMaster" />} onChange={() => this.handleMergeType("createMaster", true)} checked={mergeType === "createMaster"} />
                                        <CustomInput className="mr-3" type="radio" id="mergeMasters" label={<FormattedMessage id="ProfileMergeDetail.MergeMasterProfiles" />} onChange={() => this.handleMergeType("mergeMasters", true)} checked={mergeType === "mergeMasters"} />
                                        <CustomInput type="radio" id="editMaster" label={<FormattedMessage id="ProfileMergeDetail.EditMaster" />} onChange={() => this.handleMergeType("editMaster", true)} checked={mergeType === "editMaster"} />
                                    </div>
                                </Col>
                                <Col className="text-right mb-3">
                                    <Button className="btn-sm btn-host mr-2" onClick={() => this.showProfiles('collapse')}><i className="fas fa-plus" /> </Button>
                                    <Button className="btn-sm btn-host" onClick={this.saveMasterProfile}><i className="fas fa-save" /></Button>
                                </Col>
                            </Row>

                            <Collapse isOpen={this.state.collapse} className="mb-5">
                                {this.state.collapse ?
                                    <SearchProfile
                                        fromAnotherPage={true}
                                        addProfile={this.addProfile}
                                        pageSize={5}
                                        isMaster={isMaster}
                                        disableMasterFilter={disableMasterFilter}
                                        history={this.props.history}
                                        showHotel={true}
                                        mergeProfile={true}
                                    />
                                    : ''}
                            </Collapse>

                            <Row className="mb-2">
                                <Col className="col-4 border-right small">
                                    <Row className="border-bottom mb-2"/*position-sticky*/ style={{ 'top': '5px', zIndex: '1000', height: '65px' }}>
                                        <Col className="col-3"> </Col>
                                        <Col className="d-flex align-items-center pointer mb-2" onClick={() => master && master.id ? window.open("/ProfileDetails/" + master.id, '_blank') : ''}>
                                            <Badge className="profileBadge mr-2"> M </Badge>
                                            <b className="font-weight-bold m-0" style={{ fontSize: '13px' }}> <FormattedMessage id="ProfileMergeDetail.Master" /> </b>
                                        </Col>
                                        <Col className="text-right d-flex flex-column pl-1" style={{ justifyContent: 'space-evenly' }}>
                                            <span onClick={this.removeAllProfiles} style={{ cursor: 'pointer', fontSize: '14px' }}> <i className="fas fa-trash-alt" /> </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1 align-items-center">
                                        <Label className="font-weight-bold col-sm-3"> <FormattedMessage id="ProfileDetails.Type" /> </Label>
                                        <div className="col-9">
                                            <CustomSelect
                                                placeholder=""
                                                options={getProfileType(this.props.intl)}
                                                value={master.type ? getProfileType(this.props.intl).find(t => t.value === master.type) : ''}
                                                onChange={this.handleSelect.bind(this, 'type')}
                                                isClearable
                                                isSearchable
                                                required
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center">
                                        <Label className="font-weight-bold col-sm-3"> <FormattedMessage id="ProfileDetails.Name" /> </Label>
                                        <div className="col-9">
                                            <Input type="text" bsSize="sm" name="firstName" value={master.firstName ? master.firstName : ''} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center">
                                        <Label className="font-weight-bold col-sm-3"> <FormattedMessage id="ProfileDetails.Surname" /> </Label>
                                        <div className="col-9">
                                            <Input type="text" bsSize="sm" name="lastName" value={master.lastName ? master.lastName : ''} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center" style={{ height: '38px' }}>
                                        <Label className="font-weight-bold col-sm-3 pr-0"> <FormattedMessage id="ProfileDetails.FullName" /> </Label>
                                        <div className="col-9">
                                            <Input type="text" bsSize="sm" name="fullName" value={master.fullName ? master.fullName : ''} onChange={(e) => this.handleChange(e)} readOnly />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center" style={{ height: '38px' }}>
                                        <Label className="font-weight-bold col-sm-3"> <FormattedMessage id="ProfileDetails.OtherNames" /> </Label>
                                        <div className="col-9">
                                            <Input type="text" bsSize="sm" name="middleName" value={master.middleName ? master.middleName : ''} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center">
                                        <Label className="font-weight-bold col-sm-3"> <FormattedMessage id="ProfileDetails.Gender" /> </Label>
                                        <div className="col-9">
                                            <Input type="text" bsSize="sm" name="gender" value={master.gender ? master.gender : ''} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center" style={{ height: '38px' }}>
                                        <Label className="font-weight-bold col-sm-3 pr-0"> <FormattedMessage id="ProfileDetails.Nationality" /> </Label>
                                        <div className="col-9">
                                            <Input type="text" bsSize="sm" name="nationality" value={master.nationality ? master.nationality : ''} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center">
                                        <Label className="font-weight-bold col-sm-3"> <FormattedMessage id="ProfileDetails.Language" /> </Label>
                                        <div className="col-9">
                                            <Input type="text" bsSize="sm" name="language" value={master.language ? master.language : ''} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center" style={{ height: '38px' }}>
                                        <Label className="font-weight-bold col-sm-3 pr-0"> <FormattedMessage id="ProfileDetails.DateOfBirth" /> </Label>
                                        <div className="col-9">
                                            <SingleDatePicker
                                                id="BirthDate"
                                                isOutsideRange={day => day >= moment()}
                                                date={master.birthDate ? moment(master.birthDate) : null}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                onDateChange={date => this.setState({ master: { ...this.state.master, birthDate: date } })}
                                                showDefaultInputIcon={true}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-1 align-items-center">
                                        <Label className="font-weight-bold col-sm-3"> <FormattedMessage id="ProfileDetails.ISS" /> </Label>
                                        <div className="col-9">
                                            <CustomSelect
                                                placeholder=""
                                                options={getISSOptions(this.props.intl)}
                                                value={master.iss !== null ? getISSOptions(this.props.intl).find(t => t.value === master.iss) : ''}
                                                onChange={this.handleSelect.bind(this, 'iss')}
                                                isClearable
                                                isSearchable
                                            />
                                        </div>
                                    </Row>
                                </Col>

                                <div className="col-8 pr-0">
                                    <Row className="scrollableDiv" id="div1">
                                        {duplicatedProfiles && duplicatedProfiles.map((profile, key) =>
                                            <ProfileMainData
                                                key={key}
                                                index={key}
                                                profile={profile}
                                                removeProfile={this.removeProfile}
                                                handleAllSwitches={this.handleAllSwitches}
                                                handleSwitch={this.handleSwitch}
                                                state={this.state}
                                                mergeType={mergeType}
                                            />
                                        )}
                                    </Row>
                                </div>
                            </Row>
                        </Form>

                        <ProfileDataTabs
                            master={this.state.master}
                            duplicatedProfiles={duplicatedProfiles}
                            handleSwitch={this.handleListSwitch}
                            state={this.state}
                            removeData={this.removeData}
                        />

                    </CardBody>
                </BlockUi>
            </div>
        );
    }
};

export default injectIntl(ProfileMergeDetail)