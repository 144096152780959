import React, { Component } from 'react';
import { Input, Row, Col, Button, Form, Label, FormGroup, Card, UncontrolledTooltip, Badge } from 'reactstrap';
import { getGenderOptions, getISSOptions, getProfileType } from '../Base/ReferenceDataFunctions';
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import countryList from "react-select-country-list";
import CustomSelect from "../Base/CustomSelect";
import { ErrorAlert } from '../Base/ErrorAlert';
import { SingleDatePicker } from "react-dates";
import { getAPI, putAPI, postAPI, deleteAPI } from '../Base/API';
import BlockUi from 'react-block-ui';
import 'react-dates/initialize';
import moment from "moment";
import { getComboLanguages } from '../Base/ReferenceDataFunctions';

import Authorization from '../Base/Authorization';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import RightToBeForgottenModal from './RightToBeForgottenModal';
import { ProfileHistory } from '../Base/HistoryComponents';
import ProfileAppliedRules from './ProfileAppliedRules';

class ProfilePersonalData extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            deleteModal: false,
            appliedRulesModal: false,
            profile: this.props.profile ? this.props.profile : '',
            rules: this.props.rules ? this.props.rules : null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.profile !== nextProps.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.state.rules) {
            this.state.rules.forEach(rule => {
                if (rule.field) {
                    var field = document.getElementById(rule.field);

                    var icon = document.createElement('i');
                    icon.className = "fas fa-exclamation-triangle ml-2";
                    icon.setAttribute('style', 'color: orange');
                    icon.innerHTML = `<span className="toolTip"> <b className="mb-1">${rule.name}</b> <br/> ${rule.description} </span>`

                    icon.onmouseover = function myFunction() {
                        var toolTip = icon.firstChild;
                        toolTip.classList.toggle("show");
                    };
                    icon.onmouseout = function myFunction() {
                        var toolTip = icon.firstChild;
                        toolTip.classList.toggle("show");
                    };

                    field && field.insertBefore(icon, field.firstElementChild.nextSibling);
                }
            })
        }
    }

    saveProfileData = (evt, body) => {
        evt.preventDefault();
        this.setState({ block: true });

        body.birthDate = body.birthDate ? moment(body.birthDate).format('YYYY-MM-DD') : null;

        putAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.ProfileSaved" />, <FormattedMessage id="generic.success" />);

                    if (this.props.newProfile) {
                        this.props.history.push({ pathname: `/ProfileDetails/${data.response[0].id}` });
                        this.props.updateNewProfile();
                    }
                    else {
                        this.props.updateProfile(data.response[0]);
                    }
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/profile`, JSON.stringify({ request: body }))
    }

    revalidateProfile = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.ProfileRevalidated" />, <FormattedMessage id="generic.success" />);
                }

                this.setState({ block: false });
            }
        }, `/api/gmsscheduletask/ScheduleTasks/ProfileRules/${this.props.profileId}`)
    }

    createMaster = () => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;


            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.MasterCreated" />, <FormattedMessage id="generic.success" />);
                    this.setState({ block: false }, this.props.getProfile);
                    return;
                }

                this.setState({ block: false });
            }
        }, `/api/gmsscheduletask/DataQuality/MergeProfile?profileId=${this.props.profileId}`)
    }

    deleteProfile = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.toggleModal('deleteModal');
                }
                else {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.ProfileDeleted" />, <FormattedMessage id="generic.success" />);
                    this.props.history.push({ pathname: `/SearchProfile` });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/${this.props.profileId}`);
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            profile: {
                ...prevState.profile,
                [name]: combo ? combo.value : null
            }
        }));
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;
        this.setState(prevState => ({
            profile: {
                ...prevState.profile,
                [name]: value
            }
        }));
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    render() {
        const { profile, error, deleteModal, rightToBeForgottenModal, appliedRulesModal } = this.state;
        const { canEditProfile } = this.props;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={error} />

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.deleteProfile(e)}
                        text={<FormattedMessage id="ProfileDetails.ConfirmDeleteText" />}
                        block={this.state.block}
                    />
                : ''}

                {rightToBeForgottenModal ?
                    <RightToBeForgottenModal
                        modal={rightToBeForgottenModal}
                        toggleModal={() => this.toggleModal('rightToBeForgottenModal')}
                        profileId={this.props.profileId}
                        getProfile={this.props.getProfile}
                    />
                : ''}

                {appliedRulesModal ?
                    <ProfileAppliedRules
                        modal={appliedRulesModal}
                        toggleModal={() => this.toggleModal('appliedRulesModal')}
                        profileId={this.props.profileId}
                    />
                : ''}

                <div className="m-2 p-2">
                    <Form onSubmit={(e) => this.saveProfileData(e, profile)}>
                        <Row className={`${global.isMobile && this.props.isPortal ? '' : 'mb-4'} align-items-center`}>
                            <Col className={`col-5 col-lg-2 d-flex align-items-center`}>
                                <h5 className="m-0 text-truncate">
                                    <i className="icon-icon-profile mr-2" /><FormattedMessage id="ProfileDetails.PersonalData" />
                                </h5>
                            </Col>
                            {!global.isMobile ?
                                <Col>
                                    {this.props.isPortal ?
                                        <>
                                            <Badge className="px-2 font_size_xxs" color="info" id="isPortal">
                                                <i className="fas fa-plug mr-1" /> Portal
                                            </Badge>
                                            
                                            <UncontrolledTooltip target="isPortal" placement="bottom">
                                                <FormattedMessage id="ProfileDetails.IsPortalTooltip" />
                                            </UncontrolledTooltip>
                                        </>
                                    : ''}
                                </Col>
                            :''}
                            <Col className={`d-flex justify-content-end ${global.isMobile ? 'col-7' : ''}`}>
                                {this.props.newProfile === false ?
                                    <>
                                        <ProfileHistory
                                            component={"profile"}
                                            profileId={this.props.profileId}
                                        />

                                        {profile.isMaster && !profile.rightToBeForgotten  ?
                                            <Authorization
                                                perform="profile:rtbf"
                                                yes={() =>
                                                    <>
                                                        <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal('rightToBeForgottenModal')} id="rightToBeForgottenBtn">
                                                            <i className="fas fa-user-slash" />
                                                        </Button>
                                                        <UncontrolledTooltip target="rightToBeForgottenBtn" placement="bottom">
                                                            <FormattedMessage id="ProfileDetails.ForgetProfile" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                }
                                                no={() => <div></div>}
                                            />
                                        : ''}

                                        <Authorization
                                            perform="profile:delete"
                                            yes={() =>  (
                                                <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal('deleteModal')}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />

                                        <Authorization
                                            perform="profilePersonalData:autoMerge"
                                            yes={() => (
                                                !profile.isMaster && (profile.associateProfiles ? !profile.associateProfiles.some(p => p.isMaster) : true) && !profile.rightToBeForgotten ?
                                                    <>
                                                        <Button className="btn-sm btn-host ml-2" onClick={this.createMaster} id="createMaster">
                                                            <i className="fas fa-people-arrows" />
                                                        </Button>
                                                        <UncontrolledTooltip target="createMaster" placement="bottom">
                                                            <FormattedMessage id="ProfileDetails.CreateMasterAutomatically" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                : ''
                                            )}
                                            no={() => <div></div>}
                                        />

                                        {!profile.rightToBeForgotten ?
                                            <Authorization
                                                perform="profilePersonalData:revalidate"
                                                yes={() => (
                                                    <>
                                                        <Button className="btn-sm btn-host ml-2" onClick={this.revalidateProfile} id="revalidateProfile">
                                                            <i className="fas fa-user-check" />
                                                        </Button>
                                                        <UncontrolledTooltip target="revalidateProfile" placement="bottom">
                                                            <FormattedMessage id="ProfileDetails.RevalidateProfile" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                )}
                                                no={() => <div></div>}
                                            />
                                            : ''}


                                        <Authorization
                                            perform="profilePersonalData:rulesApplied:view"
                                            yes={() => (
                                                <>
                                                    <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal('appliedRulesModal')} id="appliedRules">
                                                        <i className="fas fa-user-tag" />
                                                    </Button>
                                                    <UncontrolledTooltip target="appliedRules" placement="bottom">
                                                        <FormattedMessage id="ProfileDetails.AppliedRules" />
                                                    </UncontrolledTooltip>
                                                </>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </>
                                : ''}

                                <Authorization
                                    perform="profilePersonalData:save"
                                    yes={() => canEditProfile &&(
                                        <Button className="btn-sm btn-host ml-2" type="submit" disabled={profile.type !== 'Person' && !profile.firstName && !profile.lastName}>
                                            <i className="fas fa-save" />
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                        </Row>
                        {global.isMobile ?
                            this.props.isPortal ?
                                <Row className='mb-4'>
                                    <Col className='text-left col-12'>
                                        <Badge className="px-2 font_size_xxs" color="info" id="isPortal">
                                            <i className="fas fa-plug mr-1" /> Portal
                                        </Badge>
                                        
                                        <UncontrolledTooltip target="isPortal" placement="bottom">
                                            <FormattedMessage id="ProfileDetails.IsPortalTooltip" />
                                        </UncontrolledTooltip>
                                    </Col>
                                </Row>
                            : ''
                        : ''}

                        <FormGroup row>
                            <Label sm={2} >
                                <FormattedMessage id="ProfileDetails.EntityType" />
                            </Label>
                            <Col sm={2}>
                                <CustomSelect
                                    placeholder=""
                                    options={getProfileType(this.props.intl)}
                                    value={getProfileType(this.props.intl).find(t => t.value === profile.type) || ''}
                                    onChange={this.handleSelect.bind(this, 'type')}
                                    isClearable
                                    isSearchable
                                    required
                                />
                            </Col>
                            <Label sm={2} id="Gender">
                                <FormattedMessage id="ProfileDetails.Gender" />
                            </Label>
                            <Col sm={2}>
                                <CustomSelect
                                    placeholder=""
                                    options={getGenderOptions(this.props.intl)}
                                    value={getGenderOptions(this.props.intl).find(g => g.value === profile.gender) || ''}
                                    onChange={this.handleSelect.bind(this, 'gender')}
                                    isClearable
                                    isSearchable
                                />
                            </Col>
                            <Label sm={2} id="BirthDate">
                                <FormattedMessage id="ProfileDetails.DateOfBirth" />
                            </Label>
                            <Col sm={2}>
                                <SingleDatePicker
                                    id="dateOfBirth"
                                    isOutsideRange={day => day > moment()}
                                    date={profile.birthDate ? moment(profile.birthDate) : null}
                                    focused={this.state.focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    onDateChange={date => this.setState({ profile: { ...this.state.profile, birthDate: date } })}
                                />
                            </Col>

                        </FormGroup>

                        <FormGroup row>
                            <Label sm={2} id="FirstName">
                                <FormattedMessage id="ProfileDetails.Name" />
                            </Label>
                            <Col sm={2}>
                                <Input
                                    type="text"
                                    maxLength="100"
                                    name="firstName"
                                    required={profile.type === 'Person'}
                                    value={profile.firstName ? profile.firstName : ''}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Label sm={2} id="LastName">
                                <FormattedMessage id="ProfileDetails.Surname" />
                            </Label>
                            <Col sm={2}>
                                <Input
                                    type="text"
                                    maxLength="100"
                                    name="lastName"
                                    required
                                    value={profile.lastName ? profile.lastName : ''}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Label sm={2}>
                                <FormattedMessage id="ProfileDetails.OtherNames" />
                            </Label>
                            <Col sm={2}>
                                <Input type="text" maxLength="100" name="middleName" value={profile.middleName || ''} onChange={this.handleChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2} id="FullName" className="pr-0">
                                <FormattedMessage id="ProfileDetails.FullName" />
                            </Label>
                            <Col sm={10}>
                                <Input type="text" name="fullName" value={profile.fullName ? profile.fullName : ''} maxLength="200" onChange={this.handleChange} readOnly />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="align-items-center">
                            <Label sm={2} id="Nationality">
                                <FormattedMessage id="ProfileDetails.Nationality" />
                            </Label>
                            <Col sm={2}>
                                <CustomSelect
                                    options={countryList().getData()}
                                    placeholder=""
                                    value={profile.nationality ? countryList().getData().find(country => country.value === profile.nationality) : ''}
                                    onChange={this.handleSelect.bind(this, 'nationality')}
                                    isClearable isSearchable
                                />
                            </Col>
                            <Label sm={2} id="Language">
                                <FormattedMessage id="ProfileDetails.Language" />
                            </Label>
                            <Col sm={2}>
                                <Input type="text" className="shadow" maxLength="5" name="language" value={profile.language ? profile.language : ''} onChange={this.handleChange} />
                            </Col>
                            <Label sm={2} className="pr-0">
                                <FormattedMessage id="ProfileDetails.ISS" />
                            </Label>
                            <Col sm={2}>
                                <CustomSelect
                                    options={getISSOptions(this.props.intl)}
                                    placeholder=""
                                    value={getISSOptions(this.props.intl).find(el => el.value == profile.iss) || ''}
                                    onChange={this.handleSelect.bind(this, 'iss')}
                                    isClearable isSearchable
                                />
                            </Col>
                        </FormGroup>

                        {profile.rightToBeForgotten ?
                            <div className="mt-5">
                                <h5> <i className="fas fa-user-slash" /> <FormattedMessage id="ProfileDetails.ForgetfulnessReason" /> </h5>
                                <span className="pl-4 ml-2"> <FormattedMessage id={`RightToBeForgottenModal.${profile.rightToBeForgotten}`} /></span>
                            </div>
                        : ''}

                        <Row className="mb-3 mt-5 align-items-center">
                            <Col className={`${global.isMobile ? 'col-12 mb-2' : ''}`}>
                                <h5 className="m-0"><i className="fas fa-highlighter mr-2" /><FormattedMessage id="ProfileDetails.GeneralNotes" /></h5>

                            </Col>

                            <Col className={`${!global.isMobile ? 'text-right' : 'col-12 text-muted'}`} style={{ fontSize: !global.isMobile ? '13px' : '10px' }}>
                                {profile.createdAt &&
                                    <>
                                        {profile.isAutomaticMerge || profile.isManualMerge ?
                                            <div className="mb-1">
                                                <FormattedMessage id={`ProfileDetails.${profile.isManualMerge ? 'Manual' : 'Automatic'}MergeAt`} />
                                                <span className="ml-2">
                                                    {profile.automaticMergeAt ? moment(profile.automaticMergeAt).format("YYYY-MM-DD") : ''} 
                                                </span>
                                            </div>
                                        : ''}

                                        <div className="mb-1">
                                            <FormattedMessage id="ProfileDetails.CreationDate" />: <span className="ml-2"> {moment(profile.createdAt).format("YYYY-MM-DD")}</span>
                                        </div>

                                        <Authorization
                                            perform="admin:view"
                                            yes={() => (
                                                <div>
                                                    <FormattedMessage id="ProfileDetails.UpatedDate" />:
                                                    <span className="ml-2"> {moment(profile.updatedAt).format("YYYY-MM-DD")}</span>
                                                </div>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </>
                                }

                            </Col>
                        </Row>


                        <Input
                            type="textarea" name="guestInfo"
                            className="py-2"
                            style={{ minHeight: '150px' }}
                            value={profile.guestInfo || ''} onChange={this.handleChange}
                        />
                    </Form>
                </div>
            </BlockUi>
        );
    }
}
export default injectIntl(ProfilePersonalData)