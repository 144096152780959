import React, { Component } from 'react';

class SpaceCell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCells: [],
            originalCells: []
        };
    }

    componentDidMount(){
        const { value } = this.props;
        
        if(!value)
            return;

        const selectedCells = [];

        for(let i = 0; i < 4; i++) {
            if(value[i]?.spaceReservationList?.length){
                selectedCells.push(i);
            }
        }

        this.setState({ selectedCells, originalCells: selectedCells });
    }

    handleDragStart = (e, key) => {
        const { selectedCells, originalCells } = this.state;

        if(!selectedCells.includes(key)){
            this.setState({ selectedCells: [...selectedCells, key], originalCells: originalCells.filter((a) => a !== key) }, () => this.props.setDraggingRow());
        }
    }

    handleDragEnd = (e) => {
        this.props.setDraggingRow();
        this.props.toggleDetailPopOver();
    }

    handleDragOver = (e, key) => {
        const { selectedCells, originalCells } = this.state;
        const { canDrag } = this.props;
        
        if(!selectedCells.includes(key) && canDrag()){
            this.setState({ selectedCells: [...selectedCells, key], originalCells: originalCells.filter((a) => a !== key) });
        }
    }

    handleClick = (key) => {
        const { selectedCells, originalCells } = this.state;
        const { isPopOverOpened } = this.props;

        if(!selectedCells.includes(key)) {
            this.setState({ selectedCells: [...selectedCells, key], originalCells: originalCells.filter((a) => a !== key) }, () => this.props.toggleDetailPopOver());
        }
        else if(isPopOverOpened()) {
            this.setState({ selectedCells: selectedCells.filter((k) => k !== key), originalCells: originalCells.filter((a) => a !== key) });
        }
        else {
            this.props.toggleDetailPopOver();
        }
    }

    render() {
        const { selectedCells, originalCells } = this.state;
        const { value, rowKey, colKey, spaceCode } = this.props;
        // ServiceTypeCode
        // FromDate
        // ToDate
        // StatusCode
        // Pax
        // Children
        // Children2
        // DailyPrice
        // Notes

        const id = `cell-${rowKey}-${colKey}`;
        return (
            <>
                <div id={id} className={"py-1 px-2 w-100 h-100 d-flex align-items-center"} style={{ gap: '5%' }}>
                    <div className={`${selectedCells.includes(0) ? `cell-time-slot-space-${spaceCode} cell-time-slots-selected${originalCells.includes(0) ? '' : '-focused'}`:''} h-100 cell-time-slots ${value[0].value ? '' : 'cell-time-slots-empty'} ${value[0].spaceReservationList?.length ? 'cell-time-slots-selected' : ''} cell-time-slot-time-${colKey} cell-time-slot-index-0`}>
                        <div
                            className={`h-100 w-100`}
                            onClick={_ => this.handleClick(0)}
                            draggable={true}
                            onDragStart={e => this.handleDragStart(e, 0)}
                            onDragEnd={e => this.handleDragEnd(e, 0)}
                            onDragOver={e => this.handleDragOver(e, 0)}
                        />
                    </div>
                    <div className={`${selectedCells.includes(1) ? `cell-time-slot-space-${spaceCode} cell-time-slots-selected${originalCells.includes(1) ? 'bananas' : '-focused'}`:'batatas'} h-100 cell-time-slots ${value[1].value ? '' : 'cell-time-slots-empty'} ${value[1].spaceReservationList?.length ? 'cell-time-slots-selected' : ''} cell-time-slot-time-${colKey} cell-time-slot-index-1`}>
                        <div
                            className={`h-100 w-100`}
                            onClick={_ => this.handleClick(1)}
                            draggable={true}
                            onDragStart={e => this.handleDragStart(e, 1)}
                            onDragEnd={e => this.handleDragEnd(e, 1)}
                            onDragOver={e => this.handleDragOver(e, 1)}
                        />
                    </div>
                    <div className={`${selectedCells.includes(2) ? `cell-time-slot-space-${spaceCode} cell-time-slots-selected${originalCells.includes(2) ? '' : '-focused'}` : ''} h-100 cell-time-slots ${value[2].value ? '' : 'cell-time-slots-empty'} ${value[2].spaceReservationList?.length ? 'cell-time-slots-selected' : ''} cell-time-slot-time-${colKey} cell-time-slot-index-2`}>
                        <div
                            className={`h-100 w-100`}
                            onClick={_ => this.handleClick(2)}
                            draggable={true}
                            onDragStart={e => this.handleDragStart(e, 2)}
                            onDragEnd={e => this.handleDragEnd(e, 2)}
                            onDragOver={e => this.handleDragOver(e, 2)}
                        />
                    </div>
                    <div className={`${selectedCells.includes(3) ? `cell-time-slot-space-${spaceCode} cell-time-slots-selected${originalCells.includes(3) ? '' : '-focused'}` :''} h-100 cell-time-slots ${value[3].value ? '' : 'cell-time-slots-empty'} ${value[3].spaceReservationList?.length ? 'cell-time-slots-selected' : ''} cell-time-slot-time-${colKey} cell-time-slot-index-3`}>
                        <div
                            className={`h-100 w-100`}
                            onClick={_ => this.handleClick(3)}
                            draggable={true}
                            onDragStart={e => this.handleDragStart(e, 3)}
                            onDragEnd={e => this.handleDragEnd(e, 3)}
                            onDragOver={e => this.handleDragOver(e, 3)}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default SpaceCell;