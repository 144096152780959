import React, { Component } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, UncontrolledTooltip, Badge, Collapse } from 'reactstrap';
import { getProfileType } from '../Base/ReferenceDataFunctions';
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import ProfilePersonalData from './ProfilePersonalData';
import { getAPI, deleteAPI } from '../Base/API';
import moment from 'moment';
import ProfileOverview from './ProfileOverview';
import ProfileJourney from './ProfileInteractions/ProfileJourney';
import ProfileRevenue from './ProfileInteractions/ProfileRevenue';
import ProfileReservations from './ProfileInteractions/Reservations/ProfileReservations';
import ProfileVouchers from './ProfileInteractions/ProfileVouchers';
import ProfileForms from './ProfileInteractions/ProfileForms';
import AssociatedProfiles from './AssociatedProfiles';
import { ProfileCredit } from './ProfileInteractions/Credit/ProfileCredit';
import ProfileInterests from './ProfileInterests';
import ProfileMarketing from './ProfileInteractions/ProfileMarketing';
import { ProfileReview } from './ProfileReview';
import ProfileContacts from './ProfileContacts';
import { StyledCard } from '../Base/CommonUIComponents';
import ProfileAddresses from './ProfileAddresses';
import ProfileChat from './ProfileInteractions/Chat/ProfileChat';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import ProfileInformations from './ProfileInformations';

import roiback from '../../img/roiback.jpg';
import egoi from '../../img/egoi.png';
import profileNow from '../../img/profileNow.png';
import ProfileDocuments from './ProfileDocuments';
import ProfileConsents from './ProfileConsents';
import MainDataCard from './MainDataCard';
import { ProfileSalesMarketing } from './ProfileSalesMarketing';
import { AdminIcon } from '../AdminIcon';
import ProfileWarnings from './ProfileWarnings';
import { ProfilePreferences } from './ProfilePreference';
import ProfileMembershipCard from './ProfileMembershipCard';
import ProfileCustomData from './ProfileCustomData/ProfileCustomData';
import ProfileJourneys from './ProfileInteractions/ProfileJourneys';
import ProfileSalesProcess from './ProfileInteractions/ProfileSalesProcess';
import { ProfileSalesProcessInteractions } from './ProfileInteractions/ProfileSalesProcessInteractions';
import { checkSalesProcessPermission, getCurrentUser } from '../SalesProcess/SalesProcessFunctions';


class ProfileDetails extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            profile: {},
            activeTab: 'Profile',
            socialMediaModal: false,
            salesMarketingModal: false,
            preferencesModal: false,
            documentsModal: false,
            contactModal: false,
            consentsModal: false,
            warningModal: false,
            sendSendGridEmailModal: false,
            sendEmailModal: false,
            sendSmsModal: false,
            sendTwillioSmsModal: false,
            cardModal: false,
            newProfile: this.props.match.params.id ? false : true,
            profileRules: [],
            collapse: false,
            relationsList: [],
            openMenu: 1,
            profileId: null,
            startDateFilter: moment().subtract(1, 'year'),
            endDateFilter: moment(),
            hotels: [],
            sources: [
                { value: 'Csv', label: 'Csv', icon: <i className="fas fa-file-csv mr-1" /> },
                { value: 'Roiback', label: 'Roiback', icon: <img alt="" src={roiback} height="14px" className="mr-1" /> },
                { value: 'Egoi', label: 'Egoi', icon: <img alt="" src={egoi} height="14px" className="mr-1" /> },
                { value: 'ProfileNow', label: 'Profile Now', icon: <img alt="" src={profileNow} height="14px" className="mr-1" /> },
            ],
            fixedHotelFilter: null,
            canEditProfile: false, 
            menuCollapse: false,
            profileComboOptions: []
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        if (!this.state.newProfile) {
            const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

            if (this.props.match.params.id && (!isNaN(this.props.match.params.id) || guidRegex.test(this.props.match.params.id))) {
                this.getProfile(this.props.location.rules, true);
                this.getHotels();
            }
        }
        else {
            window.location.hash = "#Profile";
            this.setState({ canEditProfile: true });
        }

        window.setTimeout(() => {
            if (CheckAuthorization("user:restrictHotelFilter", null)) {
                this.setState({
                    fixedHotelFilter: global.hotelId
                })
            }
        }, 2000 || global.hotelId); 
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.hash !== this.props.location.hash && this.props.location.hash?.substring(1) !== this.state.activeTab) {
            this.goToSelectedHashMenu();
        }

        if (prevProps.match.params.id !== this.props.match.params.id && !this.state.newProfile) {
            this.setState({ profile: {}, relationsList: [], profileRules: [], profileId: null, canEditProfile: false })
            this.getProfile(this.props.location.rules);
        }
    }

    getHotels = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                var combo = [];

                data.response.map((item) =>
                    combo.push({ 'value': item.hotelId, 'label': item.name2, hotelGroupId: item.hotelGroupId, hotelGroupName: item.hotelGroupName })
                )

                this.setState({ hotels: combo });
            }
        }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true');
    }

    getRelations = (firstCall) => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, () => {
                    if (firstCall) {
                        this.goToSelectedHashMenu();
                    }
                });
                return;
            }
            if (data) {
                if (firstCall) {
                    this.goToSelectedHashMenu()
                }

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ relationsList: data.response, block: false });
                }
                else {
                    this.setState({ block: false });
                }
            }
            else {
                this.setState({ block: false }, () => {
                    if (firstCall) {
                        this.goToSelectedHashMenu();
                    }
                });
            }
        }, `/api/gms/Profile/v1/profile/${this.state.profileId}/relation`)
    }

    getProfile = (rules, firstCall) => {
        this.setState({ block: true });
        
        var params = '';
        if (rules) {
            rules.forEach(rule => { params += `&includeRules=${rule}` })
        }

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false }, () => {
                    if (firstCall) {
                        this.goToSelectedHashMenu();
                    }
                });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    var profile = this.getProfilesWithValidations(data.response[0]);
                    var profileRules = data.response[0].rules && data.response[0].rules.length > 0 ? data.response[0].rules.filter(rule => rule.entity === 'Profile' && rule.ruleType !== 'DuplicationCheck') : [];
                    var source = null;

                    if (data.response[0].source) source = this.state.sources.find(el => el.value === data.response[0].source);
                    const profileComboOptions = [{ value: profile.id, label: profile.fullName, type: 'profile' }];

                    this.setState({
                        profile: profile,
                        profileId: data.response[0].id,
                        profileRules,
                        source,
                        canEditProfile: profile.isMaster || (!profile.isMaster && !profile.masterId),
                        profileComboOptions
                    }, () => {
                        this.getRelations(firstCall);
                        this.groupRepeatedTags();
                        if (data.response[0].rules && data.response[0].rules.length > 0) {
                            this.showWarningIcons(data.response[0].rules)
                        }
                    });
                }
                else {
                    this.setState({ block: false }, () => {
                        if (firstCall) {
                            this.goToSelectedHashMenu();
                        }
                    });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/${this.props.match.params.id}?includeConnections=true` + params)
    }

    getProfilesWithValidations = (profile) => {
        //check for expired docs
        if (profile.documents && profile.documents.length > 0) {
            profile.documents.forEach((doc) => {
                doc.isExpired = doc.validUntil ? moment(doc.validUntil).isBefore(moment(), 'days') : false;
            })
        }

        //check for loyalty cards
        if (profile.membershipCard && profile.membershipCard.length > 0) {
            profile.membershipCard.forEach((mc) => {
                mc.isExpired = mc.validTo ? moment(mc.validTo).isBefore(moment(), 'days') : false;
            })
        }

        return profile;
    }

    groupRepeatedTags = () => {
        const { profile } = this.state;

        const tags = [];

        profile.profileTags && profile.profileTags.forEach(tag => {
            const groupIndex = tags.findIndex(el => el.tagGroupId === tag.tagGroupId);
            const profileIndex = profile.associateProfiles && profile.associateProfiles.findIndex(p => p.id === tag.profileId);

            if (profileIndex > -1) {
                const selProfile = profile.associateProfiles[profileIndex];

                tag.hotelName = selProfile.hotelName;
                tag.profileName = selProfile.firstName + ' ' + selProfile.lastName;
            }

            if (groupIndex > -1) {
                const tagIndex = tags[groupIndex].tags.findIndex(tg => tg.tagId === tag.tagId);

                if (tagIndex > -1) {
                    tags[groupIndex].tags[tagIndex].frequency = tags[groupIndex].tags[tagIndex].frequency + tag.frequency;
                    tags[groupIndex].tags[tagIndex].allTags.push(tag);
                }
                else {
                    tags[groupIndex].tags.push({ code: tag.description, tagId: tag.tagId, frequency: tag.frequency, allTags: [tag] });
                }
            }
            else {
                tags.push({ tagGroupId: tag.tagGroupId, tagGroupCode: tag.tagGroupCode, tags: [{ code: tag.description, tagId: tag.tagId, frequency: tag.frequency, allTags: [tag] }] });
            }
        });

        profile.profileTags = tags;

        this.setState({ profile });
    }

    showWarningIcons = (rules) => {
        var otherRules = rules.filter(rule => rule.entity !== 'Profile' && rule.ruleType !== 'DuplicationCheck');

        if (otherRules) {
            otherRules.forEach(rule => {
                if (rule.field && rule.entityId) {

                    var field = document.getElementById(`${rule.field}-${rule.entityId}`);
                    var menuBadge = document.getElementById(`Badge-ProfileDetails.${rule.entity}`);

                    if (menuBadge) {
                        //Change badge color of menu
                        menuBadge.classList.add("bg-yellow");
                    }


                    //Add warning icon
                    var icon = document.createElement('i');
                    icon.className = "fas fa-exclamation-triangle ml-2";
                    icon.setAttribute('style', 'color: orange');
                    icon.innerHTML = `<span className="toolTip"> <b className="mb-1">${rule.name}</b> <br/> ${rule.description} </span>`

                    icon.onmouseover = function myFunction() {
                        var toolTip = icon.firstChild;
                        toolTip.classList.toggle("show");
                    };
                    icon.onmouseout = function myFunction() {
                        var toolTip = icon.firstChild;
                        toolTip.classList.toggle("show");
                    };

                    field && field.firstElementChild && field.insertBefore(icon, field.firstElementChild.nextSibling);
                }
            })
        }
    }

    toggle(id) {
        this.setState({ activeTab: id, menuCollapse: false });
        window.location.hash = "#" + id;
        window.scrollTo(0, 0);
    }

    //Muda para o menú que está selecionado no hash do url (chamar apenas quando os dados do profile estiverem carregados)
    goToSelectedHashMenu = () => {
        const menu1 = ['Profile', 'CustomData', 'Addresses', 'Consents', 'Contacts', 'Documents', 'Informations', 'Preferences', 'MembershipCards', 'SalesMarketing', 'Credits', 'Warnings', 'Interests', 'AssociatedProfiles'];

        const activeTab = window.location.hash.substring(1) || 'Profile';

        this.setState({
            activeTab,
            openMenu: activeTab === 'Overview' ? 0 : menu1.some(el => el === activeTab) ? 1 : 2
        })
    }

    toggleModal = (modal, selected, obj) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            [selected]: obj
        }))
    }

    updateTable = (data, key, isArray) => {
        this.setState({ block: true });

        const { profile } = this.state;
        var array = profile[key] ? profile[key] : profile[key] = [];

        if (isArray) {
            data.forEach(obj => {
                const index = array && array.findIndex(el => el.id === obj.id);

                if (index === -1) {
                    array.push(obj);
                }
                else {
                    array[index] = obj;
                }
            })
        }
        else {
            const index = array && array.findIndex(el => el.id === data.id);

            if (index === -1) {
                array.push(data);
            }
            else {
                array[index] = data;
            }
        }
        

        this.setState({ profile: { ...this.state.profile, [key]: array }, block: false });
    }

    removeFromTable = (elementId, type) => {
        this.setState({ block: true });

        const { profile } = this.state;
        var array = profile[type].filter(el => el.id !== elementId);

        this.setState({ profile: { ...this.state.profile, [type]: array }, block: false });
    }

    removeElement = (profileId, elementId, type, listName) => {
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id={`ProfileDetails.${type}Saved`} />, <FormattedMessage id="generic.success" />);
                    this.removeFromTable(elementId, listName);
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/profile/${type}/${profileId}/${elementId}`)
    }

    updateRelationsList = (relationId, addRelation, relationData) => {
        var relationsList = [...this.state.relationsList];

        if (addRelation) {
            const index = relationsList.findIndex(el => el.id === relationId);

            if (index !== -1) {
                relationsList[index] = relationData;
            }
            else relationsList.push(relationData);
        }
        else {
            relationsList = relationsList.filter(el => el.id !== relationId);
        }
        this.setState({ relationsList });
    }

    tabItem = (activeTab, title, icon, badgeCount, isAdmin, hasWarning) => {
        return (

            <NavItem className="pb-2">
                <NavLink className={this.state.activeTab === activeTab ? 'text-primary  border-0  ' : 'bg-white  border-0 '} onClick={() => this.toggle(activeTab)} style={{ cursor: 'pointer' }} >
                    {this.state.activeTab === activeTab ?
                        <div style={{ 'left': '0', 'position': 'absolute', 'borderRadius': '0px 30px 30px 0px', 'width': '7px', 'height': '14px', 'background': '#0667FF 0% 0% no-repeat padding-box', 'opacity': '1', 'marginTop': '4px' }}></div>
                        : ''}
                    <div>
                        <i className={icon}></i><FormattedMessage id={title} />
                        {isAdmin ? <AdminIcon /> : ''}
                        {badgeCount ? <Badge id={`Badge-${title}`} className="float-right">{badgeCount}</Badge> : ''}

                        {hasWarning ?
                            <span className="float-right">
                                <i className="icon-icon-warnings-enabled text-warning fa-sm mr-2" id={`warning-${activeTab}`} />

                                <UncontrolledTooltip placement="bottom" target={`warning-${activeTab}`} >
                                    <FormattedMessage id={hasWarning.text} />
                                </UncontrolledTooltip>
                            </span>
                        : ''}
                    </div>
                </NavLink>
            </NavItem>
        );
    }

    groupByHotel = (array) => {
        return array
            .reduce((hash, obj) => {
                if (obj.hotelName === undefined) return hash;
                return Object.assign(hash, { [obj.hotelName]: (hash[obj.hotelName] || []).concat(obj) })
            }, {})
    }

    handleDates = (startDate, endDate) => {
        this.setState({
            startDateFilter: startDate,
            endDateFilter: endDate
        });
    }

    updateNewProfile = () => {
        this.setState({
            newProfile: false
        }, this.getProfile);
    }

    updateProfile = (newProfile) => {
        const { profile } = this.state;
        Object.assign(profile, newProfile);

        this.setState({ profile });
    }

    render() {
        const { block, error, profile, newProfile, profileRules, relationsList, profileId, contactModal, selectedContact, socialMediaModal, sendSendGridEmailModal, sendEmailModal, sendSmsModal, sendTwillioSmsModal, selectedSocialMedia, canEditProfile, menuCollapse, profileComboOptions } = this.state;

        const currentSalesProcessUser = getCurrentUser();

        const informations = profile.informations ? this.groupByHotel(profile.informations) : null;
        const preferences = profile.preferences ? this.groupByHotel(profile.preferences) : null;

        const hasProfile360 = global.modules && global.modules.some(m => m === 'Profile360');
        const hasWhatsApp = global.modules && global.modules.some(m => m === 'WhatsApp');
        const hasHeyCard = global.modules && global.modules.some(m => m === 'HeyCard');
        const hasHijiffy = global.modules && global.modules.some(m => m === 'HiJiffy');
        const hasSalesProcess = global.modules && global.modules.some(m => m === 'SalesProcess');
        const hasEgoi = global.modules && global.modules.some(m => m === 'Egoi');
        const hasSendGrid = global.modules && global.modules.some(m => m === 'SendGrid');
        const hasTwillio = global.modules && global.modules.some(m => m === 'TwilioMessaging');
        const hasJourneys = global.modules && global.modules.some(m => m === 'Journeys');

        const profileType = profile.type && getProfileType(this.props.intl).find(t => t.value === profile.type);

        const hasExpiredDocs = profile.documents && profile.documents.some(doc => doc.isExpired);
        const hasExpiredLoyaltyCard = profile.membershipCard && profile.membershipCard.some(mc => mc.isExpired);

        const showConsentsPage = profileType ? profileType.value === "Person" || profileType.value === "Owner" || profileType.value === "TimeSharing" : false 

        return (

            <div className="container-fluid content-row p-0">
                {profileId || newProfile ?
                    <StyledCard block={block} error={error}>
                        <Row>
                            <Col className='col-sm-12 col-lg-2 pr-lg-0'>
                                {!newProfile ?
                                    <MainDataCard
                                        profile={profile}
                                        hotels={this.state.hotels}
                                        profileType={profileType}
                                        toggleMenuCollapse={() => this.setState({ menuCollapse: !this.state.menuCollapse })}
                                    />
                                    : ''}

                                <Nav tabs vertical className="h-100 border-0">
                                    {!newProfile ?
                                        <Collapse isOpen={!global.isMobile || menuCollapse} className={global.isMobile ? 'mb-3' : ''}>
                                            <Card className="bg-white p-2 border-0 mb-3 shadow">
                                                <h5 onClick={() => { this.setState({ 'openMenu': 0, 'activeTab': 'Overview', menuCollapse: false }); window.location.hash = "#Overview"; window.scrollTo(0, 0); }} className={this.state.activeTab === 'Overview' ? ' pl-2 m-0 py-1 text-primary' : 'pl-2 m-0 py-1'} style={{ cursor: 'pointer' }} >
                                                    <FormattedMessage id="ProfileDetails.Overview" />
                                                </h5>
                                                {/*this.tabItem('0', 'ProfileDetails.Overview', 'fas fa-street-view mr-2', null)*/}
                                            </Card>

                                            <Card className="bg-white p-2 border-0 mb-3 shadow">
                                                <h5 className="pl-2 m-0 py-1" onClick={() => this.setState({ 'openMenu': 1 })} style={{ cursor: 'pointer' }}><FormattedMessage id="ProfileDetails.ProfileInformations" /></h5>
                                                <Collapse isOpen={this.state.openMenu === 1} className="pt-2">
                                                    {this.tabItem('Profile', 'ProfileDetails.Profile', 'icon-icon-profile mr-2', null)}
                                                    {!global.isMobile ? 
                                                        <>
                                                            {this.tabItem('CustomData', 'ProfileDetails.CustomData', 'icon-icon-custom-fields mr-2', (profile.profileCustomData && profile.profileCustomData.length))}
                                                            {this.tabItem('Addresses', 'ProfileDetails.ProfileAddress', 'icon-icon-addresses mr-2 ', (profile.addresses && profile.addresses.length))}
                                                            {showConsentsPage ? this.tabItem('Consents', 'ProfileDetails.ProfileConsent', 'icon-icon-consents mr-2', (profile.consents && profile.consents.length)) : ''}
                                                        </>
                                                    :''}
                                                    {this.tabItem('Contacts', 'ProfileDetails.ProfileContact', 'icon-icon-contacts mr-2', (profile.contacts && profile.contacts.length))}
                                                    {!global.isMobile ? 
                                                        this.tabItem('Documents', 'ProfileDetails.ProfileDocument', 'icon-icon-documents mr-2', (profile.documents && profile.documents.length), false, (hasExpiredDocs ? { text: "Profile.HasExpiredDocs" } : null))
                                                    :''}
                                                    {this.tabItem('Informations', 'ProfileDetails.ProfileInformation', 'icon-icon-information mr-2', (profile.informations == null ? 0 : profile.informations.length) + (profile.profileDefault == null ? 0 : profile.profileDefault.length))}
                                                    {this.tabItem('Preferences', 'ProfileDetails.ProfilePreference', 'icon-icon-preferences mr-2 ', (profile.preferences && profile.preferences.length))}
                                                    {!global.isMobile ?
                                                        <>
                                                            {this.tabItem('MembershipCards', 'ProfileDetails.MembershipCard', 'icon-icon-member-card mr-2 ', (profile.membershipCard && profile.membershipCard.length), null, (hasExpiredLoyaltyCard ? { text: "Profile.HasExpiredLoyaltyCard" } : null))}
                                                            {this.tabItem('SalesMarketing', 'ProfileDetails.ProfileSaleMarketing', 'icon-icon-sales-marketing mr-2 ', (profile.salesMarketing && profile.salesMarketing.length))}
                                                        </> 
                                                    :''}
                                                    {this.tabItem('Credits', 'ProfileDetails.Credit', 'icon-icon-credit mr-2 ', (profile.profileCredit && profile.profileCredit.length))}
                                                    {!global.isMobile ?
                                                        <>
                                                            {this.tabItem('Warnings', 'ProfileDetails.Warnings', 'icon-icon-warnings mr-2 ', (profile.profileWarnings && profile.profileWarnings.length))}
                                                            {hasProfile360 ? this.tabItem('Interests', 'ProfileDetails.Interests', 'icon-icon-interests mr-2 ', (profile.profileTags && profile.profileTags.reduce(function (acc, cur) { if (cur.tags) acc = acc + cur.tags.length; return acc }, 0))) : <div />}
                                                        </>
                                                    :''}
                                                    {this.tabItem('AssociatedProfiles', 'ProfileDetails.AssociatedProfiles', 'icon-icon-associated-profiles mr-2 ', ((profile && profile.associateProfiles || relationsList.length) ? ((profile && profile.associateProfiles ? profile.associateProfiles.length : 0) + relationsList.length) : ''))}
                                                </Collapse>
                                            </Card>

                                            {hasProfile360 ?
                                                <Authorization
                                                    perform="profileInteractions:view"
                                                    yes={() => (
                                                        <Card className="bg-white p-2 border-0 shadow">
                                                            < h5 className="pl-2 m-0 py-1" style={{ cursor: 'pointer' }} onClick={() => this.setState({ 'openMenu': 2 })}><FormattedMessage id="ProfileDetails.ProfileInteractions" /></h5>
                                                            <Collapse isOpen={this.state.openMenu === 2} className="pt-2">

                                                                {CheckAuthorization("profileInteractions:reservations:view") && this.tabItem('Reservations', 'ProfileDetails.Reservations', 'icon-icon-reservas mr-2 ', null)}
                                                                {CheckAuthorization("profileInteractions:revenue:view") && this.tabItem('Revenue', 'ProfileDetails.Revenue', 'icon-icon-revenue mr-2 ', null)}
                                                                {!global.isMobile ?
                                                                    <>
                                                                        {this.tabItem('Journey', 'ProfileDetails.Journey', 'icon-icon-journey mr-2 ', null)}
                                                                        {this.tabItem('Reviews', 'ProfileDetails.Reviews', 'icon-icon-reviews mr-2 ', null)}
                                                                        {this.tabItem('Forms', 'ProfileDetails.Forms', 'fas fa-file-signature mr-2 ', null)}
                                                                        {hasEgoi || hasSendGrid ? this.tabItem('Marketing', 'ProfileDetails.Marketing', 'icon-icon-marketing mr-2 ', null) : ''}
                                                                        {hasHeyCard ? this.tabItem('Vouchers', 'ProfileDetails.Vouchers', 'icon-icon-vouchers mr-2 ', null) : ''}
                                                                        {hasHijiffy ? this.tabItem('Hijiffy', 'ProfileDetails.Hijiffy', 'icon-icon-chat mr-2 ', null) : ''}
                                                                    </>
                                                                :''}

                                                                <Authorization
                                                                    perform="salesProcess:view"
                                                                    yes={() => (
                                                                        hasSalesProcess ?
                                                                            <div>
                                                                                {checkSalesProcessPermission('ProfileDetails:deals', currentSalesProcessUser?.role) ?
                                                                                    this.tabItem('SalesProcess', 'ProfileDetails.Deals', 'fas fa-briefcase mr-2 ', null)
                                                                                : ''}

                                                                                {checkSalesProcessPermission('ProfileDetails:dealActions', currentSalesProcessUser?.role) ?
                                                                                    this.tabItem('SalesProcessInteractions', 'ProfileDetails.SalesProcessInteractions', 'fas fa-clipboard-list mr-2 ', null) 
                                                                                : ''}
                                                                            </div>
                                                                            
                                                                        :''
                                                                    )}
                                                                    no={() => <div></div>}
                                                                />
                                                                
                                                                {!global.isMobile ?
                                                                    <>
                                                                        <Authorization
                                                                            perform="admin:view"
                                                                            yes={() => (
                                                                                hasWhatsApp ?
                                                                                    this.tabItem('Chat', 'ProfileDetails.Chat', 'fas fa-comments mr-2 ', null, false)
                                                                                : ''
                                                                            )}
                                                                            no={() => <div></div>}
                                                                        />
                                                                        <Authorization
                                                                            perform="admin:view"
                                                                            yes={() => (
                                                                                (hasJourneys ?
                                                                                    this.tabItem('CustomerJourney', 'ProfileDetails.CustomerJourney', 'fas fa-project-diagram mr-2', null, true)
                                                                                : '')
                                                                            )}
                                                                            no={() => <div></div>}
                                                                        />
                                                                    </>
                                                                :''}
                                                            </Collapse>
                                                        </Card>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                                : ''}
                                        </Collapse>
                                        :
                                        this.tabItem('Profile', 'ProfileDetails.Profile', 'icon-icon-profile mr-2', null)}
                                </Nav>
                            </Col>
                            <Col className={`${!global.isMobile ? 'col-10 pl-0' : 'col-12'}`}>
                                <TabContent activeTab={this.state.activeTab} className="h-100">
                                    <TabPane tabId="Overview" className="border-0 my-0 p-0 "  >
                                        {this.state.activeTab === 'Overview' ?
                                            <ProfileOverview
                                                profileId={this.state.profileId}
                                                tags={profile && profile.profileTags ? profile.profileTags : null}
                                                warnings={profile && profile.profileWarnings ? profile.profileWarnings : null}
                                                startDate={this.state.startDateFilter}
                                                endDate={this.state.endDateFilter}
                                                handleDates={this.handleDates}
                                                hotels={this.state.hotels}
                                                fixedHotelFilter={this.state.fixedHotelFilter}
                                            />
                                            : ''}
                                    </TabPane>

                                    <TabPane tabId="Profile" className="border-0 my-0 p-0">
                                        {newProfile || Object.keys(profile).length > 0 ?
                                            <ProfilePersonalData
                                                profile={profile}
                                                rules={profileRules}
                                                profileId={this.state.profileId}
                                                newProfile={newProfile}
                                                history={this.props.history}
                                                updateNewProfile={this.updateNewProfile}
                                                updateProfile={this.updateProfile}
                                                getProfile={() => this.getProfile(this.props.location.rules)}
                                                isPortal={profile.isPortal || (profile.isMaster && profile.associateProfiles?.some(profile => profile.isPortal))}
                                                canEditProfile={canEditProfile}
                                            />
                                            : ''}
                                    </TabPane>
                                    
                                    <TabPane tabId="CustomData" className="border-0 my-0 p-0">
                                        <ProfileCustomData
                                            customData={profile && profile.profileCustomData ? [...profile.profileCustomData] : []}
                                            removeElement={this.removeElement}
                                            updateTable={this.updateTable}
                                            profileId={profileId}
                                            canEditProfile={canEditProfile}
                                        />  
                                    </TabPane>

                                    <TabPane tabId="Addresses" className="border-0 my-0 p-0 h-100">
                                        <ProfileAddresses
                                            profile={profile}
                                            removeElement={this.removeElement}
                                            updateTable={this.updateTable}
                                            profileId={profileId}
                                            canEditProfile={canEditProfile}
                                        />  
                                    </TabPane>

                                    <TabPane tabId="Consents" className="border-0  my-0 p-0 h-100">
                                        <ProfileConsents
                                            profile={profile}
                                            profileId={profileId}
                                            updateTable={this.updateTable}
                                            removeElement={this.removeElement}
                                            canEditProfile={canEditProfile}
                                        />
                                    </TabPane>

                                    <TabPane tabId="Contacts" className="border-0  my-0 p-0 h-100">
                                        <ProfileContacts
                                            profile={profile}
                                            profileId={profileId}
                                            contactModal={contactModal}
                                            selectedContact={selectedContact}
                                            socialMediaModal={socialMediaModal}
                                            selectedSocialMedia={selectedSocialMedia}
                                            sendSendGridEmailModal={sendSendGridEmailModal}
                                            sendEmailModal={sendEmailModal}
                                            sendSmsModal={sendSmsModal}
                                            sendTwillioSmsModal={sendTwillioSmsModal}
                                            hasEgoi={hasEgoi}
                                            hasSendGrid={hasSendGrid}
                                            hasTwillio={hasTwillio}
                                            toggleModal={this.toggleModal}
                                            removeElement={this.removeElement}
                                            updateTable={this.updateTable}
                                            nationality={profile.nationality}
                                            addresses={profile.addresses}
                                            canEditProfile={canEditProfile}
                                        />
                                    </TabPane>

                                    <TabPane tabId="Documents" className="border-0  my-0 p-0 h-100">
                                        <ProfileDocuments
                                            profile={profile}
                                            profileId={profileId}
                                            updateTable={this.updateTable}
                                            removeElement={this.removeElement}
                                            canEditProfile={canEditProfile}
                                        />
                                    </TabPane>

                                    <TabPane tabId="Informations" className="border-0  my-0 p-0 h-100">
                                        <ProfileInformations
                                            block={block}
                                            informations={informations}
                                            profileInfo={profile && profile.salesMarketing}
                                            profileId={profile && profile.id}
                                            profileDefault={profile && profile.profileDefault && [...profile.profileDefault]}
                                            groupByHotel={this.groupByHotel}
                                            removeElement={this.removeElement}
                                            updateTable={this.updateTable}
                                            isMaster={profile?.isMaster}
                                            hotels={this.state.hotels || []}
                                            canEditProfile={canEditProfile}
                                        />
                                    </TabPane>

                                    <TabPane tabId="Preferences" className="border-0  my-0 p-0 h-100">
                                        <ProfilePreferences
                                            preferences={preferences}
                                            profileId={profile && profile.id}
                                            removeElement={this.removeElement}
                                            updateTable={this.updateTable}
                                            canEditProfile={canEditProfile}
                                        />
                                    </TabPane>

                                    <TabPane tabId="MembershipCards" className="border-0  my-0 p-0 h-100">
                                        <ProfileMembershipCard
                                            getProfile={this.getProfile}
                                            membershipCards={profile?.membershipCard}
                                            profileType={profile?.type}
                                            profileId={profile && profile.id}
                                            removeFromTable={this.removeFromTable}
                                            updateTable={this.updateTable}
                                            isMaster={profile?.isMaster}
                                            canEditProfile={canEditProfile}
                                        />
                                    </TabPane>

                                    <TabPane tabId="SalesMarketing" className="border-0  my-0 p-0 h-100">
                                        <ProfileSalesMarketing
                                            profile={profile}
                                            removeElement={this.removeElement}
                                            updateTable={this.updateTable}
                                            profileId={profileId}
                                            hotels={this.state.hotels || []}
                                            canEditProfile={canEditProfile}
                                        />
                                    </TabPane>

                                    <TabPane tabId="Credits" className="border-0  my-0 p-0 h-100">
                                        <ProfileCredit
                                            block={block}
                                            profile={profile}
                                            updateTable={this.updateTable}
                                            removeElement={this.removeElement}
                                            groupByHotel={this.groupByHotel}
                                            hotels={this.state.hotels || []}
                                            profileId={profileId}
                                            canEditProfile={canEditProfile}
                                        />
                                    </TabPane>

                                    <TabPane tabId="Warnings" className="border-0  my-0 p-0 h-100">
                                        {this.state.activeTab === 'Warnings' ?
                                            <ProfileWarnings
                                                profile={profile}
                                                removeElement={this.removeElement}
                                                updateTable={this.updateTable}
                                                profileId={profileId}
                                                hotels={this.state.hotels || []}
                                                canEditProfile={canEditProfile}
                                            />
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="Interests" className="border-0  my-0 p-0 h-100">
                                        {
                                            profile && Object.keys(profile).length > 0 && hasProfile360 ?
                                                <ProfileInterests
                                                    profileId={this.state.profileId}
                                                    interests={profile && profile.profileTags ? profile.profileTags : null}
                                                    canEditProfile={canEditProfile}
                                                />
                                                :
                                                ''
                                        }
                                    </TabPane>

                                    <TabPane tabId="AssociatedProfiles" className="border-0  my-0 p-0 h-100">
                                        {this.state.activeTab === 'AssociatedProfiles' && profile && Object.keys(profile).length > 0 ?
                                            <AssociatedProfiles
                                                profileId={this.state.profileId}
                                                associatedProfiles={profile.associateProfiles ? profile.associateProfiles : null}
                                                hotelId={profile.hotelId}
                                                relationsList={relationsList}
                                                updateRelationsList={this.updateRelationsList}
                                                sources={this.state.sources}
                                                history={this.props.history}
                                                fixedHotelFilter={this.state.fixedHotelFilter}
                                                canEditProfile={canEditProfile}
                                            />
                                            : ''}
                                    </TabPane>

                                    <TabPane tabId="Reservations" className="border-0  my-0 p-0 h-100">
                                        {CheckAuthorization("profileInteractions:reservations:view") && this.state.activeTab === 'Reservations' ?
                                            <ProfileReservations
                                                profileId={this.state.profileId}
                                                startDate={this.state.startDateFilter}
                                                endDate={this.state.endDateFilter}
                                                handleDates={this.handleDates}
                                                hotels={this.state.hotels}
                                                profile={profile}
                                                fixedHotelFilter={this.state.fixedHotelFilter}
                                                canEditProfile={canEditProfile}
                                            />
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="Revenue" className="border-0  my-0 p-0 h-100">
                                        {CheckAuthorization("profileInteractions:revenue:view") && this.state.activeTab === 'Revenue' ?
                                            <ProfileRevenue
                                                profileId={this.state.profileId}
                                                startDate={this.state.startDateFilter}
                                                endDate={this.state.endDateFilter}
                                                handleDates={this.handleDates}
                                                hotels={this.state.hotels}
                                                fixedHotelFilter={this.state.fixedHotelFilter}
                                            />
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="Journey" className="border-0  my-0 p-0 h-100">
                                        {this.state.activeTab === 'Journey' ?
                                            <ProfileJourney
                                                profileId={this.state.profileId}
                                            />
                                            : ''}
                                    </TabPane>

                                    <TabPane tabId="Reviews" className="border-0  my-0 p-0 h-100">
                                        {
                                            this.state.activeTab === 'Reviews' ?
                                                <ProfileReview profileId={this.state.profileId} />
                                                :
                                                ''
                                        }
                                    </TabPane>

                                    <TabPane tabId="Hijiffy" className="border-0  my-0 p-0 h-100">
                                        <Card className="ml-1 my-0 mr-0 border-0 bg-light h-100">
                                            <div className="m-2 p-2">
                                                <Row className="mb-2">
                                                    <Col>
                                                        <h5><i className="icon-icon-chat mr-1"></i><FormattedMessage id="ProfileDetails.Hijiffy" /></h5>
                                                    </Col>
                                                </Row>
                                                <iframe style={{ width: '100%', height: '100%', minHeight: '650px', border: 'none', boxShadow: 'none' }}
                                                    src={`https://host.console.hijiffy.com/contact?search=${profile.contacts && profile.contacts.find(c => c.type === 'Email') && profile.contacts.find(c => c.type === 'Email').contact ?
                                                        profile.contacts.find(c => c.type === 'Email').contact
                                                        :
                                                        profile.fullName ?
                                                            profile.fullName
                                                            :
                                                            profile.lastName}`}
                                                    title="Hijiffy"></iframe>
                                            </div>
                                        </Card>
                                    </TabPane>

                                    <TabPane tabId="Vouchers" className="border-0  my-0 p-0 h-100">
                                        {profile && Object.keys(profile).length > 0 && hasHeyCard && this.state.activeTab === 'Vouchers' ?
                                            <ProfileVouchers
                                                profileId={this.state.profileId}
                                                profile={this.state.profile}
                                                history={this.props.history}
                                                canEditProfile={canEditProfile}
                                            />
                                            : ''}
                                    </TabPane>

                                    <Authorization
                                        perform="salesProcess:view"
                                        yes={() => (
                                            <div>
                                                <TabPane tabId="SalesProcess" className="border-0 my-0 p-0 h-100">
                                                    {
                                                        this.state.activeTab === 'SalesProcess' ?
                                                            <ProfileSalesProcess profileId={this.state.profileId} history={this.props.history} currentSalesProcessUser={currentSalesProcessUser} />
                                                            :
                                                            ''
                                                    }
                                                </TabPane>
                                                <TabPane tabId="SalesProcessInteractions" className="border-0 my-0 p-0 h-100">
                                                    {
                                                        this.state.activeTab === 'SalesProcessInteractions' ?
                                                            <ProfileSalesProcessInteractions profileId={this.state.profileId} history={this.props.history} currentSalesProcessUser={currentSalesProcessUser} profileComboOptions={profileComboOptions} />
                                                            :
                                                            ''
                                                    }
                                                </TabPane>
                                            </div>
                                        )}
                                        no={() => <div></div>}
                                    />

                                    <TabPane tabId="Marketing" className="border-0 my-0 p-0 "  >
                                        {
                                            this.state.activeTab === 'Marketing' ?
                                                <ProfileMarketing profileId={this.state.profileId} hasEgoi={hasEgoi} />
                                                :
                                                ''
                                        }
                                    </TabPane>


                                    <TabPane tabId="Forms" className="border-0  my-0 p-0 h-100">
                                        {this.state.activeTab === 'Forms' ?
                                            <ProfileForms
                                                profileId={this.state.profileId}
                                            />
                                            : ''}
                                    </TabPane>

                                    <TabPane tabId="Chat" className="border-0  my-0 p-0 h-100">
                                        {this.state.activeTab === 'Chat' && profile ?
                                            <ProfileChat
                                                profileId={this.state.profileId}
                                                hasWhatsApp={hasWhatsApp}
                                                profile={profile}
                                            />
                                            : ''}
                                    </TabPane>
                                    <TabPane tabId="CustomerJourney" className="border-0  my-0 p-0 h-100">
                                        {this.state.activeTab === 'CustomerJourney' && profile ?
                                            <ProfileJourneys
                                                history={this.props.history}
                                                profileId={this.state.profileId}
                                            />
                                        : ''}
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </StyledCard>
                :''}
            </div>
        );
    }
}
export default injectIntl(ProfileDetails)