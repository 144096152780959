import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Input, Progress, Row } from 'reactstrap';
import CoolDataGrid from '../../Base/CoolDataGrid/CoolDataGrid';
import { ErrorAlert } from '../../Base/ErrorAlert';
import moment from 'moment-timezone';
import SalesDealDetailProposalBulkUpdate from './SalesDealDetailProposalBulkUpdate';
import { CoolTooltip } from '../../Base/CommonUIComponents';

class SalesDealDetailProposalBlockStep2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            startDate: null,
            endDate: null,
            cols: [],
            rows: [],
            catList: [],
            blockDetails: null,
        };
    }

    componentWillReceiveProps(nextProps){
        const { startDate, endDate, blockDetails } = nextProps;

        const diffDetails = () => {
            if(blockDetails && this.state.blockDetails){
                blockDetails.forEach((detail) => {
                    const props = this.state.blockDetails.find(({date, categoryId}) => date === detail.date && categoryId === detail.categoryId);

                    if(!props)
                        return true;

                    Object.keys(detail).forEach((objKey) => {
                        if(props[objKey] !== detail[objKey]){
                            return true;
                        }
                    });
                });
            }

            return blockDetails !== this.state.blockDetails;
        }

        if(startDate !== this.state.startDate || endDate !== this.state.endDate || diffDetails()){
            this.setState({ startDate, endDate, blockDetails: JSON.parse(JSON.stringify(blockDetails))  }, () => this.initialCalc());
        }
    }

    componentDidMount(){
        const { blockDetails, endDate, startDate } = this.props;
        this.setState({ startDate, endDate, blockDetails: JSON.parse(JSON.stringify(blockDetails))  }, () => this.initialCalc());
    }

    initialCalc = () => {
        const { startDate, endDate, blockDetails } = this.state;
        const { intl } = this.props;
        const catMetrics = ["originalRooms", "pickUp", "pickUpTentative", "freeRoomsBlock"]

        const cols = [
            {
                key: 'name',
                isFixed: true,
                name: intl.formatMessage({ id: "SalesProcess.Category" }),
                filterable: true,
                width: '280px'
            }
        ];

        const numberOfDays = moment(endDate).diff(moment(startDate), 'day');
  
        for (let d = 1; d <= numberOfDays; d++) {
            const date = moment(startDate).clone().add((d - 1), 'day');
            
            const isWeekend = (date.day() === 6) || (date.day()  === 0);

            cols.push(
                {
                    key: moment(date).format('YYYY-MM-DD'),
                    name: `${moment(date).format('ddd')} ${moment(date).format('DD-MM')}`,
                    width: 100,
                    isWeekend,
                    isEditable: true,
                    filterable: true
                }
            );
        }
        
        const catList = blockDetails
            .map(({categoryId, categoryDescription}) => (
                {
                    id: categoryId,
                    name: categoryDescription
                }
            ))
            .filter((x, i, a) => a.findIndex(w => w.id === x.id) == i);

        const rows = catList?.map((c, idx) => {
            const catInv = blockDetails.filter(({categoryId}) => categoryId === c.id);

            const metrics = catMetrics.map(metric => ({
                data: {
                    name: <div className="text-muted"><FormattedMessage id={`SalesProcess.${metric}`}/></div>,
                },
                emptyNull: true,
                isSmall: true
            }));
            
            const days = {};

            if(catInv && catInv.length > 0){
                catInv.forEach(inv => {
                    const formatted = moment(inv.date).format('YYYY-MM-DD');
                    const inventory = inv.inventoryRooms;
                    const freeRooms = inventory - inv.freeRoomsGlobal;
                    const percentage = parseInt((freeRooms / inventory) * 100);
                    const color = (0 <= percentage && percentage < 33) ?
                        { color: 'success', hex: '#5cb85c' }
                    : (33 <= percentage && percentage < 66) ?
                        { color: 'warning', hex: '#f0ad4e' }
                    : { color: 'danger', hex: '#d9534f' };

                    days[formatted] = (
                        <>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                padding: '0.25rem',
                            }}>
                                <div>
                                    <Progress
                                        color={color.color}
                                        value={percentage + 1}
                                        style={{
                                            maxHeight: '5px',
                                        }}
                                    />
                                </div>
                                <div>
                                    <Input
                                        id={`availabilityPercentage-${formatted}-${c.id}`}
                                        type="number"
                                        step={1}
                                        style={{ backgroundColor: ((inv.unsavedCurrentRooms || 0) > inv.freeRoomsGlobal) ? '#f1acb2' : '' }}
                                        className='original-rooms-input-create-block'
                                        onChange={e => this.props.handleDetailsChange(e, formatted, c.id)}
                                        value={inv.currentRooms}
                                    />
                                </div>
                            </div>
                            <CoolTooltip trigger="focus" target={`availabilityPercentage-${formatted}-${c.id}`} placement="right">
                                {inventory > 0 ?
                                    <>
                                        <i className={`far fa-square mr-2`} style={{ background: color.hex }} /> {percentage}%
                                    </>
                                :''}
                                <div className="d-flex align-items-center"> <i className="fas fa-hashtag fa-xs mr-2 pr-1 ml-1" /> {freeRooms}/{inventory} </div>
                            </CoolTooltip>
                        </>
                    );
                    
                    metrics.forEach((metric, key) => {
                        metric.data[formatted] = inv[catMetrics[key]];
                    });
                });
            }

            return ({
                data: {
                    name: c.name,
                    ...days
                },
                children: metrics,
                emptyNull: true,
                isOpened: this.state.rows && this.state.rows[idx] && this.state.rows[idx].isOpened
            })
        })??[];
        
        this.setState({ cols, rows, catList });
    }

    toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    }
    
    render() {
        const { block, error, startDate, endDate, cols, rows, modal, catList } = this.state;
        const { intl, handleDetailsChangeBulk, id } = this.props;
        
        return (
            <div className='mt-4'>
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    {startDate && endDate && cols?.length && id ?
                        <div>
                            <Row className='mt-2'>
                                <Col className="col-4"></Col>
                                <Col className="col-4 text-center">
                                    <b style={{ fontSize: '1.1em' }}>
                                        <span>{moment(startDate).format('DD MMMM')}</span>
                                        <span className='mx-1'>-</span>
                                        <span>{moment(endDate).format('DD MMMM')}</span>
                                    </b>
                                </Col>
                                <Col className="col-4 d-flex align-items-center justify-content-end">
                                    <>
                                        <Button id="bulkUpdate" title={intl.formatMessage({ id: "SalesProcess.BulkUpdate" })} className="btn btn-host btn-sm" onClick={this.toggleModal}>
                                            <i className="fas fa-tasks"/>
                                        </Button>
                                        {modal ?
                                            <SalesDealDetailProposalBulkUpdate
                                                handleDetailsChangeBulk={handleDetailsChangeBulk}
                                                toggleModal={this.toggleModal}
                                                modal={modal}
                                                fromDate={startDate}
                                                toDate={endDate}
                                                catList={catList}
                                            />
                                        :''}
                                    </>
                                </Col>
                            </Row>
                            <div className='mt-4'>
                                <CoolDataGrid
                                    cols={cols}
                                    rows={rows}
                                    newHotelRowStyle={false}
                                    tableName={"BlockRoomAvail"}
                                />
                            </div>
                        </div>
                    :
                        <div></div>
                    }
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesDealDetailProposalBlockStep2);