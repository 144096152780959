import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Card, Col, Form, Input, Modal, ModalBody, Nav, NavItem, NavLink, Row } from 'reactstrap'
import { TabContent } from 'reactstrap';
import { TabPane } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { CheckAuthorization } from '../../Base/Authorization';

class NewEmailModal extends Component {

    constructor (props) {
        super(props);
        this.state = {
            block: true,
            activeTab: '0',
            yourTemplates: [],
            inspTemplates: []
        };
        this.form = React.createRef();
    }

    componentDidMount(){
        this.getTemplates();
    }
    
    getTemplates = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if(!(data.errors && data.errors.length > 0) && data.response && data.response.length > 0){
                    const inspTemplates = data.response.filter(({templateGroupId}) => templateGroupId);
                    const yourTemplates = data.response.filter(({templateGroupId}) => !templateGroupId);

                    this.setState({ inspTemplates, yourTemplates, block: false });
                }
            }
            this.setState({ block: false });
        }, `/api/gms/Template/v1/templates/master?type=Template&pageSize=9999&pageIndex=0&anyGroup=true`);
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    validateForm = (e, copyId) => {
        if (!this.form.current.reportValidity()) {
            return;
        }
        this.props.newTemplate(e, copyId);
    }
    
    render() {
        const { toggleModal, newTemplateName, handleNewTemplateName, folders, isNewEmail, copyTemplateId } = this.props;
        const { block, activeTab, yourTemplates, inspTemplates } = this.state;

        return (
            <Modal isOpen={true} className={isNewEmail && !copyTemplateId ? "" : 'noSize'}>
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className='p-4'>
                    <form ref={this.form} onSubmit={e => e.preventDefault()}>
                        <Row>
                            <Col>
                                <b><FormattedMessage id="stripo.name" /></b>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            <Col className='text-center'>
                                <Input
                                    type='text'
                                    value={newTemplateName}
                                    required
                                    autoFocus
                                    onChange={handleNewTemplateName}
                                />
                            </Col>
                        </Row>
                        {isNewEmail && !copyTemplateId ?
                            <Row className='mt-2'>
                                <Col>
                                    <Nav tabs className="border-0">
                                        <NavItem>
                                            <NavLink className={activeTab === '0' ? 'tab-border-host text-host border-0' : 'border-0'} onClick={() => this.toggleTab('0')} style={{ cursor: "pointer" }}>
                                                <b><FormattedMessage id="stripo.Yours"/></b>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('1')} style={{ cursor: "pointer" }}>
                                                <b><FormattedMessage id="stripo.Inspirational" /></b>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <BlockUi blocking={block}>
                                        <TabContent activeTab={activeTab} style={{ minHeight: "200px" }}>
                                            <TabPane className='border-0' tabId="0">
                                                <Row className='mt-2 pl-2'>
                                                    <Col className='col-2'>
                                                        <Card onClick={e => this.validateForm(e)} className='shadow border-0 p-2 cursor-pointer' style={{ flexDirection: "column", justifyContent: "space-between" }}>
                                                            <Row>
                                                                <Col style={{ height: "200px", overflow: "hidden" }}>
                                                                    <div className='d-flex' style={{ gap: '0.8rem', flexDirection: 'column' }}>
                                                                        <div className='d-flex align-items-center' style={{ gap: '0.8rem', height: '40px' }}>
                                                                            <div style={{ width: '40%', background: '#edf3fb', height: '100%' }}></div>
                                                                            <div style={{ width: '60%', background: '#edf3fb', height: '100%' }}></div>
                                                                        </div>
                                                                        <div className='d-flex align-items-center' style={{ gap: '0.8rem', height: '40px' }}>
                                                                            <div style={{ width: '100%', background: '#edf3fb', height: '100%' }}></div>
                                                                        </div>
                                                                        <div className='d-flex align-items-center' style={{ gap: '0.8rem', height: '40px' }}>
                                                                            <div style={{ width: '50%', background: '#edf3fb', height: '100%' }}></div>
                                                                            <div style={{ width: '50%', background: '#edf3fb', height: '100%' }}></div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2 py-1'>
                                                                <Col>
                                                                    <FormattedMessage id="stripo.Empty"/>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    {yourTemplates.map((temp, key) =>
                                                        <Col className='col-2' key={key}>
                                                            <Card onClick={e => this.validateForm(e, temp.id)} className='shadow border-0 p-2 cursor-pointer' style={{ flexDirection: "column", justifyContent: "space-between" }}>
                                                                <Row>
                                                                    <Col style={{ height: "200px", overflow: "hidden" }}>
                                                                        <img alt={temp.name} style={{ width: '100%' }} src={`data:image/png;base64, ${temp.templateDefaultPreview}`}/>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mt-2 py-1'>
                                                                    <Col>{temp.name}</Col>
                                                                </Row>
                                                            </Card>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </TabPane>
                                            <TabPane className='border-0' tabId="1">
                                                {folders.filter(({id}) => inspTemplates.find(({templateGroupId}) => templateGroupId === id)).map((folder, key) =>
                                                    <Row className='mb-2 pl-2'>
                                                        <Col key={key}>
                                                            <Row>
                                                                <Col>
                                                                    <b>{folder.name}</b>
                                                                </Col>
                                                            </Row>
                                                            <Row className='veryCoolScrollBar py-3' style={{ display: "flex", flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'start', overflowX: 'auto' }}>
                                                                {inspTemplates.filter(({templateGroupId}) => templateGroupId === folder.id).map((temp, key) =>
                                                                    <Col className='col-2' key={key}>
                                                                        <Card onClick={e => this.validateForm(e, temp.id)} className='shadow border-0 p-2 cursor-pointer' style={{ flexDirection: "column", justifyContent: "space-between" }}>
                                                                            <Row>
                                                                                <Col style={{ height: "200px", overflow: "hidden" }}>
                                                                                    <img alt={temp.name} style={{ width: '100%' }} src={`data:image/png;base64, ${temp.templateDefaultPreview}`}/>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mt-2 py-1'>
                                                                                <Col>{temp.name}</Col>
                                                                            </Row>
                                                                        </Card>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </TabPane>
                                        </TabContent>
                                    </BlockUi>
                                </Col>
                            </Row>
                        :
                            <Row className='mt-2'>
                                <Col className='text-center'>
                                    <Button type="button" onClick={e => this.validateForm(e, copyTemplateId)} className="btn btn-sm btn-host">
                                        <FormattedMessage id="stripo.create" />
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </form>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(NewEmailModal);