import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Authorization from "./Base/Authorization";
import NoAccessPage from './NoAccessPage';
import { Route } from 'react-router-dom';
import { SearchButton } from './SearchButton';
import ErrorBoundary from '../Utils/ErrorBoundary';
import GoogleAnalytics from '../Utils/GoogleAnalytics';
import { BrowserVersionNotSupported, isBrowserVersionSupported } from './BrowserVersion';
import { getAPI } from './Base/API';
import BlockUi from 'react-block-ui';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            isBrowserVersionSupported: true,
            block: true
        };
    }

    componentDidMount() {
        this.setState({ isBrowserVersionSupported: isBrowserVersionSupported() })

        this.getUserData();
    }

    getUserData = () => {
        getAPI((result) => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message,stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                global.modules = data.modules;
                global.hotelCountryCode = data.hotelCountryCode;
                global.hotelTimezone = data.hotelTimezone;
                global.hotelId = data.defaultProperty;
                global.toMasterProperties = data.toMasterProfile;
                global.showComplexes = data.showComplexes;
                global.hotelCurrency = data.hotelCurrency || 'EUR';
                global.hotelGroupId = data.hotelGroupId;
                global.hotelName = data.hotelName;
                global.userId = data.id;
                global.populateCoordinates = data.populateCoordinates;
                global.userAllowedProperties = data.userAllowedProperties;

                this.setState({ user: data, property: data.defaultProperty }, this.getSalesProcessUsers);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/user`);
    }

    getSalesProcessUsers = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUsers: false });

                return;
            }
            if (data) {
                global.salesProcessUsers = data.data?.map(u => ({
                    value: u.id,
                    label: `${u.firstName} ${u.lastName}`,
                    isCurrentUser: u.userId === global.userId,
                    hotelList: u.hotelIds,
                    ...u
                }))
            }
        }, '/api/gms/SalesProcess/v1/salesprocessusers?pageSize=1000&pageIndex=0');
    }


    render () {
        return (
            this.state.isBrowserVersionSupported ?
                <ErrorBoundary>
                    <BlockUi tag="div" blocking={this.state.block}>
                        <GoogleAnalytics />

                        <div className="bg-light">
                            {this.state.user ?
                                <Authorization
                                    perform="GmsClient"
                                    yes={() => (
                                        <>
                                            <NavMenu
                                                user={this.state.user}
                                                property={this.state.property}
                                            />
                                            <Container className="bg-light h-100 container-fluid">
                                                {this.props.children}

                                                <SearchButton />
                                            </Container>
                                        </>
                                    )}
                                    no={() => (
                                        <Route path='/' component={NoAccessPage} />
                                    )}
                                />
                            : ''}
                        </div>
                    </BlockUi>
                </ErrorBoundary>
                :
                <BrowserVersionNotSupported />
        );
    }
}
