import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Collapse, CustomInput, Input, Row } from 'reactstrap';
import CoolSelectProfile from '../../Base/CoolSelectProfile';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import CustomSelect from '../../Base/CustomSelect';
import moment from 'moment-timezone';
import { getAPI } from '../../Base/API';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { SalesProcessHotelCombo } from '../SalesProcessFunctions';

class SalesDealDetailProposalBlockStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            priceListBlock: true,
            segmentsBlock: true,
            subSegmentsBlock: true,
            distributionChannelsBlock: true,
            packageBlock: true,
            customStatusBlock: true,
            error: null,
            priceListOptions: [],
            priceListOptionsUnfiltered: [],
            packageOptions: [],
            packageOptionsUnfiltered: [],
            segmentOptions: [],
            segmentOptionsUnfiltered: [],
            subSegmentOptions: [],
            subSegmentOptionsUnfiltered: [],
            distChannelOptions: [],
            distChannelOptionsUnfiltered: [],
            defaultReservationCustomStatusOptions: [],
            defaultReservationCustomStatusOptionsUnfiltered: [],
            collapse: this.props.blockData?.id ? false : true,
            hotelOptions: this.props.dealHotelList?.map((id) => global.hotelList.find(({value}) => value === id))
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.blockData?.id !== this.props.blockData?.id){
            this.setState({ collapse: nextProps.blockData?.id ? false : true });
        }
        if(nextProps.selectedHotelId !== this.props.selectedHotelId){
            this.reloadRefData(nextProps.selectedHotelId)
        }
    }
    
    componentDidMount(){
        this.getRefData();
    }

    getRefData = () => {
        this.getReferenceDataPL();
        this.getReferenceDataSegments();
        this.getReferenceDataSubSegments();
        this.getReferenceDataDistributionChannels();
        this.getReferenceDataPackage();
        this.getReferenceDataCustomStatus();
    }

    refDataTreatment = (data) => {
        const { selectedHotelId } = this.props;
        const returnValue = {
            filtered: data
                ?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId)
                .map(({description, code, hotelId}) => ({
                    value: code,
                    hotelId,
                    label: (
                        <span>
                            {description}
                            <b className="ml-1">({code})</b>
                        </span>
                    )
                })),
            brute: data?.map(({description, code, hotelId}) => ({
                value: code,
                hotelId,
                label: (
                    <span>
                        {description}
                        <b className="ml-1">({code})</b>
                    </span>
                )
            })),
        }
        return returnValue;
    }

    reloadRefData = (selectedHotelId) => {
        const { priceListOptionsUnfiltered, packageOptionsUnfiltered, segmentOptionsUnfiltered, subSegmentOptionsUnfiltered,
        distChannelOptionsUnfiltered, defaultReservationCustomStatusOptionsUnfiltered } = this.state;

        const priceListOptions = priceListOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId);
        const packageOptions = packageOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId);
        const segmentOptions = segmentOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId);
        const subSegmentOptions = subSegmentOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId);
        const distChannelOptions = distChannelOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId);
        const defaultReservationCustomStatusOptions = defaultReservationCustomStatusOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId);

        this.setState({ priceListOptions, packageOptions, segmentOptions, subSegmentOptions, distChannelOptions, defaultReservationCustomStatusOptions });
    }

    getReferenceDataPL = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, priceListBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    priceListOptions: filtered,
                    priceListOptionsUnfiltered: brute,
                    priceListBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, priceListBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=PriceList`)
    }

    getReferenceDataSegments = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, segmentsBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    segmentOptions: filtered,
                    segmentOptionsUnfiltered: brute,
                    segmentsBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, segmentsBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=Segments`)
    }
    
    getReferenceDataSubSegments = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, subSegmentsBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    subSegmentOptions: filtered,
                    subSegmentOptionsUnfiltered: brute,
                    subSegmentsBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, subSegmentsBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=SubSegments`)
    }
    
    getReferenceDataDistributionChannels = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, distributionChannelsBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    distChannelOptions: filtered,
                    distChannelOptionsUnfiltered: brute,
                    distributionChannelsBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, distributionChannelsBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=DistributionChannels`)
    }
    
    getReferenceDataPackage = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, packageBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    packageOptions: filtered,
                    packageOptionsUnfiltered: brute,
                    packageBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, packageBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=Package`)
    }
    
    getReferenceDataCustomStatus = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, customStatusBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    defaultReservationCustomStatusOptions: filtered,
                    defaultReservationCustomStatusOptionsUnfiltered: brute,
                    customStatusBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, customStatusBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=ReservationStatus`)
    }

    toggleCollapse = () => {
        this.setState({ collapse: !this.state.collapse });
    }

    render() {
        const { priceListOptions, priceListBlock, packageOptions, packageBlock, defaultReservationCustomStatusOptions, customStatusBlock, segmentOptions, segmentsBlock,
        subSegmentOptions, subSegmentsBlock, distChannelOptions, distributionChannelsBlock, error, collapse, hotelOptions } = this.state;
        const { intl, blockData, handleChange, associateProfile, handleDateChange, handleDatesChange, handleCombo, handleCheckBoxChange, handleHotelChange, selectedHotelId,
        fromDate, toDate, handleChangeFocus, focused, entityOptions } = this.props;
        
        return (
            <>
                <ErrorAlert error={error}/>
                <Row>
                    <Col className="col-3">
                        <div className='title-sm required'>
                            <FormattedMessage id="SalesProcess.Hotel"/>
                        </div>
                        <SalesProcessHotelCombo
                            required={true}
                            isDisabled={selectedHotelId && blockData?.id}
                            value={selectedHotelId}
                            isSearchable={true}
                            onChangeFunc={handleHotelChange}
                            placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                        />
                    </Col>
                    <Col className="col-3 d-flex align-items-center justify-content-between" style={{ gap: '7%' }}>
                        <div style={{ width: '50%' }}>
                            <div className='title-sm'>
                                <FormattedMessage id="SalesProcess.BlockPrefix"/>
                            </div>
                            <Input
                                type='text'
                                name="blockPrefix"
                                onChange={handleChange}
                                value={blockData?.blockPrefix??''}
                                placeholder={intl.formatMessage({ id: "SalesProcess.BlockPrefix" })}
                            />
                        </div>
                        <div style={{ width: '50%' }}>
                            <div className='title-sm required'>
                                <FormattedMessage id="SalesProcess.BlockCode"/>
                            </div>
                            <Input
                                type='text'
                                name="code"
                                onChange={handleChange}
                                value={blockData?.code??''}
                                required={true}
                                placeholder={intl.formatMessage({ id: "SalesProcess.BlockCode" })}
                            />
                        </div>
                    </Col>
                    <Col className="col-3">
                        <div className='title-sm'>
                            <FormattedMessage id="SalesProcess.Entity"/>
                        </div>
                        <CustomSelect
                            options={entityOptions}
                            required={false}
                            value={entityOptions?.find(({ value }) => value === blockData?.entityMasterId)}
                            isSearchable={true}
                            onChange={e => handleCombo(e, 'entityMasterId')}
                            placeholder={intl.formatMessage({ id: "SalesProcess.Entity" })}
                        />
                    </Col>
                    <Col className="col-3">
                        <div className='title-sm required'>
                            <FormattedMessage id="SalesProcess.Dates"/>
                        </div>
                        <DateRangePicker
                            startDate={fromDate ? moment(fromDate) : null}
                            startDateId="fromDateId"
                            endDate={toDate ? moment(toDate) : null}
                            endDateId="toDateId"
                            isOutsideRange={_ => false}
                            onDatesChange={handleDatesChange}
                            focusedInput={focused}
                            onFocusChange={handleChangeFocus}
                            small={true}
                            showDefaultInputIcon={true}
                            required={true}
                            renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                </Row>
                <Collapse isOpen={collapse}>
                    <Row>
                        <Col className="col-3 mt-3">
                            <div className='title-sm required'>
                                <FormattedMessage id="SalesProcess.PriceList"/>
                            </div>
                            <CustomSelect
                                options={priceListOptions}
                                required={true}
                                isLoading={priceListBlock}
                                value={priceListOptions?.find(({ value }) => value === blockData?.priceListCode)}
                                isSearchable={true}
                                onChange={e => handleCombo(e, 'priceListCode')}
                                placeholder={intl.formatMessage({ id: "SalesProcess.PriceList" })}
                                isDisabled={!selectedHotelId}
                            />
                        </Col>
                        <Col className="col-3 mt-3">
                            <div className='title-sm required'>
                                <FormattedMessage id="SalesProcess.Package"/>
                            </div>
                            <CustomSelect
                                options={packageOptions}
                                required={true}
                                isLoading={packageBlock}
                                value={packageOptions?.find(({ value }) => value === blockData?.packageCode)}
                                isSearchable={true}
                                onChange={e => handleCombo(e, 'packageCode')}
                                placeholder={intl.formatMessage({ id: "SalesProcess.Package" })}
                                isDisabled={!selectedHotelId}
                            />
                        </Col>
                        <Col className="col-3 mt-3">
                            <div className='title-sm'>
                                <FormattedMessage id="SalesProcess.DefaultReservationCustomStatus"/>
                            </div>
                            <CustomSelect
                                options={defaultReservationCustomStatusOptions}
                                isLoading={customStatusBlock}
                                value={defaultReservationCustomStatusOptions?.find(({ value }) => value === blockData?.defaultReservationCustomStatusCode)}
                                isSearchable={true}
                                onChange={e => handleCombo(e, 'defaultReservationCustomStatusCode')}
                                placeholder={intl.formatMessage({ id: "SalesProcess.DefaultReservationCustomStatus" })}
                                isDisabled={!selectedHotelId}
                            />
                        </Col>
                        <Col className='col-3 mt-3 d-flex align-items-center justify-content-between'>
                            <div style={{ width: '50%' }}>
                                <div className='title-sm'>
                                    <FormattedMessage id="SalesProcess.IsElastic"/>
                                </div>
                                <div style={{ height: '38px' }}>
                                    <CustomInput
                                        type="checkbox"
                                        id={"IsElastic"}
                                        className="text-ellipsis mr-4"
                                        checked={blockData?.isElastic}
                                        onChange={_ => handleCheckBoxChange("isElastic")}
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <div className='title-sm'>
                                    <FormattedMessage id="SalesProcess.IsGuaranteed"/>
                                </div>
                                <div style={{ height: '38px' }}>
                                    <CustomInput
                                        type="checkbox"
                                        id={"IsGuaranteed"}
                                        className="text-ellipsis"
                                        checked={blockData?.isGuaranteed}
                                        onChange={_ => handleCheckBoxChange("isGuaranteed")}
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </Col>

                        <Col className="col-3 mt-3">
                            <div className='title-sm required'>
                                <FormattedMessage id="SalesProcess.SegmentCode"/>
                            </div>
                            <CustomSelect
                                options={segmentOptions}
                                required={true}
                                isLoading={segmentsBlock}
                                value={segmentOptions?.find(({ value }) => value === blockData?.segmentCode)}
                                isSearchable={true}
                                onChange={e => handleCombo(e, 'segmentCode')}
                                placeholder={intl.formatMessage({ id: "SalesProcess.SegmentCode" })}
                                isDisabled={!selectedHotelId}
                            />
                        </Col>
                        <Col className="col-3 mt-3">
                            <div className='title-sm required'>
                                <FormattedMessage id="SalesProcess.SubSegmentCode"/>
                            </div>
                            <CustomSelect
                                options={subSegmentOptions}
                                required={true}
                                isLoading={subSegmentsBlock}
                                value={subSegmentOptions?.find(({ value }) => value === blockData?.subSegmentCode)}
                                isSearchable={true}
                                onChange={e => handleCombo(e, 'subSegmentCode')}
                                placeholder={intl.formatMessage({ id: "SalesProcess.SubSegmentCode" })}
                                isDisabled={!selectedHotelId}
                            />
                        </Col>
                        <Col className="col-3 mt-3">
                            <div className='title-sm required'>
                                <FormattedMessage id="SalesProcess.DistChannelCode"/>
                            </div>
                            <CustomSelect
                                options={distChannelOptions}
                                required={true}
                                isLoading={distributionChannelsBlock}
                                value={distChannelOptions?.find(({ value }) => value === blockData?.distChannelCode)}
                                isSearchable={true}
                                onChange={e => handleCombo(e, 'distChannelCode')}
                                placeholder={intl.formatMessage({ id: "SalesProcess.DistChannelCode" })}
                                isDisabled={!selectedHotelId}
                            />
                        </Col>
                        <Col className="col-3 mt-3">
                            <div className='title-sm'>
                                <FormattedMessage id="SalesProcess.OptionDate"/>
                            </div>
                            <SingleDatePicker
                                id="OptionDate"
                                isOutsideRange={day => false}
                                date={blockData?.optionDate}
                                focused={this.state.focused}
                                onFocusChange={({ focused }) => this.setState({ focused })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                onDateChange={handleDateChange}
                                showClearDate={true}
                            />
                        </Col>

                        <Col className="col-12 mt-3">
                            <div className='title-sm required'>
                                <FormattedMessage id="SalesProcess.Description"/>
                            </div>
                            <Input
                                type='textarea'
                                name="description"
                                style={{ minHeight: '150px' }}
                                required={true}
                                onChange={handleChange}
                                value={blockData?.description??''}
                                placeholder={intl.formatMessage({ id: "SalesProcess.Description" })}
                            />
                        </Col>
                    </Row>
                </Collapse>
                {blockData?.id ?
                    <div
                        style={{
                            minHeight: '1px',
                            background: '#dfdfdf',
                            marginTop: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                        }}
                        onClick={this.toggleCollapse}
                    >
                        <div
                            style={{
                                display: 'flex',
                                background: '#dfdfdf',
                                position: 'absolute',
                                top: '1px',
                                padding: '0rem 1rem',
                                borderRadius: '0 0 10px 10px',
                                fontSize: '0.6em',
                                height: '9px',
                                cursor: 'pointer',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {collapse ?
                                <i className="fas fa-chevron-up"/>
                            :
                                <i className="fas fa-chevron-down"/>
                            }
                        </div>
                    </div>
                :''}
            </>
        );
    }
}

export default injectIntl(SalesDealDetailProposalBlockStep1);
