import React, { Component } from 'react';
import { Form, Button, Col, Row, Card } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../Base/Notification';
import { ErrorAlert } from '../Base/ErrorAlert';
import { DefaultPage, FormatAmountNumber, SelectHotel } from '../Base/CommonUIComponents';
import { DateRangePicker } from 'react-dates';
import { getAPI, postAPI } from '../Base/API';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import CoolDataGrid from '../Base/CoolDataGrid/CoolDataGrid';
import CustomTwoMonthDateRangePicker from '../Base/CustomTwoMonthDateRangePicker';
import { getSalesGroupOptions } from '../Base/ReferenceDataFunctions';


class SalesBudget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
            hotelId: null
        }
    }


    componentDidMount() {
        this.getBudget()
    }
    
    getBudget = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () => {
            const { startDate, endDate, hotelId } = this.state;

            let qs = `?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`;
            if (hotelId) qs += `&hotelId=${hotelId}`;

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.initialCalc(startDate, endDate, data.response);
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/budget${qs}`);
        });
    }

    syncBudget = (e, hotelId) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            const { startDate, endDate } = this.state;

            let qs = `?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}&hotelId=${hotelId}`;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.response?.length > 0) {
                        this.getBudget();
                        return;
                    }

                    this.setState({ error: errorMessage, block: false })
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/budget${qs}`);
        });
    }

    initialCalc = (startDate, endDate, data) => {
        const year = startDate.year();
        const yearOptions = [(year - 1), year, (year + 1), (year + 2)];
        const salesGroupList = getSalesGroupOptions();


        if (data?.length > 0) {
            const { intl } = this.props;
            const numberOfDays = moment(endDate).diff(moment(startDate), 'day') + 1;

            const columns = [
                {
                    key: 'name',
                    isFixed: true,
                    name: "Sales Group / Dates",//intl.formatMessage({ id: "SalesProcess.Name" }),
                    filterable: true,
                    width: 220
                }
            ];

            for (let d = 1; d <= numberOfDays; d++) {
                const date = startDate.clone().add((d - 1), 'day');

                const isWeekend = (date.day() === 0) || (date.day() === 6);

                columns.push(
                    {
                        key: moment(date).format('YYYY-MM-DD'),
                        name: `${moment(date).format('ddd')} ${moment(date).format('DD-MM')}`,
                        width: 120,
                        isWeekend,
                        filterable: true,
                        isToday: moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
                    }
                );
            }

            const valuesRow = (quantity, roomNights, value, additionalClass) => {
                if (quantity !== null && roomNights !== null && value !== null) {
                    return (
                        <div className={"align-items-center justify-content-between w-100 " + additionalClass}>
                            <div>
                                <div >
                                    {roomNights && roomNights > 0 ?
                                        <span><b>#</b> {roomNights}</span>
                                        : ''}
                                </div>

                                {/*<div >
                                    <i className="fas fa-hashtag mr-1 text-muted" />{quantity}
                                </div>*/}
                            </div>
                            <div >
                                <FormatAmountNumber value={value} currency="EUR" />
                            </div>
                        </div>
                    )
                }
            }

            const renderHotelCell = (hotelName, hotelId) => {
                return (
                    <div className="w-100 d-flex align-items-center justify-content-between text-truncate">
                        <span className="text-truncate" title={hotelName ?? hotelId}>{hotelName ?? hotelId}</span>
                        <span>
                                <i className="fas fa-sync color-host pointer mx-1"  onClick={(e) => this.syncBudget(e, hotelId)}/>
                        </span>
                    </div>
                )
            }

            const rows = data[0].hotels.map(hotel => {
                const hotelRows = columns.slice(1).reduce((acc, day) => {
                    let quantity = null, roomNights = null, value = null;

                    const dayValues = hotel.salesGroups.reduce((acc, cur) => {
                        const validDays = cur.days.filter(gd => moment(gd.date).format("YYYY-MM-DD") === day.key);

                        if (validDays?.length > 0) {
                            acc = acc.concat(validDays);
                        }

                        return acc;
                    }, []);

                    
                    if (dayValues?.length > 0) {
                        quantity = 0; roomNights = 0; value = 0;

                        dayValues.forEach(el => {
                            quantity += el.quantity ?? 0;
                            roomNights += el.roomNights ?? 0;
                            value += el.value ?? 0;
                        })
                    }

                    acc[day.key] = valuesRow(quantity, roomNights, value, " px-2 ");

                    return acc;
                }, {});

                return (
                    {
                        data: {
                            name: renderHotelCell(global.hotelList?.find(({ value }) => value === hotel.hotelId)?.label, hotel.hotelId),
                            hotelId: hotel.hotelId,
                            ...hotelRows,
                        },
                        rowHeight: '60px',
                        isOpened: true,
                        emptyNull: true,
                        children: hotel.salesGroups.map(group => {
                            const dailyValues = group.days.reduce((acc, day) => {
                                const formatedDate = moment(day.date).format("YYYY-MM-DD");

                                acc[formatedDate] = valuesRow(day.quantity, day.roomNights, day.value);

                                return acc;
                            }, {});

                            return {
                                data: {
                                    name: salesGroupList.find(el => el.value === group.salesGroupCode)?.label ?? group.salesGroupCode,
                                    ...dailyValues
                                },
                                rowHeight: '60px',
                            }
                        })
                    }
                );
            })

            if (this.props.hotelList.length !== rows.length) {
                this.props.hotelList.forEach(h => {
                    if (!rows.some(el => el.data.hotelId === h.value)) {
                        rows.push({
                            data: {
                                name: renderHotelCell(h.label, h.value),
                                hotelId: h.value
                            },
                            rowHeight: '60px',
                        })
                    }
                })
            }

            this.setState({ columns, rows, block: false, yearOptions });
        }
        else {
            this.setState({ columns: [], rows: [], block: false, yearOptions });
        }
    }

    handleSelect = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null });
    }
    
    toggleChangeCurrentDate = () => {
        this.setState({ changeCurrentDateOpened: !this.state.changeCurrentDateOpened });
    }

    loadMonths = (monthKey) => {
        const { startDate } = this.state;
        const newStartDate = startDate.clone().add('month', monthKey);
        const newEndDate = newStartDate.clone().endOf('month');
        this.setState({ startDate: newStartDate, endDate: newEndDate, block: true }, () => this.getBudget());
    }

    handleDates = (startDate, endDate) => {
        const getData = startDate && endDate && this.state.focusedInput === "endDate";
        this.setState({ startDate, endDate, block: getData }, () => {
            if (getData) {
                this.getBudget();
            }
        });
    }
    handleCurrentDate = (name, value, focused) => {
        this.setState({ focusedInput: null }, () => {
            let { startDate, endDate } = this.props;

            if (focused === "startDate") {
                startDate = startDate ? moment(startDate).set(name, value) : moment().set(name, value);
                endDate = moment(startDate).isAfter(moment(endDate)) ? moment(startDate).add(1, "month") : endDate;
            }
            else {
                endDate = endDate ? moment(endDate).set(name, value) : moment().set(name, value);
                startDate = moment(endDate).isBefore(moment(startDate)) ? moment(endDate).subtract(1, "month") : startDate;
            }
             
           this.handleDates(startDate, endDate);

            this.setState({ focusedInput: focused, firstMonthRender: moment(startDate), endMonthRender: moment(endDate) });
        })

    }
    

    render() {
        const { startDate, endDate, hotelId, block, error, columns, rows, changeCurrentDateOpened, yearOptions } = this.state;
        const { intl, icon, currentUser } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <h4 className='m-0'>
                            <i className={`${icon} mr-2`} />
                            <FormattedMessage id="SalesProcess.SalesDealBudget" />
                        </h4>
                    </div>

                    <Card className="border-0 p-3 shadow mt-4" id="SalesProcessGrid">
                        <Form onSubmit={this.getBudget}>
                            <Row className="mb-4 align-items-center">
                                <Col className="col-4" >
                                    <SelectHotel
                                        icon="icon-icon-hotel"
                                        isSearchable
                                        onChangeFunc={this.handleSelect}
                                        name="hotelId"
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                        value={hotelId || ''}
                                        params='?onlyFromHotelGroup=true'
                                        hotelIdsToFilter={currentUser?.hotelIds}
                                    />
                                </Col>
                                <Col className='col-4 d-flex align-items-center justify-content-center'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div>
                                            <div className='cursor-pointer' onClick={_ => this.loadMonths(-1)}>
                                                <b><i className="fas fa-chevron-left" /></b>
                                            </div>
                                        </div>
                                        <div>
                                            <CustomTwoMonthDateRangePicker
                                                startDate={startDate}
                                                endDate={endDate}
                                                handleDates={this.handleDates}
                                            />
                                        </div>

                                        <div>
                                            <div className='cursor-pointer' onClick={_ => this.loadMonths(1)}>
                                                <b><i className="fas fa-chevron-right" /></b>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                                </Row>
                        </Form>
                        {columns?.length ?
                            <CoolDataGrid
                                //onScroll={onScroll}
                                rows={rows}
                                cols={columns}
                                tableName="BudgetGrid"
                                //loadMonths={loadMonths}
                            />
                            :
                            <DefaultPage height="40vh" icon="fas fa-wallet" text={"SalesProcess.NoData"} />}
                    </Card>
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesBudget);