import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import SalesDealDetailProposalBlockStep1 from './SalesDealDetailProposalBlockStep1';
import SalesDealDetailProposalBlockStep2 from './SalesDealDetailProposalBlockStep2';
import moment from 'moment-timezone';
import BlockUi from 'react-block-ui';
import ConfirmActionModal from '../../Base/ConfirmActionModal';

class SalesDealDetailProposalBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            blockData: {},
            blockDetails: [],
            originalBlockDetails: [],
            selectedHotelId: this.props.selectedHotelId ?? ((this.props.dealHotelList && this.props.dealHotelList.length === 1) ? this.props.dealHotelList[0] : null),
            toDate: null,
            fromDate: null,
            focused: null,
            confirmActionModal: false,
            entityOptions: [],
        };
    }

    componentDidMount(){
        const { selectedProposal } = this.props;
        const entityOptions = this.props.getDealEntities();

        this.setState({ entityOptions }, () =>{
            if (selectedProposal && selectedProposal.externalId)
                this.getBlockInfo();
        });
    }

    getBlockInfo = () => {
        const { selectedHotelId } = this.state;
        const { selectedProposal } = this.props;

        this.setState({ block: true }, () => {

            getAPI(result => {
                const { data, error } = result;

                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    this.setState({ block: false, blockData: data.response && data.response[0]}, () => this.blockDataTreatment());
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SalesProcess/v1/blockInfo?hotelId=${selectedHotelId}&blockId=${selectedProposal.externalId}`);
        });
    }

    blockDataTreatment = () => {
        if (this.state.blockData) {
            const blockDataUntreated = (this.state.blockData.headerData && this.state.blockData.headerData[0]) ?? [];
            const blockData = {};

            Object.keys(blockDataUntreated).forEach(key => {
                const treatedkey = (key[0]).toLocaleLowerCase() + key.slice(1); // PMS sends its object keys first letter in upper case and we work in camel case;
                blockData[treatedkey] = blockDataUntreated[key];
            });

            const blockDetailsUntreated = this.state.blockData.details ?? [];
            const blockDetails = blockDetailsUntreated.map(detail => {
                const d = {};

                Object.keys(detail).forEach(key => {
                    const treatedkey = (key[0]).toLocaleLowerCase() + key.slice(1); // PMS sends its object keys first letter in upper case and we work in camel case;
                    d[treatedkey] = detail[key];
                });

                return d;
            });

            if(blockData){
                blockData.entityProfile = {
                    name: blockData.entityName,
                    profileId: blockData.entityMasterId
                };
            }

            this.setState({ originalBlockDetails: blockDetails, blockData, blockDetails, toDate: blockData.toDate, fromDate: blockData.fromDate });
        }
        else{
            const blockDetails = this.state.blockDetails.map(detail => {
                const d = {};

                Object.keys(detail).forEach(key => {
                    const treatedkey = (key[0]).toLocaleLowerCase() + key.slice(1); // PMS sends its object keys first letter in upper case and we work in camel case;
                    d[treatedkey] = detail[key];
                });

                return d;
            });

            this.setState({ originalBlockDetails: blockDetails, blockDetails });
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            blockData: {
                ...this.state.blockData,
                [name]: value
            }
        });
    }

    handleDatesChange = ({ startDate, endDate }) => {
        const { focused, blockData } = this.state;

        this.setState({
            fromDate: startDate?.format('YYYY-MM-DD'),
            toDate: endDate?.format('YYYY-MM-DD')
        }, () => {
            if(startDate && endDate && focused === "endDate" && blockData.id){
                this.toggleConfirmActionModal()
            }
        });
    }

    handleChangeFocus = (focused) => {
        this.setState({ focused });
    }

    handleDateChange = (date) => {
        this.setState({
            blockData: {
                ...this.state.blockData,
                optionDate: date?.format('YYYY-MM-DD')
            }
        });
    }

    handleCheckBoxChange = (name) => {
        this.setState({
            blockData: {
                ...this.state.blockData,
                [name]: !this.state.blockData[name]
            }
        })
    }

    handleCombo = (e, name) => {
        this.setState({
            blockData: {
                ...this.state.blockData,
                [name]: e?.value
            }
        })
    }

    saveBlock = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            const { blockData, blockDetails, selectedHotelId, fromDate, toDate } = this.state;

            const payload = {
                ...blockData,
                fromDate,
                toDate
            }

            if(blockDetails && blockDetails.length > 0){
                payload.details = [...blockDetails].filter(({ currentRooms, originalRooms, pickUp, pickUpTentative, freeRoomsBlock }) => currentRooms !== 0 || originalRooms !== 0 
                    || pickUp !== 0 || pickUpTentative !== 0 || freeRoomsBlock !== 0 );
                payload.details = payload.details.map((a) => ({
                    rooms: a.currentRooms,
                    headerId: a.headerId,
                    fromDate: a.date,
                    toDate: a.date,
                    categoryCode: a.categoryCode,
                }));
            }

            postAPI(result => {
                const { data, error } = result;

                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    this.getBlockInfo();
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SalesProcess/v1/pmsblock?hotelId=${selectedHotelId}&dealId=${this.props.dealId}`, payload);
        });
    }

    associateProfile = (profile) => {
        this.setState({
            blockData: {
                ...this.state.blockData,
                entityMasterId: profile.profileId,
                entityProfile: {
                    profileId: profile.profileId,
                    name: profile.name
                }
            }
        });
    }

    handleDetailsChange = (e, currentDate, catId) => {
        const { value } = e.target;
        const { blockDetails } = this.state;
        const blockDetailsCopy = [...blockDetails];
        const day = blockDetailsCopy.find(({date, categoryId}) => moment(date).isSame(currentDate) && categoryId === catId);
        const parsed = parseInt(value);
        day.unsavedCurrentRooms = (day.unsavedCurrentRooms??0) + (parsed - day.currentRooms);
        day.currentRooms = isNaN(parsed) ? 0 : parsed;

        this.setState({ blockDetails: blockDetailsCopy });
    }

    handleDetailsChangeBulk = (value, catList, dates, type) => {
        const { blockDetails } = this.state;
        const blockDetailsCopy = [...blockDetails];

        catList.forEach(({id}) => {
            dates.forEach(date => {
                const currentDate = date.format('YYYY-MM-DD');

                const day = [...blockDetailsCopy].find(({date, categoryId}) => moment(date).isSame(currentDate) && categoryId === id);
                const parsed = isNaN(parseInt(value)) ? 0 : parseInt(value);

                if(type === "define"){
                    day.unsavedCurrentRooms = (day.unsavedCurrentRooms??0) + (parsed - day.currentRooms);
                    day.currentRooms = parsed;
                }
                else{
                    day.unsavedCurrentRooms = (day.unsavedCurrentRooms??0) + parsed;
                    day.currentRooms = day.currentRooms + parsed;
                }
            });
        });

        this.setState({ blockDetails: blockDetailsCopy });
    }

    handleHotelChange = (name, combo) => {
        this.setState({ selectedHotelId: combo?.value });
    }

    toggleConfirmActionModal = () => {
        this.setState({ confirmActionModal: !this.state.confirmActionModal });
    }

    render() {
        const { blockData, error, blockDetails, selectedHotelId, block, fromDate, toDate, focused, confirmActionModal, entityOptions } = this.state;
        const { toggleActiveSupraTab, dealHotelList } = this.props;

        return (
            <div className='h-100'>
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col>
                            <form onSubmit={this.saveBlock}>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='d-flex aling-items-center'>
                                        <div onClick={_ => toggleActiveSupraTab("Calendar")} className='p-2 mr-2 cursor-pointer'>
                                            <i className='fas fa-chevron-left'/>
                                        </div>
                                        <h5 className='d-flex align-items-center justify-content-start m-0'><FormattedMessage id="SalesProcess.Block"/></h5>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <Button className="btn btn-host btn-sm" type="submit" disabled={blockData ? false : true }>
                                            <i className="fas fa-save"/>
                                        </Button>
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div>
                                        <SalesDealDetailProposalBlockStep1
                                            blockData={blockData}
                                            entityOptions={entityOptions}
                                            handleChange={this.handleChange}
                                            associateProfile={this.associateProfile}
                                            dealHotelList={dealHotelList}
                                            handleDateChange={this.handleDateChange}
                                            handleDatesChange={this.handleDatesChange}
                                            handleCombo={this.handleCombo}
                                            handleCheckBoxChange={this.handleCheckBoxChange}
                                            selectedHotelId={selectedHotelId}
                                            handleHotelChange={this.handleHotelChange}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            handleChangeFocus={this.handleChangeFocus}
                                            focused={focused}
                                        />
                                    </div>

                                    {blockData ?
                                        <div>
                                            <SalesDealDetailProposalBlockStep2
                                                handleDetailsChange={this.handleDetailsChange}
                                                handleDetailsChangeBulk={this.handleDetailsChangeBulk}
                                                blockDetails={blockDetails}
                                                startDate={blockData.fromDate}
                                                endDate={blockData.toDate}
                                                restartDetails={this.restartDetails}
                                                id={blockData.id}
                                            />
                                        </div>
                                        : ''}
                                </div>
                            </form>
                        </Col>

                        {confirmActionModal ?
                            <ConfirmActionModal
                                modal={confirmActionModal}
                                toggleModal={this.toggleConfirmActionModal}
                                actionFunction={this.saveBlock}
                                text={<FormattedMessage id="SalesProcess.ApplyDates"/>}
                                block={block}
                            />
                        :''}
                    </Row>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesDealDetailProposalBlock);
