import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';
import { checkSalesProcessPermission, getAllowedSalesProcessUsers } from './SalesProcessFunctions';
import { FormattedMessage } from 'react-intl';
import SalesProcess from './SalesProcess';
import { DefaultPage } from '../Base/CommonUIComponents';


class SalesProcessWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userHasAccess: null,
            tabs: [
                { name: 'SalesProcessExecutiveReport', icon: 'fas fa-user-tie', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesProcessExecutiveReport" /> },
                { name: 'SalesLeads', icon: 'fas fa-bullseye', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesLeads" /> },
                { name: 'SalesPipelineStage', icon: 'fas fa-route', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesPipelineStage" /> },
                { name: 'SalesDealList', icon: 'fas fa-briefcase', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesDealList" /> },
                // { name: 'SalesProcessResources', icon: 'far fa-calendar-alt', wasOpened: false },
                { name: 'SalesProcessGrid', icon: 'fas fa-table', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesProcessGrid" /> },
                { name: 'SalesProcessTasks', icon: 'fas fa-clipboard-list', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesProcessTasks" /> },
                // { name: 'SalesProcessStats', icon: 'far fa-chart-bar', wasOpened: false },
                { name: 'SalesDealDetail', notMenu: true, label: <FormattedMessage id="SalesProcess.SalesDealDetail" /> },
                { name: 'SalesBudget', icon: 'fas fa-wallet', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesBudget" /> },
                {
                    name: 'Config', icon: 'fas fa-cog', isSelectable: false, wasOpened: false, label: <FormattedMessage id="SalesProcess.Configurations" />,
                    subMenus: [
                        { name: 'SalesSource', icon: 'fas fa-code-branch', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesSource" /> },
                        { name: 'SalesPipeline', icon: 'fas fa-sliders-h', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesPipeline" /> },
                        { name: 'SalesProcessCustomization', icon: 'fas fa-indent', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesProcessCustomization" /> },
                        { name: 'SalesDealUsers', icon: 'fas fa-users', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesDealUsers" /> },
                        { name: 'SalesProcessSettings', icon: 'far fa-envelope', isSelectable: true, wasOpened: false, label: <FormattedMessage id="SalesProcess.SalesProcessSettings" /> },
                    ]
                },
            ],
        };
    }

    componentDidMount() {
        this.setState({ block: true }, () => {
            window.setTimeout(
                () => {
                    const { tabs } = this.state;
                    const users = getAllowedSalesProcessUsers();
                    const currentUser = users?.find(el => el.isCurrentUser);
                    

                    if (currentUser) {
                        //Check menu permission
                        tabs.forEach(tab => {
                            if (!tab.notMenu) {
                                if (tab.subMenus?.length > 0) {
                                    tab.subMenus.forEach(subMenu => {
                                        const isVisible = checkSalesProcessPermission(subMenu.name, currentUser.role);

                                        subMenu.isVisible = isVisible;

                                        if (isVisible) tab.isVisible = true;
                                    })
                                }
                                else {
                                    tab.isVisible = checkSalesProcessPermission(tab.name, currentUser.role);
                                }
                            }
                            else {
                                tab.isVisible = true;
                            }
                        })

                        this.setState({
                            userHasAccess: true,
                            currentUser,
                            tabs,
                            block: false
                        });
                    }
                    else {
                        this.setState({ userHasAccess: true, block: false });
                    }
                },
                global.hotelList && global.salesProcessUsers ? 0 : 2500
            );
        })
    }
    
    render() {
        const { userHasAccess, block, error, currentUser, tabs } = this.state;
        
        return (
            <div className="border-0 h-100 py-2" id='SalesProcess'>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    {userHasAccess !== null ?
                        userHasAccess ?
                            <SalesProcess
                                location={this.props.location}
                                history={this.props.history}
                                salesUsers={global.salesProcessUsers}
                                currentUser={currentUser}
                                tabs={tabs
                                    .filter(item => item.isVisible)
                                    .map(item => ({
                                        ...item,
                                        subMenus: item.subMenus?.filter(sub => sub.isVisible)
                                    }))
                                }
                            />
                            :
                            <DefaultPage text="SalesProcess.NoAccess" icon="fas fa-user-lock" height="80vh" />
                    : ''}
                </BlockUi>
            </div>
        )
    }
}

export default SalesProcessWrapper;