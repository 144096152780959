import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Button, Popover } from 'reactstrap';
import SpaceManagment from './SpaceManagment';

class SpacePopover extends Component {

    constructor(props) {
        super(props);
        this.state = {
            spacesDetails: [{}],
            selectedSpaceTimeSlotLength: 0,
            space: null,
            date: null,
        };
    }

    componentDidMount() {
        const { eventData, target, isOrphan, spacesOptions } = this.props;
        this.initialCalc(eventData, target, isOrphan, spacesOptions);
    }

    initialCalc = (eventData, target, isOrphan, spacesOptions) => {
        if(isOrphan){
            return;
        }
        
        const noSpace = 5 + target.slice(5).indexOf('-');
        const spaceKey = target.slice(5, noSpace);

        const date = target.slice(noSpace + 1);
        const space = spacesOptions[spaceKey];

        let spacesDetails = eventData.spaces.filter(({spaceName, toDate, fromDate}) =>
            spaceName === space?.description &&
            moment(fromDate).startOf('d').isSameOrBefore(date) &&
            moment(toDate).startOf('d').isSameOrAfter(date)
        ).map((a) => ({
            ...a,
            children: a.children ?? a.children1,
            pax: a.pax ?? a.adults,
            spaceCode: space.code,
            id: a.id ?? a.spaceReservationId,
            statusCode: a.statusCode ?? a.reservationStatusCode
        }));
        
        if(!spacesDetails.length){
            spacesDetails = [{
                spaceName: space?.description,
                spaceCode: space?.code,
                spaceId: space?.id
            }];
        }

        this.setState({ spacesDetails, space, date, target });
    }

    // componentWillReceiveProps(nextProps){
    //     const selectedSpaceTimeSlot = document.getElementsByClassName(`cell-time-slot-space-${nextProps.spaceName}`);
        
    //     if(selectedSpaceTimeSlot.length !== this.state.selectedSpaceTimeSlotLength){
    //         this.initialCalc();
    //     }
    // }

    // componentDidMount(){
    //     this.initialCalc();
    // }

    // initialCalc = () => {
    //     const { spaceName, colKey } = this.props;

    //     const selectedSpaceTimeSlot = document.getElementsByClassName(`cell-time-slot-space-${spaceName}`);

    //     if(!selectedSpaceTimeSlot)
    //         return;

    //     const timeSlots = [];

    //     selectedSpaceTimeSlot.forEach(element => {
    //         const str = 'cell-time-slot-time-';
    //         const idx = element.className.indexOf(str) + str.length;
    //         const timeClass = element.className.slice(idx, idx + 10);

    //         if(timeClass){
    //             let tSlot = timeSlots.find(({date}) => date === timeClass);
    
    //             if(!tSlot){
    //                 timeSlots.push({ date: timeClass, slots: [0,0,0,0] });
    //                 tSlot = timeSlots.find(({date}) => date === timeClass);
    //             }

    //             const stotStr = 'cell-time-slot-index-';
    //             const slotIdx = element.className.indexOf(stotStr) + stotStr.length;
    //             const slotClass = element.className.slice(slotIdx, slotIdx + 1);

    //             if(slotClass){
    //                 tSlot.slots[parseInt(slotClass)] += 1;
    //             }
    //         }
    //     });

    //     const selectedDateIdx = timeSlots.findIndex(({date}) => date === colKey);
        
    //     if(!selectedDateIdx < 0)
    //         return;

    //     const selectedDate = timeSlots[selectedDateIdx]

    //     const sorted = timeSlots
    //         .sort((a,b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'));

    //     const allContinuousDates = [...sorted]
    //         .slice(0, selectedDateIdx)
    //         .reverse()
    //         .filter((date, idx) => moment(date.date).format('YYYY-MM-DD') === moment(selectedDate.date).add((idx     - 1), 'day').format('YYYY-MM-DD'))
    //         .concat([...sorted]
    //             .slice(selectedDateIdx)
    //             .filter((date, idx) => moment(date.date).format('YYYY-MM-DD') === moment(selectedDate.date).add(idx, 'day').format('YYYY-MM-DD'))
    //         );

    //     const arr = allContinuousDates.flatMap(date => {
    //         let query = `.cell-time-slot-space-${spaceName}.cell-time-slot-time-${date.date}`;

    //         return date.slots
    //         .map((a, key) => a > 0 ? document.querySelector(query + `.cell-time-slot-index-${key}`) : null)
    //         .filter(a => a);
    //     });
        
    //     arr.forEach((element) => {
    //         if(!element.className.includes("cell-time-slots-selected-focused")){
    //             element.classList.add("cell-time-slots-selected-focused");
    //             element.classList.remove("cell-time-slots-selected");
    //         }
    //     })
        
    //     const lastContinuousDate = timeSlots.reduce((a, b) => {
    //         if(moment(b.date).format('YYYY-MM-DD') === moment(a.date).add(1, 'day').format('YYYY-MM-DD')){
    //             return b;
    //         }
    //         else{
    //             return a;
    //         }
    //     }, selectedDate);
    
    //     if(!lastContinuousDate)
    //         return;
        
    //     const firstContinuousDate = timeSlots.reduce((a, b) => {
    //         if(moment(b.date).format('YYYY-MM-DD') === moment(a.date).add(-1, 'day').format('YYYY-MM-DD')){
    //             return b;
    //         }
    //         else{
    //             return a;
    //         }
    //     }, selectedDate);

    //     if(!firstContinuousDate)
    //         return;

    //     const firstIdx = firstContinuousDate.slots.findIndex(a => a > 0);
    //     const lastIdx = lastContinuousDate.slots.findLastIndex(a => a > 0);

    //     const startDate = moment(firstContinuousDate.date).set('hour', (firstIdx === 0 ? 0 : firstIdx === 1 ? 6 : firstIdx === 2 ? 12 : 18));
    //     const endDate = moment(lastContinuousDate.date).set('hour', (lastIdx === 0 ? 6 : lastIdx === 1 ? 12 : lastIdx === 2 ? 18 : 24));

    //     this.setState({
    //         selectedSpaceTimeSlotLength: selectedSpaceTimeSlot.length,
    //         spaceData: {
    //             ...this.state.spaceData,
    //             startDate,
    //             endDate,
    //             startHour: startDate.format('HH:mm'),
    //             endHour: endDate.format('HH:mm'),
    //         }
    //     });
    // }

    // componentWillUnmount(){
    //     const focusedList = document.getElementsByClassName('cell-time-slots-selected-focused');

    //     while(focusedList.length > 0){
    //         const element = focusedList[0];
    //         element.classList.add("cell-time-slots-selected");
    //         element.classList.remove("cell-time-slots-selected-focused");
    //     };
    // }

    render() {
        const { toggleDetailPopOver, eventTypeOptions, customStatusOptions, eventData, saveSpace, spacesOptions } = this.props;
        const { spacesDetails, date, target } = this.state;

        return (
            target ?
                <Popover isOpen={true} target={target} placementPrefix='coolPopover bs-popover' onScroll={e => e.stopPropagation()}>
                    <Button onClick={toggleDetailPopOver} className="closeModal">
                        <i className="fas fa-times fa-sm"/>
                    </Button>
                    <div className={`d-flex align-items-center justify-content-between pt-3 pb-2 ${spacesDetails.length > 1 ? 'overflow-auto' : ''} veryCoolScrollBar`} style={{ maxWidth: spacesDetails.length > 1 ? '700px' : '' }}>
                        {spacesDetails.map((space, key) =>
                            <div style={{ borderLeft: key ? '1px solid lightgrey' : '' }}>
                                <SpaceManagment
                                    spaceData={space}
                                    spaceName={space.spaceName}
                                    spaceCode={space.spaceCode}
                                    key={key}
                                    colKey={date}
                                    toggleDetailPopOver={toggleDetailPopOver}
                                    eventTypeOptions={eventTypeOptions}
                                    customStatusOptions={customStatusOptions}
                                    eventData={eventData}
                                    saveSpace={saveSpace}
                                    spacesOptions={spacesOptions}
                                />
                            </div>
                        )}
                    </div>
                </Popover>
            :''
        );
    }
}

export default SpacePopover;
