import React, { Component } from 'react';
import { BlankCard, FormatAmountNumber, StyledModal, ActiveDeletedStatusBadge } from '../Base/CommonUIComponents';
import { Button, ButtonGroup, Col, Row, Collapse } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import moment from 'moment-timezone';

class ProposalHistoryModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            pageSize: 10,
            pageIndex: 0,
            proposalHistoryList: [],
            collapseList: [],
        };
    }

    componentDidMount(){
        this.getproposalHistoryList();
    }

    getproposalHistoryList = () => {
        const { salesDealId } = this.props;
        const { pageSize, pageIndex } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ proposalHistoryList: data.data, block: false });
                return;
            }
            else
                this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/ProposalHistory/${salesDealId}?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getproposalHistoryList());
        }
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.getproposalHistoryList());
    }

    toggleCollapse = (key) => {
        this.setState(prevState => ({
            collapseList: {
                ...prevState.collapseList,
                [key]: !prevState.collapseList[key] 
            }
        }));
    }

    render() {
        const { toggleModal, modal } = this.props;
        const { block, error, pageSize, pageIndex, proposalHistoryList, collapseList } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} className="bigModal" size="xl">
                <BlankCard block={block} error={error}>
                    <Row>
                        <Col>
                            <h5 className='m-0'>
                                <i className="fas fa-history mr-2" />
                                <FormattedMessage id="SalesDeal.ProposalHistory" />
                            </h5>
                        </Col>
                    </Row>
                    {proposalHistoryList && proposalHistoryList.length > 0 ?
                        <div className='mt-4'>
                            <div style={{ maxHeight: '50vh', overflow: 'auto' }} className='veryCoolScrollBar'>
                                {proposalHistoryList.map((res, key) => 
                                    <Row key={key} className='w-100'>
                                        <Col
                                            className='shadow mb-2 bg-white'
                                            style={{
                                                borderRadius: '4px',
                                                gap: '1%'
                                            }}
                                        >
                                            {res.lastVersion ?
                                                <Row className="p-2 mt-1">
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.ResNumber`} />
                                                        </div>
                                                        <div title={res.lastVersion.externalId} className='text-truncate mt-2'>
                                                            {res.lastVersion.externalId}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.Status`} />
                                                        </div>
                                                        <div className='text-truncate mt-2'>
                                                            <ActiveDeletedStatusBadge status={!res.lastVersion.deleted} />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.Version`} />
                                                        </div>
                                                        <div title={res.lastVersion.version} className='text-truncate mt-2'>
                                                            {res.lastVersion.version}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.UpdatedAt`} />
                                                        </div>
                                                        <div title={res.lastVersion.updatedAt} className='text-truncate mt-2'>
                                                            {moment(res.lastVersion.updatedAt).format('YYYY-MM-DD hh:mm:ss')}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.CheckIn`} />
                                                        </div>
                                                        <div title={res.lastVersion.startDate} className='text-truncate mt-2'>
                                                            {moment(res.lastVersion.startDate).format('YYYY-MM-DD hh:mm:ss')}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.CheckOut`} />
                                                        </div>
                                                        <div title={res.lastVersion.endDate} className='text-truncate mt-2'>
                                                            {moment(res.lastVersion.endDate).format('YYYY-MM-DD hh:mm:ss')}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.RoomsValue`} />
                                                        </div>
                                                        <div title={res.lastVersion.numberOfRooms} className='text-truncate mt-2'>
                                                            <FormatAmountNumber currency={global.hotelCurrency} value={res.lastVersion.roomsValue ?? 0} />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.EventsValue`} />
                                                        </div>
                                                        <div title={res.lastVersion.numberOfEvents} className='text-truncate mt-2'>
                                                            <FormatAmountNumber currency={global.hotelCurrency} value={res.lastVersion.eventsValue ?? 0} />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.SpacesValue`} />
                                                        </div>
                                                        <div title={res.lastVersion.numberOfEquipment} className='text-truncate mt-2'>
                                                            <FormatAmountNumber currency={global.hotelCurrency} value={res.lastVersion.spacesValue ?? 0} />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.EquipmentValue`} />
                                                        </div>
                                                        <div title={res.lastVersion.numberOfEquipment} className='text-truncate mt-2'>
                                                            <FormatAmountNumber currency={global.hotelCurrency} value={res.lastVersion.equipmentValue ?? 0} />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <div className='title-sm text-truncate'>
                                                            <FormattedMessage id={`SalesDeal.TotalValue`} />
                                                        </div>
                                                        <div title={res.lastVersion.totalValue} className='text-truncate mt-2'>
                                                            <FormatAmountNumber currency={global.hotelCurrency} value={res.lastVersion.totalValue ?? 0} />
                                                        </div>
                                                    </Col>
                                                    <Col className="d-flex align-items-center pl-4 pr-0 pointer col-1" style={{ justifyContent: 'center' }} onClick={() => this.toggleCollapse(key)}>
                                                        <span className="d-flex align-items-center">
                                                            <i className={`fas fa-chevron-${collapseList[key] ? `up` : `down`}`} />
                                                        </span>
                                                    </Col>
                                                </Row>
                                                : ''
                                            }
                                            <Collapse isOpen={collapseList[key] ?? false}>
                                                {res.allVersions && res.allVersions.length > 0 && res.allVersions.map((x, idx) =>
                                                    <Row
                                                        key={idx}
                                                        className='p-2 mt-1'
                                                        style={{
                                                            borderTop: '1px solid lightgrey',
                                                        }}
                                                    >
                                                        <Col className="col-1">
                                                        </Col>
                                                        <Col className="col-1">
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                {x.version}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                {moment(x.updatedAt).format('YYYY-MM-DD hh:mm:ss')}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                {moment(x.startDate).format('YYYY-MM-DD hh:mm:ss')}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                {moment(x.endDate).format('YYYY-MM-DD hh:mm:ss')}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={x.roomsValue ?? 0} />
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={x.eventsValue ?? 0} />
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={x.spacesValue ?? 0} />
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={x.equipmentValue ?? 0} />
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                            <div className='text-truncate'>
                                                                <FormatAmountNumber currency={global.hotelCurrency} value={x.totalValue ?? 0} />
                                                            </div>
                                                        </Col>
                                                        <Col className="col-1">
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Collapse>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                            <Row className="mt-4">
                                <Col>
                                    <ButtonGroup onClick={this.changePageSize}>
                                        <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                        <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                        <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                        <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                    </ButtonGroup>
                                </Col>

                                <Col className="text-right">
                                    <span>
                                        <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                            <i className="fas fa-angle-left" />
                                        </Button>
                                        <span className="mr-2">
                                            <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                        </span>
                                        <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={proposalHistoryList && proposalHistoryList.length < pageSize}>
                                            <i className="fas fa-angle-right" />
                                        </Button>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    :
                        <div>
                            <FormattedMessage id="SalesDeal.NoProposalHistory"/>
                        </div>
                    }
                </BlankCard>
            </StyledModal>
        );
    }
}

export default ProposalHistoryModal;
