import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatAmountNumber } from '../Base/CommonUIComponents';

class SalesDealProposalResumeBar extends Component {
    render() {
        const { salesDealProposals, classString = '' } = this.props;

        return (
            <div className={'mt-2 p-3 shadow bg-white ' + classString} style={{ position: 'sticky', bottom: '0' }}>
                <div className='d-flex align-items-center justify-content-between text-left'>
                    <div className='d-flex align-items-center justify-content-start'>
                        <div>
                            <div className='title-sm'>
                                <FormattedMessage id="SalesDeal.Rooms"/>
                            </div>
                            <div>
                                {salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfRooms??0), 0) ?? 0}
                            </div>
                        </div>
                        <div className='ml-4'>
                            <div className='title-sm'>
                                <FormattedMessage id="SalesDeal.Spaces"/>
                            </div>
                            <div>
                                {salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfSpaces??0), 0) ?? 0}
                            </div>
                        </div>
                        <div className='ml-4'>
                            <div className='title-sm'>
                                <FormattedMessage id="SalesDeal.Equipment"/>
                            </div>
                            <div>
                                {salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfEquipment??0), 0) ?? 0}
                            </div>
                        </div>
                    </div>
                    <div className='text-right'>
                        <div className='title-sm'>
                            <FormattedMessage id="SalesDeal.Total"/>
                        </div>
                        <div style={{ fontSize: '1.2em' }}>
                            <b>
                                <FormatAmountNumber
                                    currency={global.hotelCurrency}
                                    value={salesDealProposals.reduce((prev, curr) => prev + (curr.totalValue??0), 0) ?? 0}
                                />
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SalesDealProposalResumeBar;
