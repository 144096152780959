import React, { Component } from 'react'
import { Row, Col, Input, Button, CardBody, CardHeader, Card, Form, Label } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import GenericTextArea from '../../../Base/GenericTextArea';
import Authorization from '../../../Base/Authorization';
import CustomSelect from '../../../Base/CustomSelect';

class FormStyles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardVisibiltyOptions: [{ value: true, label: <FormattedMessage id="generic.yes" /> }, { value: false, label: <FormattedMessage id="generic.no" />}],
            questionNumberVisibilityOptions: [{ value: true, label: <FormattedMessage id="generic.yes" /> }, { value: false, label: <FormattedMessage id="generic.no" />}],
            labelPositionOptions: [{ value: 'top', label: <FormattedMessage id="FormBuilder.Top" /> }, { value: 'left', label: <FormattedMessage id="FormBuilder.Left" />}, { value: 'hidden', label: <FormattedMessage id="FormBuilder.Hidden" />}],
            questionErrorLocationOptions: [{ value: 'top', label: <FormattedMessage id="FormBuilder.Top" /> }, { value: 'bottom', label: <FormattedMessage id="FormBuilder.bottom" />}],
            logoPositionOptions: [{ value: '-webkit-center', label: <FormattedMessage id="FormBuilder.Center" /> }, { value: '-webkit-left', label: <FormattedMessage id="FormBuilder.Left" /> }, { value: '-webkit-right', label: <FormattedMessage id="FormBuilder.Right" /> }],
            buttonSubmitPositionOptions: [{ value: 'center', label: <FormattedMessage id="FormBuilder.Center" /> }, { value: 'start', label: <FormattedMessage id="FormBuilder.Left" /> }, { value: 'end', label: <FormattedMessage id="FormBuilder.Right" /> }]
        }
    }

    render() {
        const { survey, handleInputChange, handleBackgroundImageChange, handleBackgroundOpacityChange, handleStylesChange, hideQuestionCard, hideQuestionNumber, labelPosition, questionErrorLocation, layoutDisplay, hideQuestionTitle, buttonSubmitPosition } = this.props;

        console.log(survey.backgroundColor);

        return (
            <div>
                <Row className='align-items-center'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.BakcgroundColor" /></Label>
                        {survey.backgroundColor === "transparent" || !survey.backgroundColor ?
                            <div className='redLineFormStyles' />
                        :''
                        }
                        <Input
                            className="p-0 border-0 noShadow"
                            required={true}
                            type="color" 
                            value={survey.backgroundColor !== "transparent" && survey.backgroundColor ? survey.backgroundColor : "#FFFFFF"}
                            onChange={({target}) => handleStylesChange({ target: {name: "backgroundColor", value: target?.value} })}
                        />
                        {survey.backgroundColor !== "transparent" || !survey.backgroundColor ?
                            <div className='removeColor'>
                                <i className="fas fa-times fa-sm" onClick={() => handleStylesChange({ target: {name: "backgroundColor", value: 'transparent'} })}></i>    
                            </div>
                        :''}
                    </Col>
                    
                </Row>
                <Row className='mt-3 align-items-center'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'> <FormattedMessage id="FormBuilder.MainColor" /></Label>
                        {survey.mainColor === "transparent" || !survey.mainColor ?
                            <div className='redLineFormStyles' />
                        :''
                        }
                        <Input
                            className='p-0 border-0 noShadow'
                            required={true}
                            type="color" 
                            value={survey.mainColor !== "transparent" && survey.mainColor ? survey.mainColor : "#FFFFFF"}
                            onChange={({target}) => handleStylesChange({ target: {name: "mainColor", value: target?.value} })}
                        />
                        {survey.mainColor !== "transparent" || !survey.mainColor ?
                            <div className='removeColor'>
                                <i className="fas fa-times fa-sm" onClick={() => handleStylesChange({ target: {name: "mainColor", value: 'transparent'} })}></i>    
                            </div>
                        :''}
                    </Col>
                </Row>
                <Row className='mt-3 align-items-center'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.ButtonColor" /></Label>
                    
                    
                        {survey.buttonColor === "transparent" || !survey.buttonColor ?
                            <div className='redLineFormStyles' />
                        :''
                        }
                        <Input
                            className='p-0 border-0 noShadow'
                            type="color" 
                            required={true}
                            value={survey.buttonColor !== "transparent" && survey.buttonColor ? survey.buttonColor : "#FFFFFF"}
                            onChange={({target}) => handleStylesChange({ target: {name: "buttonColor", value: target?.value} })}
                        />
                        {survey.buttonColor !== "transparent" || !survey.buttonColor ?
                            <div className='removeColor'>
                                <i className="fas fa-times fa-sm" onClick={() => handleStylesChange({ target: {name: "buttonColor", value: 'transparent'} })}></i>    
                            </div>
                        :''}
                    </Col>
                </Row>
                <Row className='mt-3 align-items-center'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.TextColor" /></Label>
                    
                        {survey.textColor === "transparent" || !survey.textColor ?
                            <div className='redLineFormStyles' />
                        :''
                        }
                        <Input 
                            className='p-0 border-0 noShadow'
                            type="color" 
                            required={true}
                            value={survey.textColor !== "transparent" && survey.textColor ? survey.textColor : "#FFFFFF"}
                            onChange={({target}) => handleStylesChange({ target: {name: "textColor", value: target?.value} })}
                        />
                    
                        {survey.textColor !== "transparent" || !survey.textColor ?
                            <div className='removeColor'>
                                <i className="fas fa-times fa-sm" onClick={() => handleStylesChange({ target: {name: "textColor", value: 'transparent'} })}></i>    
                            </div>
                        :''}
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'> <FormattedMessage id="PNFormDetails.Image" /></Label>
                        <Input
                            className='px-4'
                            sm={9}
                            type="text"
                            name="imageUrl"
                            value={survey.imageUrl || ''}
                            onChange={handleInputChange}
                            maxLength="500"
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.LogoPosition" /></Label>
                        <CustomSelect 
                            isSearchable={false}
                            isDisabled={!survey.imageUrl}
                            options={this.state.logoPositionOptions}
                            value={this.state.logoPositionOptions.find(option => option.value === survey.logoPosition)}
                            onChange={this.props.handleLogoPositionChange.bind(this)}
                        />
                    </Col>
                </Row>
                <Row className='mt-3 align-items-center'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'> <FormattedMessage id="FormBuilder.BackgroundImage" /></Label>
                        <Input
                            className='px-4'
                            sm={9}
                            type="text"
                            name="backgroundImage"
                            value={survey.backgroundImage || ''}
                            onChange={handleBackgroundImageChange}
                            maxLength="500"
                        />
                    </Col>
                </Row>
                <Row className='mt-3 align-items-center'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'>
                            <FormattedMessage id="FormBuilder.BackgroundImageOpacity" />
                            <span className='pl-2'>
                                ({survey.backgroundOpacity ? (survey.backgroundOpacity * 100).toFixed(0) : 0})%
                            </span>
                        </Label>
                        <Input
                            className='p-0'
                            disabled={!survey.backgroundImage}
                            type="range"
                            name="backgroundOpacity"
                            value={survey.backgroundOpacity ? Math.round(survey.backgroundOpacity * 100) : 0}
                            onChange={handleBackgroundOpacityChange}
                            max={100}
                            min={0}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.LabelPosition" /></Label>
                        <CustomSelect 
                            isSearchable={false}
                            options={this.state.labelPositionOptions}
                            value={this.state.labelPositionOptions.find(option => option.value === labelPosition)}
                            onChange={this.props.changeStylesSelect.bind(this, 'labelPosition')}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.HideQuestionCard" /></Label>
                        <CustomSelect 
                            isSearchable={false}
                            options={this.state.cardVisibiltyOptions}
                            value={this.state.cardVisibiltyOptions.find(option => option.value === hideQuestionCard)}
                            onChange={this.props.changeStylesSelect.bind(this, 'hideQuestionCard')}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.HideQuestionNumber" /></Label>
                        <CustomSelect 
                            isSearchable={false}
                            isDisabled={labelPosition === 'hidden'}
                            options={this.state.questionNumberVisibilityOptions}
                            value={this.state.questionNumberVisibilityOptions.find(option => option.value === hideQuestionNumber)}
                            onChange={this.props.changeStylesSelect.bind(this, 'hideQuestionNumber')}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.HideQuestionTitle" /></Label>
                        <CustomSelect
                            options={this.state.questionNumberVisibilityOptions}
                            value={this.state.questionNumberVisibilityOptions.find(option => option.value === hideQuestionTitle)}
                            onChange={this.props.changeStylesSelect.bind(this, 'hideQuestionTitle')}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.questionErrorLocation" /></Label>
                        <CustomSelect 
                            isSearchable={false}
                            options={this.state.questionErrorLocationOptions}
                            value={this.state.questionErrorLocationOptions.find(option => option.value === questionErrorLocation)}
                            onChange={this.props.changeStylesSelect.bind(this, 'questionErrorLocation')}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.LayoutDisplay" /></Label>
                        <CustomSelect
                            options={this.state.logoPositionOptions}
                            value={this.state.logoPositionOptions.find(option => option.value === layoutDisplay)}
                            onChange={this.props.handleLayoutDisplay.bind(this, 'layoutDisplay')}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Label className='d-flex align-items-center px-0 title-sm'><FormattedMessage id="FormBuilder.ButtonSubmitPosition" /></Label>
                        <CustomSelect
                            options={this.state.buttonSubmitPositionOptions}
                            value={this.state.buttonSubmitPositionOptions.find(option => option.value === buttonSubmitPosition)}
                            onChange={this.props.handleButtonSubmitPosition.bind(this, 'buttonSubmitPosition')}
                        />
                    </Col>
                </Row>
                <Authorization
                    perform="admin:view"
                    yes={() => (
                        <Row className='mt-3'>
                            <Col>
                                <Label className='d-flex align-items-center px-0 title-sm'> <FormattedMessage id="PNFormDetails.Style" /></Label>
                                <GenericTextArea
                                    backgroundColor={"white"}
                                    onChange={handleInputChange}
                                    name={"css"}
                                    value={survey.css || ''}
                                    placeholder={''}
                                    className="py-1"
                                    style={{
                                        width: '100%',
                                        background: 'transparent',
                                        boxShadow: 'unset',
                                        outline: '0',
                                        maxHeight : '500px',
                                        minHeight: '50px',
                                        height: '50px',
                                        padding: '5px 0',
                                        resize: 'none',
                                        color: '#07294C',
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                />
            </div>
        )
    }
}

export default injectIntl(FormStyles);
