import moment from 'moment-timezone';
import React, { Component } from 'react';
import { CoolTooltip, KebabMenu } from '../Base/CommonUIComponents';
import { FormattedMessage } from 'react-intl';

class FocusActionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNote: false,
            isTask: false,
            status: null,
        };
    }

    componentDidMount() {
        this.getStatus();
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.action !== this.props.action) || (nextProps.action.id !== this.props.action.id)) {
            this.getStatus();
        }
    }

    getStatus = () => {
        const { action } = this.props;

        let status = moment().isSameOrAfter(action.startDate, 'date') ? ((action.endDate && moment().isSameOrBefore(action.endDate, 'date')) ? "OnGoing" : "Late") : "Soon";
        const isNote = action.type === "Note";
        const isTask = action.type === "Task";

        if(isTask){
            const missedTask = action.dueDate && moment().isSameOrAfter(action.dueDate, 'date');
            const onGoingTask = action.dueDate && moment().isSameOrBefore(action.dueDate, 'date') && action.status === "OnGoing";
            const soonTask = action.dueDate && moment().isSameOrBefore(action.dueDate, 'date') && action.status !== "OnGoing";

            status = missedTask ? "Late" : onGoingTask ? "OnGoing" : soonTask ? "Soon" : null;
        }
        this.setState({ isNote, isTask, status });
    }

    render() {
        const { action, finishedFunction, editFunction, deleteFunction, pinAction, downloadFunction, bigAction, onGoingFunction } = this.props;
        const { isNote, isTask, status } = this.state;
        
        return (
            bigAction ?
                <div className='p-3 mb-4 bg-white shadow border-0'>
                    <div className='d-flex w-100'>
                        <div className='mr-2 d-flex align-items-center'>
                            <div id={`ActiontType-${action.type}-${action.id}`}
                                style={{
                                    fontSize: '0.85em',
                                    background: '#0665ff',
                                    color: 'white',
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '100%'
                                }}
                            >
                                {action.type === 'Email' ?
                                    <i style={{ marginTop: '1px' }} className="far fa-envelope"></i>
                                : action.type === 'Call' ?
                                    <i className="fas fa-phone"></i>
                                : action.type === 'Note' ?
                                    <i style={{ marginTop: '1px' }} className="far fa-sticky-note"></i>
                                : action.type === 'File' ?
                                    <i style={{ marginTop: '1px' }} className="fas fa-file"></i>
                                : action.type === 'Meeting' ?
                                    <i style={{ marginTop: '1px' }} className="far fa-building"></i>
                                : action.type === 'Status' ?
                                    <i style={{ marginTop: '1px' }} className="fas fa-power-off"></i>
                                : action.type === 'Create' ?
                                    <i style={{ marginTop: '1px' }} className="fas fa-briefcase"/>
                                : action.type === 'Stage' ?
                                    <i style={{ marginTop: '1px' }} className="fas fa-level-up-alt"/>
                                : action.type === 'Task' ?
                                    <i className="far fa-clipboard"/>
                                : ''}
                            </div>
                            <CoolTooltip placement="left" target={`ActiontType-${action.type}-${action.id}`}>
                                <FormattedMessage id={`SalesProcess.${action.type}`}/>
                            </CoolTooltip>
                        </div>
                        <div className={`d-flex align-items-center justify-content-start w-100`}>
                            <div className={'text-left'}>
                                {action.title}
                            </div>
                            {isNote ?
                                <div className='text-left mx-2'>
                                    <div className={`text-muted`} style={{ fontSize: '0.9em' }}>
                                        <div className='emailDescription smallDescription'>
                                            <div dangerouslySetInnerHTML={{ __html: action.summary }}/>
                                        </div>
                                    </div>
                                </div>
                            :''}
                        </div>
                        <div className={`d-flex align-items-center`}>
                            <div className={`d-flex align-items-start} justify-content-end`}>
                                <div className='d-flex align-items-center justify-content-end text-muted' style={{ fontSize: '0.9em' }}>
                                    {isTask && action.dueDate ?
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            {moment(action.dueDate).format('YYYY-MM-DD HH:mm')}
                                        </div>
                                    : action.startDate ?
                                            <div style={{ whiteSpace: 'nowrap' }}>
                                                {moment(action.startDate).format('YYYY-MM-DD HH:mm')}
                                            </div>
                                    :''}
                                    {action && action.pinned ?
                                        <div className='ml-2'>
                                            <i id={`PinnedTag-${action.id}`} className="fas fa-thumbtack" style={{ color: '#0665ff', fontSize: '0.9em' }} />
                                            <CoolTooltip placement="top" target={`PinnedTag-${action.id}`}>
                                                <FormattedMessage id="SalesProcess.Pinned" />
                                            </CoolTooltip>
                                        </div>
                                    : action.status ?
                                        <div>
                                            <div id={`ActionStatus-${action.id}`}>
                                                <div className="ml-2 d-flex align-items-center justify-content-center">
                                                    {action.status === 'Scheduled' ?
                                                        status === "Late" ?
                                                            <i className="text-danger fas fa-exclamation-circle"/>
                                                        : status === "Soon" ?
                                                            <i style={{ color: 'lightgrey' }} className="fas fa-minus-circle"/>
                                                        :
                                                            <i style={{ color: '#0665ff' }} className="fas fa-arrows-alt-h"/>
                                                    : action.status === 'Cancelled' ?
                                                        <i className="fas fa-times-circle color-light-red"/>
                                                    : action.status === 'OnGoing' ?
                                                        <i className="fas fa-arrows-alt-h" style={{ color: "#0665ff" }}/>
                                                    : action.status === 'Finished' ?
                                                        <i className="fas fa-check-circle text-success"/>
                                                    : ''}
                                                </div>
                                            </div>
                                            <CoolTooltip placement="top" target={`ActionStatus-${action.id}`}>
                                                {action.status === 'Scheduled' ?
                                                    status === "Late" ?
                                                        <FormattedMessage id={`SalesProcess.LateAction`}/>
                                                    : status === "Soon" ?
                                                        <FormattedMessage id={`SalesProcess.ActionSoon`}/>
                                                    :
                                                        <FormattedMessage id={`SalesProcess.OnGoingAction`}/>
                                                :
                                                    <FormattedMessage id={`SalesProcess.${action.status}`}/>
                                                }
                                            </CoolTooltip>
                                        </div>
                                    :''}
                                </div>
                                <div className='ml-2'>
                                    {action && (action.type === 'File' || action.type === 'Note') ?
                                        <KebabMenu
                                            direction={'left'}
                                            editFunction={editFunction}
                                            deleteFunction={deleteFunction}
                                            downloadFunction={downloadFunction}
                                            extraFields={[
                                                { text: action.pinned ? "SalesProcess.Unpin" : "SalesProcess.Pin", function: pinAction, icon: "fas fa-thumbtack mr-2" }
                                          ]}
                                        />
                                        : action && action.type === 'Task' ?
                                            <KebabMenu
                                                editFunction={editFunction}
                                                deleteFunction={deleteFunction}
                                                deleteText={"SalesDeal.Cancel"}
                                                extraFields={[
                                                    action.status == "Scheduled" ? {
                                                        text: "SalesProcess.OnGoing",
                                                        textClass: "onGoingColorIcon",
                                                        function: _ => onGoingFunction(action),
                                                        icon: "fas fa-arrows-alt-h mr-2 onGoingColorIcon",
                                                    } : null,
                                                    action.status == "OnGoing" ? {
                                                        text: "SalesProcess.Finished",
                                                        textClass: "text-success",
                                                        function: _ => finishedFunction(action),
                                                        icon: "mr-2 fas fa-check-circle text-success"
                                                    } : null,
                                                ].filter(Boolean)}
                                            />
                                    :
                                        <KebabMenu
                                            direction={'left'}
                                            editFunction={editFunction}
                                            deleteFunction={deleteFunction}
                                            extraFields={[
                                                {
                                                    text: "SalesProcess.Finished",
                                                    textClass: "text-success",
                                                    function: _ => finishedFunction(action),
                                                    icon: "mr-2 fas fa-check-circle text-success"
                                                }
                                            ]}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <div className='p-3 mb-4 bg-white shadow border-0'>
                    <div className='d-flex w-100'>
                        <div className='mr-2'>
                            <div id={`ActiontType-${action.type}-${action.id}`}
                                style={{
                                    fontSize: '0.85em',
                                    background: '#0665ff',
                                    color: 'white',
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '100%'
                                }}
                            >
                                {action.type === 'Email' ?
                                    <i style={{ marginTop: '1px' }} className="far fa-envelope"></i>
                                : action.type === 'Call' ?
                                    <i className="fas fa-phone"></i>
                                : action.type === 'Note' ?
                                    <i style={{ marginTop: '1px' }} className="far fa-sticky-note"></i>
                                : action.type === 'File' ?
                                    <i style={{ marginTop: '1px' }} className="fas fa-file"></i>
                                : action.type === 'Meeting' ?
                                    <i style={{ marginTop: '1px' }} className="far fa-building"></i>
                                : action.type === 'Status' ?
                                    <i style={{ marginTop: '1px' }} className="fas fa-power-off"></i>
                                : action.type === 'Create' ?
                                    <i style={{ marginTop: '1px' }} className="fas fa-briefcase"/>
                                : action.type === 'Stage' ?
                                    <i style={{ marginTop: '1px' }} className="fas fa-level-up-alt"/>
                                : action.type === 'Task' ?
                                    <i className="far fa-clipboard"/>
                                : ''}
                            </div>
                            <CoolTooltip placement="left" target={`ActiontType-${action.type}-${action.id}`}>
                                <FormattedMessage id={`SalesProcess.${action.type}`}/>
                            </CoolTooltip>
                        </div>
                        <div className={`d-flex align-items-center justify-content-between w-100`}>
                            <div className={'text-left'}>
                                {action.title}
                            </div>
                        </div>
                        <div className={!isNote ? `d-flex align-items-center` : ''}>
                            <div className={`d-flex align-items-${isNote ? 'start' : 'center'} justify-content-end`}>
                                <div className='d-flex align-items-center justify-content-end text-muted' style={{ fontSize: '0.9em' }}>
                                    {isTask && action.dueDate ?
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            {moment(action.dueDate).format('YYYY-MM-DD HH:mm')}
                                        </div>
                                    : action.startDate ?
                                            <div style={{ whiteSpace: 'nowrap' }}>
                                                {moment(action.startDate).format('YYYY-MM-DD HH:mm')}
                                            </div>
                                    :''}
                                    {action && action.pinned ?
                                        <div className='ml-2'>
                                            <i id={`PinnedTag-${action.id}`} className="fas fa-thumbtack" style={{ color: '#0665ff', fontSize: '0.9em' }} />
                                            <CoolTooltip placement="top" target={`PinnedTag-${action.id}`}>
                                                <FormattedMessage id="SalesProcess.Pinned" />
                                            </CoolTooltip>
                                        </div>
                                    : action.status ?
                                        <div>
                                            <div id={`ActionStatus-${action.id}`}>
                                                <div className="ml-2 d-flex align-items-center justify-content-center">
                                                    {action.status === 'Scheduled' ?
                                                        status === "Late" ?
                                                            <i className="text-danger fas fa-exclamation-circle"/>
                                                        : status === "Soon" ?
                                                            <i style={{ color: 'lightgrey' }} className="fas fa-minus-circle"/>
                                                        :
                                                            <i style={{ color: '#0665ff' }} className="fas fa-arrows-alt-h"/>
                                                    : action.status === 'Cancelled' ?
                                                        <i className="fas fa-times-circle color-light-red"/>
                                                    : action.status === 'OnGoing' ?
                                                        status === "Late" ?
                                                            <i className="text-danger fas fa-exclamation-circle"/>
                                                        : 
                                                            <i className="fas fa-arrows-alt-h" style={{ color: "#0665ff" }}/>
                                                    : action.status === 'Finished' ?
                                                        <i className="fas fa-check-circle text-success"/>
                                                    : ''}
                                                </div>
                                            </div>
                                            <CoolTooltip placement="top" target={`ActionStatus-${action.id}`}>
                                                {action.status === 'Scheduled' ?
                                                    status === "Late" ?
                                                        <FormattedMessage id={`SalesProcess.LateAction`}/>
                                                    : status === "Soon" ?
                                                        <FormattedMessage id={`SalesProcess.ActionSoon`}/>
                                                    :
                                                        <FormattedMessage id={`SalesProcess.OnGoingAction`}/>
                                                :
                                                    status === "Late" ?
                                                        <FormattedMessage id={`SalesProcess.LateAction`}/>
                                                    : 
                                                        <FormattedMessage id={`SalesProcess.${action.status}`}/>
                                                }
                                            </CoolTooltip>
                                        </div>
                                    :''}
                                </div>
                                <div className='ml-2'>
                                    {action && (action.type === 'File' || action.type === 'Note') ?
                                        <KebabMenu
                                            direction={'left'}
                                            editFunction={editFunction}
                                            deleteFunction={deleteFunction}
                                            downloadFunction={downloadFunction}
                                            extraFields={[
                                                { text: action.pinned ? "SalesProcess.Unpin" : "SalesProcess.Pin", function: pinAction, icon: "fas fa-thumbtack mr-2" }
                                            ]}
                                        />
                                        : action && action.type === 'Task' ?
                                            <KebabMenu
                                                editFunction={editFunction}
                                                deleteFunction={deleteFunction}
                                                deleteText={"SalesDeal.Cancel"}
                                                extraFields={[
                                                    action.status == "Scheduled" ? {
                                                        text: "SalesProcess.OnGoing",
                                                        textClass: "onGoingColorIcon",
                                                        function: _ => onGoingFunction(action),
                                                        icon: "fas fa-arrows-alt-h mr-2 onGoingColorIcon",
                                                    } : null,
                                                    action.status == "OnGoing" ? {
                                                        text: "SalesProcess.Finished",
                                                        textClass: "text-success",
                                                        function: _ => finishedFunction(action),
                                                        icon: "mr-2 fas fa-check-circle text-success"
                                                    } : null,
                                                ].filter(Boolean)}
                                            />
                                    :
                                        <KebabMenu
                                            direction={'left'}
                                            editFunction={editFunction}
                                            deleteFunction={deleteFunction}
                                            extraFields={[
                                                {
                                                    text: "SalesProcess.Finished",
                                                    textClass: "text-success",
                                                    function: _ => finishedFunction(action),
                                                    icon: "mr-2 fas fa-check-circle text-success"
                                                }
                                            ]}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {isNote ?
                        <div className='text-left'>
                            <div className={`text-muted ${action.salesDealActionUsers?.length ? 'my-3' : 'mt-3'}`} style={{ fontSize: '0.9em' }}>
                                <div className='emailDescription smallDescription'>
                                    <div dangerouslySetInnerHTML={{ __html: action.summary }}/>
                                </div>
                            </div>
                        </div>
                    :''}
                </div>
        );
    }
}

export default FocusActionCard;
