import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import { Card, Col, Row, Collapse, Badge } from 'reactstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';

class SalesDealProposalEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            treatedSpaces: [],
            openedEventDetail: {},
            totalValue: 0,
        };
    }

    componentDidMount() {
        var totalValue = 0;
        const openedEventDetail = {};
        if (this.props.proposals?.length > 0 && this.props.proposals.filter(x => x.type === "Event")?.length > 0) {
            this.props.proposals.filter(x => x.type === "Event").forEach(x => 
                totalValue += x.eventsValue
            )
        }
        if (this.props.proposals?.length > 0 && this.props.proposals.reduce((acc, cur) => acc + cur.proposalJSON?.data[0]?.DetailsEvents?.length, 0) === 1) {
            this.props.proposals[0].proposalJSON.data[0].DetailsEvents.forEach(event => {
                openedEventDetail[event.EventName] = true;

                if (event.PriceDetailsNonSpace?.length > 0) {
                    event.PriceDetailsNonSpace.forEach(nonSpace => {
                        openedEventDetail[`${event.EventName}-Equipments`] = true;
                    })
                }

                if (event.Spaces?.length > 0) {
                    event.Spaces.forEach((space, spaceIdx) => {
                        openedEventDetail[`${event.EventName}-Spaces`] = true;

                        if (space.PriceDetails?.length > 0) {
                            space.PriceDetails.forEach(details => {
                                openedEventDetail[`${event.EventName}-Space-${spaceIdx}`] = true;
                            })
                        }
                    })
                }
            })
        }
        this.setState({ openedEventDetail, totalValue });
    }

    toggleOpenedEventDetail = (key) => {
        //TODO trocar eventName como key para event id
        const { openedEventDetail } = this.state;

        openedEventDetail[key] = !openedEventDetail[key];
        this.setState({ openedEventDetail })
    }


    getCsvData = () => {
        const { proposals } = this.props;

        let eventsCsv = [[
            this.props.intl.formatMessage({ id: "SalesDeal.Reservation" }), this.props.intl.formatMessage({ id: "SalesDeal.TotalPrice" })
        ]];

        if (proposals) {
            proposals.forEach(proposal => {
                if (proposal.isVisible && proposal.proposalJSON?.data[0]?.DetailsEvents?.length > 0) {
                    eventsCsv.push([proposal.externalId, proposal.proposalJSON.data[0].DetailsEvents.reduce((acc, cur) => acc + cur.PriceTotal, 0)])

                    if (proposal.proposalJSON.data[0].DetailsEvents?.length > 0) {
                        eventsCsv.push([]);
                        eventsCsv.push([,
                            this.props.intl.formatMessage({ id: "SalesDeal.Events" }), this.props.intl.formatMessage({ id: "SalesDeal.Type" }),
                            this.props.intl.formatMessage({ id: "SalesDeal.CreateDate" }), this.props.intl.formatMessage({ id: "SalesDeal.EventManager" }),
                            this.props.intl.formatMessage({ id: "SalesDeal.Adults" }), this.props.intl.formatMessage({ id: "SalesDeal.Children" }),
                            this.props.intl.formatMessage({ id: "SalesDeal.PriceTotal" }), this.props.intl.formatMessage({ id: "SalesDeal.Status" })
                        ]);

                        proposal.proposalJSON.data[0].DetailsEvents.forEach(event => {
                            if (event.isVisible) {
                                eventsCsv.push([, event.EventName, event.EventType,
                                    (event.CreateDate ? moment(event.CreateDate).format("YYYY-MM-DD") : ''), event.EventManager,
                                    event.Adults, ((event.Children1 ?? 0) + (event.Children2 ?? 0) + (event.Children3 ?? 0)),
                                    event.PriceTotal, event.ReservationStatusDescription
                                ]);

                                if (event.PriceDetailsNonSpace?.length > 0) {
                                    eventsCsv.push([]);
                                    eventsCsv.push([].concat([,,
                                        this.props.intl.formatMessage({ id: "SalesDeal.Equipment" }), this.props.intl.formatMessage({ id: "SalesDeal.Code" }),
                                        this.props.intl.formatMessage({ id: "SalesDeal.Type" }), this.props.intl.formatMessage({ id: "SalesDeal.Amount" })
                                    ]));

                                    event.PriceDetailsNonSpace.forEach(nonSpace => {
                                        eventsCsv.push([, , nonSpace.ChargeDescription, nonSpace.ChargeCode,nonSpace.Type, nonSpace.AmountAfterTax]);
                                    })
                                }
                                
                                if (event.Spaces?.length > 0) {
                                    eventsCsv.push([]);
                                    eventsCsv.push([, ,
                                        this.props.intl.formatMessage({ id: "SalesDeal.Spaces" }), this.props.intl.formatMessage({ id: "SalesDeal.Type" }),
                                        this.props.intl.formatMessage({ id: "SalesDeal.FromDate" }), this.props.intl.formatMessage({ id: "SalesDeal.ToDate" }),
                                        this.props.intl.formatMessage({ id: "SalesDeal.Adults" }), this.props.intl.formatMessage({ id: "SalesDeal.Children" })
                                    ]);

                                    event.Spaces.forEach(space => {
                                        eventsCsv.push([, , space.SpaceName, space.SpaceTypeDescription,
                                            (space.FromDate ? moment(space.FromDate).format("YYYY-MM-DD HH:mm") : ''), (space.ToDate ? moment(space.ToDate).format("YYYY-MM-DD HH:mm") : '' ),
                                            space.Adults, ((space.Children1 ?? 0) + (space.Children2 ?? 0) + (space.Children3 ?? 0))
                                        ]);


                                        if (space.PriceDetails?.length > 0) {
                                            eventsCsv.push([]);
                                            eventsCsv.push([, , ,
                                                this.props.intl.formatMessage({ id: "SalesDeal.Details" }), this.props.intl.formatMessage({ id: "SalesDeal.Code" }),
                                                this.props.intl.formatMessage({ id: "SalesDeal.Type" }), this.props.intl.formatMessage({ id: "SalesDeal.Amount" })
                                            ]);


                                            space.PriceDetails.forEach(details => {
                                                eventsCsv.push([, , , details.ChargeDescription, details.ChargeCode, details.Type, details.AmountAfterTax]);
                                            })


                                            eventsCsv.push([]);
                                        }
                                    })
                                }

                                eventsCsv.push([]);
                            }
                        })
                    }
                }
            })
        }

        return eventsCsv;
    }

    
    render() {
        const { proposals, dealName } = this.props;
        const { openedEventDetail, totalValue } = this.state;

        return (
            <div>
                <div className='d-flex align-items-center justify-content-between text-muted pl-2 mb-3'>
                    <div style={{ fontSize: '1.1em' }}>
                        <i className="fas fa-theater-masks mr-2"/>
                        <FormattedMessage id="SalesDeal.Events"/>
                    </div>
                    <div style={{ fontSize: '1.4em' }}>
                        <FormatAmountNumber value={totalValue} />

                        <CSVLink className="btn btn-sm btn-host ml-3" data={this.getCsvData()} filename={`${dealName} Events.csv`}>
                            <i className="fas fa-file-download" />
                        </CSVLink>
                    </div>
                </div>

                {proposals?.map((proposal, index) =>
                    proposal.isVisible && proposal.proposalJSON?.data[0]?.DetailsEvents?.length > 0 &&
                        <Card body className="shadow border-0 mb-3" key={index}>
                            <div className="border-bottom pb-2 mb-3">
                                <Row style={{ fontSize: '1.1em' }} className='text-muted d-flex align-items-center justify-content-between'>
                                    <Col>{/*<i className="mr-2 fas fa-receipt" />*/} {proposal.externalId}</Col>
                                    <Col className="text-right">
                                        <FormatAmountNumber value={proposal.proposalJSON.data[0].DetailsEvents.reduce((acc, cur) => acc + cur.PriceTotal, 0)} />
                                    </Col>
                                </Row>
                            </div>

                            {proposal.proposalJSON.data[0].DetailsEvents?.map((event, eventIdx) => event.isVisible &&
                                <div key={eventIdx} className="mb-3 ">
                                    <div className="p-2" style={{ backgroundColor: '#f3f3f3' }}>
                                        <Row className="align-items-center">
                                            <Col className="d-flex align-items-center">
                                                <i className="fas fa-theater-masks mr-3" />
                                                <div>
                                                    {event.EventName}
                                                    <div className="text-muted">{event.EventType}</div>
                                                </div>
                                            </Col>
                                            <Col className="col-2 d-flex justify-content-between align-items-center" >

                                                {event.ReservationStatusDescription ?
                                                    <Badge className="p-1" color="primary">{event.ReservationStatusDescription}</Badge>
                                                    : <div/>}

                                                <CollapseIcon
                                                    toggleOpenedEventDetail={_ => this.toggleOpenedEventDetail(event.EventName)}
                                                    isOpen={openedEventDetail[event.EventName]}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <Collapse isOpen={openedEventDetail[event.EventName]} className="mx-3 mt-3">
                                        <Row className="mx-1">
                                            <Col>
                                                <div className="title-sm"><FormattedMessage id="SalesDeal.CreateDate" /></div>
                                                {event.CreateDate ? moment(event.CreateDate).format("YYYY-MM-DD") : ''}
                                            </Col>
                                            <Col>
                                                <div className="title-sm"><FormattedMessage id="SalesDeal.EventManager" /></div>
                                                {event.EventManager}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="title-sm"><FormattedMessage id="SalesDeal.Adults" /></div>
                                                {event.Adults}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="title-sm"><FormattedMessage id="SalesDeal.Children" /></div>
                                                {(event.Children1 ?? 0) + (event.Children2 ?? 0) + (event.Children3 ?? 0)}</Col>
                                            <Col sm={2}>
                                                <div className="title-sm"><FormattedMessage id="SalesDeal.PriceTotal" /></div>
                                                <FormatAmountNumber value={event.PriceTotal}/>
                                            </Col>
                                        </Row>

                                        {event.PriceDetailsNonSpace?.length > 0 &&
                                            <div className="mt-5 mx-2">
                                                <div className="text-muted">
                                                    <Row className="p-2 align-ite-center" style={{ backgroundColor: '#f3f3f3' }}>
                                                        <Col><i className="fas fa-microphone mr-2" /> <FormattedMessage id="SalesDeal.Equipment" /></Col>
                                                        <Col sm={2} className="title-sm"> <FormattedMessage id="SalesDeal.Type" /></Col>
                                                        <Col sm={2} className="title-sm pr-0">
                                                            <FormattedMessage id="SalesDeal.Amount" />

                                                            <CollapseIcon
                                                                toggleOpenedEventDetail={_ => this.toggleOpenedEventDetail(`${event.EventName}-Equipments`)}
                                                                isOpen={openedEventDetail[`${event.EventName}-Equipments`]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <Collapse isOpen={openedEventDetail[`${event.EventName}-Equipments`]}>
                                                    {event.PriceDetailsNonSpace.map((nonSpace, nonSpaceIdx) =>
                                                        <div key={nonSpaceIdx} className="align-items-center p-2 overbglight border-top">
                                                            <Row className="align-items-center">
                                                                <Col>
                                                                    {nonSpace.ChargeDescription}
                                                                    <div className="title-sm">{nonSpace.ChargeCode}</div>
                                                                </Col>
                                                                <Col sm={2}>{nonSpace.Type || '-'}</Col>
                                                                <Col sm={2}> <FormatAmountNumber value={nonSpace.AmountAfterTax} /></Col>
                                                            </Row>
                                                        </div>
                                                    )}
                                                </Collapse>
                                            </div>
                                        }


                                        {event.Spaces?.length > 0 &&
                                            <div className="mt-5 mx-2">
                                                <div className="text-muted">
                                                    <Row className="p-2 align-items-end" style={{ backgroundColor: '#f3f3f3' }}>
                                                        <Col sm={3}><i className="fas fa-store-alt mr-2" />  <FormattedMessage id="SalesDeal.Spaces" /></Col>
                                                        <Col className="title-sm"> <FormattedMessage id="SalesDeal.FromDate" /></Col>
                                                        <Col className="title-sm"> <FormattedMessage id="SalesDeal.ToDate" /></Col>
                                                        <Col sm={2} className="title-sm"> <FormattedMessage id="SalesDeal.Adults" /></Col>
                                                        <Col sm={2} className="title-sm pr-0">
                                                            <FormattedMessage id="SalesDeal.Children" />

                                                            <CollapseIcon
                                                                toggleOpenedEventDetail={_ => this.toggleOpenedEventDetail(`${event.EventName}-Spaces`)}
                                                                isOpen={openedEventDetail[`${event.EventName}-Spaces`]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <Collapse isOpen={openedEventDetail[`${event.EventName}-Spaces`]}>
                                                    {event.Spaces.map((space, spaceIdx) =>
                                                        <div key={spaceIdx} className="p-2 border-top overbglight">
                                                            <Row>
                                                                <Col sm={3}>
                                                                    {space.SpaceName}
                                                                    <div className="title-sm">{space.SpaceTypeDescription}</div>
                                                                </Col>
                                                                <Col>{space.FromDate ? moment(space.FromDate).format("YYYY-MM-DD HH:mm") : ''}</Col>
                                                                <Col>{space.ToDate ? moment(space.ToDate).format("YYYY-MM-DD HH:mm") : ''}</Col>
                                                                <Col sm={2}>{space.Adults}</Col>
                                                                <Col sm={2}>{(space.Children1 ?? 0) + (space.Children2 ?? 0) + (space.Children3 ?? 0)}</Col>
                                                            </Row>

                                                            {space.PriceDetails && space.PriceDetails.length > 0 ?
                                                                <div className=" mx-4 mt-3">
                                                                    <Row className="p-2 align-items-end" style={{ backgroundColor: '#f3f3f3' }}>
                                                                        <Col><i className="fas fa-box-open mr-2" />  <FormattedMessage id="SalesDeal.Details" /></Col>
                                                                        <Col sm={2} className="title-sm pr-2" style={{ paddingLeft: '2.5rem' }}> <FormattedMessage id="SalesDeal.Type" /></Col>
                                                                        <Col sm={2} className="title-sm pr-0" style={{ paddingLeft: '2.5rem' }}>
                                                                            <FormattedMessage id="SalesDeal.Amount" />

                                                                            <CollapseIcon
                                                                                toggleOpenedEventDetail={_ => this.toggleOpenedEventDetail(`${event.EventName}-Space-${spaceIdx}`)}
                                                                                isOpen={openedEventDetail[`${event.EventName}-Space-${spaceIdx}`]}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                    <Collapse isOpen={openedEventDetail[`${event.EventName}-Space-${spaceIdx}`]}>
                                                                        {space.PriceDetails?.map((details, detailsIndex) =>
                                                                            <Row className="py-2 border-top" key={detailsIndex} style={{ backgroundColor: 'white' }}>
                                                                                <Col>
                                                                                    {details.ChargeDescription}
                                                                                    <div className="title-sm">{details.ChargeCode}</div>
                                                                                </Col>
                                                                                <Col sm={2} className="pr-2" style={{ paddingLeft: '2rem' }}>{details.Type}</Col>
                                                                                <Col sm={2} className="pr-2" style={{ paddingLeft: '2rem' }}><FormatAmountNumber value={details.AmountAfterTax} /></Col>
                                                                            </Row>
                                                                        )}
                                                                    </Collapse>
                                                                </div>
                                                            : ''}
                                                        </div>
                                                    )}
                                                </Collapse>
                                            </div>
                                        }
                                    </Collapse>
                            </div>
                            )}
                        </Card>
                )}
            </div>
        );
    }
}

export default injectIntl(SalesDealProposalEvents);


const CollapseIcon = ({ toggleOpenedEventDetail, isOpen }) => {
    return <span className='pointer mr-2 float-right' onClick={toggleOpenedEventDetail}>
        {isOpen ?
            <i className="fas fa-chevron-up" />
            :
            <i className="fas fa-chevron-down" />
        }
    </span>
}