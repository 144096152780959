import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { FormatAmountNumber } from '../../Base/CommonUIComponents';
import moment from 'moment-timezone';

class SpaceList extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        const { spaceList, customStatusOptions, toggleDetailPopup } = this.props;

        return (
            <div>
                <div className='bg-white shadow py-3 px-3 text-muted' style={{ fontSize: '0.9em' }}>
                    <Row>
                        <Col className="col-3">
                            <FormattedMessage id="SalesProcess.Name"/>
                        </Col>
                        <Col>
                            <FormattedMessage id="SalesProcess.ServiceType"/>
                        </Col>
                        <Col>
                            <FormattedMessage id="SalesProcess.Status"/>
                        </Col>
                        <Col>
                            <FormattedMessage id="SalesProcess.FromDate"/>
                        </Col>
                        <Col>
                            <FormattedMessage id="SalesProcess.ToDate"/>
                        </Col>
                        <Col className="col-1">
                            <FormattedMessage id="SalesProcess.Pax"/>
                        </Col>
                        <Col className="col-1">
                            <FormattedMessage id="SalesProcess.TotalPrice"/>
                        </Col>
                    </Row>
                </div>
                {spaceList?.length ?
                    <div className='mt-2 bg-white shadow' style={{ fontSize: '0.95em' }}>
                        {spaceList.map((space, key) =>
                            <React.Fragment key={key}>
                                {key ?
                                    <hr className='my-0'/>
                                :''}
                                <div className='py-3 row-space-list cursor-pointer' onClick={_ => toggleDetailPopup(space.spaceReservationId)}>
                                    <Row className='px-3'>
                                        <Col className="col-3">
                                            <div className='text-truncate' title={space.spaceName}>
                                                {space.spaceName ?? '-'}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='text-truncate' title={space.spaceTypeDescription}>
                                                {space.spaceTypeDescription ?? '-'}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='text-truncate' title={customStatusOptions.find(({value}) => value === space.reservationStatusCode)?.description}>
                                                {space.reservationStatusCode ?
                                                    customStatusOptions.find(({value}) => value === space.reservationStatusCode)?.label??'-'
                                                :'-'}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='text-truncate' title={space.fromDate ? moment(space.fromDate).format('YYYY-MM-DD HH:mm') : null}>
                                                {space.fromDate ?
                                                    moment(space.fromDate).format('YYYY-MM-DD HH:mm')
                                                :'-'}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='text-truncate' title={space.toDate ? moment(space.toDate).format('YYYY-MM-DD HH:mm') : null}>
                                                {space.toDate ?
                                                    moment(space.toDate).format('YYYY-MM-DD HH:mm')
                                                :'-'}
                                            </div>
                                        </Col>
                                        <Col className="col-1">
                                            <div className='text-truncate' title={(space.adults??0) + (space.children1??0) + (space.children2??0) + (space.children3??0)}>
                                                {(space.adults??0) + (space.children1??0) + (space.children2??0) + (space.children3??0)}
                                            </div>
                                        </Col>
                                        <Col className="col-1">
                                            {(() => {
                                                const numberOfDays = moment(space.toDate).add(-1, 'minute').diff(moment(space.fromDate), 'day') + 1;
                                                console.log(numberOfDays)
                                                const total = (space.dailyPrice??0) * numberOfDays;
                                                return (
                                                    <div className='text-truncate' title={total}>
                                                        <FormatAmountNumber value={total}/>
                                                    </div>
                                                );
                                            })()}
                                        </Col>
                                    </Row>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                :
                    <div className='w-100 d-flex align-items-center justify-content-center' style={{ minHeight: '100px' }}>
                        <FormattedMessage id="SalesProcess.NoSpaceReservationsConfigured"/>
                    </div>
                }
            </div>
        );
    }
}

export default SpaceList;
