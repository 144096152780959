import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Input } from 'reactstrap';
import { FormsWithTitleSelect, ReviewProFormsSelect } from '../Common/MarketingFunctions';
import { RenderTemplateSelection } from '../Common/CommunicationFunctions';
import { getLicenseModules } from '../../Base/ReferenceDataFunctions';
import { CommonTimePicker } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import { SingleDatePicker } from "react-dates";
import BlockUi from 'react-block-ui';
import moment from 'moment';

class CustomStep2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            surveyProviderOptions: []
        };
    }

    componentDidMount() {
        const { hasProfileNowModule, hasReviewProModule, hasTypeFormModule, intl } = this.props;
        const { surveyProviderOptions } = this.state;
        

        if (hasReviewProModule) {
            surveyProviderOptions.push(getLicenseModules(intl)[3].options[0]);
        }
        if (hasProfileNowModule) {
            surveyProviderOptions.push(getLicenseModules(intl)[7].options[0]);
        }
        if (hasTypeFormModule) {
            surveyProviderOptions.push(getLicenseModules(intl)[7].options[1]);
        }

        this.setState({ surveyProviderOptions });
    }
    
    handleTemplates = (templateId, countries, data) => {
        const { campaign } = this.props;
        campaign.templates = campaign.templates ? campaign.templates : [];
        const idx = campaign.templates.findIndex(el => el === data);

        if (idx !== -1) {
            if (templateId) {
                campaign.templates[idx].templateId = templateId;
            }

            if (countries !== undefined) {
                campaign.templates[idx].countries = countries;
            }
        }

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    handleMultiTemplates = (templateId) => {
        const { campaign } = this.props;
        campaign.templates = campaign.templates ? campaign.templates : [];
        const idx = campaign.templates.findIndex(el => el.templateId.toString() === templateId.toString());

        if (idx === -1) {
            campaign.templates.push({ templateId: templateId });
        }
        else {
            campaign.templates.splice(idx, 1);
        }

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    addTab = () => {
        const { campaign } = this.props;
        campaign.templates = campaign.templates ? campaign.templates : [];
        campaign.templates.push({ isDefault: false });

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    removeTab = (data) => {
        const { campaign } = this.props;

        campaign.templates = campaign.templates.filter(el => el !== data);

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    handleDefaultTemplate = (templateId) => {
        const { campaign } = this.props;
        campaign.templates = campaign.templates ? campaign.templates : [];

        const index = campaign.templates.findIndex(el => el.isDefault === true);

        if (index !== -1) {
            campaign.templates[index].templateId = templateId;
        }
        else {
            campaign.templates.push({ templateId: templateId, isDefault: true, countries: [] });
        }

        const e = { target: { name: 'templates', value: campaign.templates } };
        this.props.handleChange(e);
    }

    handleTimePicker = (combo, name) => {
        const { campaign } = this.props;
        const time = campaign.scheduleDay ? moment(campaign.scheduleDay) : moment();

        if (combo && combo.value && name === 'min'){
            time.set('minute', combo.value);
        }
        else{
            time.set('hour', combo.value);
        }

        const e = { target: { name: 'scheduleDay', value: time.format("YYYY-MM-DD HH:mm") }};

        this.props.handleChange(e);
    }

    handleScheduleDay = (date) => {
        const scheduleDay = date;
        if (scheduleDay) {
            const isToday = moment(scheduleDay).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
            const currentHours = isToday ? moment().add(1, 'hours').format("HH") : '00';
            scheduleDay.set('hour', currentHours);
            
            scheduleDay.set('minute', '00');
        }

        const e = { target: { name: 'scheduleDay', value: scheduleDay } };

        this.props.handleChange(e);
    }

    render() {
        const { campaign, handleChange, handleSelect, handleSurveySelect, handleText } = this.props;
        const { surveyProviderOptions } = this.state;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Row className="mb-2">
                    <Col>
                        <h5><FormattedMessage id="Campaigns.ScheduleDay" /></h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2} >
                        <SingleDatePicker
                            id="ScheduleDay"
                            date={campaign.scheduleDay ? moment(campaign.scheduleDay) : null}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            small={true}
                            numberOfMonths={1}
                            onDateChange={this.handleScheduleDay}
                            showDefaultInputIcon={true}
                            required
                        />
                    </Col>
                    <Col className='col-2'>
                        <CommonTimePicker
                            handleTimePicker={this.handleTimePicker}
                            time={campaign.scheduleDay}
                            isToday={moment(campaign.scheduleDay).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")}
                            currentHours={moment().add(1, 'hours').format("HH")}
                        />
                    </Col>
                </Row>


                {
                    campaign.marketingType === 'CustomInquiry' ?
                        campaign.surveyProvider === "ReviewPro" ?
                            <div>
                                <Row className="mt-2">
                                    <Col sm={2}>
                                        <h5>
                                            <FormattedMessage id="Campaigns.Provider" />
                                        </h5>
                                    </Col>
                                    <Col sm={4}>
                                        <h5>
                                            <FormattedMessage id="Campaigns.Form" />
                                        </h5>
                                    </Col>
                                    <Col sm={6}>
                                        <h5>
                                            <FormattedMessage id="Campaigns.PmsId" />
                                        </h5>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm={2}>
                                        <CustomSelect options={surveyProviderOptions} onChange={handleSurveySelect.bind(this, 'surveyProvider')} value={surveyProviderOptions.find(opt => opt.value === campaign.surveyProvider)} />
                                    </Col>
                                    <Col sm={4}>
                                        <ReviewProFormsSelect
                                            id="ReviewProFormsSelectReviewPro"
                                            key="ReviewProFormsSelectReviewPro"
                                            onChangeFunc={handleSelect.bind(this, 'surveyId')}
                                            value={campaign.surveyId}
                                            url={`/api/gms/ReviewPro/v1/Surveys`}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Input type="text" id="propertyId" name="propertyId" value={campaign.propertyId} onChange={handleText} />
                                    </Col>
                                </Row>
                            </div>
                            :
                            campaign.surveyProvider === "ProfileNowForms" ?
                                <div>
                                    <Row className="mt-2">
                                        <Col sm={2}>
                                            <h5>
                                                <FormattedMessage id="Campaigns.Provider" />
                                            </h5>
                                        </Col>
                                        <Col sm={10}>
                                            <h5>
                                                <FormattedMessage id="Campaigns.Form" />
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col sm={2}>
                                            <CustomSelect options={surveyProviderOptions} onChange={handleSurveySelect.bind(this, 'surveyProvider')} value={surveyProviderOptions.find(opt => opt.value === campaign.surveyProvider)} />
                                        </Col>
                                        <Col sm={10}>
                                            <ReviewProFormsSelect
                                                id="ReviewProFormsSelectProfileNow"
                                                key="ReviewProFormsSelectProfileNow"
                                                onChangeFunc={handleSelect.bind(this, 'surveyId')}
                                                value={campaign.surveyId}
                                                url={`/api/gms/Survey/v1/survey?hotelGroup=${global.hotelGroupId}&all=true`}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                :
                                campaign.surveyProvider === "Typeform" ?
                                    <div>
                                        <Row className="mt-2">
                                            <Col sm={2}>
                                                <h5>
                                                    <FormattedMessage id="Campaigns.Provider" />
                                                </h5>
                                            </Col>
                                            <Col sm={10}>
                                                <h5>
                                                    <FormattedMessage id="Campaigns.Form" />
                                                </h5>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col sm={2}>
                                                <CustomSelect options={surveyProviderOptions} onChange={handleSurveySelect.bind(this, 'surveyProvider')} value={surveyProviderOptions.find(opt => opt.value === campaign.surveyProvider)} />
                                            </Col>
                                            <Col sm={10}>
                                                <FormsWithTitleSelect
                                                    id="FormsWithTitleSelectTypeForm"
                                                    key="FormsWithTitleSelectTypeForm"
                                                    onChangeFunc={handleSelect.bind(this, 'surveyId')}
                                                    value={campaign.surveyId}
                                                    url={`/api/gms/TypeForm/Forms?page=1&pageSize=200`}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    <div>
                                        <Row className="mt-2">
                                            <Col sm={2}>
                                                <h5>
                                                    <FormattedMessage id="Campaigns.Provider" />
                                                </h5>
                                            </Col>
                                            <Col sm={10}>
                                                <h5>
                                                    <FormattedMessage id="Campaigns.Form" />
                                                </h5>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col sm={2}>
                                                <CustomSelect options={surveyProviderOptions} onChange={handleSurveySelect.bind(this, 'surveyProvider')} value={surveyProviderOptions.find(opt => opt.value === campaign.surveyProvider)} />
                                            </Col>
                                            <Col sm={10}>
                                                <CustomSelect options={[]} />
                                            </Col>
                                        </Row>
                                    </div>
                        :
                        ''
                }
                
                
                {this.props.hasEgoi || this.props.hasSendGrid || this.props.hasTwillio ?
                    <RenderTemplateSelection
                        hasEgoi={this.props.hasEgoi}
                        hasSendGrid={this.props.hasSendGrid}
                        hasTwillio={this.props.hasTwillio}
                        campaignTemplates={campaign?.templates}
                        tabs={this.state.tabs}
                        handleTemplates={this.handleTemplates}
                        addTab={this.addTab}
                        removeTab={this.removeTab}
                        handleDefaultTemplate={this.handleDefaultTemplate}
                        senderChannel={this.props.campaign.senderChannel}

                        //MultiSelection Props
                        isMultiSelection={campaign.marketingType === 'CustomAB'}
                        handleChange={handleChange}
                        sampleSize={campaign.sampleSize}
                        handleMultiTemplates={this.handleMultiTemplates}
                    />
                : '' }
            </BlockUi>
        );
    }
}
export default injectIntl(CustomStep2)