import React, { Component } from 'react'
import { Row, Col, Button, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import ReactHtmlParser from 'react-html-parser';
import RenderHtmlAsImage from '../Base/RenderHtmlAsImage';
import { handleNotification } from '../Base/Notification';
import { getAPI, postAPI } from '../Base/API';

class SalesProcessEditTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.templateRef = React.createRef();
        this.state = {
            block: false,
            formOptions: [],
            error: null,
            dealDetail: {
                name: 'Deal Name',
                mainContact: {
                    name: 'John Smith'
                },
                hotelInfo: [
                    {
                        name: 'EpicProfileNow S.A.'
                    }
                ],
                proposalData: {
                    startDate: '22/08/2024',
                    endDate: '28/08/2024',
                    numberOfSpaces: '8',
                    adults: '29',
                    children: '0',
                    amountBeforeTax: '9508.00',
                    taxToPay: '1200.00',
                    amountAfterTax: '10708.00',
                    rooms: {
                        numberOfRooms: '17',
                        RoomList: [
                            {
                                categoryCode: 'DTWN',
                                checkin: '25/08/2024',
                                rooms: '1',
                                unitPrice: '600.00',
                                amountBeforeTax: '500.00',
                                taxToPay: '100.00',
                                amountAfterTax: '600.00'
                            },
                            {
                                categoryCode: 'DDBL',
                                checkin: '25/08/2024',
                                rooms: '1',
                                unitPrice: '600.00',
                                amountBeforeTax: '500.00',
                                taxToPay: '100.00',
                                amountAfterTax: '600.00'
                            },
                            {
                                categoryCode: 'DDBL',
                                checkin: '25/08/2024',
                                rooms: '1',
                                unitPrice: '600.00',
                                amountBeforeTax: '500.00',
                                taxToPay: '100.00',
                                amountAfterTax: '600.00'
                            },
                            {
                                categoryCode: 'DTWN',
                                checkin: '25/08/2024',
                                rooms: '1',
                                unitPrice: '600.00',
                                amountBeforeTax: '500.00',
                                taxToPay: '100.00',
                                amountAfterTax: '600.00'
                            },
                            {
                                categoryCode: 'DDBL',
                                checkin: '25/08/2024',
                                rooms: '1',
                                unitPrice: '600.00',
                                amountBeforeTax: '500.00',
                                taxToPay: '100.00',
                                amountAfterTax: '600.00'
                            },
                        ]
                    },
                    events: {
                        eventList: [
                            {
                                eventName: '(G2517) GRP SCENIC LIS250824.1',
                                startDate: '25/08/2024',
                                quantity: '1',
                                unitPrice: '1508.00',
                                amountBeforeTax: '1300.84',
                                taxToPay: '207.16',
                                amountAfterTax: '1508.00'
                            }
                        ]
                    },
                    equipment: {
                        equipmentList: [
                            {
                                chargeDescription: 'Rest Allora Comidas 13%',
                                startDate: '-',
                                quantity: '1',
                                unitPrice: '20.00',
                                amountBeforeTax: '15.00',
                                taxToPay: '5.00',
                                amountAfterTax: '20.00'
                            },
                            {
                                chargeDescription: 'Rest Allora Bebidas 13%',
                                startDate: '-',
                                quantity: '3',
                                unitPrice: '30.00',
                                amountBeforeTax: '78.00',
                                taxToPay: '12.00',
                                amountAfterTax: '90.00'
                            },
                            {
                                chargeDescription: 'Rest Allora Bebidas 23%',
                                startDate: '-',
                                quantity: '1',
                                unitPrice: '12.00',
                                amountBeforeTax: '10.00',
                                taxToPay: '2.00',
                                amountAfterTax: '12.00'
                            },
                        ]
                    }
                }
            }
        };
    }

    generateDefaultTemplate = () => {
        const { dealDetail, previewTemplate } = this.state;
        const { configs, selectedTemplate } = this.props;

        var exportedHTML = JSON.stringify(previewTemplate.find(x => !x.languageCode)?.exportedHTML);

        if (exportedHTML) {
            var deal = JSON.stringify(dealDetail)
            const config = JSON.stringify(configs?.find(c => c.id === selectedTemplate.configIds[0]));
            var payload = {
                html: exportedHTML,
                config,
                dealDetail: deal
            }

            this.setState({ block: true }, () => {
                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data && data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        }

                        this.setState({ html: data, block: false });
                        return;
                    }
                    else this.setState({ error: errorMessage, block: false });

                }, `/api/gms/SalesProcess/v1/communication/generateDefaultTemplate`, payload)
            });
        }

    }

    getMasterTemplateHtml = (id) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const template = data.data;

                this.setState({ previewTemplate: template }, template ? this.generateDefaultTemplate : '');
                
                return;
            }
            else this.setState({ error: errorMessage });
        }, `/api/gms/SalesProcess/v1/communication/templates?masterTemplateId=${id}`);
    }

    prt() {
        var divContents = document.getElementById("printThis").innerHTML;
        var a = window.open('', 'PRINT');
        a.document.write(divContents);
        a.document.close();

        a.onafterprint = a.close;


        var is_chrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

        a.onload = function () { // necessary if the div contain images
            a.focus();
            a.print();
            a.close();
        };
    }

    componentDidMount() {
        this.getMasterTemplateHtml(this.props.selectedTemplate?.id)
    }

    render() {
        const { modal, toggleModal, selectedTemplate, configs } = this.props;
        const { html, block, error } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xxl" style={{ 'maxWidth': '90vw' }} >
                <BlankCard style={{ maxHeight: '85vh' }} block={block} error={error}>
                    <Form onSubmit={(e) => this.props.updateMasterTemplate(e)}>
                        <Row>
                            <Col>
                                <h4>
                                    <i className="fas fa-edit mr-2" />
                                    {selectedTemplate?.name}
                                </h4>
                            </Col>
                            <Col className="text-right pr-0">
                                <Button className="btn-sm btn-host mr-3" onClick={this.prt.bind(this)}>
                                    <i className="fas fa-print" />
                                </Button>
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-5 mt-4">
                                <Row className="mb-3">
                                    <Col>
                                        <b><FormattedMessage id="SalesProcess.AssociatedConfigs" /></b>
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn-host btn-sm" disabled={selectedTemplate.configIds?.some((x) => x == '') } onClick={() => this.props.addConfig('')}>
                                            <i className="fas fa-plus " />
                                        </Button>
                                    </Col>
                                </Row>
                                {selectedTemplate.configIds?.map((id, key) => {
                                    const config = configs.find(c => c.id === id);
                                    return (
                                        <Row>
                                            <Col sm={9} className="mb-3">
                                                <CustomSelect
                                                    placeholder=""
                                                    options={configs.map(c => ({ label: c.name, value: c.id, disabled: selectedTemplate.configIds.some((x) => x == c.id) }))}
                                                    value={config ? { value: config.id, label: config.name } : ''}
                                                    onChange={(combo) => this.props.handleListElement(key, combo ? combo.value : null)}
                                                    isClearable
                                                    isSearchable
                                                    required
                                                />
                                            </Col>
                                            <Col sm={1} className="text-right mt-2 pr-4">
                                                <i className="fas fa-trash-alt text-danger pointer" onClick={_ => this.props.handleListElement(key, null, true)} />
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                            <Col className="text-center border p-0"  style={{ maxHeight: "75vh", minHeight: "75vh"}}>                            
                                {html ?
                                    <div id="printThis" style={{ width: "100%", height: "100%" }}>
                                        <iframe
                                            id={`myIframe`}
                                            title="Meu iFrame"
                                            srcDoc={html}
                                            height="100%"
                                            width="100%"
                                            ref={this.templateRef}
                                            style={{ border: "none" }}
                                            onLoad={() => {
                                                const iframe = document.getElementById('myIframe');
                                                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                                                iframeDoc.documentElement.classList.add('veryCoolScrollBar');

                                                const styleElement = iframeDoc.createElement('style');
                                                styleElement.textContent = `
                                                .veryCoolScrollBar::-webkit-scrollbar {
                                                    height: 1px;
                                                    width: 4px;
                                                }

                                                .veryCoolScrollBar::-webkit-scrollbar-track {
                                                    background: rgb(241, 241, 241);
                                                    border-radius: 10px;
                                                }

                                                .veryCoolScrollBar::-webkit-scrollbar-thumb {
                                                    border-radius: 10px;
                                                    background: rgb(193, 193, 193);
                                                }
                                                `;
                                                iframeDoc.head.appendChild(styleElement);
                                            }}
                                        />
                                    </div>
                                    :
                                    <p className="text-muted pt-4"><FormattedMessage id="SalesProcess.NoAssociatedConfigTemplate" /></p>
                                }
                            </Col>
                        </Row>
                    </Form>
                </BlankCard>
            </StyledModal>
        )
    }
}

export default injectIntl(SalesProcessEditTemplateModal);