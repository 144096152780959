import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Input, Modal, Row } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import { ErrorAlert } from '../Base/ErrorAlert';
import microsoft365 from '../../img/Microsoft365.png';
import { checkSalesProcessPermission, getCurrentUser, getUsersRoleType } from './SalesProcessFunctions';
import IntlTelInput from 'react-intl-tel-input';
import { SelectHotel } from '../Base/CommonUIComponents';
import 'react-intl-tel-input/dist/main.css';

class SalesProcessUserModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            phoneNumber: this.props.user?.phoneNumber,
        };
    }

    onPhoneNumberChange = (isValid, value, countryData, fullNumber) => {
        const subscriptionName = document.getElementsByClassName('tel-input');
        subscriptionName[0].setCustomValidity("")
        this.setState({
            phoneNumber: fullNumber,
            isValid
        }, () => this.props.handleUserChange({ target: { name: 'phoneNumber', value: fullNumber } }));
    };

    createUser = (e, phoneNumber, validPhone) => {
        e.preventDefault();

        const subscriptionName = document.getElementsByClassName('tel-input');

        if (!validPhone && phoneNumber) {
            subscriptionName[0].setCustomValidity(this.props.intl.formatMessage({ id: "SalesProcess.InvalidPhoneNumber" }));
        }
        else {
            subscriptionName[0].setCustomValidity("");
        }

        if (this.form.current.reportValidity()) {
            this.props.createUser(e);
        }
    }

    render() {
        const { toggleModal, user, handleUserChange, handleComboChange, adalUsersOptions, blockAdal, errorModal, logInOutUserExternal, hasOffice365, salesUsers, blockModal, handleHotel, intl } = this.props;
        const { phoneNumber, isValid } = this.state;
        
        return (
            <Modal isOpen={true} toggle={toggleModal}>
                <ErrorAlert error={errorModal}/>
                <BlockUi tag="div" blocking={blockModal}>
                    <Button onClick={toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"/>
                    </Button>
                    <Card className='border-0 p-4 shadow'>
                        <form ref={this.form}  onSubmit={(e) => this.createUser(e, phoneNumber, isValid)}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <h5><FormattedMessage id="SalesDeal.CreateUser" /></h5>
                                </div>
                                <div>
                                    {user.id && user.value ?
                                        <Button className="btn btn-host btn-sm mr-2 bg-danger border-danger" type="button" onClick={() => this.props.toggleDeleteModal(user)}>
                                            <i className="fas fa-trash" />
                                        </Button>
                                    :''}
                                    <Button className="btn btn-host btn-sm" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <Row>
                                    <Col className='col-12'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.PNUser" />
                                        </div>
                                        <div className='big-combo'>
                                            <CustomSelect
                                                isLoading={blockAdal}
                                                value={adalUsersOptions.find(({ value }) => value === user.userId)}
                                                options={adalUsersOptions}
                                                placeholder=""
                                                required={true}
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={(combo) => handleComboChange(combo, 'userId')}
                                                filterOption={(option, inputValue) => {
                                                    const { email, value } = option.data;
                                                    return (
                                                        value?.toString().includes(inputValue) ||
                                                        email?.toLowerCase().includes(inputValue?.toLowerCase())
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.FirstName" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.firstName}
                                                name="firstName"
                                                required={true}
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.LastName" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.lastName}
                                                name="lastName"
                                                required={true}
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.Email" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.email}
                                                name="email"
                                                required={true}
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.PhoneNumber" />
                                        </div>
                                        <div>
                                            <IntlTelInput
                                                preferredCountries={['pt']}
                                                onPhoneNumberChange={this.onPhoneNumberChange}
                                                value={phoneNumber}
                                                containerClassName="intl-tel-input w-100"
                                                inputClassName="form-control tel-input telPadding"
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.Role" />
                                        </div>
                                        <div>
                                            <CustomSelect
                                                value={getUsersRoleType(this.props.intl).find(({ value }) => value === user.role)}
                                                options={getUsersRoleType(this.props.intl)}
                                                placeholder=""
                                                required={true}
                                                isClearable={false}
                                                onChange={(combo) => handleComboChange(combo, 'role')}
                                                isDisabled={!checkSalesProcessPermission("SalesDealUsers:manageAll", getCurrentUser()?.role)}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.Manager" />
                                        </div>
                                        <div>
                                            <CustomSelect
                                                value={salesUsers && salesUsers.find(({ value }) => value === user.managerId)}
                                                options = {salesUsers && salesUsers.filter(({ value, managerId, userId }) => {
                                                    const isCreating = !user.id && !user.value;
                                                    const isNotSelf = value !== user.id;

                                                    if (isCreating) {
                                                        return isNotSelf && userId !== user.userId;
                                                    }
                                                    const isNotOwnManager = managerId !== user.id && managerId !== user.userId;
                                                    
                                                    return isNotSelf && isNotOwnManager;
                                                })}
                                                placeholder=""
                                                required={false}
                                                isClearable={true}
                                                isDisabled={user.userId === null || user.userId === undefined}
                                                onChange={(combo) => handleComboChange(combo, 'managerId')}
                                            />
                                        </div>
                                    </Col>
                                    <Col className={`col-6 mt-3`}>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.PMSUserCode" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.pmsUserCode}
                                                name="pmsUserCode"
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className={`col-6 mt-3`}>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.Notes" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.notes}
                                                name="notes"
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className={`${hasOffice365 && global.userId === user.userId ? 'col-10' : 'col-12'} mt-3`}>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.Hotels" />
                                        </div>
                                        <div>
                                            <SelectHotel
                                                isSearchable
                                                onChangeFunc={handleHotel}
                                                name="hotelIds"
                                                placeholder={''}
                                                value={user.hotelIds || []}
                                                params='?onlyFromHotelGroup=true'
                                                isMulti={true}
                                                isDisabled={!checkSalesProcessPermission("SalesDealUsers:manageAll", getCurrentUser()?.role)}
                                            />
                                        </div>
                                    </Col>
                                    {hasOffice365 && global.userId === user.userId ?
                                        <Col className='col-2 mt-3 d-flex align-items-end justify-content-end'>
                                            <Button className='btn btn-sm btn-host btn-secondary d-flex align-items-center justify-content-center officeButton' type='button' onClick={logInOutUserExternal}>
                                                <div className='mr-1'>
                                                    {user.hasExternalAccess ?
                                                        <FormattedMessage id="SalesProcess.DisconnectOffice365"/>
                                                    :
                                                        <FormattedMessage id="SalesProcess.ConnectOffice365"/>
                                                    }
                                                </div>
                                                <img alt="" src={microsoft365} height="22px"/>
                                            </Button>
                                        </Col>
                                    :''}
                                </Row>
                            </div>
                        </form>
                    </Card>
                </BlockUi>
            </Modal>
        );
    }
}

export default injectIntl(SalesProcessUserModal);
