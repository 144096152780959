import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, Form, Input } from 'reactstrap';
import { StyledModal } from '../../Base/CommonUIComponents';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { SelectCustomType } from '../SalesProcessFunctions';

import BlockUi from 'react-block-ui';
import SalesDealList from '../SalesDealList';

const AssociateDeal = ({ block, error, toggleModal, modal, salesUsers, salesPipelines, salesSources, salesSourcesTypes, dealList, currentDeal, getDealList, associateDeal }) => {
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [dealRelationType, setDealRelationType] = useState(null);

    const selectDeal = (deal) => {
        setSelectedDeal(deal);
    }

    const handleChangeRelationType = (combo) => {
        setDealRelationType(combo ? combo.value : null);
    }

    return ( 
        <StyledModal toggleModal={toggleModal} modal={modal} size="xl" style={{ minWidth: 0, maxWidth: '85%', width: '85%' }}>
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <div className='p-1'>
                    <Form onSubmit={(e) => associateDeal(e, currentDeal?.id, selectedDeal?.id, dealRelationType)}>
                        <Row className="mb-3 mt-2 justify-content-bewtween align-items-center">
                            <Col>
                                <h5><FormattedMessage id="SalesProcess.AssociateDeal" /></h5>
                            </Col>
                            <Col className='text-right'>
                                <Button className="btn-sm btn-host btn btn-secondary" type="submit" disabled={selectedDeal === null || selectedDeal === undefined}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <Row className="mb-4 mt-2 align-items-center">
                            <Col className='d-flex flex-column mb-3 col-3'>
                                <p className='m-0 pb-2'><FormattedMessage id="SalesProcess.SelectedDeal" /></p>
                                <div className='d-flex align-items-center'>
                                    <Input
                                        type="text"
                                        name="selectedDeal"
                                        placeholder=""
                                        value={selectedDeal?.name || '-'}
                                        required
                                        disabled
                                    />
                                    {selectedDeal ?
                                        <i onClick={() => selectDeal(null)} className="fas fa-trash-alt text-danger cursor-pointer ml-2" />
                                    : ''}
                                </div>
                            </Col>
                            <Col className='d-flex flex-column mb-3 col-3'>
                                <p className='m-0 pb-2'><FormattedMessage id="SalesProcess.SelectDealAssociationType" /></p>
                                <SelectCustomType 
                                    name="DealRelationType"
                                    onChangeFunc={(combo) => handleChangeRelationType(combo)}
                                    required={true}
                                    value={dealRelationType}
                                    placeholder=""
                                    isDisabled={false}
                                    isClearable={true}
                                    type={'DealRelationType'}
                                />
                            </Col>
                        </Row>
                        <hr />
                    </Form>

                    <div className="verticalScroll" style={{ height: '55vh', overflowX: 'hidden' }}>
                        <SalesDealList
                            selectDealDetail={selectDeal}
                            icon={'fas fa-briefcase'}
                            salesUsers={salesUsers}
                            salesPipelines={salesPipelines}
                            salesSources={salesSources}
                            salesSourcesTypes={salesSourcesTypes}
                            dealList={dealList}
                            getDealList={getDealList}
                            fromAssociateDeal={true}
                        />
                    </div>
                </div>

            </BlockUi>
        </StyledModal>
    )
};

export default injectIntl(AssociateDeal);